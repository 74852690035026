import { _ } from "@sablier/v2-mixins";
import { createWalletClient, getAddress, http } from "viem";
import { privateKeyToAccount } from "viem/accounts";
import { sepolia } from "viem/chains";
import { createConnector } from "wagmi";
import { mock } from "wagmi/connectors";
import type { IEnvironmentVariable, IWagmiAddress } from "@sablier/v2-types";

export const isHostCypress = process.env.NEXT_PUBLIC_SABLIER_ENV === "test";

function account() {
  const secret: IEnvironmentVariable = process.env.NEXT_PUBLIC_TEST_PRIVATE_KEY;

  if (_.isNilOrEmptyString(secret)) {
    throw new Error(
      "Missing environment variable: NEXT_PUBLIC_TEST_PRIVATE_KEY",
    );
  }

  const key = secret.startsWith("0x") ? secret : `0x${secret}`;
  return privateKeyToAccount(key as IWagmiAddress);
}

async function getClient() {
  return createWalletClient({
    key: "mock",
    name: "mock",
    account: account(),
    chain: sepolia,
    transport: http(),
  });
}

function getConnector() {
  const address = account()?.address;

  const base = mock({
    accounts: [getAddress(address)],
    features: {
      reconnect: true,
    },
  });

  const client = getClient();

  const custom = createConnector((config) => ({
    ...base(config),
    /**
     *  Should configure the walletClient here through getClient() but
     * the implementation of the mock connects doesn't seem to allow it.
     */
    getClient: async () => await client,
  }));

  return custom;
}

export { getClient, getConnector };
