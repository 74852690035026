import { tags } from "@sablier/v2-constants";
import { useChainData } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { IAddress } from "@sablier/v2-types";
import { Base } from "../../system";

interface Props {
  className?: string;
  chainId?: number;
  address?: IAddress;
}

function Token({ className, chainId, address }: Props) {
  const { chain } = useChainData(_.toNumber(chainId));
  const { t } = useT();

  return (
    <Base
      className={className}
      cy={tags.warningToken}
      description={t("warnings.token.description", {
        name: chain?.name,
        address,
      })}
      label={{ value: t("warnings.token.title") }}
    />
  );
}

export default Token;
