import { useCallback, useEffect, useMemo } from "react";
import { useTokenAllowance as useTokenAllowanceBase } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import type { IFlags, IToken } from "@sablier/v2-types";
import useAccount from "./useAccount";
import useToast from "./useToast";

interface Props {
  allowance: string | undefined;
  spender: string | undefined;
  token: IToken | undefined;
}

export default function useTokenAllowance({
  allowance,
  spender,
  token,
}: Props) {
  const { address, chainId, isHostSafe, library, signer } = useAccount();
  const toast = useToast();
  const { t } = useT();

  const flags: IFlags = useMemo(() => ({ isHostSafe }), [isHostSafe]);

  const onSuccess = useCallback(() => {
    toast.add({
      id: "allowance",
      message: t("machines.allowance.success", { symbol: token?.symbol }),
      type: "success",
    });
  }, [toast, token, t]);

  const result = useTokenAllowanceBase({
    allowance,
    chainId,
    flags,
    library,
    owner: address,
    onSuccess,
    signer,
    spender,
    token,
  });

  useEffect(() => {
    if (result.isApproving) {
      toast.add({
        duration: Infinity,
        id: "allowance",
        message: t("machines.allowance.pending", { symbol: token?.symbol }),
        type: "loading",
      });
    }
  }, [result.isApproving, toast, token, t]);

  useEffect(() => {
    if (result.error) {
      toast.add({
        id: "allowance",
        message: t("machines.allowance.fail", { symbol: token?.symbol }),
        type: "fail",
      });
    }
  }, [result.isApproving, result.error, toast, token, t]);

  return result;
}
