import { _ } from "@sablier/v2-mixins";
import mixpanel from "mixpanel-browser";
import type { EventKey, IEvents } from "./events";
import { events } from "./events";

function init() {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ?? "", {
    api_host: "https://api-eu.mixpanel.com",
    ip: false,
    autotrack: true,
  });
}

function enable() {
  mixpanel.opt_in_tracking();
}

function disable() {
  mixpanel.opt_out_tracking();
}

function log(entry: EventKey | ((all: IEvents) => string)) {
  if (process.env.NEXT_PUBLIC_SABLIER_ENV === "production") {
    if (_.isFunction(entry)) {
      mixpanel.track(entry(events));
    } else {
      mixpanel.track(events[entry]);
    }
  }
}

function register(props: { [key: string]: unknown }) {
  if (process.env.NEXT_PUBLIC_SABLIER_ENV === "production") {
    mixpanel.register(props);
  }
}

function unregister(prop: string) {
  if (process.env.NEXT_PUBLIC_SABLIER_ENV === "production") {
    mixpanel.unregister(prop);
  }
}

const bundle = {
  init,
  disable,
  enable,
  log,
  register,
  unregister,
};

export default bundle;
