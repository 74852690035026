import { useMemo } from "react";
import { getTheme } from "@sablier/v2-themes";
import useWindowSize from "./useWindowSize";

const theme = getTheme();

export default function useWindowMediaQuery() {
  const { width } = useWindowSize();

  return useMemo(() => {
    return {
      maxXL: width <= parseInt(theme.sizes.deviceXL),
      maxLG: width <= parseInt(theme.sizes.deviceLG),
      maxMD: width <= parseInt(theme.sizes.deviceMD),
      maxSM: width <= parseInt(theme.sizes.deviceSM),
      maxXS: width <= parseInt(theme.sizes.deviceXS),
      maxXXS: width <= parseInt(theme.sizes.deviceXXS),

      minXL: width > parseInt(theme.sizes.deviceXL),
      minLG: width > parseInt(theme.sizes.deviceLG),
      minMD: width > parseInt(theme.sizes.deviceMD),
      minSM: width > parseInt(theme.sizes.deviceSM),
      minXS: width > parseInt(theme.sizes.deviceXS),
      minXXS: width > parseInt(theme.sizes.deviceXXS),
    };
  }, [width]);
}
