import { useMemo } from "react";
import styled from "styled-components";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Amount, Icon, Tooltip } from "@sablier/v2-components/molecules";
import { useFT, useT } from "@sablier/v2-locales";
import { BigNumber, _ } from "@sablier/v2-mixins";
import numeral from "numeral";
import type { ITokenDisplay } from "@sablier/v2-types";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 1);
    width: 100%;
  }
`;

const Left = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    row-gap: 8px;
  }
`;

const Right = styled.div`
  ${(props) => props.theme.styles.row}
`;

const Title = styled.div`
  & > p {
    ${(props) => props.theme.styles.textElement}
  }
`;

const Details = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    --size: 18px;
    --thickness: 1.8;
    gap: 4px;
    color: ${(props) => props.theme.colors.gray400};
    & > p {
      ${(props) => props.theme.styles.textParagraphMini}
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxXS} {
    ${(props) => props.theme.styles.column}
    & {
      align-items: flex-start;
      width: auto;
      margin: 0 auto;
    }
    ${Left} {
      ${(props) => props.theme.styles.column}
      & {
        gap: calc(${(props) => props.theme.sizes.edge} * 1);
        align-items: flex-start;
        padding-left: 0;
      }
    }
    ${Right} {
      padding-left: 2px;
    }
  }
`;

interface Props {
  amount?: string;
  token?: ITokenDisplay;
  subtitle?: string;
  title?: string;
}

function Total({ amount, token, subtitle: _subtitle, title: _title }: Props) {
  const { t } = useT();
  const title = useFT(_title, "words.total");
  const subtitle = useFT(_subtitle, "structs.excludingGas");

  const value = useMemo(() => {
    if (_.isNilOrEmptyString(amount)) {
      return undefined;
    }

    const formatted = new BigNumber(amount);
    if (
      formatted.isNaN() ||
      formatted.isLessThanOrEqualTo(new BigNumber(0.1).pow(6))
    ) {
      return _.toNumeralPrice(formatted.toFixed());
    }

    /**
     * Arguments for toFixed are intentionally omitted to
     * force an unrounded, normal notation as per numeral's docs.
     **/
    return numeral(formatted.toFixed()).format("0,0[.][000000000]");
  }, [amount]);

  return (
    <Wrapper>
      <Left>
        <Title>
          <p>{_.capitalize(title)}</p>
        </Title>
        <Tooltip value={t("descriptions.costs.free")}>
          <Details>
            <p>{_.capitalize(subtitle)}</p>
            <Icon value={InformationCircleIcon} />
          </Details>
        </Tooltip>
      </Left>
      <Right>
        <Amount
          empty={"dark400"}
          isFormatted
          isFallbackShown={false}
          size={[30, 14]}
          token={token}
          value={value}
        />
      </Right>
    </Wrapper>
  );
}

export default Total;
