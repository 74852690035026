import { useMemo } from "react";
import styled, { css } from "styled-components";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import TokenUnknown from "@sablier/v2-assets/tokens/custom-unknown-light.svg";
import { _ } from "@sablier/v2-mixins";
import { External } from "~/components/atoms";
import type { ThemeType } from "@sablier/v2-themes";
import type { IImage } from "@sablier/v2-types";
import type { CSSProperties, MouseEvent } from "react";
import Icon from "../Icon";

interface WrapperProps {
  ellipsis?: number | string;
  color?: keyof ThemeType["colors"];
  sizeIcon?: number | string;
  sizeTitle?: number | string;
  sizeDescription?: number | string;
}

const WrapperPartial = styled.div<WrapperProps>`
  ${(props) => props.theme.styles.row}
  & {
    column-gap: 12px;
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    row-gap: 6px;
  }
`;

const Title = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    & > p {
      ${(props) => props.theme.styles.textElement}
      & {
        line-height: 1;
      }
    }
  }
`;

const Description = styled(External)`
  ${(props) => props.theme.styles.row}
  & {
    color: ${(props) => props.theme.colors.gray400};
    column-gap: 3px;
    &:hover,
    &:active {
      text-decoration: underline;
    }
    & > p {
      ${(props) => props.theme.styles.textParagraphMini}
      & {
        margin: 0;
        color: ${(props) => props.theme.colors.gray400};
        line-height: 1;
      }
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${Title} > p {
    ${(props) =>
      props.color &&
      css`
        color: ${props.theme.colors[props.color] || props.color};
      `}

    ${(props) =>
      props.sizeTitle &&
      css`
        font-size: ${props.sizeTitle};
      `}
  }

  ${Description} > p {
    ${(props) =>
      props.color &&
      css`
        color: ${props.theme.colors[props.color] || props.color};
      `}

    ${(props) =>
      props.sizeDescription &&
      css`
        font-size: ${props.sizeDescription};
      `}
  }

  ${Title} > p, ${Description} > p {
    ${(props) =>
      props.ellipsis &&
      css`
        ${props.theme.styles.ellipsis}
        & {
          max-width: ${props.ellipsis};
        }
      `}
  }
`;

export interface Props {
  className?: string;
  color?: keyof ThemeType["colors"];
  description?: string;
  explorer?: string;
  /** Pixel value of when ellipsis should kick in for both title and description */
  ellipsis?: number;
  empty?: keyof ThemeType["colors"];
  image?: IImage;
  isFallbackShown?: boolean;
  isIconShown?: boolean;
  title?: string;
  /** Size of icon or size of [icon, title, description] */
  size?: number | [number, number, number];
  style?: CSSProperties;
}

function Bottom({
  description,
  explorer,
}: Pick<Props, "description" | "explorer">) {
  if (!_.isNilOrEmptyString(explorer)) {
    return (
      <Description
        to={explorer}
        onClick={(e: MouseEvent<HTMLElement>) => {
          e.stopPropagation();
        }}
      >
        <p>{description}</p>
        <Icon value={ArrowUpRightIcon} size={12} thickness={2.2} />
      </Description>
    );
  }

  return (
    <Description as={"div"}>
      <p>{description}</p>
    </Description>
  );
}

function TokenDeck({
  className,
  color,
  description,
  ellipsis,
  explorer,
  empty,
  image,
  isIconShown = true,
  isFallbackShown = false,
  title,
  size = 32,
  style,
}: Props) {
  const [sizeIcon, sizeTitle, sizeDescription] = useMemo(() => {
    if (_.isArray(size)) {
      return [
        _.toNumber(_.get(size, "0")),
        _.toNumber(_.get(size, "1")),
        _.toNumber(_.get(size, "2")),
      ];
    }
    return [_.toNumber(size), undefined, undefined];
  }, [size]);

  return (
    <Wrapper
      className={className}
      color={color}
      ellipsis={ellipsis && _.toSuffix(ellipsis, "px")}
      sizeIcon={sizeIcon && _.toSuffix(sizeIcon, "px")}
      sizeTitle={sizeTitle && _.toSuffix(sizeTitle, "pt")}
      sizeDescription={sizeDescription && _.toSuffix(sizeDescription, "pt")}
      style={style}
    >
      {isIconShown && (
        <Icon
          color={"dark150"}
          purpose={image || isFallbackShown ? "image" : "empty"}
          value={image || isFallbackShown ? image ?? TokenUnknown : empty}
          fallback={TokenUnknown}
          size={sizeIcon}
        />
      )}
      <Content>
        {title && (
          <Title>
            <p>{title}</p>
          </Title>
        )}
        {description && (
          <Bottom explorer={explorer} description={description} />
        )}
      </Content>
    </Wrapper>
  );
}

export default TokenDeck;
