import { EXPONENT_DECIMALS, StreamCategory } from "@sablier/v2-constants";
import { guards } from "@sablier/v2-machines";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Stream, Token } from "@sablier/v2-models";
import type { IDynamicCliffExponential } from "./config";
import type {
  IExtensionCertify,
  IExtensionCheck,
  IExtensionParamsSimulate,
  IPrecomputeParams,
  IPrecomputeResult,
} from "~/client/types";
import { EXPECTED_SEGMENTS, UNLOCK_DURATION } from "./config";

type IExtension = IDynamicCliffExponential;

export function certify({
  t,
  data,
  duration,
  start,
  end,
  field,
}: IExtensionCertify): string | undefined {
  const fields = _.omit(data as IExtension, ["purpose", "exponent"]);

  if (field === "cliffEnd") {
    return guards.validateMoment({
      t,
      purpose: "cliff",
      min: start,
      value: fields.cliffEnd.value,
      max: end,
    });
  }

  if (field === "cliffDuration") {
    return guards.validateDuration({
      t,
      purpose: "cliff",
      max: duration,
      min: "0",
      value: fields.cliffDuration.value,
    });
  }

  return undefined;
}

export function check({
  t,
  data,
  isLoadingIncluded = false,
  isWarningIncluded = false,
  timing,
}: IExtensionCheck): string | undefined {
  const fields = _.omit(data as IExtension, ["purpose", "exponent"]);

  const flags = guards.validateFormFlags({
    t,
    isWarningIncluded,
    isLoadingIncluded,
    value: fields,
  });

  if (!_.isNilOrEmptyString(flags)) {
    return flags;
  }

  const ids: (keyof typeof fields)[] =
    timing === "duration" ? ["cliffDuration"] : ["cliffEnd"];

  const required = guards.validateFormRequired({
    t,
    required: ids,
    value: fields,
  });

  if (!_.isNilOrEmptyString(required)) {
    return required;
  }

  return undefined;
}

export function identify(stream: Stream): boolean {
  return (
    stream.category === StreamCategory.LOCKUP_DYNAMIC &&
    stream.segments.length === EXPECTED_SEGMENTS &&
    stream.segments[0].amount.raw.isZero() &&
    new BigNumber(stream.segments[1].duration).isLessThanOrEqualTo(
      new BigNumber(UNLOCK_DURATION),
    ) &&
    !stream.segments[2].exponent.humanized.isEqualTo(new BigNumber(0))
  );
}

export function precomputeSingle({
  dependencies,
}: IPrecomputeParams<"single">): IPrecomputeResult {
  const { amount, token } = dependencies;

  return {
    amount: _.toValuePrepared({
      decimals: token!.decimals,
      humanized: amount,
    }),
  };
}

export function precomputeGroup({
  dependencies,
}: IPrecomputeParams<"group">): IPrecomputeResult {
  const { streams, token } = dependencies;

  return {
    amount: _.toValuePrepared({
      humanized: streams?.reduce(
        (p, c) => p.plus(new BigNumber(c.amount || 0)),
        new BigNumber(0),
      ),
      decimals: token?.decimals,
    }),
  };
}

export const precompute = { group: precomputeGroup, single: precomputeSingle };

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */
export function simulate(
  params: IExtensionParamsSimulate & { timing: "duration" },
): Stream;
export function simulate(
  params: IExtensionParamsSimulate & { timing: "range" },
): Stream;
export function simulate(params: IExtensionParamsSimulate): Stream;

export function simulate({
  dependencies,
  extras,
  timing,
}: IExtensionParamsSimulate): Stream {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const { purpose: _purpose, ...extended } = extras as IExtension;
  const {
    amount,
    cancelability,
    transferability,
    chainId,
    duration,
    end,
    start,
    token,
  } = dependencies;

  const deposit = _.toValuePrepared({
    humanized: amount,
    decimals: token?.decimals,
  });

  const streamToken = new Token({
    address: token!.address,
    chainId,
    decimals: token!.decimals,
    name: token!.name,
    symbol: token!.symbol,
  });

  const exponent = _.toValuePrepared({
    humanized: extended.exponent,
    decimals: EXPONENT_DECIMALS,
  });

  const defaultExp = _.toValuePrepared({
    humanized: "1",
    decimals: EXPONENT_DECIMALS,
  });

  const startTime =
    timing === "duration"
      ? _.toSeconds(new BigNumber(Date.now()).toString())
      : _.toSeconds(start);

  const endTime =
    timing === "duration"
      ? _.toSeconds(
          new BigNumber(Date.now()).plus(new BigNumber(duration!)).toString(),
        )
      : _.toSeconds(end);

  const cliffTime =
    timing === "duration"
      ? _.toSeconds(
          new BigNumber(Date.now())
            .plus(new BigNumber(extended.cliffDuration.value!))
            .toString(),
        )
      : _.toSeconds(extended.cliffEnd.value);

  const cliffD = new BigNumber(cliffTime).minus(new BigNumber(startTime));
  const totalD = new BigNumber(endTime).minus(new BigNumber(startTime));
  const cliffAmount = new BigNumber(deposit).times(cliffD).dividedBy(totalD);
  const remainingAmount = new BigNumber(deposit).minus(cliffAmount);
  const unlock = new BigNumber(UNLOCK_DURATION).dividedBy(1000);

  return new Stream(
    {
      ...Stream.base(),
      chainId,
      cancelable: cancelability!,
      transferable: transferability!,
      category: StreamCategory.LOCKUP_DYNAMIC,
      depositAmount: deposit,
      endTime,
      intactAmount: deposit,
      startTime,
      segments: [
        {
          id: "0",
          position: "0",
          amount: "0",
          milestone: "",
          endAmount: "0",
          endTime: cliffTime,
          startTime,
          startAmount: "0",
          exponent: defaultExp,
        },
        {
          id: "1",
          position: "1",
          amount: cliffAmount.toString(),
          milestone: "",
          endAmount: cliffAmount.toString(),
          endTime: new BigNumber(cliffTime).plus(unlock).toString(),
          startTime: cliffTime,
          startAmount: "0",
          exponent: defaultExp,
        },
        {
          id: "2",
          position: "2",
          amount: remainingAmount.toString(),
          milestone: "",
          endAmount: deposit,
          endTime,
          startTime: new BigNumber(cliffTime).plus(unlock).toString(),
          startAmount: cliffAmount.toString(),
          exponent,
        },
      ],
    },
    streamToken,
  );
}
