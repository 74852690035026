import { Input } from "@sablier/v2-components/organisms";
import {
  STEPPER_MAX_STEPS_GROUP,
  STEPPER_MAX_STEPS_SINGLE,
  STEPPER_MIN_STEPS,
} from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { useAirstreamFieldStepper, useGroupFieldStepper } from "./logic";

function AirstreamStepper() {
  const { steps, onBlur, onChange } = useAirstreamFieldStepper();
  const { t } = useT();

  return (
    <Input.AmountStepper
      id={steps.field.id}
      min={STEPPER_MIN_STEPS}
      max={STEPPER_MAX_STEPS_SINGLE}
      isEditable
      measure={"unlocks"}
      label={{ value: t("form.label.steps") }}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={t("form.placeholder.steps")}
      value={steps.field!.value}
    />
  );
}

function GroupStepper({ streamId }: { streamId: string }) {
  const { label, steps, onBlur, onChange } = useGroupFieldStepper(streamId);
  const { t } = useT();

  return (
    <Input.AmountStepper
      id={steps.field.id}
      min={STEPPER_MIN_STEPS}
      max={STEPPER_MAX_STEPS_GROUP}
      isEditable
      measure={t("words.unlocks")}
      label={label}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={t("form.placeholder.steps")}
      value={steps.field!.value}
    />
  );
}

const Fields = {
  AirstreamStepper,
  GroupStepper,
};

export default Fields;
