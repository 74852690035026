import { useEffect } from "react";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { Crisp } from "crisp-sdk-web";
import { PERMISSION_ID } from "../constants";
import { usePermissionsStore, usePermissionsStoreAccessor } from "./store";

function usePermissions() {
  const accessor = usePermissionsStoreAccessor();
  const { permissions, update } = usePermissionsStore();

  return {
    accessor,
    permissions,
    update,
  };
}

export function usePermissionCheck() {
  const { permissions } = usePermissionsStore();

  useEffect(() => {
    if (_.has(permissions, PERMISSION_ID.sentry)) {
      if (permissions[PERMISSION_ID.sentry] === true) {
        vendors.crash.enable();
      } else {
        vendors.crash.disable();
      }
    }
    if (_.has(permissions, PERMISSION_ID.crisp)) {
      if (permissions[PERMISSION_ID.crisp] === true) {
        if (!_.isNilOrEmptyString(process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID)) {
          Crisp.configure(process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID);
          Crisp.chat.show();
        }
      } else {
        if (Crisp.chat.isVisible()) {
          Crisp.chat.hide();
          Crisp.configure("");
        }
      }
    }
    if (_.has(permissions, PERMISSION_ID.mixpanel)) {
      if (permissions[PERMISSION_ID.mixpanel] === true) {
        vendors.track.enable();
      } else {
        vendors.track.disable();
      }
    }
  }, [permissions]);
}

export default usePermissions;
