import { safe } from "wagmi/connectors";

export const domains = [/app.safe.global$/, /safe.lightlink.io$/];

/** This will check that the app was loaded as part of an IFrame which most likely means it's a safe. */
export const isHostSafe =
  !(typeof window === "undefined") && window?.parent !== window;

export function getConnector() {
  return safe({
    allowedDomains: domains,
    debug: false,
    shimDisconnect: false,
  });
}
