import { chains } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { IChain } from "@sablier/v2-types/chain";
import * as library from "wagmi/chains";

type Item = {
  id: number;
  local: IChain;
  library: library.Chain;
};

const supported: [Item, ...Item[]] = [
  {
    id: chains.mainnet.chainId,
    local: chains.mainnet,
    library: library.mainnet,
  },
  {
    id: chains.arbitrum.chainId,
    local: chains.arbitrum,
    library: library.arbitrum,
  },
  {
    id: chains.avalanche.chainId,
    local: chains.avalanche,
    library: library.avalanche,
  },
  {
    id: chains.base.chainId,
    local: chains.base,
    library: library.base,
  },
  {
    id: chains.blast.chainId,
    local: chains.blast,
    library: library.blast,
  },
  {
    id: chains.bsc.chainId,
    local: chains.bsc,
    library: library.bsc,
  },
  {
    id: chains.gnosis.chainId,
    local: chains.gnosis,
    library: library.gnosis,
  },
  {
    id: chains.iotex.chainId,
    local: chains.iotex,
    library: library.iotex,
  },
  {
    id: chains.lightlink.chainId,
    local: chains.lightlink,
    library: library.lightlinkPhoenix,
  },
  {
    id: chains.linea.chainId,
    local: chains.linea,
    library: library.linea,
  },
  {
    id: chains.optimism.chainId,
    local: chains.optimism,
    library: library.optimism,
  },
  {
    id: chains.polygon.chainId,
    local: chains.polygon,
    library: library.polygon,
  },
  {
    id: chains.scroll.chainId,
    local: chains.scroll,
    library: library.scroll,
  },
  {
    id: chains.sepolia.chainId,
    local: chains.sepolia,
    library: library.sepolia,
  },
  {
    id: chains.zksync.chainId,
    local: chains.zksync,
    library: library.zkSync,
  },
  {
    id: chains.zksyncSepolia.chainId,
    local: chains.zksyncSepolia,
    library: library.zkSyncSepoliaTestnet,
  },
];

export default supported;
