import { useEffect, useMemo, useState } from "react";
import { DigitLimits, FAST_UI_POLLING } from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Stream } from "@sablier/v2-models";
import { useSessionPolling } from "./store/session";

export default function usePollingLight(stream?: Stream) {
  const { isPolling } = useSessionPolling();
  const [estimatedAmount, setEstimatedAmount] = useState(
    stream?.streamedAmountEstimate,
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (stream?.isAlive && isPolling) {
        setEstimatedAmount(
          stream?.findStreamedAmount(_.toString(Date.now()), true),
        );
      }
    }, FAST_UI_POLLING);

    return () => {
      clearInterval(interval);
    };
  }, [isPolling, stream]);

  const estimatedAmountPrepared = useMemo(() => {
    const base = estimatedAmount?.humanized || new BigNumber(0);

    if (base.isZero() || (stream && !stream.isAlive)) {
      return base;
    }

    const precision = DigitLimits.MANTISSA_PRECISION;
    const flag = base.times(10 ** precision).modulo(10);

    if (flag.integerValue().isZero() && !base.isZero()) {
      return base.plus(new BigNumber(1).dividedBy(10 ** precision));
    }

    return base;
  }, [stream, estimatedAmount]);

  return { estimated: estimatedAmountPrepared };
}
