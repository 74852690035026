import {
  DEFAULT_CLIFF_EXCESS,
  DEFAULT_END_DATE_EXCESS,
} from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import type { Translate } from "@sablier/v2-locales";
import type { IMilliseconds } from "@sablier/v2-types";
import policy from "./policy";

interface DateValidProps {
  t: Translate;
  purpose?: "cliff" | "date" | "datetoday";
  /** The guard will handle a default excess (~ 30 mins) such that durations aren't very close to the max */
  isExcess?: boolean;
  /** In strict mode, max and min will be used unreachable (< and > instead of <= and >= ) */
  isStrict?: boolean;
  /** Right constraint in milliseconds (e.g. an end date) */
  max?: IMilliseconds;
  /** Left constraint in milliseconds (e.g. a start date) */
  min?: IMilliseconds;
  /** Date in milliseconds */
  value?: IMilliseconds;
}

export function validateMoment({
  t,
  purpose = "date",
  isStrict = true,
  isExcess = false,
  min,
  max,
  value: source,
}: DateValidProps): string | undefined {
  try {
    if (_.isNilOrEmptyString(source)) {
      return policy[purpose].missing(t);
    }
    const value = _.toDayjs(source);

    if (!value.isValid) {
      return policy[purpose].invalid(t);
    }
    if (!_.isNilOrEmptyString(min)) {
      const start = _.toDayjs(min);

      if (start.isAfter(value) || (isStrict && start.isSame(value))) {
        return policy[purpose].endAfterStart(t);
      }

      if (isExcess && value.diff(start) <= DEFAULT_END_DATE_EXCESS) {
        return policy.date.endAfterStartExcess(t);
      }

      if (purpose === "cliff" && value.diff(start) <= DEFAULT_CLIFF_EXCESS) {
        return policy.cliff.endAfterStart(t);
      }
    }

    if (!_.isNilOrEmptyString(max)) {
      const end = _.toDayjs(max);
      if (end.isBefore(value) || (isStrict && end.isSame(value))) {
        return policy[purpose].startBeforeEnd(t);
      }
    }
  } catch (error) {
    return policy[purpose].missing(t);
  }

  return undefined;
}

interface DurationValidProps {
  t: Translate;
  purpose?: "cliff" | "duration";
  /** In strict mode, max and min will be used unreachable (< and > instead of <= and >= ) */
  isStrict?: boolean;
  /** Right constraint in milliseconds (e.g. an end date) */
  max?: IMilliseconds;
  /** Left constraint in milliseconds (e.g. a start date) */
  min?: IMilliseconds;
  /** Date in milliseconds */
  value?: IMilliseconds;
}

export function validateDuration({
  t,
  purpose = "duration",
  isStrict = true,
  min,
  max,
  value: source,
}: DurationValidProps): string | undefined {
  try {
    if (_.isNilOrEmptyString(source)) {
      return policy.duration.missing(t);
    }
    const value = new BigNumber(source);

    if (!value.isFinite() || value.isZero()) {
      return policy.duration.invalid(t);
    }
    if (!_.isNilOrEmptyString(min)) {
      const bound = new BigNumber(min);

      if ((isStrict && value.isEqualTo(bound)) || value.isLessThan(bound)) {
        return policy.duration.tooLow(t, _.toNumeralPrice(bound));
      }
    }

    if (!_.isNilOrEmptyString(max)) {
      const bound = new BigNumber(max);
      if ((isStrict && value.isEqualTo(bound)) || value.isGreaterThan(bound)) {
        if (purpose === "cliff") {
          return policy.cliff.durationBeforeTotal(t);
        }
        return policy.duration.tooHigh(t, _.toNumeralPrice(bound));
      }
    }
  } catch (error) {
    return policy.duration.missing(t);
  }

  return undefined;
}
