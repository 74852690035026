import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AIRSTREAMS_PAGE_SIZE,
  DEFAULT_CHAIN_ID,
  MAX_SUBGRAPH_ID,
  REQUEST_ID,
} from "@sablier/v2-constants";
import {
  useRequestAirstreamList,
  useRequestAirstreamListClaimable,
  useRequestAirstreamListHidden,
} from "@sablier/v2-hooks";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Airstream } from "@sablier/v2-models";
import extensions from "~/client/extensions";
import type { StreamShape } from "@sablier/v2-constants";
import type { IFilterAirstream, ISearchAirstream } from "@sablier/v2-models";
import {
  useAirstreamStoreAccessor,
  useAirstreamStoreEligible,
} from "./store/airstream";
import useAccount from "./useAccount";

const OPTIONS = {
  after: MAX_SUBGRAPH_ID,
  first: AIRSTREAMS_PAGE_SIZE,
};

export default function useAirstreamsEligible() {
  const { chainId, address, isConfigured, isReady } = useAccount();
  const access = useAirstreamStoreAccessor();

  const { search: result } = useAirstreamStoreEligible();
  const { CIDs: claimable, isLoading: isLoadingClaimable } =
    useRequestAirstreamListClaimable({
      address,
      chainId,
      isEnabled: isConfigured,
    });
  const { IDs: excludeIds, isLoading: isLoadingHidden } =
    useRequestAirstreamListHidden({
      chainId,
      isEnabled: isReady,
    });

  const [options, setOptions] = useState<typeof OPTIONS>(OPTIONS);

  const isEnabled = useMemo(
    () =>
      isConfigured &&
      !isLoadingClaimable &&
      !isLoadingHidden &&
      (claimable?.length ?? 0) > 0 &&
      !result?.options.isComplete,
    [claimable, isConfigured, isLoadingClaimable, isLoadingHidden, result],
  );

  const filter: IFilterAirstream = useMemo(
    () =>
      Airstream.doFormatFilter({
        chainId: chainId || DEFAULT_CHAIN_ID,
        airstreamCIDs: claimable,
      }),
    [chainId, claimable],
  );

  const onSuccess = useCallback(
    (_result: ISearchAirstream) => {
      const result = _.clone(_result);
      const state = access();
      const set = state.api.setEligible;
      const eligible = state.eligible;

      const info = (result: unknown, label = "new") =>
        console.info(
          "%c[eligible airstreams]",
          "color: cornflowerblue",
          `[${label}]`,
          {
            result,
          },
        );

      result.airstreams.forEach((airstream) => {
        const resolved = Airstream.findStreamShape(airstream, (stream) =>
          extensions.identify(stream),
        );
        if (!resolved.isUnknown) {
          airstream.streamShape = resolved.id as StreamShape;
        }
      });

      /** If there are no results, you can set initial ones care-free */
      if (_.isNil(eligible)) {
        set(result);
        info(result);
      } else {
        /** If filters changed, it meas we requested for a new set of airstreams. Override based on these new rules. */
        if (!_.isEqual(eligible.filter, result.filter)) {
          set(result);
          info(result);
        } else if (!_.isEqual(eligible.options, result.options)) {
          /**
           * Edge-case: [Search set#1, Click on more for set#2, Go to a Stream's Profile, Come Back]
           * Upon coming back, this request will ask for set#1 again. Due to the rules of this branch,
           * the results would get appended again and again. The condition below makes sure the search
           * isn't resumed from somewhere else.
           */
          if (
            !_.isNil(eligible.options.after) &&
            !_.isNil(result.options.after) &&
            new BigNumber(eligible.options.after).isLessThanOrEqualTo(
              new BigNumber(result.options.after),
            )
          ) {
            return;
          }
          /** If filters remain the same, options may have changed (e.g. pagination requested followup items). Merge results. */
          const value = _.clone(eligible);
          value.options = result.options;
          value.airstreams = _.uniqBy(
            [...value.airstreams, ...result.airstreams],
            (i) => i.id,
          );
          set(value);
          info(value.airstreams, "more");
        }
      }
    },
    [access],
  );

  useEffect(() => {
    setOptions(OPTIONS);
  }, [filter]);

  const { isLoading, error } = useRequestAirstreamList({
    key: REQUEST_ID.airstreamListEligible,
    filter,
    isEnabled,
    onSuccess,
    options,
    excludeIds,
  });

  const doMore = useCallback(() => {
    if (!isLoading && _.isNilOrEmptyString(error)) {
      setOptions((prev) => ({
        ...prev,
        after: result?.options.after || OPTIONS.after,
      }));
    }
  }, [error, isLoading, setOptions, result]);

  return {
    doMore,
    error,
    isLoading,
    result,
  };
}
