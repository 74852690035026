import { DigitPurpose } from "@sablier/v2-constants";
import { StreamStatus } from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import numeral from "numeral";
import { assign, createMachine } from "xstate";
import type { Context } from "./context";
import { Initial, Limits } from "./context";
import { addPadding } from "./helper";

// Stale: https://stately.ai/viz/885f8402-9875-4fae-bca7-20a9b5658bcb

const machine = createMachine(
  {
    tsTypes: {} as import("./amount.typegen").Typegen0,
    id: "amountMachine",
    initial: "initial",
    context: Initial,
    predictableActionArguments: true,
    schema: {
      context: {} as Context,
      events: {} as { type: "FORMAT"; source: string; status?: StreamStatus },
    },
    states: {
      initial: {
        on: {
          FORMAT: {
            target: "prepare",
            actions: "doPrepare",
          },
        },
      },
      prepare: {
        always: [
          {
            cond: "isAbbreviationRequired",
            target: "abbreviate",
          },
          {
            target: "characteristics",
          },
        ],
      },
      abbreviate: {
        entry: "doAbbreviate",
        always: [
          {
            actions: "doAbbreviatePrefix",
            target: "initial",
          },
        ],
      },
      characteristics: {
        entry: "doCharacteristics",
        always: [
          {
            cond: "isPrefixRequired",
            actions: "doCharacteristicsPrefix",
            target: "adjust_precision",
          },
          {
            target: "adjust_precision",
          },
        ],
      },

      adjust_precision: {
        always: [
          {
            cond: "isCharacteristicOverPrecision",
            target: "mantissa_prepare",
          },
          {
            target: "mantissa",
          },
        ],
      },
      mantissa_prepare: {
        entry: "doMantissaPrepare",
        always: [
          {
            target: "mantissa",
          },
        ],
      },

      mantissa: {
        entry: "doMantissa",
        always: [
          {
            cond: "isSuffixRequired",
            actions: "doMantissaSuffix",
            target: "divide_and_highlight",
          },
          {
            target: "divide_and_highlight",
          },
        ],
      },
      divide_and_highlight: {
        entry: "doDivideAndHighlight",
        always: [
          {
            target: "initial",
          },
        ],
      },
    },
  },

  {
    actions: {
      // Prepare the necessary context variables
      doPrepare: assign({
        source: (_context, event) => event.source,
        computed: (_context, event) => {
          const { source, status } = event;

          // Format the source such that it's separated by "." and gets cut after a certain precision

          const isZero = new BigNumber(source).isZero();
          const isDone = status !== StreamStatus.STREAMING;

          const decimals = isZero
            ? 0
            : isDone
            ? BigNumber.min(
                new BigNumber(source).decimalPlaces() ||
                  Limits.MANTISSA_PRECISION_FLOOR,
                Limits.MANTISSA_PRECISION,
              ).toNumber()
            : Limits.MANTISSA_PRECISION;

          const sanitized = new BigNumber(source).toFormat(
            decimals,
            BigNumber.ROUND_DOWN,
            {
              decimalSeparator: ".",
              groupSeparator: "",
            },
          );

          const parts = sanitized.split(".");
          // Extract number slice before the decimal point
          const characteristic = new BigNumber(parts[0]);
          // Extra number slice after the decimal point and remove unnecessary padding (zeroes)
          const mantissa = parts[1] || "0";
          const characteristicDigits = characteristic.toString().split("");
          const mantissaDigits = mantissa.toString().split("");

          return {
            ..._.cloneDeep(Initial).computed,
            definition: [],
            characteristic,
            characteristicDigits,
            mantissa: new BigNumber(mantissa),
            mantissaDigits,
            sanitized,
            source,
          };
        },
      }),
      doAbbreviate: assign({
        computed: (context) => {
          const { computed } = _.cloneDeep(context);
          const { definition } = computed;

          const formatted = numeral(
            context.computed.sanitized.toString(),
          ).format("(0,0000 a)");

          formatted.split("").forEach((item) => {
            if (new RegExp(/^[0-9]+$/).test(item)) {
              definition.push({ d: item, p: DigitPurpose.CHARACTERISTIC });
            } else if ([".", ","].includes(item)) {
              definition.push({ d: item, p: DigitPurpose.DIVIDER });
            } else if (new RegExp(/^[a-zA-Z]+$/).test(item)) {
              definition.push({
                d: item.toUpperCase(),
                p: DigitPurpose.ABBREVIATION,
              });
            }
          });

          return {
            ...context.computed,
            definition,
          };
        },
      }),

      doAbbreviatePrefix: assign({
        computed: (context) => {
          const { computed } = _.cloneDeep(context);
          const { characteristicPrecision: precision, definition } = computed;

          let digits = 0;
          let foundDivider = false;

          definition.forEach(({ d }) => {
            if (!foundDivider) {
              if (d === ".") {
                foundDivider = true;
              } else {
                digits++;
              }
            }
          });

          addPadding(definition, digits, precision, true);

          return {
            ...context.computed,
            definition,
          };
        },
      }),
      // Paint the characteristic digits (those before the decimals point)
      doCharacteristics: assign({
        computed: (context) => {
          const { computed } = _.cloneDeep(context);
          const { characteristicDigits, definition } = computed;

          // Use all characteristic digits
          characteristicDigits.forEach((d) => {
            definition.push({ d, p: DigitPurpose.CHARACTERISTIC });
          });

          return {
            ...context.computed,
            definition,
          };
        },
      }),
      // Paint some prefix 0s if the characteristic digits are too few
      doCharacteristicsPrefix: assign({
        computed: (context) => {
          const { computed } = _.cloneDeep(context);
          const {
            characteristicDigits,
            characteristicPrecision: precision,
            definition,
          } = computed;

          addPadding(definition, characteristicDigits.length, precision, true);
          return {
            ...context.computed,
            definition,
          };
        },
      }),
      // Prepare the mantissa size based on how many characteristic were already painted (those are of more importance)
      doMantissaPrepare: assign({
        computed: (context) => {
          const { computed } = _.cloneDeep(context);
          const { characteristicDigits, characteristicPrecision } = computed;

          const initial = computed.mantissaDigits.length;
          let mantissaSuffix = true;
          let mantissaDigits = _.cloneDeep(computed.mantissaDigits);

          mantissaDigits = mantissaDigits.slice(
            0,
            Limits.MANTISSA_SLICE_PRECISION,
          );
          const difference =
            characteristicDigits.length - characteristicPrecision;

          if (difference > 0) {
            [...Array(difference).keys()].forEach(() => {
              if (mantissaDigits.length - 2 > 0) {
                mantissaDigits = mantissaDigits.slice(0, -2);
              }
            });
          }

          if (mantissaDigits.length !== initial) {
            mantissaSuffix = false;
          }

          return {
            ...context.computed,
            mantissaDigits,
            mantissaSuffix,
          };
        },
      }),
      // Paint the mantissa digits (those after the decimal point)
      doMantissa: assign({
        computed: (context) => {
          const { computed } = _.cloneDeep(context);
          const { mantissaDigits, definition } = computed;

          // Use all remaining mantissa digits

          mantissaDigits.forEach((d) => {
            definition.push({ d, p: DigitPurpose.MANTISSA });
          });

          return {
            ...context.computed,
            definition,
          };
        },
      }),
      // Paint some suffix 0s if mantissa digits are too few
      doMantissaSuffix: assign({
        computed: (context) => {
          const { computed } = _.cloneDeep(context);
          const {
            definition,
            mantissaDigits,
            mantissaPrecision,
            mantissaSuffix,
          } = computed;

          // Use all remaining mantissa digits
          if (mantissaSuffix && mantissaDigits.length < mantissaPrecision) {
            addPadding(definition, mantissaDigits.length, mantissaPrecision);
          }

          return {
            ...context.computed,
            definition,
          };
        },
      }),
      // Paint the divider (decimal point) and add final highlights
      doDivideAndHighlight: assign({
        computed: (context) => {
          const { computed } = _.cloneDeep(context);
          const { definition } = computed;

          const lastCharacteristicIndex = definition.findIndex(
            (item, index) =>
              [DigitPurpose.CHARACTERISTIC, DigitPurpose.PREFIX].includes(
                item.p,
              ) &&
              index + 1 < definition.length &&
              [DigitPurpose.MANTISSA].includes(definition[index + 1].p),
          );

          if (lastCharacteristicIndex !== -1) {
            definition[lastCharacteristicIndex].p ===
              DigitPurpose.CHARACTERISTIC;
            definition.splice(lastCharacteristicIndex + 1, 0, {
              d: ".",
              p: DigitPurpose.DIVIDER,
            });
          }

          return {
            ...context.computed,
            definition,
          };
        },
      }),
    },
    guards: {
      isAbbreviationRequired: (context) => {
        const { computed } = context;
        return (
          computed.characteristic.toString().length >=
          Limits.ABBREVIATE_PRECISION
        );
      },
      isCharacteristicOverPrecision: (context) => {
        const { computed } = context;
        return (
          computed.characteristicDigits.length >=
          computed.characteristicPrecision
        );
      },
      isPrefixRequired: (context) => {
        const { computed } = context;
        return (
          computed.characteristicDigits.length <
          computed.characteristicPrecision
        );
      },
      isSuffixRequired: (context) => {
        const { computed } = context;
        return (
          computed.mantissaSuffix &&
          computed.mantissaDigits.length < computed.mantissaPrecision
        );
      },
    },
  },
);

/**
 * A single instances of this machine is necessary.
 */

export default machine;
