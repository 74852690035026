import { CodegenConfig } from "@graphql-codegen/cli";

const config: CodegenConfig = {
  debug: true,
  documents: ["./src/documents/airstream/*.ts"],
  generates: {
    "./src/generated/airstream/": {
      preset: "client",
      plugins:
        [] /** Using any plugin will cause types to be duplicated in generated/graphql.ts */,
      presetConfig: {
        fragmentMasking: false,
        gqlTagName: "gql",
      },
    },
  },
  ignoreNoDocuments: true,
  silent: false,
  overwrite: true,
  // the schema should point to a functional subgraph endpoint
  schema:
    "https://api.studio.thegraph.com/query/57079/sablier-v2-ms-sepolia/version/latest",
};

const endpoint = config.schema as string;

export { endpoint };
export default config;
