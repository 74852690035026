import { useEffect, useMemo } from "react";
import {
  AIRSTREAMS_PAGE_SIZE,
  MAX_SUBGRAPH_ID,
  QUERY_CACHE_TIME,
  REQUEST_ID,
} from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Airstream, Token } from "@sablier/v2-models";
import { client } from "@sablier/v2-subgraphs";
import { useQuery } from "@tanstack/react-query";
import type { IFilterAirstream, ISearchAirstream } from "@sablier/v2-models";
import useRequestQuery from "./useRequestAirstreamListQuery";

interface Props {
  key?: string[];
  excludeIds?: string[];
  filter: IFilterAirstream;
  options?: {
    after: string;
    first: number;
  };
  isEnabled: boolean;
  onSuccess: (result: ISearchAirstream) => void;
  onError?: (error: unknown, result: ISearchAirstream) => void;
  /**
   * Decide if the indexer query will attempt to retry and jump
   * to the fallback endpoint if certain conditions aren't being met
   */
  onJump?: (result: { campaigns: { id: string }[] }) => boolean;
}

export default function useRequestAirstreamList({
  key = REQUEST_ID.airstreamList,
  filter,
  excludeIds = [],
  onError = _.noop,
  onSuccess = _.noop,
  onJump,
  isEnabled = false,
  options = {
    after: MAX_SUBGRAPH_ID,
    first: AIRSTREAMS_PAGE_SIZE,
  },
}: Props) {
  const endpoint = useMemo(
    () =>
      client.getEndpointByChain({
        chainId: filter.chainId,
        feature: "airstream",
      }),
    [filter],
  );

  const query = useRequestQuery(filter);
  const timestamp = useMemo(
    () => new BigNumber(_.toSeconds(Date.now().toString())),
    [],
  );

  const variables = useMemo(() => {
    const admin = filter.admin?.toLowerCase();
    const asset = filter.token?.toLowerCase();
    const isAlive = filter.isAlive;
    const name = (() => {
      if (_.isNilOrEmptyString(filter.name)) {
        return "";
      }

      if (filter.name.length && String(filter.name).at(0) === "$") {
        return filter.name.slice(1);
      }

      return filter.name;
    })();

    return {
      chainId: filter.chainId,
      first: options.first,
      skip: 0,
      airstreamIds: filter.airstreamIds?.map((id) => id?.toLowerCase()),
      excludeIds: excludeIds.length
        ? excludeIds.map((id) => id?.toLowerCase())
        : ["_"],
      airstreamCIDs: filter.airstreamCIDs,
      subgraphId: options.after,
      admin,
      asset,
      isAlive,
      name,
    };
  }, [filter, options, excludeIds]);

  /**
   * Later on, we run comparisons on the set of variables to append or replace results.
   * Timestamps will mess up the comparison.
   */
  const variables_complete = useMemo(
    () => ({ ...variables, currentTimestamp: timestamp }),
    [variables, timestamp],
  );

  const { data, error, isLoading } = useQuery({
    queryKey: [...key, { unique: variables }],
    queryFn: async () => {
      const result = await client.request(
        endpoint,
        query,
        variables_complete,
        {},
        onJump,
      );
      return {
        result,
        variables,
      };
    },
    staleTime: QUERY_CACHE_TIME,
    gcTime: QUERY_CACHE_TIME,
    enabled: isEnabled,
    retry: false,
  });

  /** -------------- On Success -------------- */
  /** Checks should run against a valid `data` */

  useEffect(() => {
    if (!_.isNilOrEmptyString(data?.result)) {
      const { result, variables } = data || {};
      const after = result?.campaigns.length
        ? result.campaigns[result.campaigns.length - 1].subgraphId
        : MAX_SUBGRAPH_ID;
      const isComplete = (result?.campaigns.length || 0) < options.first;

      const search: ISearchAirstream = {
        filter,
        options: {
          after,
          first: options.first,
          isComplete,
          variables,
        },
        airstreams: [],
      };

      if (!_.isNilOrEmptyString(data)) {
        result?.campaigns.forEach((item) => {
          const token = new Token(item.asset);
          const airstream = new Airstream(item, token);
          search.airstreams.push(airstream);
        });
      }

      onSuccess(search);
    }
  }, [data, filter, options.first, onSuccess]);

  /** -------------- On Error -------------- */
  /** Checks should run against the `error` */

  useEffect(() => {
    if (!_.isNilOrEmptyString(error)) {
      const search: ISearchAirstream = {
        filter,
        options: {
          after: MAX_SUBGRAPH_ID,
          first: options.first,
          error: _.toString(error),
          isComplete: true,
          variables,
        },
        airstreams: [],
      };

      onError(error, search);
    }
  }, [error, filter, options.first, onError, variables]);

  return useMemo(
    () => ({
      error,
      isLoading: isLoading && isEnabled,
    }),
    [error, isLoading, isEnabled],
  );
}
