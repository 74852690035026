import { useEffect, useRef } from "react";
import { _ } from "@sablier/v2-mixins";
import type { ISigner } from "@sablier/v2-types";
import useAccount from "./useAccount";
import useExpectedCheck from "./useExpectedCheck";
import useIsSanctionedAccount from "./useIsSanctionedAccount";
import useMixpanel from "./useMixpanel";
import useNextRouterCleanup from "./useNextRouterCleanup";
import { usePermissionCheck } from "./usePermissions";
import useResetter from "./useResetter";

export default function useWatcher() {
  const { isConfigured, signer, status } = useAccount();
  const { own: reset } = useResetter();
  const previous = useRef<ISigner | undefined>(undefined);

  /**
   * Every time the signer changes (includes chainId and account changes) we refetch data.
   * Using a basic useEffect seems to be catching this change faster than the @wagmi/core watchSigner feature.
   */
  useEffect(() => {
    if (isConfigured && signer && status === "connected") {
      console.info("%c[watcher signer]", "color: snow", { signer });

      if (previous.current) {
        /**
         * The first event that triggers this watcher is the initial connect event.
         * That one doesn't have to the reset caches so we track the previous signer to avoid that.
         */
        void reset();
      }

      previous.current = signer;
    } else if (
      (status === "unsure" || status === "disconnected") &&
      previous.current
    ) {
      console.info("%c[watcher signer]", "color: snow", "[unsure cleanup]");

      /**
       * If the account has disconnected or has been switched to an unsure state and
       * we used to have a signer (app might be populated with that account's data), we reset the caches.
       */

      void reset();
      previous.current = undefined;
    }
  }, [isConfigured, reset, signer, status]);

  /** Run application level watchers */

  useExpectedCheck();
  usePermissionCheck();
  useMixpanel();
  useNextRouterCleanup();

  /** Build dependency structure for the Watcher context */

  const { isSanctioned } = useIsSanctionedAccount();

  return { isSanctioned };
}
