import { memo } from "react";
import styled from "styled-components";
import { ArrowUpIcon } from "@heroicons/react/24/outline";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { rgba } from "polished";
import type { ThemeType } from "@sablier/v2-themes";
import Icon from "../Icon";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    grid-gap: calc(${(props) => props.theme.sizes.edge});
    justify-content: center;
    width: auto;
    height: 36px;
    max-width: ${(props) => props.theme.sizes.streamCircle}px;
    & > div {
      height: 100% !important;
    }
  }
`;

const Box = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  border: 2px solid ${(props) => rgba(props.theme.colors.dark500, 1)};
  border-radius: 4px;
  background-color: ${(props) => rgba(props.theme.colors.dark500, 0.2)};
  backdrop-filter: blur(5.5px);
`;

const Light = styled.div`
  position: absolute;
  width: 40px;
  height: 10px;
  background-color: ${(props) => props.theme.colors.gray200};
  opacity: 0.3;
  filter: blur(20px);
`;

const Content = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    z-index: 20;
    gap: 10px;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-inline: 12px;
  }
`;

const Divider = styled.div`
  width: 2px;
  height: 12px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.gray200};
`;

interface ITint {
  color?: keyof ThemeType["colors"];
  gradient?: keyof ThemeType["gradients"];
}

const Tint = styled.div<ITint>`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    flex-shrink: 0;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${(props) => {
      return props.gradient
        ? props.theme.gradients[props.gradient]
        : props.theme.colors[props.color || "white"];
    }};

    &:after {
      position: absolute;
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.dark300};
    }
  }
`;

const Item = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: 6px;
    &:last-child {
      --size: 16px;
      --thickness: 3px;
      --color: ${(props) => props.theme.colors.white};
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  cursor: pointer;
  &:hover,
  &:active {
    background-color: ${(props) => rgba(props.theme.colors.dark500, 0.3)};
  }
`;

function Legend({
  isCanceled = false,
  isLinear = false,
  onClick,
}: {
  isCanceled?: boolean;
  isLinear?: boolean;
  onClick: () => void;
}) {
  const { t } = useT();

  return (
    <Wrapper onClick={onClick}>
      <Box>
        <Light />
        <Background />
        <Content>
          <Item>
            <Tint
              gradient={isCanceled ? "red" : "secondary"}
              title={_.capitalize(t("structs.streamedAmount"))}
            />
          </Item>

          <Item>
            <Tint
              color={"primaryMiddle"}
              title={_.capitalize(t("structs.withdrawnAmount"))}
            />
          </Item>
          {!isLinear && (
            <Item>
              <Tint
                color={"dark700"}
                title={_.capitalize(t("structs.elapsedTime"))}
              />
            </Item>
          )}
          <Divider />
          <Item>
            <Icon value={ArrowUpIcon} />
          </Item>
        </Content>
      </Box>
    </Wrapper>
  );
}

export default memo(Legend);
