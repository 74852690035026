import {
  DEFAULT_GAS_LIMIT,
  DEFAULT_MAX_GAS_LIMIT,
  MAX_AMOUNT_PADDED,
} from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { BigNumber, _ } from "@sablier/v2-mixins";
import type { Output } from "@sablier/v2-contracts";
import type {
  IAddress,
  IFlags,
  IImage,
  IProvider,
  ISigner,
  ISignerOrProvider,
  IToken,
  ITransactionReceipt,
  IWagmiAddress,
  IWagmiConfig,
  UniswapTokenInfo,
} from "@sablier/v2-types";

export interface Params {
  address: string;
  chainId: number;
  decimals: number;
  name: string;
  symbol: string;
  image?: IImage | undefined;
}

/**
 * ------------------------------
 * The Token model will shape incoming data as an ERC20 asset.
 * ------------------------------
 * Locally, each app will implement its own `useToken(s)` utility.
 * The helper hook can provide an extra layer for the token resolution, where
 * data stored in other parts can be merged to construct new/improved tokens
 *
 * e.g. tokens coming from the subgraph can be merged with
 * those coming from the token-lists
 * ------------------------------
 *
 * The cache system for Tokens is implemented directly into the model.
 * It's an experiment such that there are no external dependencies we need to worry about.
 */
export default class Token implements IToken {
  readonly payload: Params;
  readonly address: IAddress;
  readonly chainId: number;
  readonly decimals: number;
  readonly name: string;
  readonly symbol: string;

  /**
   * Allowance history[address][chainId][owner][spender]
   */
  static history: Record<
    string,
    Record<number, Record<string, Record<string, BigNumber>>>
  > = {};

  image?: IImage | undefined;
  isLocal = false;
  isSelected = false;
  isWhitelisted = false;

  constructor(params: Params) {
    this.payload = params;
    this.address = _.toAddress(params.address);
    this.chainId = _.toNumber(params.chainId);
    this.decimals = _.toNumber(params.decimals);
    this.name = _.toString(params.name);
    this.symbol = _.toString(params.symbol);

    this.image = params.image;
  }

  static reset(): void {
    Token.history = {};
  }

  static findCache(params: {
    address: string;
    chainId: number;
    owner: string | undefined;
    spender: string | undefined;
  }): BigNumber | undefined {
    const { address, chainId, owner, spender } = params;

    const value = _.get(Token.history, [
      _.toAddress(address),
      chainId,
      _.toAddress(owner),
      _.toAddress(spender),
    ]);
    if (!_.isNilOrEmptyString(value) && BigNumber.isBigNumber(value)) {
      return new BigNumber(value);
    }

    return undefined;
  }

  static async getAllowanceFor(
    library: IWagmiConfig,
    params: {
      address: string;
      owner: string | undefined;
      provider: ISignerOrProvider;
      spender: string | undefined;
    },
  ): Promise<BigNumber> {
    const { provider, owner, address, spender } = params || {};
    const chainId = (await _.extractChainId(provider)) || 0;

    const query = framework.contextualize(
      address,
      chainId!,
      "token",
      "allowance",
      [owner as IWagmiAddress, (spender || owner) as IWagmiAddress],
    );
    const previews = await framework.preview({ queries: [query] });
    const results = await framework.read(library, { previews });
    const output = results[0].result as Output<"token", "allowance">;

    const value = new BigNumber(output.toString());

    _.set(
      Token.history,
      [_.toAddress(address), chainId, _.toAddress(owner), _.toAddress(spender)],
      value,
    );

    return value;
  }

  async getAllowance(
    library: IWagmiConfig,
    params: {
      owner: string | undefined;
      provider: IProvider;
      spender: string | undefined;
    },
  ): Promise<BigNumber> {
    return Token.getAllowanceFor(library, {
      ...params,
      address: this.address,
    });
  }

  static async getBalanceFor(
    library: IWagmiConfig,
    params: {
      address: string;
      owner: string;
      provider: ISignerOrProvider;
    },
  ): Promise<BigNumber> {
    const { provider, owner, address } = params || {};
    const chainId = (await _.extractChainId(provider)) || 0;

    const query = framework.contextualize(
      address,
      chainId!,
      "token",
      "balanceOf",
      [owner as IWagmiAddress],
    );
    const previews = await framework.preview({ queries: [query] });
    const results = await framework.read(library, { previews });
    const output = results[0].result as Output<"token", "balanceOf">;

    return new BigNumber(output.toString());
  }

  async getBalance(
    library: IWagmiConfig,
    params: {
      owner: string;
      provider: ISignerOrProvider;
    },
  ): Promise<BigNumber> {
    return Token.getBalanceFor(library, {
      ...params,
      address: this.address,
    });
  }

  static async doApproveFor(
    library: IWagmiConfig,
    params: {
      address: string;
      allowance?: BigNumber;
      decimals?: number;
      flags?: IFlags;
      signer: ISigner;
      spender?: string;
    },
  ): Promise<ITransactionReceipt | undefined> {
    const { address, allowance, decimals, signer, spender } = params || {};
    const chainId = (await _.extractChainId(signer)) || 0;

    const limit =
      _.isNilOrEmptyString(allowance) || allowance.isZero()
        ? MAX_AMOUNT_PADDED(decimals).toString()
        : allowance.toFixed(0).toString();

    const query = framework.contextualize(
      address,
      chainId!,
      "token",
      "approve",
      [spender as IWagmiAddress, _.toBigInt(limit)],
    );

    const prepared = await framework.fuel(library, {
      query,
      signer,
      fallback: DEFAULT_GAS_LIMIT,
      maxGas: DEFAULT_MAX_GAS_LIMIT,
    });

    console.info("%c[pre-transaction]", "color: mediumslateblue", {
      query,
      prepared,
      signer,
    });

    const onExecuteSafe = async () => {
      const transaction = await framework.safeWrite(library, {
        queries: [query],
      });
      const receipt = framework.safeWait(library, { hash: transaction });

      return {
        receipt,
        transaction,
      };
    };

    const onExecuteWallet = async () => {
      const transaction = await framework.write(library, { prepared });
      const receipt = framework.wait(library, { hash: transaction });

      return {
        receipt,
        transaction,
      };
    };

    const { receipt, transaction } = params.flags?.isHostSafe
      ? await onExecuteSafe()
      : await onExecuteWallet();

    console.info("%c[post-transaction]", "color: mediumslateblue", {
      transaction,
      receipt,
    });

    Token.reset();
    return receipt;
  }

  async doApprove(
    library: IWagmiConfig,
    params: {
      allowance?: BigNumber;
      decimals?: number;
      flags?: IFlags;
      signer: ISigner;
      spender?: string;
    },
  ): Promise<ITransactionReceipt | undefined> {
    return Token.doApproveFor(library, {
      ...params,
      address: this.address,
    });
  }

  static async isAllowedFor(
    library: IWagmiConfig,
    params: {
      address: string;
      allowance: BigNumber;
      decimals: number;
      isCached?: boolean;
      owner: string | undefined;
      provider: ISignerOrProvider;
      spender: string | undefined;
    },
  ): Promise<boolean> {
    if (params.allowance.isZero()) {
      return true;
    }

    const chainId = (await _.extractChainId(params.provider)) || 0;

    const cachedLimit = (() => {
      if (params.isCached) {
        const value = Token.findCache({ ...params, chainId });
        return value;
      }

      return undefined;
    })();

    const limit =
      cachedLimit && BigNumber.isBigNumber(cachedLimit)
        ? cachedLimit
        : await Token.getAllowanceFor(library, params);

    const requested = _.toValue({
      humanized: params.allowance,
      decimals: params.decimals,
    }).raw;

    let isAllowed = false;
    if (
      (_.isNilOrEmptyString(requested) ||
        requested.isZero() ||
        requested.isNaN()) &&
      !(_.isNilOrEmptyString(limit) || limit.isZero() || limit.isNaN())
    ) {
      isAllowed = true;
    } else {
      isAllowed = limit.isGreaterThanOrEqualTo(requested);
    }

    return isAllowed;
  }

  async isAllowed(
    library: IWagmiConfig,
    params: {
      allowance: BigNumber;
      isCached?: boolean;
      owner: string | undefined;
      provider: ISignerOrProvider;
      spender: string | undefined;
    },
  ): Promise<boolean> {
    return Token.isAllowedFor(library, {
      ...params,
      address: this.address,
      decimals: this.decimals,
    });
  }

  static fromUniswapToken(source: UniswapTokenInfo) {
    return new Token(source);
  }

  static areEqual(t1: IToken, t2: IToken) {
    return t1.address === t2.address && t1.chainId === t2.chainId;
  }
}
