import { _ } from "@sablier/v2-mixins";
import type { ChangeEvent, MouseEvent } from "react";

export function doSanitize<T>(value: T) {
  return _.isNilOrEmptyString(value) ? "" : value;
}

export function onChange<T>(e: ChangeEvent<HTMLInputElement>, callback: T) {
  if (!_.isFunction(callback)) {
    return;
  }
  callback(e.target.value, e);
}

export function onHitbox<T>(
  e: MouseEvent<HTMLDivElement>,
  onClick: T,
  isDisabled?: boolean,
) {
  if (!isDisabled) {
    try {
      const box = e.target as Element;
      const input = box.parentElement?.querySelector("input");

      if (input) {
        _.attempt(() => {
          input.focus();
        });
      }

      if (_.isFunction(onClick)) {
        onClick();
      }
    } catch (error) {
      console.debug(error);
    }
  }
}
