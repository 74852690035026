import { useState } from "react";
import { QUERY_PERSIST_TIME, REQUEST_ID } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { HydrationBoundary, QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { AppProps } from "next/app";
import type { PersistQueryClientOptions } from "@tanstack/react-query-persist-client";
import type { PropsWithChildren } from "react";

export default function ReactQueryProvider({
  children,
  pageProps,
}: PropsWithChildren<{
  pageProps: AppProps<{ dehydratedState: unknown }>["pageProps"];
}>) {
  const [client] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            gcTime: QUERY_PERSIST_TIME,
          },
        },
      }),
  );

  const [options] = useState(() => {
    const persister = createSyncStoragePersister({
      storage: _.isWindow() ? window.localStorage : undefined,
    });

    const bundle: Omit<PersistQueryClientOptions, "queryClient"> = {
      persister,
      maxAge: QUERY_PERSIST_TIME,
      dehydrateOptions: {
        shouldDehydrateQuery: ({ queryKey }) => {
          return REQUEST_ID.persisted.includes(_.toString(queryKey[0]));
        },
      },
    };

    return bundle;
  });

  return (
    <PersistQueryClientProvider client={client} persistOptions={options}>
      <HydrationBoundary state={pageProps.dehydratedState}>
        {children}
      </HydrationBoundary>
    </PersistQueryClientProvider>
  );
}
