import { useRef } from "react";
import { instantiator } from "@sablier/v2-machines";
import { _ } from "@sablier/v2-mixins";
import { useMachine } from "@xstate/react";

type Generator<P> = typeof instantiator.create<P>;
type Arguments<P> = Parameters<Generator<P>>;
type Configuration<P> = Arguments<P>["0"];

function useMachineInstantiator<Payload>(
  configuration: Configuration<Payload>,
) {
  const reference = useRef(instantiator.create<Payload>(configuration));

  return useMachine(reference.current);
}

export default useMachineInstantiator;
