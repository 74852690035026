import { useCallback, useMemo } from "react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { Button } from "@sablier/v2-components/molecules";
import { tags } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { useAccount } from "~/client/hooks";
import { Base } from "../../system";

function Connect({ className }: { className?: string }) {
  const { isConnected, isConnecting, connect } = useAccount();
  const { t } = useT();

  const description = useMemo(() => {
    if (!isConnected) {
      return t("warnings.connect.description");
    }

    return "";
  }, [isConnected, t]);

  const onClick = useCallback(() => {
    if (!isConnected || isConnecting) {
      (async () => {
        try {
          if (connect && _.isFunction(connect)) {
            void connect();
          }
        } catch (error) {
          vendors.crash.log(error);
        }
      })();
    }
  }, [connect, isConnected, isConnecting]);

  return (
    <Base
      className={className}
      cy={tags.warningConnect}
      description={description}
      label={{
        icon: UserCircleIcon,
        value: t("warnings.connect.title"),
      }}
    >
      <Button
        accent={"warning"}
        appearance={"outline"}
        isMini
        onClick={onClick}
        title={t("warnings.connect.action")}
      />
    </Base>
  );
}

export default Connect;
