import { memo, useMemo } from "react";
import styled, { css } from "styled-components";
import {
  ArrowTrendingUpIcon,
  BanknotesIcon,
  BoltIcon,
  CalendarIcon,
  CheckIcon,
  ClockIcon,
  DocumentPlusIcon,
  FlagIcon,
  ForwardIcon,
  GlobeAltIcon,
  HandRaisedIcon,
  InformationCircleIcon,
  LockClosedIcon,
  NoSymbolIcon,
  PaperAirplaneIcon,
  PresentationChartLineIcon,
  SquaresPlusIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import {
  Address,
  Amount as AmountBase,
  Chain,
  Detail,
} from "@sablier/v2-components/molecules";
import { StreamCategory, StreamStatus } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { IAddress, IChainDisplay, IMilliseconds } from "@sablier/v2-types";
import type { ComponentProps, PropsWithChildren } from "react";

const DetailAdvanced = styled(Detail)`
  *[data-component="frame"],
  *[data-component="label"] {
    color: ${(props) => props.theme.colors.primaryMiddle};
  }
`;

const DetailCliff = styled(Detail)<{ isHighlighted: boolean }>`
  ${(props) =>
    props.isHighlighted &&
    css`
      *[data-component="frame"],
      *[data-component="label"],
      *[data-component="value"] {
        color: ${(props) => props.theme.colors.yellow};
      }
    `}
`;

const DetailEnsurance = styled(Detail)`
  *[data-component="frame"],
  *[data-component="label"] {
    color: ${(props) => props.theme.colors.white};
  }
`;

const DetailLoading = styled(Detail)`
  *[data-component="frame"] {
    background-color: ${(props) => props.theme.colors.dark300};
  }
`;

interface Props extends PropsWithChildren<unknown> {
  isLoading?: boolean;
  value?: string;
  toPage?: string;
}

interface AmountProps extends Omit<Props, "value"> {
  label?: string;
  value?: ComponentProps<typeof AmountBase>;
}

interface NetworkProps extends Omit<Props, "value"> {
  value?: IChainDisplay;
}

interface ManagerProps extends Omit<Props, "value"> {
  value?: IAddress;
}

interface TimelineProps extends Props {
  status?: StreamStatus;
  label?: string;
}

interface EnsuranceProps extends Props {
  label?: string;
}
interface DurationProps extends Props {
  label?: string;
}

interface StartedOnAdapting extends Props {
  value?: IMilliseconds;
}

function Advanced({
  category,
  ...props
}: Props & { category: StreamCategory | undefined }) {
  const { t } = useT();
  return (
    <DetailAdvanced
      icon={ArrowTrendingUpIcon}
      label={
        category === StreamCategory.LOCKUP_TRANCHED
          ? t("structs.sablierTranchedStream")
          : t("structs.sablierDynamicStream")
      }
      {...props}
    />
  );
}

function Amount({ value, label, isLoading, toPage }: AmountProps) {
  const { t } = useT();
  return (
    <Detail
      icon={BanknotesIcon}
      isLoading={isLoading}
      label={label || t("words.amount")}
      toPage={toPage}
    >
      <AmountBase
        {...value}
        tooltip={_.toPrefix(
          _.toString(value?.value),
          (value?.token?.symbol || "") + " ",
        )}
      />
    </Detail>
  );
}

function Cancelable(props: Props) {
  const { t } = useT();
  return (
    <Detail icon={HandRaisedIcon} label={t("words.cancelability")} {...props} />
  );
}

function Transferable(props: Props) {
  const { t } = useT();
  return (
    <Detail
      icon={PaperAirplaneIcon}
      label={t("words.transferability")}
      {...props}
    />
  );
}

function Cliff({ isCliffing, ...props }: Props & { isCliffing?: boolean }) {
  const { t } = useT();
  return (
    <DetailCliff
      isHighlighted={!!isCliffing}
      icon={isCliffing ? LockClosedIcon : ClockIcon}
      label={`${t("words.cliff")}${
        isCliffing ? ` (${_.capitalize(t("words.ongoing"))})` : ""
      }`}
      {...props}
    />
  );
}

function Duration(durationProps: DurationProps) {
  const { t } = useT();
  const { label = t("structs.durationDetailsLabel"), ...props } = durationProps;
  return <Detail icon={ClockIcon} label={label} {...props} />;
}

function Ensurance(ensuranceProps: EnsuranceProps) {
  const { t } = useT();
  const { label = t("structs.ensurance"), ...props } = ensuranceProps;
  return <DetailEnsurance icon={FlagIcon} label={label} {...props} />;
}

function Info(infoProps: EnsuranceProps) {
  const { t } = useT();
  const { label = t("structs.more"), ...props } = infoProps;
  return (
    <DetailEnsurance icon={InformationCircleIcon} label={label} {...props} />
  );
}

function CreatedOn(props: Props) {
  const { t } = useT();
  return (
    <Detail icon={DocumentPlusIcon} label={t("structs.created")} {...props} />
  );
}

function StartedOn(props: Props) {
  const { t } = useT();

  return (
    <Detail icon={DocumentPlusIcon} label={t("structs.startedOn")} {...props} />
  );
}

function StartedOnAdapting(props: StartedOnAdapting) {
  const { t } = useT();

  const label = useMemo(() => {
    if (_.toDayjs(props.value).isAfter(_.toDayjs(Date.now().toString()))) {
      return t("structs.startsOn");
    }

    return t("structs.startedOn");
  }, [props, t]);

  return (
    <Detail
      {...props}
      icon={DocumentPlusIcon}
      label={label}
      value={_.toDuration(props.value, "date-short")[0]}
    />
  );
}

function ClaimsEndOn(props: Props) {
  const { t } = useT();
  return (
    <Detail
      icon={CalendarIcon}
      label={_.capitalize(t("structs.claimsEnd"))}
      labelIcon={InformationCircleIcon}
      {...props}
    />
  );
}

function Group(props: Props) {
  const { t } = useT();
  return <Detail icon={SquaresPlusIcon} label={t("words.group")} {...props} />;
}

function Network({ value, ...props }: NetworkProps) {
  const { t } = useT();
  return (
    <Detail icon={GlobeAltIcon} label={t("words.chain")} {...props}>
      <Chain
        empty={"dark300"}
        image={value?.image}
        size={18}
        name={value?.name || ""}
      />
    </Detail>
  );
}

function Manager({ value, ...props }: ManagerProps) {
  const { t } = useT();
  return (
    <Detail
      icon={UserCircleIcon}
      label={_.capitalize(t("words.manager"))}
      {...props}
    >
      <Address
        isCopyOnClick={"icon"}
        preview={_.toShortAddress(value)}
        value={value}
      />
    </Detail>
  );
}

function Shape(props: Props) {
  const { t } = useT();
  return (
    <Detail
      icon={PresentationChartLineIcon}
      label={t("words.shape")}
      {...props}
    />
  );
}

function Status(props: Props) {
  const { t } = useT();
  return <Detail icon={BoltIcon} label={t("words.status")} {...props} />;
}

function Timeline({
  status = StreamStatus.STREAMING,
  label,
  ...props
}: TimelineProps) {
  const labelIcon = useMemo(() => {
    switch (status) {
      case StreamStatus.PENDING:
        return ForwardIcon;
      case StreamStatus.SETTLED:
      case StreamStatus.DEPLETED_SETTLED:
        return CheckIcon;
      case StreamStatus.CANCELED:
      case StreamStatus.DEPLETED_CANCELED:
        return NoSymbolIcon;
      case StreamStatus.STREAMING:
      default:
        return undefined;
    }
  }, [status]);

  return (
    <Detail
      icon={CalendarIcon}
      label={label}
      labelIcon={labelIcon}
      {...props}
    />
  );
}

function Loading({ className }: { className?: string }) {
  return <DetailLoading className={className} isLoading />;
}

const Details = {
  Advanced: memo(Advanced),
  Amount: memo(Amount),
  Cancelable: memo(Cancelable),
  ClaimsEndOn: memo(ClaimsEndOn),
  Cliff: memo(Cliff),
  General: memo(Detail),
  CreatedOn: memo(CreatedOn),
  StartedOn: memo(StartedOn),
  StartedOnAdapting: memo(StartedOnAdapting),
  Ensurance: memo(Ensurance),
  Group: memo(Group),
  Loading: memo(Loading),
  Info: memo(Info),
  Manager: memo(Manager),
  Network: memo(Network),
  Shape: memo(Shape),
  Status: memo(Status),
  Timeline: memo(Timeline),
  Transferable: memo(Transferable),
  Duration: memo(Duration),
};

export default Details;
