import type {
  IExtensionParamsGroup,
  IExtensionParamsSingle,
  IExtensionResultDurationsLD,
  IExtensionResultTimestampsLD,
} from "~/client/types";

async function processSingle(_params: IExtensionParamsSingle): Promise<{
  batch: IExtensionResultTimestampsLD | IExtensionResultDurationsLD;
}> {
  throw new Error("Deprecated implementation in favor of Tranched Stepper.");
}

async function processGroup(_params: IExtensionParamsGroup): Promise<{
  batch: IExtensionResultDurationsLD | IExtensionResultTimestampsLD;
}> {
  throw new Error("Deprecated implementation in favor of Tranched Stepper.");
}
export const process = { group: processGroup, single: processSingle };
