import {
  FormProvider,
  useFormContext,
  useFormField,
  useFormStore,
} from "@sablier/v2-contexts";
import { _ } from "@sablier/v2-mixins";
import type { StreamShape } from "@sablier/v2-constants";
import type { IMilliseconds, IStoreSelector, IToken } from "@sablier/v2-types";
import type { PropsWithChildren } from "react";
import type { IExtension } from "~/client/extensions";

export interface IForm<T = IExtension> {
  address: {
    id: string;
    isFocused: boolean;
    isLoading: boolean;
    resolution?: {
      address?: string;
      ens?: string;
      preview?: string;
      short?: string;
    };
    value?: string;
    warning?: string;
  };
  amount: {
    id: string;
    isLoading: boolean;
    value?: string;
    warning?: string;
  };
  cancelability: {
    id: string;
    value: boolean;
    warning?: string;
  };
  transferability: {
    id: string;
    value: boolean;
    warning?: string;
  };
  duration: {
    id: string;
    isActive: boolean;
    value?: IMilliseconds;
    warning?: string;
  };
  start: {
    id: string;
    value?: IMilliseconds;
    warning?: string;
  };
  end: {
    id: string;
    value?: IMilliseconds;
    warning?: string;
  };
  max: {
    id: string;
    value?: string;
  };
  shape: {
    id: string;
    value: StreamShape;
  };
  token: {
    id: string;
    value?: IToken;
    warning?: string;
  };
  extension: T;
}

const initial: IForm = {
  address: {
    id: "address",
    isFocused: false,
    isLoading: false,
    resolution: undefined,
    value: undefined,
    warning: undefined,
  },
  amount: {
    id: "amount",
    isLoading: false,
    value: undefined,
    warning: undefined,
  },
  cancelability: {
    id: "cancelability",
    value: true,
    warning: undefined,
  },
  transferability: {
    id: "transferability",
    value: true,
    warning: undefined,
  },
  duration: {
    id: "duration",
    isActive: true,
    value: undefined,
    warning: undefined,
  },
  end: {
    id: "end",
    value: undefined,
    warning: undefined,
  },
  max: {
    id: "max",
    value: undefined,
  },
  shape: {
    id: "shape",
    value: "linear",
  },
  start: {
    id: "start",
    value: undefined,
    warning: undefined,
  },
  token: {
    id: "token",
    value: undefined,
    warning: undefined,
  },
  extension: {
    purpose: undefined,
  },
};

function Provider({ children }: PropsWithChildren<unknown>) {
  return <FormProvider<IForm> initial={initial}>{children}</FormProvider>;
}

function useAccessor<T = IExtension>() {
  return useFormContext<IForm<T>>().getState;
}

function useForm<Slice>(selector: IStoreSelector<IForm, Slice>): Slice {
  return useFormStore(selector);
}

function useField<I extends keyof IForm>(id: I) {
  return useFormField<IForm, I>(id);
}

export { useAccessor, useField, useForm };

export default Provider;
