import { _ } from "@sablier/v2-mixins";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import type { IExpected, RecursivePartial } from "@sablier/v2-types";
import type { IStoreExpected } from "~/client/types";
import { PERSIST_ID } from "../constants";

const initial: Omit<IStoreExpected, "api"> = {
  list: [],
};

const useStore = create<IStoreExpected>()(
  devtools(
    persist(
      (set) => ({
        ...initial,
        api: {
          removeItem: (id: string) =>
            set((prev) => {
              return {
                list: prev.list.filter(
                  (item) => item.id.toLowerCase() !== id.toLowerCase(),
                ),
              };
            }),
          addItem: (item: IExpected) =>
            set((prev) => {
              return { list: [...prev.list, item] };
            }),
          setList: (list: IExpected[]) =>
            set((_prev) => {
              return { list };
            }),
          updateItem: (update: RecursivePartial<IExpected> & { id: string }) =>
            set((prev) => {
              return {
                list: prev.list.map((item) => {
                  if (item.id.toLowerCase() !== update.id.toLowerCase()) {
                    return item;
                  }
                  return _.merge(item, update);
                }),
              };
            }),
          reset: (omit?: (keyof IStoreExpected)[]) =>
            set(() => _.omit(initial, omit || [])),
        },
      }),
      {
        name: PERSIST_ID.expected,
        partialize: (state) => ({ list: state.list }),
      },
    ),
  ),
);

export default useStore;
