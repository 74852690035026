import styled, { css } from "styled-components";
import { ProgressBar } from "~/components/atoms";
import type { ThemeType } from "@sablier/v2-themes";
import type { ComponentProps, PropsWithChildren } from "react";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

const Box = styled.div<{ color?: keyof ThemeType["colors"] }>`
  ${(props) => props.theme.styles.row}
  & {
    width: 100%;
    height: 100%;
    padding: calc(${(props) => props.theme.sizes.edge} * 3 / 4 - 2px);
    padding-right: 2px;
    border: 2px solid ${(props) => props.theme.colors.border};
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.dark150};
    & > * {
      width: 100%;
    }

    ${(props) =>
      props.color &&
      css`
        & > * {
          color: ${props.theme.colors[props.color]} !important;
        }
      `}
  }
`;

export interface Props extends ComponentProps<typeof ProgressBar> {
  className?: string;
  color?: keyof ThemeType["colors"];
}

export default function ProgressBox({
  className,
  color,
  ...props
}: PropsWithChildren<Props>) {
  return (
    <Wrapper className={className}>
      <Box color={color}>
        <ProgressBar {...props} />
      </Box>
    </Wrapper>
  );
}
