import {
  FormProvider,
  useFormContext,
  useFormField,
  useFormStore,
} from "@sablier/v2-contexts";
import { _ } from "@sablier/v2-mixins";
import type { IInputOption, IStoreSelector, IToken } from "@sablier/v2-types";
import type { PropsWithChildren } from "react";

export type IStreamOptionMore = { withAddress: string; withAlias: string };
export type IStreamOption = IInputOption<IStreamOptionMore>;

interface IForm {
  chain: {
    id: string;
    value: number | undefined;
    warning?: string;
  };
  recipient: {
    id: string;
    isFocused: boolean;
    isLoading: boolean;
    resolution?: {
      address?: string;
      ens?: string;
      preview?: string;
      short?: string;
    };
    value?: string;
    warning?: string;
  };
  sender: {
    id: string;
    isFocused: boolean;
    isLoading: boolean;
    resolution?: {
      address?: string;
      ens?: string;
      proxy?: string;
      preview?: string;
      short?: string;
    };
    value?: string;
    warning?: string;
  };

  streams: {
    id: string;
    current?: string;
    value: IStreamOption[];
    warning?: string;
  };
  token: {
    id: string;
    value?: IToken;
    warning?: string;
  };
}

const initial: IForm = {
  chain: {
    id: "chain",
    value: undefined,
    warning: undefined,
  },
  recipient: {
    id: "recipient",
    isFocused: false,
    isLoading: false,
    resolution: undefined,
    value: undefined,
    warning: undefined,
  },
  sender: {
    id: "sender",
    isFocused: false,
    isLoading: false,
    resolution: undefined,
    value: undefined,
    warning: undefined,
  },
  streams: {
    id: "streams",
    current: undefined,
    value: [],
    warning: undefined,
  },
  token: {
    id: "token",
    value: undefined,
    warning: undefined,
  },
};

function Provider({ children }: PropsWithChildren<unknown>) {
  return <FormProvider<IForm> initial={initial}>{children}</FormProvider>;
}

function useAccessor() {
  return useFormContext<IForm>().getState;
}

function useForm<Slice>(selector: IStoreSelector<IForm, Slice>): Slice {
  return useFormStore(selector);
}

function useField<I extends keyof IForm>(id: I) {
  return useFormField<IForm, I>(id);
}

export { useAccessor, useField, useForm };

export default Provider;
