import { useCallback, useEffect, useMemo, useState } from "react";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { QUERY_CACHE_ENS_TIME, chains } from "@sablier/v2-constants";
import { useRequestProxy } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import { IAddress, IProvider, ISigner, IWagmiConfig } from "@sablier/v2-types";
import {
  useAccount,
  useDisconnect,
  usePublicClient,
  useConfig as useWagmiConfig,
  useEnsName as useWagmiEnsName,
  useWalletClient,
} from "wagmi";
import { PERMISSION_ID, legacy, supported } from "~/client/constants";
import permissions from "~/client/stores/permissions";
import { peripheral } from "~/client/utils";
import { mock, safe } from "./connectors";

export const isHostSafe = safe.isHostSafe;
export const isHostCypress = mock.isHostCypress;
/** This is non-reactive so make sure to refresh the page after the value is reset */
export const isHostUnstable =
  !isHostSafe &&
  !isHostCypress &&
  permissions.getState().permissions[PERMISSION_ID.unstable_connector] === true;

export type IContext = {
  address: IAddress | undefined;
  proxy: IAddress | undefined;
  ens: string | undefined;
  chainId?: number;
  connect: (() => void) | undefined;
  connector: ReturnType<typeof useAccount>["connector"];
  disconnect: (() => void) | undefined;
  isConfigured: boolean;
  isConnected: boolean;
  isConnecting: boolean;
  isDisconnected: boolean;
  isHostSafe: boolean;
  isHostCypress: boolean;
  /** User is connected to a legacy chain, which doesn't allow creating streams */
  isLegacy: boolean;
  isMounted: boolean;
  isReconnecting: boolean;
  /** User is connected to a support chain */
  isSupported: boolean;
  /** System is mounted, chain is supported and account is not in loading state. General data can be fetched. */
  isReady: boolean;
  library: IWagmiConfig;
  provider: IProvider | undefined;
  signer: ISigner | undefined;
  status: ReturnType<typeof useAccount>["status"] | "unsure";
};

export const initial: IContext = {
  address: undefined,
  proxy: undefined,
  chainId: undefined,
  connect: undefined,
  connector: undefined,
  disconnect: undefined,
  ens: undefined,
  isConfigured: false,
  isConnected: false,
  isConnecting: true,
  isDisconnected: false,
  isHostSafe,
  isHostCypress,
  isLegacy: false,
  isMounted: false,
  isReady: false,
  isReconnecting: false,
  isSupported: false,
  library: undefined as unknown as IWagmiConfig,
  provider: undefined,
  signer: undefined,
  status: "disconnected",
};

export default function useConfig() {
  const [isMounted, setIsMounted] = useState(false);

  const { openConnectModal: connect } = useConnectModal();

  const account = useAccount();
  const chainId = useConfigChainId(isMounted);
  const library = useWagmiConfig();
  const disconnect = useConfigDisconnect();
  const ens = useConfigENS(isMounted);

  /**
   * Signer == WalletClient, Provider == PublicClient
   * Context in https://wagmi.sh/react/migration-guide#provider--signer-terminology
   */

  const provider = usePublicClient({ chainId });
  const { data: signer } = useWalletClient({ chainId });
  const isSupported = useMemo(
    () => !_.isNil(supported.find((item) => item.id === chainId)),
    [chainId],
  );

  const isLegacy = useMemo(() => legacy.includes(chainId ?? -1), [chainId]);

  const { proxy, isLoadingProxy } = useConfigProxy(isMounted && isSupported);
  const status = useConfigStatus(isLoadingProxy);

  const config = useMemo(() => {
    /**
     * Configure initial values - if not mounted,
     * revert to defaults
     */
    const conditional = !isMounted
      ? initial
      : {
          ...initial,
          ...account,
          address: _.toAddress(account.address) as string,
          proxy,
          connect,
          disconnect,
          ens,
          isMounted,
          status,
        };

    /**
     * Only allow the app to load user content once everything
     * is loaded and the user is connected
     */
    const isConfigured =
      account.isConnected &&
      !isLoadingProxy &&
      isMounted &&
      isSupported &&
      !_.isNilOrEmptyString(account.address) &&
      !_.isNil(signer);

    const isReady =
      isMounted &&
      isSupported &&
      !(account.isConnecting || account.isReconnecting);

    const common = {
      chainId,
      isConfigured,
      isLegacy,
      isReady,
      isSupported,
      library,
      provider: provider || undefined,
      signer: signer || undefined,
    };

    /**
     * Make a final alteration to the status if the user is
     * connected but the chosen chain is not supported
     */
    if (!isSupported && isMounted && account.status === "connected") {
      return {
        ...initial,
        chainId,
        disconnect,
        isConnected: false,
        isConnecting: false,
        isDisconnected: false,
        isMounted: true,
        isSupported: false,
        library,
        status: "unsure" as const,
      };
    }

    return {
      ...conditional,
      ...common,
    };
  }, [
    account,
    chainId,
    connect,
    disconnect,
    ens,
    isLegacy,
    isLoadingProxy,
    isMounted,
    isSupported,
    provider,
    proxy,
    signer,
    status,
    library,
  ]);

  useEffect(() => setIsMounted(true), []);

  return config;
}

/**
 * ------------------------------
 *  LOGIC | PIECES
 * ------------------------------
 */

function useConfigDisconnect() {
  const { disconnect: _disconnect } = useDisconnect();

  /**
   * Safe connectors should never disconnect
   */

  return useCallback(() => {
    if (!isHostSafe && _.isFunction(_disconnect)) {
      _disconnect();
    }
  }, [_disconnect]);
}

function useConfigENS(isMounted: boolean) {
  const { address } = useAccount();
  const chainId = useConfigChainId(isMounted);

  const { data: ens } = useWagmiEnsName({
    address,
    query: {
      gcTime: QUERY_CACHE_ENS_TIME,
      enabled:
        !!chainId &&
        [chains.mainnet.chainId, chains.sepolia.chainId].includes(chainId),
    },
  });

  return _.toString(ens || "");
}

function useConfigChainId(isMounted: boolean) {
  const { chain } = useAccount();
  const provider = usePublicClient({ chainId: chain?.id });

  return useMemo(() => {
    if (chain) {
      return chain.id;
    }
    if (isMounted && provider && provider.chain) {
      return provider.chain.id;
    }
    return undefined;
  }, [chain, isMounted, provider]);
}

function useConfigProxy(isReady: boolean) {
  const { address } = useAccount();
  const chainId = useConfigChainId(isReady);

  const { isLoading, value } = useRequestProxy({
    owner: address as string,
    chainId,
    registry: peripheral(chainId, "registry").address,
  });

  return useMemo(
    () => ({
      proxy: value,
      isLoadingProxy: isLoading,
    }),
    [isLoading, value],
  );
}

function useConfigStatus(isLoadingProxy: boolean) {
  const { status } = useAccount();

  return useMemo(() => {
    if (status === "connected" && isLoadingProxy) {
      return "connecting";
    }
    return status;
  }, [status, isLoadingProxy]);
}
