import { fallback, http } from "wagmi";
import type { IEnvironmentVariable } from "@sablier/v2-types";
import type { supported } from "~/client/constants";

const ALCHEMY = (process.env.NEXT_PUBLIC_ALCHEMY_ID ??
  "") satisfies IEnvironmentVariable;

const INFURA = (process.env.NEXT_PUBLIC_INFURA_ID ??
  "") satisfies IEnvironmentVariable;

export function transport(configurations: typeof supported) {
  return configurations.reduce((r, c) => {
    const priority = c.local.rpc.priority || [
      "infura",
      "alchemy",
      "hosted",
      "universal",
    ];

    const sequence = priority
      .map((solution) => {
        if (Object.keys(c.local.rpc).includes(solution)) {
          if (solution === "alchemy") {
            return c.local.rpc.alchemy?.(ALCHEMY);
          }

          if (solution === "infura") {
            return c.local.rpc.infura?.(INFURA);
          }
          return c.local.rpc[solution]?.();
        }

        return undefined;
      })
      .filter((s) => s)
      .map((s) => http(s));

    return { ...r, [c.id]: fallback(sequence) };
  }, {});
}
