import { useMemo } from "react";
import { ClockIcon } from "@heroicons/react/24/outline";
import { tags } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { IMilliseconds } from "@sablier/v2-types";
import { Base } from "../../system";

interface Props {
  className?: string;
  start: {
    value?: IMilliseconds;
    label?: string;
  };
  permission: string | undefined;
}

function Early({ className, start, permission = "execute" }: Props) {
  const { t } = useT();
  const description = useMemo(() => {
    const suffix = !_.isNilOrEmptyString(start?.label)
      ? `, ${start?.label}`
      : "";
    const date = `${_.toDuration(start?.value, "date-full")[0]} ${suffix}`;
    const reason = !_.isNilOrEmptyString(start?.value)
      ? t("warnings.permission.starting", { permission, date })
      : "";

    return t("warnings.later", { reason });
  }, [start, permission, t]);

  return (
    <Base
      className={className}
      cy={tags.warningEarly}
      description={description}
      label={{ icon: ClockIcon, value: t("warnings.early.title") }}
    />
  );
}

export default Early;
