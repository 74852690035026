import { Translate } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { IExtensionDependencies, ISummaryExtension } from "~/client/types";
import { IDynamicUnlockLinear } from "./config";

function summary(
  dependencies: IExtensionDependencies,
  extension: unknown,
  t: Translate,
): ISummaryExtension {
  const fields = extension as IDynamicUnlockLinear;
  const { token } = dependencies;

  const isComplete = !_.isNilOrEmptyString(fields.unlock.value);
  const isValid = _.isNilOrEmptyString(fields.unlock.warning);

  return {
    isComplete,
    isValid,
    list: [
      {
        label: `${_.capitalize(t("structs.unlockLabel"))}:`,
        purpose: "amount",
        token,
        value: fields.unlock.value?.toString(),
      },
    ],
  };
}

export default summary;
