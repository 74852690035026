import { gql } from "../../generated/airstream/gql";

/**
 * AND (4)
 *
 * +==============================================================+
 * |                                                              |
 * |     █████╗ ███╗   ██╗██████╗      ██╗    ██╗  ██╗    ██╗     |
 * |    ██╔══██╗████╗  ██║██╔══██╗    ██╔╝    ██║  ██║    ╚██╗    |
 * |    ███████║██╔██╗ ██║██║  ██║    ██║     ███████║     ██║    |
 * |    ██╔══██║██║╚██╗██║██║  ██║    ██║     ╚════██║     ██║    |
 * |    ██║  ██║██║ ╚████║██████╔╝    ╚██╗         ██║    ██╔╝    |
 * |    ╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝      ╚═╝         ╚═╝    ╚═╝     |
 * |                                                              |
 * +==============================================================+
 *
 */

export const getAirstreams_ByAliveByAdminByIdsByName = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByAdminByIdsByName(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $admin: Bytes!
    $airstreamIds: [String!]
    $name: String!
    $subgraphId: BigInt!
    $currentTimestamp: BigInt!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { admin: $admin }
          { id_in: $airstreamIds }
          { chainId: $chainId }
          { nickname_contains: $name }
          { subgraphId_lt: $subgraphId }
          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByAdminByAssetByName = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByAdminByAssetByName(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $admin: Bytes!
    $asset: String!
    $excludeIds: [String!]
    $name: String!
    $currentTimestamp: BigInt!
    $subgraphId: BigInt!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { admin: $admin }
          { id_not_in: $excludeIds }
          { asset: $asset }
          { chainId: $chainId }
          { nickname_contains: $name }
          { subgraphId_lt: $subgraphId }
          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

/**
 * AND (3)
 *
 * +==============================================================+
 * |                                                              |
 * |     █████╗ ███╗   ██╗██████╗      ██╗    ██████╗     ██╗     |
 * |    ██╔══██╗████╗  ██║██╔══██╗    ██╔╝    ╚════██╗    ╚██╗    |
 * |    ███████║██╔██╗ ██║██║  ██║    ██║      █████╔╝     ██║    |
 * |    ██╔══██║██║╚██╗██║██║  ██║    ██║      ╚═══██╗     ██║    |
 * |    ██║  ██║██║ ╚████║██████╔╝    ╚██╗    ██████╔╝    ██╔╝    |
 * |    ╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝      ╚═╝    ╚═════╝     ╚═╝     |
 * |                                                              |
 * +==============================================================+
 */
export const getAirstreams_ByAdminByIdsByName = gql(/* GraphQL */ `
  query getAirstreams_ByAdminByIdsByName(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $admin: Bytes!
    $airstreamIds: [String!]
    $name: String!
    $subgraphId: BigInt!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { admin: $admin }
          { id_in: $airstreamIds }
          { chainId: { _eq: $chainId } }
          { nickname_contains: $name }
          { subgraphId_lt: $subgraphId }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAdminByAssetByName = gql(/* GraphQL */ `
  query getAirstreams_ByAdminByAssetByName(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $admin: Bytes!
    $asset: String!
    $excludeIds: [String!]
    $name: String!
    $subgraphId: BigInt!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { admin: $admin }
          { id_not_in: $excludeIds }
          { asset: $asset }
          { chainId: $chainId }
          { nickname_contains: $name }
          { subgraphId_lt: $subgraphId }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByAdminByName = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByAdminByName(
    $first: Int!
    $skip: Int!
    $chainId: BigInt
    $admin: Bytes!
    $excludeIds: [String!]
    $name: String!
    $subgraphId: BigInt!
    $currentTimestamp: BigInt!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { admin: $admin }
          { id_not_in: $excludeIds }
          { chainId: $chainId }
          { nickname_contains: $name }
          { subgraphId_lt: $subgraphId }
          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByIdsByName = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByIdsByName(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $airstreamIds: [String!]
    $name: String!
    $subgraphId: BigInt!
    $currentTimestamp: BigInt!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { id_in: $airstreamIds }
          { chainId: $chainId }
          { nickname_contains: $name }
          { subgraphId_lt: $subgraphId }
          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByAssetByName = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByAssetByName(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $asset: String!
    $excludeIds: [String!]
    $name: String!
    $subgraphId: BigInt!
    $currentTimestamp: BigInt!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { asset: $asset }
          { id_not_in: $excludeIds }
          { chainId: $chainId }
          { nickname_contains: $name }
          { subgraphId_lt: $subgraphId }
          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByAdminByIds = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByAdminByIds(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $admin: Bytes!
    $airstreamIds: [String!]
    $subgraphId: BigInt!
    $currentTimestamp: BigInt!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { admin: $admin }
          { id_in: $airstreamIds }
          { chainId: $chainId }
          { subgraphId_lt: $subgraphId }
          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByAdminByAsset = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByAdminByAsset(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $admin: Bytes!
    $asset: String!
    $subgraphId: BigInt!
    $currentTimestamp: BigInt!
    $excludeIds: [String!]
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { admin: $admin }
          { id_not_in: $excludeIds }
          { asset: $asset }
          { chainId: $chainId }
          { subgraphId_lt: $subgraphId }
          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

/**
 * AND (2)
 *
 * +==============================================================+
 * |                                                              |
 * |     █████╗ ███╗   ██╗██████╗      ██╗    ██████╗     ██╗     |
 * |    ██╔══██╗████╗  ██║██╔══██╗    ██╔╝    ╚════██╗    ╚██╗    |
 * |    ███████║██╔██╗ ██║██║  ██║    ██║      █████╔╝     ██║    |
 * |    ██╔══██║██║╚██╗██║██║  ██║    ██║     ██╔═══╝      ██║    |
 * |    ██║  ██║██║ ╚████║██████╔╝    ╚██╗    ███████╗    ██╔╝    |
 * |    ╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝      ╚═╝    ╚══════╝    ╚═╝     |
 * |                                                              |
 * +==============================================================+
 */

export const getAirstreams_ByAliveByName = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByName(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $excludeIds: [String!]
    $name: String!
    $currentTimestamp: BigInt!
    $subgraphId: BigInt!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { chainId: $chainId }
          { id_not_in: $excludeIds }
          { nickname_contains: $name }
          { subgraphId_lt: $subgraphId }
          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAdminByName = gql(/* GraphQL */ `
  query getAirstreams_ByAdminByName(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $admin: Bytes!
    $subgraphId: BigInt!
    $name: String!
    $excludeIds: [String!]
    $asset: String # Required for compatibility
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { admin: $admin }
          { id_not_in: $excludeIds }
          { chainId: $chainId }
          { nickname_contains: $name }
          { subgraphId_lt: $subgraphId }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByIdsByName = gql(/* GraphQL */ `
  query getAirstreams_ByIdsByName(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $airstreamIds: [String!]
    $name: String!
    $subgraphId: BigInt!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { id_in: $airstreamIds }
          { chainId: $chainId }
          { nickname_contains: $name }
          { subgraphId_lt: $subgraphId }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByCIDsByName = gql(/* GraphQL */ `
  query getAirstreams_ByCIDsByName(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $airstreamCIDs: [String!]
    $excludeIds: [String!]
    $subgraphId: BigInt!
    $name: String!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { ipfsCID_in: $airstreamCIDs }
          { id_not_in: $excludeIds }
          { chainId: $chainId }
          { nickname_contains: $name }
          { subgraphId_lt: $subgraphId }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAssetByName = gql(/* GraphQL */ `
  query getAirstreams_ByAssetByName(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $asset: String!
    $subgraphId: BigInt!
    $excludeIds: [String!]
    $name: String!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { asset: $asset }
          { chainId: $chainId }
          { id_not_in: $excludeIds }
          { nickname_contains: $name }
          { subgraphId_lt: $subgraphId }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAdminByIds = gql(/* GraphQL */ `
  query getAirstreams_ByAdminByIds(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $admin: Bytes!
    $airstreamIds: [String!]
    $subgraphId: BigInt!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { admin: $admin }
          { id_in: $airstreamIds }
          { chainId: { _eq: $chainId } }
          { subgraphId_lt: $subgraphId }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAdminByAsset = gql(/* GraphQL */ `
  query getAirstreams_ByAdminByAsset(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $admin: Bytes!
    $asset: String!
    $excludeIds: [String!]
    $subgraphId: BigInt!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { admin: $admin }
          { id_not_in: $excludeIds }
          { asset: $asset }
          { chainId: $chainId }
          { subgraphId_lt: $subgraphId }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByAdmin = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByAdmin(
    $first: Int!
    $skip: Int!
    $chainId: BigInt
    $admin: Bytes!
    $subgraphId: BigInt!
    $excludeIds: [String!]
    $currentTimestamp: BigInt!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { admin: $admin }
          { id_not_in: $excludeIds }
          { chainId: $chainId }
          { subgraphId_lt: $subgraphId }
          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByIds = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByIds(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $airstreamIds: [String!]
    $subgraphId: BigInt!
    $currentTimestamp: BigInt!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { id_in: $airstreamIds }
          { chainId: $chainId }
          { subgraphId_lt: $subgraphId }
          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByAsset = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByAsset(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $asset: String!
    $subgraphId: BigInt!
    $currentTimestamp: BigInt!
    $excludeIds: [String!]
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { asset: $asset }
          { id_not_in: $excludeIds }
          { chainId: $chainId }
          { subgraphId_lt: $subgraphId }
          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

/**
 * ONE
 *
 * +===================================+
 * |                                   |
 * |     ██████╗ ███╗   ██╗███████╗    |
 * |    ██╔═══██╗████╗  ██║██╔════╝    |
 * |    ██║   ██║██╔██╗ ██║█████╗      |
 * |    ██║   ██║██║╚██╗██║██╔══╝      |
 * |    ╚██████╔╝██║ ╚████║███████╗    |
 * |     ╚═════╝ ╚═╝  ╚═══╝╚══════╝    |
 * |                                   |
 * +===================================+
 */

export const getAirstreams_ByName = gql(/* GraphQL */ `
  query getAirstreams_ByName(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $currentTimestamp: BigInt!
    $subgraphId: BigInt!
    $excludeIds: [String!]
    $name: String!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { chainId: $chainId }
          { id_not_in: $excludeIds }
          { nickname_contains: $name }
          { subgraphId_lt: $subgraphId }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAlive = gql(/* GraphQL */ `
  query getAirstreams_ByAlive(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $currentTimestamp: BigInt!
    $subgraphId: BigInt!
    $excludeIds: [String!]
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { chainId: $chainId }
          { id_not_in: $excludeIds }
          { subgraphId_lt: $subgraphId }
          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAdmin = gql(/* GraphQL */ `
  query getAirstreams_ByAdmin(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $admin: Bytes!
    $subgraphId: BigInt!
    $excludeIds: [String!]
    $asset: String # Required for compatibility
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        and: [
          { admin: $admin }
          { id_not_in: $excludeIds }
          { chainId: $chainId }
          { subgraphId_lt: $subgraphId }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByIds = gql(/* GraphQL */ `
  query getAirstreams_ByIds(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $airstreamIds: [String!]
    $subgraphId: BigInt!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        id_in: $airstreamIds
        chainId: $chainId
        subgraphId_lt: $subgraphId
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByCIDs = gql(/* GraphQL */ `
  query getAirstreams_ByCIDs(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $airstreamCIDs: [String!]
    $excludeIds: [String!]
    $subgraphId: BigInt!
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        ipfsCID_in: $airstreamCIDs
        id_not_in: $excludeIds
        chainId: $chainId
        subgraphId_lt: $subgraphId
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAsset = gql(/* GraphQL */ `
  query getAirstreams_ByAsset(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $asset: String!
    $subgraphId: BigInt!
    $excludeIds: [String!]
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        asset: $asset
        chainId: $chainId
        id_not_in: $excludeIds
        subgraphId_lt: $subgraphId
      }
    ) {
      ...CampaignFragment
    }
  }
`);

/**
 * GENERAL
 *
 * +===================================================================+
 * |                                                                   |
 * |     ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ██╗         |
 * |    ██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗██║         |
 * |    ██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║██║         |
 * |    ██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║██║         |
 * |    ╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║███████╗    |
 * |     ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝    |
 * |                                                                   |
 * +===================================================================+
 */

export const getAirstreams = gql(/* GraphQL */ `
  query getAirstreams(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $subgraphId: BigInt!
    $excludeIds: [String!]
    $asset: String # Required for compatibility
  ) {
    campaigns(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        subgraphId_lt: $subgraphId
        chainId: $chainId
        id_not_in: $excludeIds
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstream_ById = gql(/* GraphQL */ `
  query getAirstream_ById($airstreamId: ID!) {
    campaign(id: $airstreamId) {
      ...CampaignFragment
    }
  }
`);

export const getActions_ByAirstream = gql(/* GraphQL */ `
  query getActions_ByAirstream(
    $first: Int!
    $airstreamId: String!
    $subgraphId: BigInt!
  ) {
    actions(
      first: $first
      orderBy: subgraphId
      orderDirection: desc
      where: { campaign: $airstreamId, subgraphId_lt: $subgraphId }
    ) {
      ...ActionFragment
      campaign {
        id
        asset {
          ...AssetFragment
        }
      }
    }
  }
`);

export const getMetadata_ByAirstream = gql(/* GraphQL */ `
  query getMetadata_ByAirstream(
    $airstreamId: ID!
    $airstreamIdClone: String!
    $dayFrom: BigInt!
    $dayTo: BigInt!
  ) {
    campaign(id: $airstreamId) {
      id
      asset {
        ...AssetFragment
      }
      activities(first: 7, where: { day_gte: $dayFrom, day_lte: $dayTo }) {
        ...ActivityFragment
      }
      actions(first: 5, orderBy: subgraphId, orderDirection: desc) {
        ...ActionFragment
      }
    }
    actions(
      first: 10
      orderBy: subgraphId
      orderDirection: desc
      where: { campaign: $airstreamIdClone, category: Claim }
    ) {
      ...ActionFragment
    }
    firsts: actions(
      first: 1
      orderBy: timestamp
      orderDirection: asc
      where: { campaign: $airstreamIdClone, category: Claim }
    ) {
      ...ActionFragment
    }
  }
`);
