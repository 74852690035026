import {
  FormProvider,
  useFormContext,
  useFormField,
  useFormStore,
} from "@sablier/v2-contexts";
import { _ } from "@sablier/v2-mixins";
import type { IAddress, IStoreSelector } from "@sablier/v2-types";
import type { PropsWithChildren } from "react";

export interface IForm {
  address: {
    id: string;
    isFocused: boolean;
    isLoading: boolean;
    resolution?: {
      address: IAddress | undefined;
      ens: string | undefined;
      preview: string | undefined;
      short: string | undefined;
    };
    value: IAddress | undefined;
    warning: string | undefined;
  };
  max: {
    id: string;
    value: string | undefined;
  };
  toggle: {
    id: string;
    value: boolean;
  };
}

const initial: IForm = {
  address: {
    id: "address",
    isFocused: false,
    isLoading: false,
    resolution: undefined,
    value: undefined,
    warning: undefined,
  },
  max: {
    id: "max",
    value: undefined,
  },
  toggle: {
    id: "toggle",
    value: true,
  },
};

function Provider({ children }: PropsWithChildren<unknown>) {
  return <FormProvider<IForm> initial={initial}>{children}</FormProvider>;
}

function useAccessor() {
  return useFormContext<IForm>().getState;
}

function useForm<Slice>(selector: IStoreSelector<IForm, Slice>): Slice {
  return useFormStore(selector);
}

function useField<I extends keyof IForm>(id: I) {
  return useFormField<IForm, I>(id);
}

export { useAccessor, useField, useForm };

export default Provider;
