import { useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import { queries } from "@sablier/v2-subgraphs";
import type { IFilterAirstream } from "@sablier/v2-models";

export default function useRequestAirstreamListQuery(filter: IFilterAirstream) {
  const isIds = useMemo(
    () =>
      !_.isNil(filter.airstreamIds) &&
      !filter.airstreamIds.find((id) => _.isNilOrEmptyString(id)),
    [filter],
  );
  const isCIDs = useMemo(
    () =>
      !_.isNil(filter.airstreamCIDs) &&
      !filter.airstreamCIDs.find((id) => _.isNilOrEmptyString(id)),
    [filter],
  );
  const isAdmin = useMemo(() => !_.isNilOrEmptyString(filter.admin), [filter]);
  const isAsset = useMemo(() => !_.isNilOrEmptyString(filter.token), [filter]);
  const isAlive = useMemo(
    () => !_.isNilOrEmptyString(filter.isAlive) && filter.isAlive,
    [filter],
  );
  const isName = useMemo(() => !_.isNilOrEmptyString(filter.name), [filter]);

  return useMemo(() => {
    if (isAdmin && isIds && isAlive && isName) {
      return queries.airstream.getAirstreams_ByAliveByAdminByIdsByName;
    }

    if (isAdmin && isAsset && isAlive && isName) {
      return queries.airstream.getAirstreams_ByAliveByAdminByAssetByName;
    }

    if (isAdmin && isIds && isAlive) {
      return queries.airstream.getAirstreams_ByAliveByAdminByIds;
    }

    if (isAdmin && isAsset && isAlive) {
      return queries.airstream.getAirstreams_ByAliveByAdminByAsset;
    }

    if (isAdmin && isIds && isName) {
      return queries.airstream.getAirstreams_ByAdminByIdsByName;
    }

    if (isAdmin && isAsset && isName) {
      return queries.airstream.getAirstreams_ByAdminByAssetByName;
    }

    if (isAsset && isAlive && isName) {
      return queries.airstream.getAirstreams_ByAliveByAssetByName;
    }

    if (isAdmin && isAlive && isName) {
      return queries.airstream.getAirstreams_ByAliveByAdminByName;
    }

    if (isIds && isAlive && isName) {
      return queries.airstream.getAirstreams_ByAliveByIdsByName;
    }

    if (isAdmin && isIds) {
      return queries.airstream.getAirstreams_ByAdminByIds;
    }

    if (isAdmin && isAsset) {
      return queries.airstream.getAirstreams_ByAdminByAsset;
    }

    if (isAsset && isAlive) {
      return queries.airstream.getAirstreams_ByAliveByAsset;
    }

    if (isAdmin && isAlive) {
      return queries.airstream.getAirstreams_ByAliveByAdmin;
    }

    if (isIds && isAlive) {
      return queries.airstream.getAirstreams_ByAliveByIds;
    }

    if (isAsset && isName) {
      return queries.airstream.getAirstreams_ByAssetByName;
    }

    if (isAdmin && isName) {
      return queries.airstream.getAirstreams_ByAdminByName;
    }

    if (isIds && isName) {
      return queries.airstream.getAirstreams_ByIdsByName;
    }

    if (isAlive && isName) {
      return queries.airstream.getAirstreams_ByAliveByName;
    }

    if (isCIDs && isName) {
      return queries.airstream.getAirstreams_ByCIDsByName;
    }

    if (isAsset) {
      return queries.airstream.getAirstreams_ByAsset;
    }

    if (isAdmin) {
      return queries.airstream.getAirstreams_ByAdmin;
    }

    if (isIds) {
      return queries.airstream.getAirstreams_ByIds;
    }

    if (isAlive) {
      return queries.airstream.getAirstreams_ByAlive;
    }

    if (isCIDs) {
      return queries.airstream.getAirstreams_ByCIDs;
    }

    if (isName) {
      return queries.airstream.getAirstreams_ByName;
    }

    return queries.airstream.getAirstreams;
  }, [isIds, isAdmin, isAsset, isAlive, isCIDs, isName]);
}
