import { ComponentProps, useMemo } from "react";
import styled from "styled-components";
import { ArrowRightIcon, UserIcon } from "@heroicons/react/24/outline";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { IAddress, IAirstreamId, ITokenDisplay } from "@sablier/v2-types";
import Button from "../Button";
import Label from "../Label";
import ProgressBox from "../ProgressBox";
import Token from "../Token";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    grid-column: span 1;
    position: relative;
    gap: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    min-height: var(
      --grid-option-row,
      ${(props) => props.theme.sizes.airstreamRow}
    );
    padding: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    border: 2px solid ${(props) => props.theme.colors.border};
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.dark200};
    overflow: hidden;
    & > * {
      z-index: ${(props) => props.theme.sizes.zIndexOver};
    }
  }
`;

const Underlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${(props) => props.theme.sizes.zIndexUnder};
  width: 100%;
  height: 100%;
`;

const Light = styled.div`
  position: absolute;
  top: -80px;
  right: -80px;
  width: 160px;
  height: 160px;
  border-radius: 80px;
  background-color: ${(props) => props.theme.colors.dark500};
  filter: blur(100px);
`;

const Top = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 1);
    width: 100%;
  }
`;

const Header = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    gap: 2px;
    width: 100%;
  }
`;

const Title = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    color: ${(props) => props.theme.colors.white};
    & > p {
      ${(props) => props.theme.styles.textBase}
      font-size: 16pt;
      line-height: 150%;
    }
  }
`;

const Brand = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    justify-content: center;
    align-items: center;
    height: 60px;
    margin-left: calc(${(props) => props.theme.sizes.edge} * 3 / 2 * -1);
    padding: 12px;
    padding-left: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    border-radius: 0 8px 8px 0;
    background-color: ${(props) => props.theme.colors.border};
    & *[data-component="icon"] {
      --size: 36px;
    }
  }
`;

const Body = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex-shrink: 0;
    flex-wrap: wrap;
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    align-items: flex-start;
    width: 100%;
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    border-bottom: 2px solid ${(props) => props.theme.colors.border};
  }
`;

const Footer = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    width: 100%;
    margin-top: auto;

    & > *:first-child {
      flex: 1;
      *[data-component="progress-label"] {
        width: auto;
        padding-right: 8px;
      }
    }
  }
`;

const Prefix = styled.div`
  --size: 12pt;
  --thickness: 3px;
  color: ${(props) => props.theme.colors.gray400};
  font-weight: 700;
`;

const Detail = styled(Button)`
  *[data-component="title"] > p {
    font-weight: 500;
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxMD} {
    border-radius: 4px;
  }
  ${(props) => props.theme.medias.maxSM} {
    padding: calc(${(props) => props.theme.sizes.edge} * 1);

    ${Title} > p {
      ${(props) => props.theme.styles.ellipsis}
      & {
        width: 60vw;
        font-size: 14pt;
      }
    }
  }

  ${(props) => props.theme.medias.maxXS} {
    ${Footer} {
      ${Prefix} {
        *[data-component="label"] {
          display: none;
        }
      }
    }

    ${Title} > p {
      width: 60vw;
      font-size: 14pt;
    }
    ${Brand} {
      height: 40px;
      margin-left: calc(${(props) => props.theme.sizes.edge} * -1);
      padding: 8px;
      border-radius: 0 4px 4px 0;
      & *[data-component="icon"] {
        --size: 24px;
      }
    }
  }

  ${(props) => props.theme.medias.max(240)} {
    ${Footer} {
      flex-direction: column;
      align-items: flex-end;
    }
    ${Title} > p {
      width: 54vw;
    }
  }
`;

interface Props {
  title: (e: string) => string;
  identifier: IAirstreamId;
  admin: IAddress | undefined;
  chainId: number;
  action?: ComponentProps<typeof Button>;
  details: ComponentProps<typeof Button>[];
  progress: Omit<ComponentProps<typeof ProgressBox>, "color" | "purpose">;
  token?: ITokenDisplay;
}

function AirstreamCard({
  action,
  admin,
  details,
  progress,
  token,
  title: title_,
}: Props) {
  const { t } = useT();

  const title = useMemo(() => {
    return title_(_.toShortAddress(admin, 6));
  }, [admin, title_]);

  return (
    <Wrapper>
      <Top>
        <Brand>
          <Token {...token} symbol={undefined} isFallbackShown />
        </Brand>
        <Header>
          <Label value={_.capitalize(t("words.campaign"))} />
          <Title>
            <p>{title}</p>
          </Title>
        </Header>
      </Top>
      <Body>
        {details.map((detail, index) => (
          <Detail
            accent={"iconicLabel"}
            appearance={"solid"}
            isMini
            isPreview
            key={index}
            {...(detail || {})}
          />
        ))}
      </Body>
      <Footer>
        <ProgressBox {...progress} color={"purpleStart"} purpose={"linear"}>
          <Prefix>
            <Label
              icon={UserIcon}
              value={`${_.capitalize(t("words.claimed"))}:`}
            />
          </Prefix>
        </ProgressBox>
        <Button
          accent={"iconicTransparent"}
          appearance={"outline"}
          title={_.capitalize(t("structs.viewDetails"))}
          titleLarge={_.capitalize(t("words.view"))}
          titleShort={_.capitalize(t("words.view"))}
          right={ArrowRightIcon}
          {...(action || {})}
        />
      </Footer>
      <Underlay>
        <Light />
      </Underlay>
    </Wrapper>
  );
}

export default AirstreamCard;
