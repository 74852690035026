import { useEffect, useMemo } from "react";
import { useIsSanctioned } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import { useRouter } from "next/router";
import { useModalSanctionAirstream } from "./modals";
import useAirstreamCurrent from "./useAirstreamCurrent";

export default function useIsSanctionedAirstream() {
  const { isReady } = useRouter();
  const { airstream, isLoading } = useAirstreamCurrent();
  const { isOpen, setOpen } = useModalSanctionAirstream();

  const { isSanctioned: isAdminSanctioned } = useIsSanctioned(airstream?.admin);
  const { isSanctioned: isTokenSanctioned } = useIsSanctioned(
    airstream?.token.address,
  );

  const isSanctioned = useMemo(
    () => isAdminSanctioned || isTokenSanctioned,
    [isAdminSanctioned, isTokenSanctioned],
  );

  useEffect(() => {
    if (_.isNil(airstream) || isLoading || !isReady) {
      return;
    }

    if (!isOpen && isSanctioned) {
      setOpen(true);
    }
  }, [airstream, isLoading, isReady, isOpen, isSanctioned, setOpen]);

  return {
    isSanctioned,
  };
}
