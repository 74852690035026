import { useMemo } from "react";
import {
  useRequestAirstreamSettings,
  useRequestMultisigOwners,
} from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import useAccount from "./useAccount";
import useAirstreamCurrent from "./useAirstreamCurrent";

function useAirstreamCurrentDetails(omit?: ("owners" | "settings")[]) {
  const { address } = useAccount();
  const { airstream, isAdmin, isReady } = useAirstreamCurrent();

  const {
    data: details,
    isLoading: isLoadingDetails,
    error: errorOnDetails,
  } = useRequestAirstreamSettings({
    address: airstream?.address,
    chainId: airstream?.chainId,
    isEnabled: isReady && !(omit || []).includes("settings"),
  });

  const {
    data: owners,
    isLoading: isLoadingOwners,
    error: errorOnOwners,
  } = useRequestMultisigOwners({
    contract: airstream?.admin,
    chainId: airstream?.chainId,
    isEnabled: isReady && !(omit || []).includes("owners"),
  });

  const isLoading = useMemo(
    () => !isReady || isLoadingDetails || isLoadingOwners,
    [isReady, isLoadingDetails, isLoadingOwners],
  );

  const isEditable = useMemo(
    () =>
      isAdmin ||
      (!_.isNil(owners) &&
        owners.some((owner) => owner.toLowerCase() === address)),
    [isAdmin, owners, address],
  );

  const isMissing = useMemo(
    () =>
      !_.isNilOrEmptyString(errorOnDetails) ||
      (!isLoading && _.isNilOrEmptyString(details)),
    [errorOnDetails, details, isLoading],
  );

  return {
    details,
    isEditable,
    isMissing,
    isLoading,
    owners,
    errorOnDetails,
    errorOnOwners,
  };
}

export default useAirstreamCurrentDetails;
