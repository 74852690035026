import { useCallback, useEffect, useMemo } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  BACKWEIGHTED_YEARS_MAX,
  BACKWEIGHTED_YEARS_MIN,
} from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { guards } from "@sablier/v2-machines";
import { BigNumber, _ } from "@sablier/v2-mixins";
import vendors from "@sablier/v2-utils/vendors";
import {
  useModalMomentPicker,
  useStreamCreateGroupAccessor,
  useStreamCreateGroupField,
} from "~/client/hooks";
import { setup } from "../../system/setup";
import { IUnlock, getDefaultDistribution } from "./config";
import { type ITranchedBackweighted } from "./config";

type IExtension = ITranchedBackweighted;

function useExtendedGroupAccess() {
  return useStreamCreateGroupAccessor<IExtension>();
}

function useExtendedStream(id: string) {
  const streams = useStreamCreateGroupField("streams");
  const stream = useMemo(
    () => streams.field.value.find((s) => s.id === id),
    [id, streams],
  );

  return stream as typeof stream & { extension: IExtension };
}

export function useGroupFieldYears(id: string) {
  const access = useExtendedGroupAccess();
  const stream = useExtendedStream(id);
  const { t } = useT();
  const years = useMemo(() => ({ field: stream.extension.years }), [stream]);

  const onBlur = useCallback(() => {
    const { index, isValid, stream, streams, update } = setup(access, id);

    if (!isValid) {
      return;
    }

    const value = stream.extension.years.value;
    const end = _.addCalendarUnit(
      stream.start.value || "0",
      value || "0",
      "year",
    );

    if (_.isNilOrEmptyString(value)) {
      const edited = {
        ...stream,
        end: {
          ...stream.end,
          warning: undefined,
        },
        extension: {
          ...stream.extension,
          years: {
            ...stream.extension.years,
            warning: undefined,
          },
        },
      };

      const cloned = [...streams];
      cloned.splice(index, 1, edited);

      update({
        streams: {
          value: cloned,
        },
      });
    } else {
      const warning = {
        /** Check the end date is not in the past (e.g. past start date + too few months) */
        end: guards.validateMoment({
          isExcess: true,
          purpose: "datetoday",
          t,
          value: end,
          min: new Date().valueOf().toString(),
        }),
        years: guards.validateAmount({
          t,
          context: "steps",
          max: (BACKWEIGHTED_YEARS_MAX + 1).toString() /** Strict < */,
          value,
          min: (BACKWEIGHTED_YEARS_MIN - 1).toString() /** Strict > */,
        }),
      };

      const error =
        warning.years ||
        (warning.end && !_.isNilOrEmptyString(stream.start.value)
          ? `${_.capitalize(t("structs.endDate"))}: ${_.lowerFirst(
              warning.end,
            )}`
          : undefined);

      const edited = {
        ...stream,
        end: {
          ...stream.end,
          value: end,
          warning: error,
        },
        extension: {
          ...stream.extension,
          years: {
            ...stream.extension.years,
            warning: error,
          },
        },
      };

      const cloned = [...streams];
      cloned.splice(index, 1, edited);

      update({
        streams: {
          value: cloned,
        },
      });
    }
  }, [access, id, t]);

  const onChange = useCallback(
    (value: string | undefined) => {
      const { index, isValid, stream, streams, update } = setup(access, id);

      if (!isValid) {
        return;
      }

      const newYears = _.toNumber(value ?? "0");
      const currentYears = _.toNumber(stream.extension.years.value ?? "0");
      let unlocks: IUnlock[] = [];

      if (newYears >= currentYears) {
        const newUnlocks = Array.from(
          {
            length: newYears - currentYears,
          },
          (_, i) =>
            ({
              year: (currentYears + i + 1).toString(),
              percentage: undefined,
              warning: undefined,
            } as IUnlock),
        );
        unlocks = [...stream.extension.unlocks.value, ...newUnlocks];
      } else {
        unlocks = [...stream.extension.unlocks.value]
          .sort((a, b) => {
            if (_.toNumber(a.year) < _.toNumber(b.year)) {
              return -1;
            }
            return 1;
          })
          .slice(0, newYears);
      }

      // const unlocks = getDefaultDistribution(value ?? "");

      const edited = {
        ...stream,
        extension: {
          ...stream.extension,
          years: {
            ...stream.extension.years,
            value,
            warning: undefined,
          },
          unlocks: {
            ...stream.extension.unlocks,
            value: unlocks,
          },
        },
      };

      const cloned = [...streams];
      cloned.splice(index, 1, edited);

      update({
        streams: {
          value: cloned,
        },
      });
    },
    [access, id],
  );

  const warning = useMemo(
    () => stream.extension.years.warning || stream.end.warning,
    [stream.end.warning, stream.extension.years.warning],
  );

  const measure = useMemo(() => {
    if (
      !_.isNilOrEmptyString(stream.extension.years.value) &&
      !_.isNilOrEmptyString(stream.start.value)
    ) {
      return `${t("words.ending")} ${
        _.toDuration(stream.end.value, "date-only")[0]
      }`;
    }
    return undefined;
  }, [stream.end.value, stream.extension.years.value, stream.start.value, t]);

  const label = useMemo(
    () => ({
      value: t("form.label.years"),
      icon: InformationCircleIcon,
      isIconLast: true,
      tooltip: {
        value: t("descriptions.years"),
      },
    }),
    [t],
  );

  /** React to linked fields: stream linked-start time influences stream linked-end (through months) */
  useEffect(() => {
    onBlur();
  }, [stream.start.value, onBlur]);

  return {
    label,
    years,
    measure,

    onChange,
    onBlur,
    warning,
  };
}

export function useGroupFieldStart(id: string) {
  const { t } = useT();
  const access = useExtendedGroupAccess();
  const stream = useExtendedStream(id);
  const start = useMemo(() => ({ field: stream.start }), [stream]);
  const { setOpen } = useModalMomentPicker();

  const label = useMemo(
    () => ({
      icon: InformationCircleIcon,
      isIconLast: true,
      tooltip: {
        value: t("descriptions.dates.settings", { zone: _.timezone().zone }),
      },
      value: t("structs.startDate"),
    }),
    [t],
  );

  const onConfirm = useCallback(
    (value?: string) => {
      const { index, isValid, stream, streams, update } = setup(access, id);

      if (!isValid) {
        return;
      }

      const edited = {
        ...stream,
        start: {
          ...stream.start,
          value,
          warning: undefined,
        },
      };

      const cloned = [...streams];
      cloned.splice(index, 1, edited);

      update({
        streams: {
          value: cloned,
        },
      });
    },
    [access, id],
  );

  const onClick = useCallback(() => {
    vendors.track.log((events) => {
      return events.openModalFrom({
        nameKey: "moment",
        placeKey: "createGroup",
      });
    });
    setOpen(true, {
      milliseconds: start.field.value,
      onConfirm,
      purpose: t("structs.startDate"),
    });
  }, [onConfirm, setOpen, start.field.value, t]);

  return { onClick, start, label };
}

export function useGroupFieldUnlocks(id: string) {
  const stream = useExtendedStream(id);
  const access = useExtendedGroupAccess();
  const { t } = useT();
  const unlocks = useMemo(
    () => ({ field: stream.extension.unlocks }),
    [stream],
  );

  const onBlurYearPercentage = useCallback(
    (year: string) => {
      const { index, isValid, stream, streams, update } = setup(access, id);

      if (!isValid) {
        return;
      }

      const extension = stream.extension;
      const unlock = extension.unlocks.value.filter(
        (unlock) => unlock.year === year,
      )[0];
      const value = unlock.percentage;
      let warning = undefined;

      if (!_.isNilOrEmptyString(value)) {
        warning = guards.validateAmount({
          t,
          context: "percentage",
          max: "101" /** Strict < */,
          value,
          min: "-1" /** Strict > */,
        });
      }

      const unlocks = [
        ...stream.extension.unlocks.value.filter(
          (unlock) => unlock.year !== year,
        ),
        {
          ...unlock,
          warning,
        },
      ];

      const edited = {
        ...stream,
        extension: {
          ...stream.extension,
          unlocks: {
            ...stream.extension.unlocks,
            value: unlocks.sort((a, b) => {
              if (_.toNumber(a.year) < _.toNumber(b.year)) {
                return -1;
              }
              return 1;
            }),
          },
        },
      };

      const newStreams = [...streams];
      newStreams.splice(index, 1, edited);
      update({
        streams: {
          value: newStreams,
        },
      });
    },
    [access, id, t],
  );

  const onChangeYearPercentage = useCallback(
    ({ value, year }: { value: string | undefined; year: string }) => {
      const { index, isValid, stream, streams, update } = setup(access, id);

      if (!isValid) {
        return;
      }

      const unlocks = [
        ...stream.extension.unlocks.value.filter(
          (unlock) => unlock.year !== year,
        ),
        {
          year,
          percentage: value,
        },
      ];
      const edited = {
        ...stream,
        extension: {
          ...stream.extension,
          unlocks: {
            ...stream.extension.unlocks,
            warning: undefined,
            value: unlocks.sort((a, b) => {
              if (_.toNumber(a.year) < _.toNumber(b.year)) {
                return -1;
              }
              return 1;
            }),
          },
        },
      };

      const newStreams = [...streams];
      newStreams.splice(index, 1, edited);
      update({
        streams: {
          value: newStreams,
        },
      });
    },
    [access, id],
  );

  return {
    unlocks,
    onChangeYearPercentage,
    onBlurYearPercentage,
  };
}

export function useGroupFieldUnlocksPrefill(id: string) {
  const access = useExtendedGroupAccess();

  const onClick = useCallback(() => {
    const { index, isValid, stream, streams, update } = setup(access, id);

    if (!isValid) {
      return;
    }

    const years = stream.extension.years.value;
    const count = new BigNumber(years || 0).isGreaterThan(0)
      ? years ?? "4"
      : "4";

    const unlocks = getDefaultDistribution(count);

    const edited = {
      ...stream,
      extension: {
        ...stream.extension,
        unlocks: {
          ...stream.extension.unlocks,
          value: unlocks,
        },
        years: {
          ...stream.extension.years,
          value: count,
        },
      },
    };

    const cloned = [...streams];
    cloned.splice(index, 1, edited);

    update({
      streams: {
        value: cloned,
      },
    });
  }, [access, id]);

  return {
    onClick,
  };
}

export function useGroupEnsurance(id: string) {
  const stream = useExtendedStream(id);
  const { t } = useT();
  const unlocks = useMemo(
    () => ({ field: stream.extension.unlocks }),
    [stream],
  );

  const isFull = useMemo(
    () =>
      unlocks.field.value
        .reduce(
          (prev, curr) => prev.plus(curr.percentage || "0"),
          new BigNumber(0),
        )
        .isEqualTo(100),
    [unlocks],
  );

  const isStepper = useMemo(
    () =>
      isFull &&
      unlocks.field.value.length >= 2 &&
      !_.isNilOrEmptyString(unlocks.field.value[0].percentage) &&
      !unlocks.field.value.some(
        (u) =>
          _.toNumber(u.percentage) !==
          _.toNumber(unlocks.field.value[0].percentage),
      ),
    [isFull, unlocks],
  );

  const label = useMemo(
    () => (isStepper ? t("warnings.backweighted.stepper.label") : ""),
    [isStepper, t],
  );

  const value = useMemo(
    () => (isStepper ? t("warnings.backweighted.stepper.value") : ""),
    [isStepper, t],
  );

  return {
    isStepper,
    label,
    value,
  };
}
