import { StreamShapes, links } from "@sablier/v2-constants";
import type { IExtensionConstructorParams } from "../../system/setup";
import type { IExtensionOverridesGeneral } from "~/client/types";
import { Extension } from "../../system/setup";

export interface ITranchedTimelock {
  purpose: Extension;
}

export function initial(
  _params: IExtensionConstructorParams,
): ITranchedTimelock {
  return { purpose: Extension.TRANCHED_TIMELOCK };
}

export const overrides = {
  general: (): IExtensionOverridesGeneral | undefined => {
    return {
      cancelability: {
        value: false,
      },
    };
  },
};

export const EXPECTED_SEGMENTS = 2;

export const shape = StreamShapes.tranchedTimelock.id;
export const template = links.streamTemplate.tranchedTimelock;
