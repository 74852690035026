import { ComponentProps } from "react";
import { Label } from "@sablier/v2-components/molecules";
import { _ } from "@sablier/v2-mixins";
import { Base } from "../../system";

interface Props {
  className?: string;
  cy?: string;
  description: string;
  label: ComponentProps<typeof Label>;
}

function General({
  children,
  className,
  cy,
  description,
  label,
}: React.PropsWithChildren<Props>) {
  return (
    <Base className={className} cy={cy} description={description} label={label}>
      {children}
    </Base>
  );
}

export default General;
