import styled from "styled-components";
import { useT } from "@sablier/v2-locales";
import { Ensurance } from "~/client/components/molecules";

const Wrapper = styled.div`
  width: 100%;

  & > div > *[data-component="card"] {
    border: 2px solid ${(props) => props.theme.colors.dark050};
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.dark200};

    *[data-component="frame"] {
      border: 2px solid ${(props) => props.theme.colors.dark050};
      background-color: ${(props) => props.theme.colors.dark100};
    }
  }
`;

function Single() {
  const { t } = useT();

  return (
    <Wrapper>
      <Ensurance
        purpose={"info"}
        label={t("warnings.timelock.cancelability.label")}
        value={t("warnings.timelock.cancelability.value")}
      />
    </Wrapper>
  );
}

function GroupGeneral() {
  return <Single />;
}

const Form = {
  /** No "Group" item component, just a general one */
  GroupGeneral,
};

export default Form;
