import { useCallback, useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import { useShallow } from "zustand/react/shallow";
import useStore from "~/client/stores/permissions";

export function usePermissionsStore() {
  const update = useStore(useShallow((state) => state.api.update));
  const permissions = useStore(useShallow((state) => state.permissions));

  return {
    update,
    permissions,
  };
}

export function usePermissionsStoreItem(id: string) {
  const action = useStore(useShallow((state) => state.api.toggle));
  const permissions = useStore(useShallow((state) => state.permissions));

  const item = useMemo(() => !!permissions[id], [id, permissions]);
  const toggle = useCallback(() => {
    action(id);
  }, [action, id]);

  return {
    item,
    toggle,
  };
}

export function usePermissionsStoreAccessor() {
  return useStore.getState;
}
