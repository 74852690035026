import { StreamShapes } from "@sablier/v2-constants";
import type { IExtensionConstructorParams } from "../../system/setup";
import type { IMilliseconds } from "@sablier/v2-types";
import { Extension } from "../../system/setup";

export interface IDynamicMonthly {
  purpose: Extension;
  months: {
    id: string;
    value?: string;
    warning?: string;
  };
}

export function initial({
  months,
}: IExtensionConstructorParams): IDynamicMonthly {
  return {
    purpose: Extension.DYNAMIC_MONTHLY,
    months: {
      id: "months",
      value: months,
      warning: undefined,
    },
  };
}
export const shape = StreamShapes.dynamicMonthly.id;
export const UNLOCK_DURATION: IMilliseconds = "1000";
