import nextDynamic from "next/dynamic";

const DetailsAirstream = nextDynamic(() => import("./Airstream/Details"), {
  ssr: false,
});
const DetailsStream = nextDynamic(() => import("./Stream/Details"), {
  ssr: false,
});

function All() {
  return (
    <>
      <DetailsAirstream />
      <DetailsStream />
    </>
  );
}

export default All;
