import { useMemo } from "react";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { ISInput } from "../system/templates";
import Template, { sides } from "../system/templates";

export interface Props extends Omit<ISInput, "left" | "onChange" | "right"> {
  flip?: {
    to?: string;
    toShort?: string;
    onClick: () => void;
  };
  resolution?: string;
}

function Moment(props: Props) {
  const { t } = useT();
  const value = useMemo(() => {
    if (_.isNilOrEmptyString(props.value)) {
      return undefined;
    }
    return _.toDuration(props.value, "date")[0];
  }, [props.value]);

  const formatted = useMemo(() => {
    const right: ISInput["right"] = [];

    if (props.resolution) {
      right.push(sides.resolution(props.resolution));
    }

    if (props.flip) {
      right.push(
        sides.flip(props.flip.to, props.flip.onClick, props.flip.toShort),
      );
    }

    if (props.isLoading) {
      right.unshift(sides.loader());
    } else {
      right.unshift(sides.calendar());
    }

    return {
      placeholder: t("form.placeholder.text"),
      ...props,
      right,
      isPreview: "soft" as const,
      value,
    };
  }, [props, value, t]);

  return <Template.Text {...formatted} />;
}

export default Moment;
