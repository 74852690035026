import { getDefaultWallets } from "@rainbow-me/rainbowkit";
import links from "@sablier/v2-constants/links";
import { cookieStorage, createConfig, createStorage } from "wagmi";
import { WAGMI_COOKIE_STORAGE, supported } from "~/client/constants";
import type {
  ArrayOfAtLeastOne,
  IEnvironmentVariable,
} from "@sablier/v2-types";
import * as mock from "./mock";
import * as safe from "./safe";
import { transport } from "./transport";
import { unstable } from "./unstable";

const PROJECT_ID = (process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID ??
  "") satisfies IEnvironmentVariable;

function configure(data: {
  first: number;
  isHostCypress: boolean;
  isHostSafe: boolean;
  isHostUnstable?: boolean;
}) {
  const ordered = [...(supported || [])].sort((a, b) =>
    a.id === data.first ? -1 : b.id === data.first ? 1 : 0,
  ) as typeof supported;

  const chains = supported.map(
    (configuration) => configuration.library,
  ) as ArrayOfAtLeastOne<(typeof supported)[0]["library"]>;

  const wallets = getDefaultWallets({
    appName: "Sablier V2",
    appIcon: links.logo.icon,
    appUrl: links.v2.client,
    projectId: PROJECT_ID,
    walletConnectParameters: {
      qrModalOptions: {
        themeMode: "dark",
        themeVariables: {
          "--wcm-z-index": "2000000",
        },
      },
    },
  });

  if (data.isHostUnstable) {
    return unstable(ordered);
  }

  const connectors = data.isHostCypress
    ? [mock.getConnector()]
    : [safe.getConnector(), ...wallets.connectors];

  const config = createConfig({
    chains,
    connectors,
    transports: transport(ordered),
    ssr: true,

    storage: createStorage({
      storage: cookieStorage,
      key: WAGMI_COOKIE_STORAGE,
    }),
  });

  return {
    config,
  };
}

export { configure, mock, safe };
