import { useEffect } from "react";
import { useIsSanctioned } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import { useModalSanctionAccount } from "./modals";
import useAccount from "./useAccount";

export default function useIsSanctionedAccount() {
  const { address } = useAccount();
  const { isSanctioned } = useIsSanctioned(address);
  const { isOpen, setOpen } = useModalSanctionAccount();

  useEffect(() => {
    if (_.isNilOrEmptyString(address)) {
      return;
    }

    if (!isOpen && isSanctioned) {
      setOpen(true);
    }
  }, [address, isOpen, isSanctioned, setOpen]);

  return { isSanctioned };
}
