import { useModal } from "@sablier/v2-contexts";
import type { IModalChain } from "@sablier/v2-types";

/**
 * This modal is a templated type of modal.
 * Think of it as an "abstract" components that need to be used in concrete implementations in each app.
 * For example, the client app uses it to instantiate a chain switcher.
 */

function useTemplateModalChainPicker<T extends IModalChain = IModalChain>(
  id: string,
  _template: "template",
) {
  return useModal<T>(id);
}

export default useTemplateModalChainPicker;
