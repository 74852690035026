import { useCallback, useEffect, useMemo } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  STEPPER_MAX_STEPS_GROUP,
  STEPPER_MAX_STEPS_SINGLE,
  STEPPER_MIN_STEPS,
} from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { guards } from "@sablier/v2-machines";
import { _ } from "@sablier/v2-mixins";
import {
  useAirstreamCreateAccessor,
  useStreamCreateGroupAccessor,
  useStreamCreateGroupField,
  useStreamCreateSingleField,
} from "~/client/hooks";
import type { ITranchedStepper } from "./config";
import { setup } from "../../system/setup";

type IExtension = ITranchedStepper;

function useExtendedAirstreamAccess() {
  return useAirstreamCreateAccessor<IExtension>();
}

function useExtendedGroupAccess() {
  return useStreamCreateGroupAccessor<IExtension>();
}

function useExtendedAirstreamFields() {
  const extension = useStreamCreateSingleField("extension");
  return extension.field as unknown as IExtension;
}

function useExtendedStream(id: string) {
  const streams = useStreamCreateGroupField("streams");
  const stream = useMemo(
    () => streams.field.value.find((s) => s.id === id),
    [id, streams],
  );

  return stream as typeof stream & { extension: IExtension };
}

export function useAirstreamFieldStepper() {
  const access = useExtendedAirstreamAccess();
  const extension = useExtendedAirstreamFields();
  const { t } = useT();

  const steps = useMemo(() => ({ field: extension.steps }), [extension]);

  const onBlur = useCallback(() => {
    const state = access();
    const extension = state.fields.extension;
    const value = extension.steps.value;
    const update = state.api.updateFields;

    if (_.isNilOrEmptyString(value)) {
      update({
        extension: {
          ...extension,
          steps: {
            ...extension.steps,
            warning: undefined,
          },
        },
      });
    } else {
      update({
        extension: {
          ...extension,
          steps: {
            ...extension.steps,
            warning: guards.validateAmount({
              t,
              context: "steps",
              max: (STEPPER_MAX_STEPS_SINGLE + 1).toString() /** Strict < */,
              value,
              min: (STEPPER_MIN_STEPS - 1).toString() /** Strict > */,
            }),
          },
        },
      });
    }
  }, [access, t]);

  const onChange = useCallback(
    (value: string | undefined) => {
      const state = access();
      const update = state.api.updateFields;
      const extension = state.fields.extension;

      update({
        extension: {
          ...extension,
          steps: {
            ...extension.steps,
            value,
            warning: undefined,
          },
        },
      });
    },
    [access],
  );

  useEffect(() => {
    onBlur();
  }, [onBlur]);

  return {
    steps,
    onBlur,
    onChange,
  };
}

export function useGroupFieldStepper(id: string) {
  const { t } = useT();
  const access = useExtendedGroupAccess();
  const stream = useExtendedStream(id);

  const steps = useMemo(() => ({ field: stream.extension.steps }), [stream]);

  const onBlur = useCallback(() => {
    const { index, isValid, stream, streams, update } = setup(access, id);

    if (!isValid) {
      return;
    }

    const extension = stream.extension;
    const value = extension.steps.value;
    let warning = undefined;

    if (!_.isNilOrEmptyString(value)) {
      warning = guards.validateAmount({
        t,
        context: "steps",
        max: (STEPPER_MAX_STEPS_GROUP + 1).toString() /** Strict < */,
        value,
        min: (STEPPER_MIN_STEPS - 1).toString() /** Strict > */,
      });
    }

    const edited = {
      ...stream,
      extension: {
        ...stream.extension,
        steps: {
          ...stream.extension.steps,
          warning,
        },
      },
    };

    const newStreams = [...streams];
    newStreams.splice(index, 1, edited);
    update({
      streams: {
        value: newStreams,
      },
    });
  }, [access, id, t]);

  const onChange = useCallback(
    (value: string | undefined) => {
      const { index, isValid, stream, streams, update } = setup(access, id);

      if (!isValid) {
        return;
      }
      const edited = {
        ...stream,
        extension: {
          ...stream.extension,
          steps: {
            ...stream.extension.steps,
            warning: undefined,
            value,
          },
        },
      };

      const newStreams = [...streams];
      newStreams.splice(index, 1, edited);
      update({
        streams: {
          value: newStreams,
        },
      });
    },
    [access, id],
  );

  const label = useMemo(
    () => ({
      value: t("form.label.steps"),
      icon: InformationCircleIcon,
      isIconLast: true,
      tooltip: {
        value: t("descriptions.firstStepUnlock"),
      },
    }),
    [t],
  );

  return {
    label,
    steps,
    onBlur,
    onChange,
  };
}
