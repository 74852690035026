import { Input } from "@sablier/v2-components/organisms";
import { DEFAULT_DECIMALS } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { useGroupFieldUnlock } from "./logic";

function GroupUnlock({ streamId }: { streamId: string }) {
  const { unlock, maximum, onBlur, onChange, onMax, token } =
    useGroupFieldUnlock(streamId);
  const { t } = useT();

  return (
    <Input.Amount
      id={unlock.field.id}
      decimals={token.field.value?.decimals || DEFAULT_DECIMALS}
      label={{ value: t("structs.unlockLabel") }}
      max={maximum}
      onBlur={onBlur}
      onChange={onChange}
      onMax={onMax}
      placeholder={t("form.placeholder.amount")}
      token={token.field.value}
      value={unlock.field.value}
      warning={unlock.field.warning}
    />
  );
}

const Fields = {
  GroupUnlock,
};

export default Fields;
