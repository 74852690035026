import { _ } from "@sablier/v2-mixins";
import { assign, createMachine } from "xstate";

type MachineContext<Create, Result, Preprocess> = {
  error: string | undefined;
  payload: Create;
  preprocess: Preprocess;
  result: Result | undefined;
};

type MachineEvents<Check, Create> =
  | { type: "SAVE"; payload: Create }
  | { type: "CHECK"; payload: Check }
  | { type: "RESET" };

type MachineServices<Preprocess> = {
  doCheck: { data: unknown };
  doProcess: { data: unknown };
  doValidate: { data: Preprocess };
};

interface Props<Check, Create, Result, Preprocess = undefined> {
  id: string;
  onCheck: ({ event }: { event: { payload: Check } }) => Promise<void>;
  onProcess: ({
    context,
  }: {
    context: MachineContext<Create, Result, Preprocess>;
  }) => Promise<void>;
  onValidate: ({
    context,
  }: {
    context: MachineContext<Create, Result, Preprocess>;
  }) => Promise<Preprocess>;
}

function create<Check, Create, Result, Preprocess = undefined>({
  id,
  onCheck,
  onProcess,
  onValidate,
}: Props<Check, Create, Result, Preprocess>) {
  return createMachine(
    {
      tsTypes: {} as import("./index.typegen").Typegen0,
      id: `formMachine-${id}`,
      initial: "initial",
      context: {
        error: undefined,
        payload: {} as Create,
        preprocess: {} as Preprocess,
        result: undefined,
      },
      predictableActionArguments: true,
      schema: {
        context: {} as MachineContext<Create, Result, Preprocess>,
        events: {} as MachineEvents<Check, Create>,
        services: {} as MachineServices<Preprocess>,
      },
      states: {
        initial: {
          on: {
            CHECK: {
              target: "check",
            },
            SAVE: {
              target: "validate",
            },
          },
          exit: "doReset",
        },
        check: {
          invoke: {
            id: "check",
            src: "doCheck",
            onDone: {
              actions: "doReset",
              target: "initial",
            },
            onError: {
              actions: "doError",
              target: "initial",
            },
          },
          on: {
            /**
             * Changes in dependencies will instruct useEffect to fire the CHECK event.
             * If this happens *while* inside of check's "doCheck" service, it won't be caught
             * and the transition will finalize without taking into account that latest, more up to date
             * version of the payload.
             * -----
             * By providing a pseudo self-transition, the machine will somehow ditch the stale service
             * and focus on the latest one.
             */

            CHECK: {
              actions: "doReset",
              target: "check",
            },
          },
        },
        validate: {
          entry: "doCache",
          invoke: {
            id: "validate",
            src: "doValidate",
            onDone: {
              actions: "doPreprocess",
              target: "process",
            },
            onError: {
              actions: "doError",
              target: "failure",
            },
          },
        },
        process: {
          invoke: {
            id: "process",
            src: "doProcess",
            onDone: {
              target: "success",
            },
            onError: {
              actions: "doError",
              target: "failure",
            },
          },
        },
        success: {
          on: {
            SAVE: {
              target: "validate",
            },
            RESET: {
              target: "initial",
            },
          },
        },
        failure: {
          on: {
            SAVE: {
              target: "validate",
            },
            RESET: {
              target: "initial",
            },
          },
        },
      },
    },
    {
      actions: {
        doCache: assign({
          payload: (_context, event) => event.payload,
        }),
        doError: assign({
          error: (_context, event) => _.toString(_.get(event, "data.message")),
        }),
        doPreprocess: assign({
          preprocess: (_context, event) => event.data,
        }),
        doReset: assign({
          error: (_context) => undefined,
          payload: (_context) => ({} as Create),
          preprocess: (_context) => ({} as Preprocess),
          result: (_context) => undefined,
        }),
      },
      services: {
        doCheck: async (_context, event) => {
          await onCheck({ event });
        },
        doValidate: async (context, _event) => {
          return onValidate({ context });
        },
        doProcess: async (context, _event) => {
          await onProcess({ context });
        },
      },
    },
  );
}

const form = {
  create,
};

export { form };
