import { useCallback, useMemo } from "react";
import { chains as assets } from "@sablier/v2-assets";
import { chains } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import type { IChain } from "@sablier/v2-types";

/** Get the local chain definition for the connected chain id (from provider) or for the arbitrary id parameter */

export default function useChainData(chainId?: number): {
  chain: IChain | undefined;
  id: number | undefined;
  find: (chainId: number | undefined) => IChain | undefined;
} {
  const find = useCallback((chainId: number | undefined) => {
    if (!chainId) {
      return undefined;
    }
    const result = _.get(chains, _.toString(chainId));

    if (_.isNil(result)) {
      return undefined;
    }

    return {
      ...result,
      image: result.image ?? assets[chainId],
    };
  }, []);

  const chain = useMemo(() => find(chainId), [chainId, find]);

  return {
    id: chainId,
    chain,
    find,
  };
}
