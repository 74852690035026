import styled from "styled-components";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { useT } from "@sablier/v2-locales";
import { getTheme } from "@sablier/v2-themes";
import { rgba } from "polished";
import { ToastBar, Toaster } from "react-hot-toast";
import { Icon } from "../../molecules";

const theme = getTheme();

const WrapperPartial = styled.div`
  .sablier-toast {
    position: relative;
    padding: calc(${(props) => props.theme.sizes.edge} * 1)
      calc(${(props) => props.theme.sizes.edge} * 1);
    color: ${(props) => props.theme.colors.gray100};
    border-radius: 6px;
    background-color: ${(props) => rgba(props.theme.colors.dark300, 0.7)};
    box-shadow: none;
    backdrop-filter: blur(4px);
    overflow: hidden;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      z-index: 15;
      width: 100%;
      height: 100%;
      background-color: ${(props) => rgba(props.theme.colors.white, 0.15)};
      pointer-events: none;
      animation: none;
    }

    &:before {
      position: absolute;
      left: -50px;
      content: "";
      z-index: 10;
      width: 140px;
      height: 100%;
      opacity: 0.2;
      filter: blur(50px);
      pointer-events: none;
    }

    & > * {
      z-index: 20;
    }

    .icon {
      width: 20px;
    }
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex: 1;
    justify-content: center;
    column-gap: calc(${(props) => props.theme.sizes.edge});
  }
`;

const Left = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    --size: 24px;
    --thickness: 1.8;
    stroke-width: 2;
    flex-shrink: 0;
  }

  &:empty {
    display: none;
  }
`;

const Center = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    min-width: 180px;
  }
`;

const Title = styled.div`
  & > p {
    ${(props) => props.theme.styles.textElement}
    & {
      color: ${(props) => props.theme.colors.white};
      font-size: 11pt;
    }
  }
`;

const Description = styled.div`
  * {
    ${(props) => props.theme.styles.textParagraphMini}
    & {
      padding-right: 10px;
      color: ${(props) => props.theme.colors.white};
      line-height: 1.4;
    }
  }
  & > div {
    margin: 0;
    padding: 0;
    * {
      display: inline-block;
      white-space: pre-wrap;
      word-break: normal;
    }
  }
  a {
    text-decoration: underline;
  }
`;

const Wrapper = styled(WrapperPartial)`
  &[data-purpose="blank"] {
    .sablier-toast {
      &:before {
        background-color: ${(props) => props.theme.colors.yellow};
      }

      ${Left}, ${Center} > ${Description} a {
        color: ${(props) => props.theme.colors.yellow};
      }
    }
  }

  &[data-purpose="error"] {
    .sablier-toast {
      &:before {
        background-color: ${(props) => props.theme.colors.red};
      }

      ${Left}, ${Center} > ${Description} a {
        color: ${(props) => props.theme.colors.red};
      }
    }
  }

  &[data-purpose="loading"] {
    .sablier-toast {
      &:before {
        background-color: ${(props) => props.theme.colors.white};
      }

      ${Left}, ${Center} > ${Description} a {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }

  &[data-purpose="success"] {
    .sablier-toast {
      &:before {
        background-color: ${(props) => props.theme.colors.green};
      }

      ${Left}, ${Center} > ${Description} a {
        color: ${(props) => props.theme.colors.green};
      }
    }
  }

  &[data-visible="true"] {
    .sablier-toast {
      &:after {
        ${(props) => props.theme.animations.fadeOut}
        & {
          animation-duration: 1s;
          animation-timing-function: ease-out;
        }
      }
    }
  }
`;

function Toast() {
  const { t: tr } = useT();
  return (
    <Toaster
      containerStyle={{
        zIndex: theme.sizes.zIndexToast,
      }}
      position={"top-right"}
      toastOptions={{
        className: "sablier-toast",
      }}
    >
      {(t) => (
        <Wrapper data-purpose={t.type} data-visible={t.visible}>
          <ToastBar toast={t}>
            {({ message }) => (
              <Content>
                <Left>
                  {t.type === "blank" && (
                    <Icon value={ExclamationTriangleIcon} />
                  )}
                  {t.type === "error" && <Icon value={XCircleIcon} />}
                  {t.type === "loading" && <Icon purpose={"spinner"} />}
                  {t.type === "success" && <Icon value={CheckCircleIcon} />}
                </Left>

                <Center>
                  <Title>
                    {t.type === "blank" && (
                      <p>{tr("structs.changesRequired")}</p>
                    )}
                    {t.type === "error" && (
                      <p>{tr("structs.somethingWrong")}</p>
                    )}
                    {t.type === "loading" && <p>{tr("words.loading")}...</p>}
                    {t.type === "success" && <p>{tr("words.success")}!</p>}
                  </Title>
                  <Description>{message}</Description>
                </Center>
              </Content>
            )}
          </ToastBar>
        </Wrapper>
      )}
    </Toaster>
  );
}

export * from "./content";

export default Toast;
