import { DEFAULT_RESET_SLEEP } from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { guards, policy } from "@sablier/v2-machines";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { zeroAddress } from "viem";
import { isHostSafe } from "~/client/contexts/Web3";
import { useModalTransaction } from "~/client/hooks/modals";
import { toast } from "~/client/hooks/useToast";
import type { useMachineForm } from "@sablier/v2-hooks";
import type { Translate } from "@sablier/v2-locales";
import type { IStream } from "@sablier/v2-models";
import type { ISigner, IWagmiAddress, IWagmiConfig } from "@sablier/v2-types";
import type { IForm } from "~/client/contexts/Form/Stream/Transfer";
import helper from "../helper";
import { transfer as wording } from "../helper/wording";

export interface Check {
  fields: IForm;
  api: {
    t: Translate;
  };
}
export interface Create extends Check {
  fields: IForm;
  signer: ISigner | undefined;
  stream: IStream;
  library: IWagmiConfig | undefined;
  api: {
    reset: () => void;
    t: Translate;
    setOpen: ReturnType<typeof useModalTransaction>["setOpen"];
    updateData: ReturnType<typeof useModalTransaction>["updateData"];
  };
}

export interface Result {
  message?: string;
}

type Machine = Parameters<typeof useMachineForm<Check, Create, Result>>;

type onCheck = Parameters<Machine["0"]["onCheck"]>["0"];
type onProcess = Parameters<Machine["0"]["onProcess"]>["0"];
type onValidate = Parameters<Machine["0"]["onValidate"]>["0"];

export async function onCheck({ event }: onCheck): Promise<void> {
  const { ...fields } = event.payload.fields;
  const { t } = event.payload.api;

  const flags = guards.validateFormFlags({
    t,
    isLoadingIncluded: true,
    isWarningIncluded: true,
    value: fields,
  });

  if (!_.isNilOrEmptyString(flags)) {
    throw new Error(flags);
  }

  const required = guards.validateFormRequired({
    t,
    required: ["address"],
    value: fields,
  });

  if (!_.isNilOrEmptyString(required)) {
    throw new Error(required);
  }
}

export async function onValidate({ context }: onValidate): Promise<undefined> {
  const { api, fields, library, stream, signer } = context.payload;
  const { t } = api;

  api.setOpen(true, {
    status: "verify",
    title: wording.title(t),
    description: wording.confirm(t).description,
    isNotClosable: true,
  });
  try {
    await onCheck({ event: context });

    if (_.isNil(signer) || _.isNil(library)) {
      throw new Error(policy.signer.missing(t));
    }

    const chainId = signer.chain!.id;
    const recipient = fields.address.resolution?.address;
    const isAddress = _.isEthereumAddress(fields.address.value);

    await guards.validateInputs(
      library,
      t,
      [
        {
          purpose: "signer",
          options: {
            expected: [stream.recipient],
            chainId: stream.chainId,
            value: signer,
          },
        },
        {
          purpose: "recipient",
          options: {
            blacklist: [stream.recipient, _.toAddress(zeroAddress)],
            value: recipient,
          },
        },
        {
          purpose: "screening",
          options: {
            chainId,
            addresses: [signer.account!.address, recipient ?? ""],
          },
        },
        ...(isAddress
          ? [
              {
                purpose: "resolution" as const,
                options: {
                  value: fields.address.value,
                  resolved: recipient,
                },
              },
            ]
          : []),
      ],
      chainId,
      { toast },
    );
  } catch (error) {
    vendors.crash.log(error);
    api.updateData({
      status: "fail",
      description: wording.fail(t).description,
      error: {
        message: _.toString(error),
        data: error,
      },
      isNotClosable: false,
    });
    throw error;
  }
  return undefined;
}

/**
 *  Machine state that actually triggers the transaction.
 *  It relies on defined, pre-validated values checked within the `onValidate` step.
 */

export async function onProcess({ context }: onProcess): Promise<void> {
  const { api, fields, library, stream, signer } = context.payload;
  const { t } = api;
  let query = undefined;

  try {
    if (_.isNil(signer) || _.isNil(library)) {
      throw new Error(policy.signer.missing(t));
    }

    const sender = _.toAddress(signer.account!.address);
    const chainId = signer.chain!.id;
    const recipient = fields.address.resolution?.address;
    const toggle = fields.toggle.value;
    const lockup = stream.contract;

    query = toggle
      ? framework.contextualize(
          lockup,
          chainId,
          stream.category,
          "withdrawMaxAndTransfer",
          [_.toBigInt(stream.tokenId), recipient as IWagmiAddress],
        )
      : framework.contextualize(
          lockup,
          chainId,
          stream.category,
          "transferFrom",
          [
            sender as IWagmiAddress,
            recipient as IWagmiAddress,
            _.toBigInt(stream.tokenId),
          ],
        );

    api.updateData({
      status: "confirm",
      description: wording.send(t, true, _.toShortAddress(recipient))
        .description,
      isNotClosable: true,
    });

    const prepared = await helper.configure(library, {
      chainId,
      query,
      signer,
    });

    console.info("%c[pre-transaction]", "color: mediumslateblue", {
      query,
      prepared,
    });

    const transaction = await framework.write(library, { prepared });

    api.updateData({
      status: "pending",
      description: wording.send(t, false, _.toShortAddress(recipient))
        .description,
      hash: !isHostSafe ? transaction : undefined,
      isNotClosable: false,
    });

    const receipt = isHostSafe
      ? await framework.safeWait(library, { hash: transaction })
      : await framework.wait(library, {
          hash: transaction,
          onReplaced: (replaced) => {
            api.updateData({
              hash: replaced.transaction.hash,
            });
          },
        });

    console.info("%c[post-transaction]", "color: mediumslateblue", {
      transaction,
      receipt,
    });

    if (receipt.status === "reverted") {
      throw new Error(policy.error.reverted(t));
    }

    await _.sleep(DEFAULT_RESET_SLEEP);

    api.updateData({
      status: "success",
      description: wording.success(t, _.toShortAddress(recipient)).description,
      hash: receipt.transactionHash,
      isNotClosable: false,
    });

    api.reset();
  } catch (error) {
    void helper.debug(
      {
        query,
        signer,
      },
      vendors.crash.log(error),
    );
    vendors.crash.log(error);
    api.updateData({
      status: "fail",
      description: wording.fail(t).description,
      error: {
        message: policy.error.message(t, error),
        data: error,
      },
      isNotClosable: false,
    });

    throw error;
  }
}
