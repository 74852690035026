import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { IImage } from "@sablier/v2-types";
import type { ISInput } from "../system/templates";
import type { IInputOption } from "@sablier/v2-types";
import Template, { sides } from "../system/templates";

const Templated = styled(Template.Text)`
  *[data-component="field"] {
    justify-content: center;
    text-align: center;
  }
`;

type IItem = IInputOption<{
  image?: IImage;
  [key: string]: unknown;
}>;

type PropsExtra =
  | {
      onChange?: never;
      onMinus: () => void;
      onPlus: () => void;
      source?: never;
      value?: IItem;
    }
  | {
      onChange: (value: IItem, direction: "left" | "right") => void;
      /** Keep the id unique so the current value's position can be successfully identified */
      source: IItem[];
      /** Keep the id unique so the current value's position can be successfully identified */
      value?: IItem;
    };

type PropsBase = Omit<ISInput, "left" | "onChange" | "right" | "value"> & {
  isChevron?: boolean;
  isOnlyRight?: boolean;
};

export type Props = PropsBase & PropsExtra;

function TextStepper(props: Props) {
  const { t } = useT();
  const isDisabled = useMemo(() => {
    if (props.isDisabled || props.isLocked) {
      return true;
    }
    if (!props.value || _.isNilOrEmptyString(props.value.id)) {
      return true;
    }
    return false;
  }, [props]);

  const onMinus = useCallback(() => {
    if (isDisabled) {
      return;
    }

    if (props.source) {
      const current = props.source.findIndex(
        (item) => item.id === props.value!.id,
      );
      if (_.isNil(current)) {
        return;
      }
      let next = current - 1;
      if (current <= 0) {
        next = props.source.length - 1;
      }
      props.onChange(props.source[next], "left");
    } else {
      if (props.onMinus) {
        props.onMinus();
      }
    }
  }, [isDisabled, props]);

  const onPlus = useCallback(() => {
    if (isDisabled) {
      return;
    }

    if (props.onChange && props.source) {
      const current = props.source.findIndex(
        (item) => item.id === props.value!.id,
      );
      if (_.isNil(current)) {
        return;
      }
      let next = current + 1;
      if (current >= props.source.length - 1) {
        next = 0;
      }
      props.onChange(props.source[next], "right");
    } else {
      if (props.onPlus) {
        props.onPlus();
      }
    }
  }, [isDisabled, props]);

  const formatted = useMemo(() => {
    const left: ISInput["left"] = props.isOnlyRight
      ? []
      : [sides.minus(onMinus, props.isChevron)];

    if (props.value?.image) {
      left.push(sides.image(props.value.image));
    }

    const right: ISInput["right"] = [sides.plus(onPlus, props.isChevron)];

    if (props.isLoading) {
      right.unshift(sides.loader());
    }

    return {
      placeholder: t("form.placeholder.text"),
      left,
      right,
      ...props,
      isPreview: true,
      onChange: undefined,
      value: props.value ? props.value.title : "",
    };
  }, [onMinus, onPlus, props, t]);

  return <Templated {...formatted} />;
}

export default TextStepper;
