import { Token } from "@sablier/v2-models";
import { IToken } from "@sablier/v2-types";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { PERSIST_ID } from "~/client/constants";
import type { IStoreLocalTokens } from "~/client/types";

const useStore = create<IStoreLocalTokens>()(
  devtools(
    persist(
      (set) => ({
        local: [],
        api: {
          addLocal: (t: IToken) =>
            set((prev) => {
              const { local } = prev;
              const index = local.findIndex((p) => Token.areEqual(p, t));
              if (!index) {
                return {};
              }

              return { local: [...local, t] };
            }),
          removeLocal: (t: IToken) =>
            set((prev) => ({
              local: prev.local.filter((p) => !Token.areEqual(p, t)),
            })),
        },
      }),
      {
        name: PERSIST_ID.localTokens,
        partialize: (state) => ({ local: state.local }),
      },
    ),
  ),
);

export default useStore;
