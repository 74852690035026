import { Informer, Label, Warning } from "~/components/molecules";
import type { ISInput } from "../../particles";
import type { PropsWithChildren } from "react";
import { Wrapper } from "../../particles";

export interface Props
  extends Pick<
    ISInput,
    | "info"
    | "isDisabled"
    | "isLoading"
    | "isLocked"
    | "isPreview"
    | "label"
    | "onBlur"
    | "onFocus"
    | "onChange"
    | "onKeyDown"
    | "under"
    | "warning"
  > {
  className?: string;
}

function Shell({
  children,
  className,
  info,
  isDisabled,
  isLoading,
  isLocked,
  isPreview,
  label,
  under = null,
  warning,
}: PropsWithChildren<Props>) {
  return (
    <Wrapper
      className={className}
      isLoading={isLoading}
      isLocked={isLocked}
      isPreview={isPreview}
      isDisabled={isDisabled}
    >
      {label && <Label {...label} />}
      {children}
      {under}
      {info && <Informer value={info} />}
      {warning && <Warning cy={"warning"} value={warning} />}
    </Wrapper>
  );
}

export default Shell;
