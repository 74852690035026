import { createContext, useCallback, useContext, useMemo, useRef } from "react";
import { _ } from "@sablier/v2-mixins";
import { useStore } from "zustand";
import { useShallow } from "zustand/react/shallow";
import type { IStoreCreator } from "./store";
import type { IStoreSelector, RecursivePartial } from "@sablier/v2-types";
import type { PropsWithChildren } from "react";
import create from "./store";

const FormContext = createContext<unknown>(undefined);

function Provider<F>({ children, initial }: PropsWithChildren<{ initial: F }>) {
  const store = useRef<IStoreCreator<F>>(create(initial));

  return (
    <FormContext.Provider value={store.current}>
      {children}
    </FormContext.Provider>
  );
}

function useFormContext<F>() {
  const context = useContext(FormContext) as IStoreCreator<F>;

  if (_.isNil(context)) {
    throw new Error(
      "An element consuming the form context/store is not wrapped by the Provider.",
    );
  }

  return context;
}

function useFormStore<F, Slice>(selector: IStoreSelector<F, Slice>) {
  const context = useFormContext<F>();
  return useStore(context, useShallow(selector));
}

function useFormField<F, I extends keyof F>(id: I) {
  const context = useFormContext<F>();

  /**
   * The zustand store needs to be accessed with a shallow equality function !
   * Between v4 and v4.3 the API has been changing quite a lot, so on updates make sure to keep an eye on this equality function.
   * */

  const field: F[I] = useStore(
    context,
    useShallow((state) => state.fields[id]),
  );
  const updater = useStore(
    context,
    useShallow((state) => state.api.updateFields),
  );

  const update = useCallback(
    (value: Partial<typeof field>) => {
      updater({
        [id]: value,
      } as RecursivePartial<F>);
    },
    [id, updater],
  );

  return useMemo(
    () => ({
      field,
      update,
    }),
    [field, update],
  );
}

export { useFormContext, useFormField, useFormStore };
export default Provider;
