import {
  CoreCategory,
  DEFAULT_GAS_LIMIT,
  DEFAULT_MAX_GAS_LIMIT,
  PeripheryCategory,
  StreamCategory,
  StreamVersion,
} from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { contracts, peripheries } from "~/client/constants";
import type { Extension } from "../extensions";
import type { IAddress } from "@sablier/v2-types";
import { dynamic, linear, tranched } from "../extensions";

export function classifier(
  chainId: keyof typeof contracts | number | undefined,
  address: IAddress | undefined,
  context: "core",
): StreamCategory | undefined {
  if (_.isNilOrEmptyString(chainId) || !_.isEthereumAddress(address)) {
    return undefined;
  }

  if (context === ("core" as const)) {
    if (!_.get(contracts, chainId)) {
      return undefined;
    }

    if (
      [
        CoreCategory.LOCKUP_LINEAR_20,
        CoreCategory.LOCKUP_LINEAR_21,
        CoreCategory.LOCKUP_LINEAR_22,
      ].some(
        (category) =>
          _.toAddress(contracts[chainId][category]?.address) ===
          _.toAddress(address),
      )
    ) {
      return StreamCategory.LOCKUP_LINEAR;
    }

    if (
      [
        CoreCategory.LOCKUP_DYNAMIC_20,
        CoreCategory.LOCKUP_DYNAMIC_21,
        CoreCategory.LOCKUP_DYNAMIC_22,
      ].some(
        (category) =>
          _.toAddress(contracts[chainId][category]?.address) ===
          _.toAddress(address),
      )
    ) {
      return StreamCategory.LOCKUP_DYNAMIC;
    }

    if (
      [CoreCategory.LOCKUP_TRANCHED_22].some(
        (category) =>
          _.toAddress(contracts[chainId][category]?.address) ===
          _.toAddress(address),
      )
    ) {
      return StreamCategory.LOCKUP_TRANCHED;
    }
  }

  return undefined;
}

export function contractor(
  chainId: keyof typeof contracts | number | undefined,
  purpose: Extension | StreamCategory = StreamCategory.LOCKUP_LINEAR,
  /** Useful for asset-specific handling (e.g. flash loans) */
  _asset?: string | undefined,
  version: StreamVersion = StreamVersion.V22,
) {
  try {
    if (
      !_.isNilOrEmptyString(chainId) &&
      !_.isNilOrEmptyString(contracts?.[chainId])
    ) {
      const flavor = (() => {
        const category = (() => {
          if (
            [
              ...linear.map((shape) => shape.toLowerCase()),
              StreamCategory.LOCKUP_LINEAR.toLowerCase(),
            ].includes(purpose.toLowerCase())
          ) {
            return StreamCategory.LOCKUP_LINEAR;
          }

          if (
            [
              ...tranched.map((shape) => shape.toLowerCase()),
              StreamCategory.LOCKUP_TRANCHED.toLowerCase(),
            ].includes(purpose.toLowerCase())
          ) {
            return StreamCategory.LOCKUP_TRANCHED;
          }

          if (
            [
              ...dynamic.map((shape) => shape.toLowerCase()),
              StreamCategory.LOCKUP_DYNAMIC.toLowerCase(),
            ].includes(purpose.toLowerCase())
          ) {
            return StreamCategory.LOCKUP_DYNAMIC;
          }

          return StreamCategory.LOCKUP_DYNAMIC;
        })();

        return {
          [StreamVersion.V20]: {
            [StreamCategory.LOCKUP_LINEAR]: CoreCategory.LOCKUP_LINEAR_20,
            [StreamCategory.LOCKUP_DYNAMIC]: CoreCategory.LOCKUP_DYNAMIC_20,
            [StreamCategory.LOCKUP_TRANCHED]: "",
          },
          [StreamVersion.V21]: {
            [StreamCategory.LOCKUP_LINEAR]: CoreCategory.LOCKUP_LINEAR_21,
            [StreamCategory.LOCKUP_DYNAMIC]: CoreCategory.LOCKUP_DYNAMIC_21,
            [StreamCategory.LOCKUP_TRANCHED]: "",
          },
          [StreamVersion.V22]: {
            [StreamCategory.LOCKUP_LINEAR]: CoreCategory.LOCKUP_LINEAR_22,
            [StreamCategory.LOCKUP_DYNAMIC]: CoreCategory.LOCKUP_DYNAMIC_22,
            [StreamCategory.LOCKUP_TRANCHED]: CoreCategory.LOCKUP_TRANCHED_22,
          },
        }[version][category];
      })();

      return contracts[chainId][flavor];
    }
  } catch (error) {
    vendors.crash.log(error);
  }
  return { alias: "", address: "", methods: {} };
}

export function peripheral(
  chainId: keyof typeof peripheries | number | undefined,
  purpose: PeripheryCategory,
) {
  try {
    if (
      !_.isNilOrEmptyString(chainId) &&
      !_.isNilOrEmptyString(peripheries?.[chainId]?.[purpose])
    ) {
      return peripheries[chainId][purpose];
    }
  } catch (error) {
    vendors.crash.log(error);
  }
  return { address: "", alias: "", methods: {} };
}

export function gasser(
  chainId?: keyof typeof contracts | number,
  purpose?: Extension | StreamCategory | PeripheryCategory | undefined,
  method?: string,
  multiplier?: number,
): { min: number; max: number } {
  try {
    if (
      !_.isNilOrEmptyString(purpose) &&
      !_.isNilOrEmptyString(chainId) &&
      !_.isNilOrEmptyString(method)
    ) {
      const isPeriphery = Object.values(PeripheryCategory).find(
        (p) => p === purpose,
      );

      const contract = isPeriphery
        ? peripheral(chainId, purpose as PeripheryCategory)
        : contractor(chainId, purpose);

      if (
        !_.isNil(contract) &&
        !_.isNil(contract.methods) &&
        _.has(contract.methods, method)
      ) {
        const limit = contract.methods[method];
        if (_.isFunction(limit)) {
          return limit(multiplier || 1);
        }

        return { min: limit.min, max: limit.max };
      }
    }
  } catch (error) {
    vendors.crash.log(error);
  }

  return { min: DEFAULT_GAS_LIMIT, max: DEFAULT_MAX_GAS_LIMIT };
}
