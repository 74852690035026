import { useMemo } from "react";
import { useT } from "@sablier/v2-locales";
import { BigNumber, _ } from "@sablier/v2-mixins";
import type { AirstreamAction, IAirstream } from "@sablier/v2-models";
import type { IAddress, IMilliseconds } from "@sablier/v2-types";
import useAccount from "./useAccount";
import useRequestGeolocation from "./useRequestGeolocation";

type Warning =
  | {
      state: "early";
      permission: string;
      start: {
        value?: IMilliseconds;
        label?: string;
      };
    }
  | {
      state: "ended";
      permission: string;
      end: {
        value?: IMilliseconds;
        label?: string;
      };
    }
  | {
      state: "depleted";
      permission: string;
    }
  | {
      state: "connect";
    }
  | {
      state: "chain";
      chainId?: number;
    }
  | {
      state: "funded";
    }
  | {
      state: "ready";
    }
  | {
      state: "whitelist";
      whitelist: { address: string; label: string }[];
    }
  | {
      state: "geoblocked";
    }
  | {
      state: "undetailed";
    };

type IPartialPurpose = "eligibilityCheck" | "eligibilityClaim";

export type Props =
  | {
      purpose: IPartialPurpose;
      airstream: IAirstream | undefined;
      deposited?: undefined;
      metadata?: undefined;
      geoblock?: string[];
      undetailed?: boolean;
    }
  | {
      purpose: "clawback";
      airstream: IAirstream | undefined;
      deposited?: undefined;
      metadata?: {
        first: AirstreamAction | undefined;
      };
      geoblock?: string[];
      undetailed?: undefined;
    }
  | {
      purpose: "create";
      airstream: { admin: IAddress | undefined; chainId: number | undefined };
      deposited?: undefined;
      metadata?: undefined;
      geoblock?: string[];
      undetailed?: undefined;
    }
  | {
      purpose: "fund";
      airstream: IAirstream | undefined;
      deposited: BigNumber;
      metadata?: undefined;
      geoblock?: string[];
      undetailed?: undefined;
    };

export default function useAirstreamWarning({
  purpose,
  airstream,
  deposited,
  metadata,
  geoblock,
  undetailed,
}: Props): Warning {
  const { address, chainId: fromChainId, isDisconnected } = useAccount();
  const { t } = useT();

  const { includes } = useRequestGeolocation();

  const isBlocked = useMemo(
    () => !_.isNilOrEmptyString(geoblock) && includes(geoblock || []),
    [includes, geoblock],
  );

  const whitelist = useMemo(() => {
    if (_.isNil(airstream)) {
      return undefined;
    }

    const admin = {
      address: _.toAddress(airstream?.admin),
      label: t("words.admin"),
    };

    const expected = {
      create: [],
      eligibilityCheck: [],
      eligibilityClaim: [],
      fund: [],
      clawback: [admin],
    }[purpose];

    if (
      expected.some((e) => {
        if (e.label === t("words.admin")) {
          if (e.address === _.toAddress(address)) {
            return true;
          }
        }
        return false;
      })
    ) {
      /** Found an expected party - don't build the whitelist */
      return undefined;
    }

    return expected.length > 0 ? expected : undefined;
  }, [address, airstream, purpose, t]);

  return useMemo(() => {
    if (!_.isNil(airstream)) {
      const permission = {
        create: t("permissions.airstreamCreate"),
        eligibilityCheck: t("permissions.airstreamEligibility"),
        eligibilityClaim: t("permissions.airstreamClaim"),
        fund: t("permissions.airstreamFund"),
        clawback: t("permissions.airstreamClawback"),
      }[purpose];

      if (purpose !== "create") {
        if (purpose === "eligibilityCheck" || purpose === "eligibilityClaim") {
          if (isBlocked) {
            return { state: "geoblocked" };
          }
          if (undetailed) {
            return { state: "undetailed" };
          }
        }

        if (purpose === "eligibilityCheck") {
          /** Checking eligibility is public, doesn't require a connected account and can be done at any time */
          return { state: "ready" };
        }

        if (purpose === "eligibilityClaim" || purpose === "fund") {
          if (!airstream.isAlive) {
            return {
              state: "ended",
              permission,
              end: {
                value: airstream.expiration,
                label: t("form.label.airstreamExpiration"),
              },
            };
          }
        }

        const isFullyFunded = deposited?.isGreaterThanOrEqualTo(
          airstream?.aggregateAmount.humanized,
        );

        if (purpose === "fund" && isFullyFunded) {
          return {
            state: "funded",
          };
        }

        if (purpose === "clawback") {
          const isInGrace = airstream.isInGrace(metadata?.first);

          if (airstream.isAlive && !isInGrace) {
            return {
              state: "early",
              permission,
              start: {
                value: airstream.expiration,
                label: t("form.label.airstreamExpiration"),
              },
            };
          }
        }
      }

      if (whitelist) {
        return { state: "whitelist", whitelist };
      }

      if (fromChainId !== airstream?.chainId) {
        return { state: "chain", chainId: airstream.chainId };
      }
    }

    if (isDisconnected) {
      return { state: "connect" };
    }

    return { state: "ready" };
  }, [
    airstream,
    deposited,
    fromChainId,
    isBlocked,
    isDisconnected,
    metadata?.first,
    purpose,
    t,
    undetailed,
    whitelist,
  ]);
}
