import { BigNumber, _ } from "@sablier/v2-mixins";
import type { IDynamicUnlockCliff } from "./config";
import type { Translate } from "@sablier/v2-locales";
import type { IExtensionDependencies, ISummaryExtension } from "~/client/types";

function summary(
  dependencies: IExtensionDependencies,
  extension: unknown,
  t: Translate,
): ISummaryExtension {
  const fields = extension as IDynamicUnlockCliff;
  const { amount, duration, end, start, timing, token } = dependencies;

  let [durationCliff, durationTotal, isComplete, isValid] = [
    _.isNilOrEmptyString(fields.unlockDuration.warning)
      ? new BigNumber(fields.unlockDuration.value || 0)
      : undefined,
    _.isNilOrEmptyString(duration) ? undefined : new BigNumber(duration || 0),
    !_.isNilOrEmptyString(fields.unlockDuration.value),
    _.isNilOrEmptyString(fields.unlockDuration.warning),
  ];

  if (timing === "range") {
    durationCliff =
      _.isNilOrEmptyString(fields.unlockEnd.warning) &&
      !_.isNilOrEmptyString(start)
        ? new BigNumber(fields.unlockEnd.value || 0)
            .minus(BigNumber(start || 0))
            .abs()
        : undefined;
    durationTotal =
      !_.isNilOrEmptyString(start) && !_.isNilOrEmptyString(end)
        ? new BigNumber(end || 0).minus(BigNumber(start || 0)).abs()
        : undefined;
    isComplete =
      !_.isNilOrEmptyString(fields.unlockEnd.value) &&
      !_.isNilOrEmptyString(fields.unlock.value);
    isValid =
      _.isNilOrEmptyString(fields.unlockEnd.warning) &&
      _.isNilOrEmptyString(fields.unlock.warning);
  }

  const cliffFor = !_.isNilOrEmptyString(durationCliff)
    ? _.toDuration(durationCliff.toNumber(), "time")[0]
    : undefined;
  const cliffAmount = new BigNumber(amount || 0).minus(
    new BigNumber(fields.unlock.value || 0),
  );
  const cliffOf =
    !_.isNilOrEmptyString(durationTotal) && !_.isNilOrEmptyString(durationCliff)
      ? durationCliff
          .div(durationTotal)
          .times(BigNumber(cliffAmount || 0))
          .toString()
      : undefined;

  return {
    isComplete,
    isValid,
    list: [
      {
        label: `${_.capitalize(t("structs.unlockLabel"))}:`,
        purpose: "amount",
        token,
        value: fields.unlock.value?.toString(),
      },
      {
        label: `${_.capitalize(t("structs.cliffFor"))}:`,
        purpose: "text",
        value: cliffFor,
      },
      {
        label: `${_.capitalize(t("structs.cliffUnlocks"))}:`,
        purpose: "amount",
        token,
        value: cliffOf,
      },
    ],
  };
}

export default summary;
