import { memo, useCallback } from "react";
import styled, { css } from "styled-components";
import {
  ArrowRightCircleIcon,
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
  PlusIcon,
  Square2StackIcon,
} from "@heroicons/react/24/outline";
import { useCopy } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import { External } from "~/components/atoms";
import type { ComponentProps, MouseEvent, PropsWithChildren } from "react";
import Icon from "../../Icon";

const Wrapper = css`
  ${(props) => props.theme.styles.row}
  & {
    --size: 18px;
    display: inline-flex;
    color: ${(props) => props.theme.colors.gray400};
    column-gap: 4px;
    cursor: pointer;

    p {
      ${(props) => props.theme.styles.textParagraph}
      & {
        display: inline-flex;
        color: ${(props) => props.theme.colors.gray100};
        line-height: 27px !important;
      }
    }

    &[data-disabled="true"] {
      opacity: 1;
      pointer-events: none;
    }
    &:not([data-disabled="true"]) {
      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

const WrapperExternal = styled(External)`
  ${Wrapper}
`;

const WrapperInternal = styled(External)`
  ${Wrapper}
`;

const WrapperClick = styled.div`
  ${Wrapper}
`;

interface Props {
  icon?: ComponentProps<typeof Icon>["value"];
  onClick?: (e?: MouseEvent<HTMLElement>) => void;
  purpose: "button" | "copy" | "external" | "internal";
  to?: string | undefined;
  value?: string | undefined;
}

function ActionCopy({
  children,
  value,
  to,
}: PropsWithChildren<Partial<Props>>) {
  const [isCopied, doCopy] = useCopy(to || "", 3000);

  return (
    <WrapperClick data-disabled={isCopied} onClick={doCopy}>
      {children ? children : <p>{value}</p>}
      {isCopied ? (
        <Icon color={"green"} value={CheckCircleIcon} size={18} />
      ) : (
        <Icon value={Square2StackIcon} size={18} />
      )}
    </WrapperClick>
  );
}

function Action({
  children,
  icon,
  onClick,
  purpose,
  to,
  value,
}: PropsWithChildren<Props>) {
  const renderBody = useCallback(
    (icon: ComponentProps<typeof Icon>["value"]) => {
      return (
        <>
          {children ? (
            children
          ) : !_.isNilOrEmptyString(value) ? (
            <p>{value}</p>
          ) : (
            false
          )}
          <Icon value={icon} size={18} />
        </>
      );
    },
    [children, value],
  );

  switch (purpose) {
    case "button":
      return (
        <WrapperClick onClick={onClick}>
          {renderBody(icon || PlusIcon)}
        </WrapperClick>
      );
    case "copy":
      return (
        <ActionCopy to={to} value={value}>
          {children}
        </ActionCopy>
      );
    case "external":
      return (
        <WrapperExternal to={to}>
          {renderBody(icon || ArrowTopRightOnSquareIcon)}
        </WrapperExternal>
      );
    case "internal":
      return (
        <WrapperInternal to={to}>
          {renderBody(icon || ArrowRightCircleIcon)}
        </WrapperInternal>
      );

    default:
      return <></>;
  }
}

export default memo(Action);
