import { useMemo } from "react";
import { StreamCategory } from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Stream } from "@sablier/v2-models";
import { getTheme } from "@sablier/v2-themes";
import type { ChartOptions } from "chart.js";

const theme = getTheme();

function getPrecision(stream: Stream) {
  const years = _.uniq(
    stream.segments.map((segment) => _.toDayjs(segment.endTime).year()),
  );
  if (years.length < 2) {
    const months = _.uniq(
      stream.segments.map((segment) => _.toDayjs(segment.endTime).month()),
    );

    if (months.length < 3) {
      return "date-minimal";
    }

    return "date-short";
  }

  return "date-only";
}

function getOptions(stream: Stream): ChartOptions<"line"> {
  const precision = getPrecision(stream);

  const body = getComputedStyle(document.body);
  const fontPrimary = body.getPropertyValue("--font-urbanist");
  const fontMonospace = body.getPropertyValue("--font-roboto-mono");

  return {
    interaction: {
      mode: "nearest",
    },
    responsive: true,
    spanGaps: true,
    maintainAspectRatio: false,
    normalized: true, // https://www.chartjs.org/docs/latest/general/performance.html#data-normalization
    clip: false,
    layout: {
      padding: {
        right: 12,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: theme.colors.dark400,
        titleFont: { family: fontPrimary, weight: "500", size: 12 },
        titleAlign: "center",
        bodyFont: { family: fontMonospace, weight: "700", size: 16 },
        bodyAlign: "center",
        padding: 10,
        displayColors: false,
        callbacks: {
          title: (context) => {
            return _.toDuration(context[0].label, "date")[0];
          },
          label: (context) => {
            return `${stream.token.symbol} ${_.toNumeralPrice(
              context.parsed.y,
            )}`;
          },
        },
      },
    },
    scales: {
      x: {
        border: { width: 0 },
        ticks: {
          color: theme.colors.gray400,
          font: {
            family: fontPrimary,
            weight: "600",
          },
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 12,
          callback(value) {
            const label = this.getLabelForValue(_.toNumber(value));
            return _.toDuration(label, precision)[0];
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        border: { width: 0 },
        offset: true,
        beginAtZero: true,
        ticks: {
          color: theme.colors.gray400,
          font: {
            family: fontPrimary,
            weight: "600",
          },
          callback: (value) => {
            return `${value}`;
          },
        },

        grid: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        tension: 0.1,
        cubicInterpolationMode:
          stream.category === StreamCategory.LOCKUP_LINEAR
            ? "default"
            : "monotone",
        borderJoinStyle: "round",
      },

      point: {
        radius: 0,
        hoverRadius: 10,
        hitRadius: 40,
        hoverBorderWidth: 2,
        hoverBackgroundColor: theme.colors.orange,
        hoverBorderColor: "white",
      },
    },
  };
}

function getDatasets(stream: Stream) {
  const sets = stream.findSegmentsAugmented().map((segment, index) => {
    if (index >= stream.segments.length - 1) {
      return segment.getDataset(stream);
    }

    const next = stream.segments[index + 1];
    const duration = new BigNumber(next.duration);

    return duration.isLessThanOrEqualTo(new BigNumber(1000))
      ? segment.getDataset(stream, new BigNumber(next.endTime))
      : segment.getDataset(stream);
  });

  const set = {
    ...sets[0],
    data: _.uniqWith(
      sets.map((set) => set.data).flat(),
      (a, b) => a.x === b.x && a.y === b.y,
    ),
  };

  return [set];
}

export function useChart(stream?: Stream) {
  const datasets = useMemo(
    () => (!_.isNil(stream) ? getDatasets(stream) : undefined),
    [stream],
  );
  const options = useMemo(
    () => (!_.isNil(stream) ? getOptions(stream) : undefined),
    [stream],
  );

  return {
    datasets,
    options,
  };
}
