import { BigNumber, _ } from "@sablier/v2-mixins";
import type { ITranchedMonthly } from "./config";
import type { Translate } from "@sablier/v2-locales";
import type { IExtensionDependencies, ISummaryExtension } from "~/client/types";

function summary(
  dependencies: IExtensionDependencies,
  extension: unknown,
  t: Translate,
): ISummaryExtension {
  const fields = extension as ITranchedMonthly;
  const { amount, end, start, token } = dependencies;

  const isComplete =
    !_.isNilOrEmptyString(fields.months.value) &&
    !_.isNilOrEmptyString(end) &&
    !_.isNilOrEmptyString(start);
  const isValid = _.isNilOrEmptyString(fields.months.warning) && isComplete;

  const months = new BigNumber(fields.months.value || 0);
  const initialUnlock = fields.initial.value ?? false;

  const stepAmount = initialUnlock
    ? new BigNumber(amount || 0).div(months.plus(1))
    : new BigNumber(amount || 0).div(months);
  const stepOf = stepAmount.toString();

  const milestones = initialUnlock
    ? [...Array(_.toNumber(months) + 1).keys()].map(
        (i) =>
          _.toDuration(
            _.addCalendarUnit(start!, i.toString(), "month"),
            "date",
          )[0],
      )
    : [...Array(_.toNumber(months)).keys()].map(
        (i) =>
          _.toDuration(
            _.addCalendarUnit(start!, (i + 1).toString(), "month"),
            "date",
          )[0],
      );

  return {
    isComplete,
    isValid,
    list: [
      {
        label: `${_.capitalize(t("structs.numberOfUnlocks"))}:`,
        purpose: "text",
        value: (initialUnlock ? months.plus(1) : months).toString(),
      },
      {
        label: `${_.capitalize(t("words.unlocks"))} of:`,
        purpose: "amount",
        token,
        value: stepOf,
      },
      {
        label: `${_.capitalize(t("structs.firstUnlock"))}:`,
        purpose: "text",
        value: initialUnlock
          ? t("structs.firstUnlockOn", {
              date: _.toDuration(start, "date-only")[0],
            })
          : t("structs.endOfFirstMonth"),
      },
      {
        label: _.capitalize(t("words.unlock")),
        purpose: "scroller",
        value: milestones,
      },
    ],
  };
}

export default summary;
