import { useMemo } from "react";
import { ClockIcon } from "@heroicons/react/24/outline";
import { tags } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { IMilliseconds } from "@sablier/v2-types";
import { Base } from "../../system";

interface Props {
  className?: string;
  end: {
    value?: IMilliseconds;
    label?: string;
  };
  permission: string | undefined;
}

function Expired({ className, end, permission = "execute" }: Props) {
  const { t } = useT();

  const description = useMemo(() => {
    const suffix = !_.isNilOrEmptyString(end?.label) ? `, ${end?.label}` : "";
    const date = `${_.toDuration(end?.value, "date-full")[0]}${suffix}`;
    const reason = !_.isNilOrEmptyString(end?.value)
      ? t("warnings.permission.ended", { permission, date })
      : "";

    return t("warnings.expired.description", { reason });
  }, [end, permission, t]);

  return (
    <Base
      className={className}
      cy={tags.warningExpired}
      description={description}
      label={{ icon: ClockIcon, value: t("warnings.expired.title") }}
    />
  );
}

export default Expired;
