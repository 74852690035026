import { useCallback, useMemo } from "react";
import { useTemplateModalChainPicker as useModal } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { modals, supported } from "~/client/constants";
import useAccount from "../useAccount";
import useChain from "../useChain";

type ICategory = "unsupported" | "online" | "offline";

export default function useModalChainSwitcher(
  onAfterChoose?: (chainId: number) => void,
) {
  const { id, isOpen, doClose, setOpen } = useModal(
    modals.chainSwitcher,
    "template",
  );

  const { connector, isHostSafe } = useAccount();
  const { id: chainId, switchTo } = useChain();

  const category: ICategory = useMemo(() => {
    if (!switchTo || isHostSafe) {
      return "unsupported";
    }
    return "online";
  }, [isHostSafe, switchTo]);

  const onChoose = useCallback(
    (id: number) => {
      if (id === chainId) {
        if (_.isFunction(onAfterChoose)) {
          onAfterChoose(id);
        }
        return;
      }

      if (switchTo) {
        (async () => {
          try {
            await switchTo({ chainId: id, connector });
          } catch (error) {
            vendors.crash.log(error);
          } finally {
            doClose();
          }
        })();
      }

      if (_.isFunction(onAfterChoose)) {
        onAfterChoose(id);
      }
    },
    [chainId, connector, doClose, onAfterChoose, switchTo],
  );

  const doOpen = useCallback(() => {
    setOpen(true, {
      chainId,
      chains: supported,
      onChoose,
    });
  }, [chainId, onChoose, setOpen]);

  return {
    id,
    category,
    isOpen,
    doClose,
    doOpen,
  };
}
