import {
  cookieStorage,
  createConfig,
  createStorage,
  fallback,
  unstable_connector,
} from "wagmi";
import { injected, metaMask } from "wagmi/connectors";
import { WAGMI_COOKIE_STORAGE, supported } from "~/client/constants";
import type { ArrayOfAtLeastOne } from "@sablier/v2-types";

export function unstable(configurations: typeof supported) {
  const chains = supported.map(
    (configuration) => configuration.library,
  ) as ArrayOfAtLeastOne<(typeof supported)[0]["library"]>;

  const transports = configurations.reduce((r, c) => {
    const transport = fallback([unstable_connector(injected)]);
    return { ...r, [c.id]: transport };
  }, {});

  const connectors = [injected(), metaMask()];

  const config = createConfig({
    chains,
    connectors,
    transports,
    ssr: true,

    storage: createStorage({
      storage: cookieStorage,
      key: WAGMI_COOKIE_STORAGE,
    }),
  });

  return {
    connectors,
    transports,
    config,
  };
}
