import { useMemo } from "react";
import styled, { css } from "styled-components";
import { CheckCircleIcon, Square2StackIcon } from "@heroicons/react/24/outline";
import { useCopy } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import type { ThemeType } from "@sablier/v2-themes";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

interface WrapperProps {
  sizeIcon?: number | string;
  sizeName?: number | string;
  color?: string;
}

const WrapperPartial = styled.div<WrapperProps>`
  ${(props) => props.theme.styles.row}
  & {
    height: ${(props) => props.sizeIcon};
    column-gap: ${(props) =>
      props.sizeIcon && parseInt(props.sizeIcon + "", 10) < 26 ? "4px" : "6px"};

    &[data-copy="icon"] {
      cursor: pointer;
      & > *:last-child {
        color: ${(props) => props.theme.colors.gray400};
      }
    }
  }
`;

const Name = styled.div`
  & > p {
    ${(props) => props.theme.styles.textElement}
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${Name} > p {
    ${(props) =>
      props.color &&
      css`
        color: ${props.color};
      `}

    ${(props) =>
      props.sizeName &&
      css`
        color: ${props.sizeName};
      `}
  }
`;

const Copy = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    display: inline-flex;
  }
`;

export interface Props {
  className?: string;
  empty?: keyof ThemeType["colors"];
  color?: keyof ThemeType["colors"];
  isCopyOnClick?: boolean | "icon";
  isIconShown?: boolean;
  isTooltip?: boolean;
  isShort?: boolean;
  preview?: string;
  /** Size of icon or size of [icon, name] */
  size?: number | [number, number];
  value?: string;
}

function Address({
  className,
  color,
  empty,
  isIconShown = true,
  isCopyOnClick = false,
  isTooltip = true,
  size = 20,
  value,
  preview,
}: Props) {
  const [isCopied, doCopy] = useCopy(value || "");
  const [sizeIcon, sizeName] = useMemo(() => {
    if (_.isArray(size)) {
      return [_.toNumber(_.get(size, "0")), _.toNumber(_.get(size, "1"))];
    }
    return [_.toNumber(size), undefined];
  }, [size]);

  return (
    <Tooltip value={isTooltip ? value : undefined} maxWidth={"400px"}>
      <Wrapper
        className={className}
        color={color}
        data-copy={isCopyOnClick}
        onClick={isCopyOnClick ? doCopy : undefined}
        sizeIcon={sizeIcon && _.toSuffix(sizeIcon, "px")}
        sizeName={sizeName && _.toSuffix(sizeName, "pt")}
      >
        {isIconShown && (
          <Icon
            purpose={_.isEthereumAddress(value) ? "identicon" : "empty"}
            value={_.isEthereumAddress(value) ? value : empty}
            size={sizeIcon}
          />
        )}
        {value && (
          <Name>
            <p>{!_.isNilOrEmptyString(preview) ? preview : value}</p>
          </Name>
        )}
        {isCopyOnClick === "icon" ? (
          <Copy>
            {isCopied ? (
              <Icon color={"green"} value={CheckCircleIcon} size={18} />
            ) : (
              <Icon value={Square2StackIcon} size={18} />
            )}
          </Copy>
        ) : (
          false
        )}
      </Wrapper>
    </Tooltip>
  );
}

export default Address;
