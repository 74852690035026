import { EXPONENT_DECIMALS, StreamCategory } from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Stream, Token } from "@sablier/v2-models";
import type { IDynamicExponential } from "./config";
import type {
  IExtensionCertify,
  IExtensionCheck,
  IExtensionParamsSimulate,
  IPrecomputeParams,
  IPrecomputeResult,
} from "~/client/types";
import { EXPECTED_SEGMENTS } from "./config";

type IExtension = IDynamicExponential;

export function certify(_params: IExtensionCertify): string | undefined {
  return undefined;
}

export function check(_params: IExtensionCheck) {
  return undefined;
}

export function identify(stream: Stream): boolean {
  return (
    stream.category === StreamCategory.LOCKUP_DYNAMIC &&
    stream.segments.length === EXPECTED_SEGMENTS &&
    !stream.segments[0].exponent.humanized.isEqualTo(new BigNumber(0))
  );
}

export function precomputeSingle({
  dependencies,
}: IPrecomputeParams<"single">): IPrecomputeResult {
  const { amount, token } = dependencies;

  return {
    amount: _.toValuePrepared({
      decimals: token!.decimals,
      humanized: amount,
    }),
  };
}

export function precomputeGroup({
  dependencies,
}: IPrecomputeParams<"group">): IPrecomputeResult {
  const { streams, token } = dependencies;

  return {
    amount: _.toValuePrepared({
      humanized: streams?.reduce(
        (p, c) => p.plus(new BigNumber(c.amount || 0)),
        new BigNumber(0),
      ),
      decimals: token?.decimals,
    }),
  };
}

export const precompute = { group: precomputeGroup, single: precomputeSingle };

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */
export function simulate(
  params: IExtensionParamsSimulate & { timing: "duration" },
): Stream;
export function simulate(
  params: IExtensionParamsSimulate & { timing: "range" },
): Stream;
export function simulate(params: IExtensionParamsSimulate): Stream;

export function simulate({
  dependencies,
  extras,
  timing,
}: IExtensionParamsSimulate): Stream {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const { purpose: _purpose, ...extended } = extras as IExtension;
  const {
    amount,
    cancelability,
    transferability,
    chainId,
    duration,
    end,
    start,
    token,
  } = dependencies;

  const exponent = _.toValuePrepared({
    humanized: extended.exponent,
    decimals: EXPONENT_DECIMALS,
  });

  const deposit = _.toValuePrepared({
    humanized: amount,
    decimals: token?.decimals,
  });

  const streamToken = new Token({
    address: token!.address,
    chainId,
    decimals: token!.decimals,
    name: token!.name,
    symbol: token!.symbol,
  });

  const startTime =
    timing === "duration"
      ? _.toSeconds(new BigNumber(Date.now()).toString())
      : _.toSeconds(start);

  const endTime =
    timing === "duration"
      ? _.toSeconds(
          new BigNumber(Date.now()).plus(new BigNumber(duration!)).toString(),
        )
      : _.toSeconds(end);

  return new Stream(
    {
      ...Stream.base(),
      chainId,
      cancelable: cancelability!,
      transferable: transferability!,
      category: StreamCategory.LOCKUP_DYNAMIC,
      depositAmount: deposit,
      endTime,
      intactAmount: deposit,
      startTime,
      segments: [
        {
          id: "0",
          position: "0",
          amount: deposit,
          milestone: "",
          endAmount: deposit,
          endTime,
          startTime,
          startAmount: "0",
          exponent,
        },
      ],
    },
    streamToken,
  );
}
