import { useMemo } from "react";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { ISInput } from "../system/templates";
import Template, { sides } from "../system/templates";

export interface Props extends Omit<ISInput, "info" | "left" | "right"> {
  info?: ISInput["info"] | "auto";
  resolution?: string;
}

const info = (isLoading = false) =>
  isLoading ? "Resolving address..." : undefined;

function Address(props: Props) {
  const { t } = useT();
  const formatted = useMemo(() => {
    const right: ISInput["right"] = [];

    if (!_.isNilOrEmptyString(props.resolution)) {
      right.push(sides.resolution(props.resolution));
    }

    if (props.isLoading) {
      right.unshift(sides.loader());
    }

    return {
      placeholder: t("form.placeholder.text"),
      ...props,
      info: props.info === "auto" ? info(props.isLoading) : props.info,
      right,
    };
  }, [props, t]);

  return <Template.Text {...formatted} />;
}

export default Address;
