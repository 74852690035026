import styled from "styled-components";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { MouseEvent } from "react";
import Button from "../Button";
import Icon from "../Icon";

interface Props {
  isIconOnly?: boolean;
  onAdd?: (e?: MouseEvent<HTMLElement>) => void;
  onRemove?: (e?: MouseEvent<HTMLElement>) => void;
  value: boolean;
}

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    --size: 10px;
    --thickness: 4;
    flex-shrink: 0;
    justify-content: center;
    width: 16px;
    height: 16px;

    color: ${(props) => props.theme.colors.white};
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.secondaryDesaturated};

    &:not([data-selected="true"]) {
      color: ${(props) => props.theme.colors.transparent};
      border: 2px solid ${(props) => props.theme.colors.gray500};
      background-color: ${(props) => props.theme.colors.dark100};

      &:hover {
        background-color: ${(props) => props.theme.colors.dark500};
      }
    }
  }
`;

function Checkbox({ onAdd, onRemove, isIconOnly = false, value }: Props) {
  const { t } = useT();

  if (isIconOnly) {
    return (
      <Wrapper data-selected={value} onClick={value ? onRemove : onAdd}>
        <Icon value={CheckIcon} />
      </Wrapper>
    );
  }

  if (value) {
    return (
      <Button
        accent={"secondaryDesaturated"}
        appearance={"transparent"}
        title={_.capitalize(t("words.selected"))}
        isMini
        isPreview={_.isNil(onRemove)}
        isUnpadded
        onClick={onRemove}
        left={() => (
          <Wrapper data-selected={"true"}>
            <Icon value={CheckIcon} />
          </Wrapper>
        )}
        leftPurpose={"custom"}
      />
    );
  }

  return (
    <Button
      accent={"gray400"}
      appearance={"transparent"}
      title={_.capitalize(t("words.select"))}
      isMini
      isPreview={_.isNil(onAdd)}
      isUnpadded
      onClick={onAdd}
      left={() => (
        <Wrapper data-selected={"false"}>
          <Icon value={CheckIcon} />
        </Wrapper>
      )}
      leftPurpose={"custom"}
    />
  );
}

export default Checkbox;
