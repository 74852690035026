import { DigitPurpose } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import type { Definition } from "./context";

export function removeEndZeroes(source: string) {
  const digits = source.split("");

  while (digits[digits.length - 1] === "0") {
    digits.pop();
  }

  return digits.join("");
}

export function addPadding(
  definition: Definition,
  existing: number,
  precision: number,
  isPrefix = false,
) {
  const empty = precision - existing;
  const prefixes = empty > 0 ? (empty > Math.floor(precision / 2) ? 2 : 1) : 0;
  [...Array(prefixes).keys()].forEach(() => {
    if (isPrefix) {
      definition.unshift({ d: "0", p: DigitPurpose.PREFIX });
    } else {
      definition.push({ d: "0", p: DigitPurpose.SUFFIX });
    }
  });
}
