import styled from "styled-components";
import type { Props as ButtonProps } from "../Button";
import Button from "../Button";

const Wrapper = styled.div<{ isMini?: boolean }>`
  ${(props) => props.theme.styles.row}
  & {
    height: calc(
      ${(props) => props.theme.sizes[props.isMini ? "buttonMini" : "button"]} +
        2 * 4px
    );
    padding: 4px;
    column-gap: 4px;
    border-radius: calc(
      (
          ${(props) =>
              props.theme.sizes[props.isMini ? "buttonMini" : "button"]} + 2 *
            4px
        ) * 1 / 2
    );
    background-color: ${(props) => props.theme.colors.dark050};
  }
`;

const Pill = styled.div<{ isMini?: boolean }>`
  ${(props) => props.theme.styles.row}
  & {
    border-radius: calc(
      1 / 2 *
        (
          ${(props) =>
            props.theme.sizes[props.isMini ? "buttonMini" : "button"]}
        )
    );
    &:not(:only-child) {
      padding: 0 calc(${(props) => props.theme.sizes.edge} * 1 / 3);
    }

    &[data-active="false"] {
      &:hover,
      &:active {
        & > *:not([data-preview="true"]) {
          p,
          svg {
            color: ${(props) => props.theme.colors.white};
          }
        }
      }
    }
    &[data-active="true"] {
      background: ${(props) => props.theme.gradients.primary};
    }
  }
`;

interface Props {
  activeId?: string;
  className?: string;
  isMini?: boolean;
  list: (Omit<ButtonProps, "accent" | "appearance"> & {
    id: string;
    title: string;
    isActive?: boolean;
  })[];
  onChange?: (id: string) => void;
}

function Pills({ activeId, className, isMini = false, onChange, list }: Props) {
  return (
    <Wrapper className={className} isMini={isMini}>
      {list.map((item) => (
        <Pill
          data-active={item.id === activeId}
          isMini={isMini}
          key={item.title}
        >
          <Button
            accent={item.id === activeId ? "white" : "gray400"}
            appearance={"transparent"}
            {...item}
            isMini={isMini}
            onClick={(e) => {
              if (item.onClick) {
                item.onClick(e);
              }
              if (onChange) {
                onChange(item.id);
              }
            }}
          />
        </Pill>
      ))}
    </Wrapper>
  );
}

export default Pills;
