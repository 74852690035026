import styled from "styled-components";
import Fields from "./fields";

const Wrapper = styled.div`
  grid-column: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: calc(${(props) => props.theme.sizes.edge} * 1);

  & > * {
    grid-column: span 1;
    &[data-single="true"] {
      grid-column: span 2;
    }
  }

  ${(props) => props.theme.medias.maxMD} {
    grid-template-columns: 1fr;

    & > *,
    & > *[data-single="true"] {
      grid-column: span 2;
    }
  }
`;

function Group({ streamId }: { streamId: string }) {
  return (
    <Wrapper>
      <Fields.GroupStart streamId={streamId} />
      <Fields.GroupYears streamId={streamId} />
      <Fields.GroupUnlocks streamId={streamId} />
      <Fields.GroupEnsurance streamId={streamId} />
    </Wrapper>
  );
}
const Form = {
  Group,
};

export default Form;
