import type { ITranchedMonthly } from "./config";
import { IConfiguration } from "../../system/setup";
import {
  excludes,
  headers,
  initial,
  overrides,
  shape,
  template,
  warnings,
} from "./config";
import Form from "./form";
import { process } from "./process";
import { certify, check, identify, precompute, simulate } from "./setup";
import summary from "./summary";

const extension: IConfiguration = {
  check,
  certify,
  headers,
  excludes,
  identify,
  process,
  Form,
  initial,
  overrides,
  shape,
  summary,
  simulate,
  precompute,
  template,
  warnings,
};

export type { ITranchedMonthly };
export { extension };
