import { memo, useMemo } from "react";
import styled from "styled-components";
import { RssIcon } from "@heroicons/react/24/outline";
import { _ } from "@sablier/v2-mixins";
import type { FC, ReactNode } from "react";
import Icon from "../Icon";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    width: 100%;
    min-height: 24px;
    column-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 4);
  }
`;

const Content = styled.div`
  & > p {
    ${(props) => props.theme.styles.textParagraphMini}
    & {
      color: ${(props) => props.theme.colors.gray100};
    }
  }
`;

const IconContainer = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex-shrink: 0;
    justify-content: center;
    width: 24px;
    height: 24px;
    color: ${(props) => props.theme.colors.gray100};
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.dark400};
  }
`;

export interface Props {
  isIconEnabled?: boolean;
  isPresizing?: boolean;
  value?:
    | string
    | null
    | number
    | boolean
    | { icon: JSX.Element | ReactNode | FC; value: string };
}

function Informer({ isIconEnabled = true, isPresizing = false, value }: Props) {
  const { icon, text } = useMemo(() => {
    console.log(value, _.isObject(value));

    if (_.isObject(value) && value?.icon) {
      return { icon: value.icon, text: value.value };
    }

    const text = _.isString(value) ? value : "";
    return { icon: RssIcon, text };
  }, [value]);

  if (_.isNilOrEmptyString(value) || value === false) {
    if (isPresizing) {
      return <Wrapper />;
    }
    return false;
  }

  return (
    <Wrapper>
      {isIconEnabled && (
        <IconContainer>
          <Icon purpose={"heroicon"} value={icon} size={12} />
        </IconContainer>
      )}

      <Content>
        <p>{text}</p>
      </Content>
    </Wrapper>
  );
}

export default memo(Informer, _.isEqual);
