import styled from "styled-components";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { External } from "@sablier/v2-components/atoms";
import { Label } from "@sablier/v2-components/molecules";
import { links } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { rgba } from "polished";
import type { Translate } from "@sablier/v2-locales";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }
`;

const Box = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    z-index: ${(props) => props.theme.sizes.zIndexUnder};
    gap: 0;
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 4px;
    opacity: 0.35;
    overflow: hidden;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr 200px;
  width: 100%;
  height: 42px;
  background-color: ${(props) => props.theme.colors.dark050};
`;

const Cell = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    padding-left: 12px;
    border: 1px solid ${(props) => props.theme.colors.border};
    & > p {
      ${(props) => props.theme.styles.textParagraph}
      & {
        ${(props) => props.theme.colors.gray400}
      }
    }
  }
`;

const Header = styled(Row)`
  background-color: ${(props) => props.theme.colors.dark200};
  ${Cell} {
    & > p {
      ${(props) => props.theme.styles.textElement}
    }
  }
`;

const Circle = styled.div`
  position: absolute;
  z-index: ${(props) => props.theme.sizes.zIndexUnder};
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.dark1000};
  opacity: 1;
  filter: blur(80px);
`;

const Overlay = styled(External)`
  ${(props) => props.theme.styles.row}
  & {
    position: absolute;
    z-index: calc(${(props) => props.theme.sizes.zIndexUnder} * 2);
    padding: calc(${(props) => props.theme.sizes.edge} * 3 / 2)
      calc(${(props) => props.theme.sizes.edge} * 3);
    border: 2px solid ${(props) => rgba(props.theme.colors.gray200, 0.1)};
    border-radius: 8px;
    background-color: ${(props) => rgba(props.theme.colors.gray200, 0.1)};
    cursor: pointer;
    backdrop-filter: blur(5px);

    *,
    *[data-component="label"] {
      color: ${(props) => props.theme.colors.gray400} !important;
      cursor: pointer;
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxSM} {
    ${Row} {
      grid-template-columns: 30px 1fr 100px;
    }
  }
`;

const header = (t: Translate) => [
  "#",
  _.capitalize(t("words.recipient")),
  _.capitalize(t("words.amount")),
];
const rows = [
  ["1", "0x1234abcd...78be", "100.23"],
  ["2", "0xAc3e2ab1...2C3e", "2,090.23"],
  ["3", "0xcC34bced...A2ee", "12,340.23"],
];

interface Props {
  isTemplateHidden?: boolean;
  name?: string;
  to?: string;
}

function Spreadsheet({
  isTemplateHidden,
  name,
  to = links.airstreamTemplate,
}: Props) {
  const { t } = useT();

  return (
    <Wrapper>
      <Box>
        <Header>
          {header(t).map((item) => (
            <Cell key={item}>
              <p>{item}</p>
            </Cell>
          ))}
        </Header>

        {rows.map((row, ri) => (
          <Row key={ri}>
            {row.map((cell) => (
              <Cell key={cell}>
                <p>{cell}</p>
              </Cell>
            ))}
          </Row>
        ))}
      </Box>
      <Circle />
      {!isTemplateHidden ? (
        <Overlay to={to}>
          <Label
            icon={ArrowDownTrayIcon}
            value={name || t("structs.templateCSV")}
          />
        </Overlay>
      ) : (
        false
      )}
    </Wrapper>
  );
}

export default Spreadsheet;
