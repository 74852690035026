import { useChainData } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import { useSwitchChain } from "wagmi";
import useAccount from "./useAccount";

/** Get the local chain definition for the connected chain id (from provider) or for the arbitrary id parameter */

export default function useChain() {
  const { chainId, connector } = useAccount();
  const { switchChainAsync: switchTo } = useSwitchChain();

  const { chain } = useChainData(chainId);

  return {
    id: chainId,
    chain,
    connector,
    switchTo,
  };
}
