import { _ } from "@sablier/v2-mixins";
import type { Translate } from "@sablier/v2-locales";
import policy from "./policy";

type Field = {
  [key: string]: unknown;
  isLoading?: boolean;
  value?: unknown;
  id?: string;
  warning?: string;
};

interface FormFlagsValidProps<T> {
  t: Translate;
  isLoadingIncluded?: boolean;
  isWarningIncluded?: boolean;
  value?: T;
}

export function validateFormFlags<T extends Record<string, Field>>({
  t,
  isLoadingIncluded = false,
  isWarningIncluded = false,
  value,
}: FormFlagsValidProps<T>): string | undefined {
  try {
    if (_.isNilOrEmptyString(value)) {
      return policy.form.values(t);
    }

    if (isLoadingIncluded) {
      if (
        Object.values(value).find(
          (field) => _.has(field, "isLoading") && field.isLoading === true,
        )
      ) {
        return policy.form.loading(t);
      }
    }

    if (isWarningIncluded) {
      const found = Object.values(value).find(
        (field) =>
          _.has(field, "warning") && !_.isNilOrEmptyString(field.warning),
      );
      if (found) {
        return policy.form.warning(t, found?.id);
      }
    }
  } catch (error) {
    console.error(error);
    return policy.form.values(t);
  }

  return undefined;
}

interface FormRequiredValidProps<T> {
  t: Translate;
  required: (keyof T)[];
  value?: T;
}

export function validateFormRequired<T extends Record<string, Field>>({
  t,
  required,
  value,
}: FormRequiredValidProps<T>): string | undefined {
  try {
    if (_.isNilOrEmptyString(value)) {
      return policy.form.values(t);
    }

    if (required && required.length) {
      const found = required.find(
        (key) =>
          _.has(value[key], "value") && _.isNilOrEmptyString(value[key].value),
      );
      if (found) {
        return policy.form.values(t, value[found]?.id);
      }
    }
  } catch (error) {
    return policy.form.values(t);
  }

  return undefined;
}
