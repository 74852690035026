import {
  AIRSTREAMS_RECIPIENTS_PAGE_SIZE,
  type StreamShape,
} from "@sablier/v2-constants";
import {
  FormProvider,
  useFormContext,
  useFormField,
  useFormStore,
} from "@sablier/v2-contexts";
import { _ } from "@sablier/v2-mixins";
import type { IAddress, IStoreSelector, IToken } from "@sablier/v2-types";
import type { PropsWithChildren } from "react";
import type { IExtension } from "~/client/extensions";
import type { IAirstreamRecipientsCalldata } from "~/client/types";

export interface IForm<T = IExtension> {
  /** Holder for backend generated data. Not a field. */
  calldata: {
    id: string;
    value: IAirstreamRecipientsCalldata | undefined;
    warning: string | undefined;
  };
  cancelability: {
    id: string;
    value: boolean;
    warning: string | undefined;
  };
  chain: {
    id: string;
    value: number | undefined;
    warning: string | undefined;
  };
  duration: {
    id: string;
    isActive: boolean;
    value: string | undefined;
    warning: string | undefined;
  };
  name: {
    id: string;
    value: string | undefined;
    warning: string | undefined;
  };
  expiration: {
    id: string;
    isActive: boolean;
    value: string | undefined;
    warning: string | undefined;
  };
  recipients: {
    id: string;
    isLoading: boolean;
    /** Pagination for the recipients preview table */
    preview: number;
    /** Status of the underlying file-to-backend interaction */
    status: "missing" | "uploaded" | "successful" | "bypassed" | "failed";
    value: File | undefined;
    warning: string | undefined;
  };
  shape: {
    id: string;
    value: StreamShape | undefined;
  };
  step: {
    id: string;
    value: number;
    warning: string | undefined;
  };
  token: {
    id: string;
    value: IToken | undefined;
    warning: string | undefined;
  };
  /** Holder for transaction generated data. Not a field. */
  transaction: {
    id: string;
    value:
      | {
          deploy?: {
            hash: string | undefined;
            airstream: IAddress | undefined;
          };
          fund?: {
            hash: string | undefined;
          };
        }
      | undefined;
    waring: string | undefined;
  };
  transferability: {
    id: string;
    value: boolean;
    warning: string | undefined;
  };
  extension: T;
}

const initial: IForm = {
  calldata: {
    id: "calldata",
    value: undefined,
    warning: undefined,
  },
  cancelability: {
    id: "cancelability",
    value: true,
    warning: undefined,
  },
  chain: {
    id: "chain",
    value: undefined,
    warning: undefined,
  },
  duration: {
    id: "duration",
    isActive: true,
    value: undefined,
    warning: undefined,
  },
  expiration: {
    id: "expiration",
    isActive: true,
    value: undefined,
    warning: undefined,
  },
  name: {
    id: "name",
    value: undefined,
    warning: undefined,
  },
  recipients: {
    id: "recipients",
    isLoading: false,
    preview: AIRSTREAMS_RECIPIENTS_PAGE_SIZE,
    status: "missing",
    value: undefined,
    warning: undefined,
  },
  shape: {
    id: "shape",
    value: undefined,
  },
  step: {
    id: "step",
    value: 1,
    warning: undefined,
  },
  token: {
    id: "token",
    value: undefined,
    warning: undefined,
  },
  transaction: {
    id: "transaction",
    value: undefined,
    waring: undefined,
  },
  transferability: {
    id: "transferability",
    value: true,
    warning: undefined,
  },
  extension: {
    purpose: undefined,
  },
};

function Provider({ children }: PropsWithChildren<unknown>) {
  return <FormProvider<IForm> initial={initial}>{children}</FormProvider>;
}

function useAccessor<T = IExtension>() {
  return useFormContext<IForm<T>>().getState;
}

function useForm<Slice>(selector: IStoreSelector<IForm, Slice>): Slice {
  return useFormStore(selector);
}

function useField<I extends keyof IForm>(id: I) {
  return useFormField<IForm, I>(id);
}

export { useAccessor, useField, useForm };

export default Provider;
