import { Fragment } from "react";
import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import type { ComponentProps } from "react";
import Address from "../Address";
import Amount from "../Amount";
import Chain from "../Chain";
import Checkbox from "../Checkbox";
import Label from "../Label";
import Action from "./Action";
import Content from "./Content";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex-wrap: wrap;
    width: 100%;
    column-gap: 4px;

    & > *,
    label,
    div:not([data-component="title"]) > p {
      display: inline-flex;
      align-items: center;
      min-height: 27px;
      line-height: 27px !important;
    }
  }
`;

interface ItemAddress {
  options: ComponentProps<typeof Address>;
  purpose: "address";
}

interface ItemAmount {
  options: ComponentProps<typeof Amount>;
  purpose: "amount";
}

interface ItemChain {
  options: ComponentProps<typeof Chain>;
  purpose: "chain";
}

interface ItemContent {
  options: ComponentProps<typeof Content>;
  purpose: "content";
}

interface ItemCopy {
  options: Omit<ComponentProps<typeof Action>, "purpose" | "to"> & {
    toCopy?: string;
  };
  purpose: "copy";
}

interface ItemCopyAddress {
  options: Omit<ComponentProps<typeof Action>, "purpose" | "to"> & {
    isIconShown?: boolean;
    preview?: string;
    value?: string;
    toCopy?: string;
  };
  purpose: "copy-address";
}

interface ItemExternal {
  options: Omit<ComponentProps<typeof Action>, "purpose" | "onClick">;
  purpose: "external";
}

interface ItemInternal {
  options: Omit<ComponentProps<typeof Action>, "purpose" | "onClick">;
  purpose: "internal";
}

interface ItemButton {
  options: Omit<ComponentProps<typeof Action>, "purpose" | "to">;
  purpose: "button";
}

interface ItemLabel {
  options: ComponentProps<typeof Label>;
  purpose: "label";
}

interface ItemSelect {
  options: ComponentProps<typeof Checkbox>;
  purpose: "select";
}

type Item =
  | ItemAddress
  | ItemAmount
  | ItemButton
  | ItemChain
  | ItemContent
  | ItemCopy
  | ItemCopyAddress
  | ItemExternal
  | ItemInternal
  | ItemLabel
  | ItemSelect;

interface Props {
  className?: string;
  description: Item[];
}

function Characteristic({ className, description }: Props) {
  return (
    <Wrapper className={className}>
      {description.map((item, index) => (
        <Fragment key={index}>
          {item.purpose === "address" && (
            <Address {...item.options} size={18} />
          )}
          {item.purpose === "amount" && <Amount {...item.options} size={18} />}
          {item.purpose === "chain" && <Chain {...item.options} />}
          {item.purpose === "content" && <Content {...item.options} />}
          {item.purpose === "copy" && (
            <Action
              {...item.options}
              to={item.options.toCopy}
              purpose={"copy"}
            />
          )}
          {item.purpose === "copy-address" && (
            <Action {...item.options} to={item.options.toCopy} purpose={"copy"}>
              <Address {...item.options} size={18} />
            </Action>
          )}
          {item.purpose === "external" && (
            <Action {...item.options} purpose={"external"} />
          )}
          {item.purpose === "internal" && (
            <Action {...item.options} purpose={"internal"} />
          )}
          {item.purpose === "button" && (
            <Action {...item.options} purpose={"button"} />
          )}
          {item.purpose === "label" && <Label {...item.options} />}
          {item.purpose === "select" && (
            <div>
              <Checkbox {...item.options} />
            </div>
          )}
        </Fragment>
      ))}
    </Wrapper>
  );
}

export default Characteristic;
