import { useMemo } from "react";
import { _ } from "@sablier/v2-mixins";

export default function useShortAddress(
  value: string | undefined,
  a = 6,
  b = -4,
) {
  return useMemo(() => _.toShortAddress(value, a, b), [a, b, value]);
}
