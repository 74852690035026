import { useEffect, useMemo } from "react";
import {
  MAX_SUBGRAPH_ID,
  REQUEST_ID,
  STREAMS_PAGE_SIZE,
} from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { Stream, Token } from "@sablier/v2-models";
import { client } from "@sablier/v2-subgraphs";
import { useQuery } from "@tanstack/react-query";
import type { IFilterStream, ISearchStream } from "@sablier/v2-models";
import type { ISearchOperator } from "@sablier/v2-types";
import useRequestQuery from "./useRequestStreamListQuery";

interface Props {
  key?: string[];
  filter: IFilterStream;
  options?: {
    after: string;
    first: number;
    operator: ISearchOperator;
  };
  isEnabled?: boolean;
  onSuccess: (result: ISearchStream) => void;
  onError?: (error: unknown, result: ISearchStream) => void;
  /**
   * Decide if the indexer query will attempt to retry and jump
   * to the fallback endpoint if certain conditions aren't being met
   */
  onJump?: (result: { streams: { id: string }[] }) => boolean;
}

export default function useRequestStreamList({
  key = REQUEST_ID.streamList,
  filter,
  onError = _.noop,
  onSuccess = _.noop,
  onJump,
  isEnabled = false,
  options = {
    after: MAX_SUBGRAPH_ID,
    first: STREAMS_PAGE_SIZE,
    operator: "AND" as const,
  },
}: Props) {
  const endpoint = useMemo(
    () =>
      client.getEndpointByChain({
        chainId: filter.chainId,
        feature: "protocol",
      }),
    [filter],
  );

  const query = useRequestQuery(filter, options.operator);
  const variables = useMemo(() => {
    const sender = filter.sender?.toLowerCase();
    const recipient = filter.recipient?.toLowerCase();
    const token = filter.token?.toLowerCase() || "";

    return {
      chainId: filter.chainId,
      first: options.first,
      skip: 0,
      streamIds: filter.streamIds?.map((id) => id?.toLowerCase()),
      subgraphId: options.after,
      sender,
      recipient,
      token,
    };
  }, [filter, options]);

  const { data, error, isLoading } = useQuery({
    queryKey: [...key, { unique: variables }],
    queryFn: async () => {
      const result = await client.request(
        endpoint,
        query,
        variables,
        {},
        onJump,
      );
      return {
        result,
        variables,
      };
    },
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: isEnabled,
    retry: false,
  });

  /** -------------- On Success -------------- */
  /** Checks should run against a valid `data` */

  useEffect(() => {
    if (!_.isNilOrEmptyString(data?.result)) {
      const { result, variables } = data || {};

      const after = result?.streams.length
        ? result.streams[result.streams.length - 1].subgraphId
        : MAX_SUBGRAPH_ID;

      const isComplete = (result?.streams.length || 0) < options.first;

      const search: ISearchStream = {
        filter,
        options: {
          after,
          first: options.first,
          operator: options.operator,
          isComplete,
          variables,
        },
        streams: [],
      };

      if (!_.isNilOrEmptyString(data)) {
        result?.streams.forEach((item) => {
          const token = new Token(item.asset);
          const stream = new Stream(item, token);
          search.streams.push(stream);
        });
      }

      onSuccess(search);
    }
  }, [data, filter, onSuccess, options]);

  /** -------------- On Error -------------- */
  /** Checks should run against the `error` */

  useEffect(() => {
    if (!_.isNilOrEmptyString(error)) {
      const search: ISearchStream = {
        filter,
        options: {
          after: MAX_SUBGRAPH_ID,
          first: options.first,
          operator: options.operator,
          error: _.toString(error),
          isComplete: true,
          variables,
        },
        streams: [],
      };

      onError(error, search);
    }
  }, [error, filter, onError, options, variables]);

  return useMemo(
    () => ({
      error,
      isLoading: isLoading && isEnabled,
    }),
    [error, isLoading, isEnabled],
  );
}
