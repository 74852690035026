import { useCallback, useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import { Informer, Label, Warning } from "~/components/molecules";
import type { ISInput } from "../../particles";
import type { ChangeEvent, MouseEvent } from "react";
import {
  Box,
  Container,
  Field,
  Highlight,
  Sides,
  Wrapper,
} from "../../particles";
import * as utils from "../utils";

export interface Props extends ISInput {
  className?: string;
}

function Text({
  className,
  file,
  id,
  info,
  isBordered,
  isDisabled,
  isLoading,
  isLocked,
  isPreview,
  label,
  left,
  onBlur,
  onChange: callback,
  onClick,
  onFocus,
  onKeyDown,
  placeholder,
  right,
  value,
  under,
  warning,
}: Props) {
  const sanitized = useMemo(() => utils.doSanitize(value), [value]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => utils.onChange(e, callback),
    [callback],
  );

  const onHitbox = useCallback(
    (e: MouseEvent<HTMLDivElement>) => utils.onHitbox(e, onClick, isDisabled),
    [isDisabled, onClick],
  );

  return (
    <Wrapper
      className={className}
      data-loading={isLoading}
      data-locked={isLocked}
      data-disabled={isDisabled}
      isLoading={isLoading}
      isLocked={isLocked}
      isDisabled={isDisabled}
      isPreview={isPreview}
    >
      {label && <Label {...label} />}
      <Container>
        {!!left && left.length > 0 && <Sides items={left} on={"left"} />}
        <Field
          id={id}
          isBordered={isBordered}
          isPreview={isPreview}
          isDisabled={isDisabled}
          onBlur={onBlur}
          onChange={onChange}
          onClick={onClick}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          value={sanitized}
          readOnly={isPreview || !callback}
          {...(file ? { ...file, type: "file" } : {})}
        />
        {!!right && right.length > 0 && <Sides items={right} on={"right"} />}
        <Box data-component={"box"} onClick={onHitbox} />
        <Highlight data-component={"highlight"} />
      </Container>
      {under}
      {info && <Informer value={info} />}
      {warning && <Warning cy={"warning"} value={warning} />}
    </Wrapper>
  );
}

export default Text;
