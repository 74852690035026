import { EXPONENT_DECIMALS, StreamCategory } from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Stream, Token } from "@sablier/v2-models";
import type { IDynamicTimelock } from "./config";
import type {
  IExtensionCertify,
  IExtensionCheck,
  IExtensionParamsSimulate,
  IPrecomputeParams,
  IPrecomputeResult,
} from "~/client/types";
import { EXPECTED_SEGMENTS, UNLOCK_DURATION } from "./config";

type IExtension = IDynamicTimelock;

export function certify(_params: IExtensionCertify): string | undefined {
  throw new Error("Deprecated implementation in favor of Tranched Timelock.");
}

export function check(_params: IExtensionCheck): string | undefined {
  throw new Error("Deprecated implementation in favor of Tranched Timelock.");
}

export function identify(stream: Stream): boolean {
  return (
    stream.category === StreamCategory.LOCKUP_DYNAMIC &&
    stream.segments.length === EXPECTED_SEGMENTS &&
    new BigNumber(stream.duration)
      .minus(new BigNumber(stream.segments[0].duration))
      .isEqualTo(UNLOCK_DURATION)
  );
}

export function precomputeSingle(
  _props: IPrecomputeParams<"single">,
): IPrecomputeResult {
  throw new Error("Deprecated implementation in favor of Tranched Timelock.");
}

export function precomputeGroup(
  _props: IPrecomputeParams<"group">,
): IPrecomputeResult {
  throw new Error("Deprecated implementation in favor of Tranched Timelock.");
}

export const precompute = { group: precomputeGroup, single: precomputeSingle };

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */
export function simulate(
  params: IExtensionParamsSimulate & { timing: "duration" },
): Stream;
export function simulate(
  params: IExtensionParamsSimulate & { timing: "range" },
): Stream;
export function simulate(params: IExtensionParamsSimulate): Stream;

export function simulate({
  dependencies,
  extras,
  timing,
}: IExtensionParamsSimulate): Stream {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const { purpose: _purpose } = extras as IExtension;
  const {
    amount,
    cancelability,
    transferability,
    chainId,
    duration,
    end,
    start,
    token,
  } = dependencies;

  const exponent = _.toValuePrepared({
    humanized: "1",
    decimals: EXPONENT_DECIMALS,
  });

  const deposit = _.toValuePrepared({
    humanized: amount,
    decimals: token?.decimals,
  });

  const streamToken = new Token({
    address: token!.address,
    chainId,
    decimals: token!.decimals,
    name: token!.name,
    symbol: token!.symbol,
  });

  const startTime =
    timing === "duration"
      ? _.toSeconds(new BigNumber(Date.now()).toString())
      : _.toSeconds(start);

  const endTime =
    timing === "duration"
      ? _.toSeconds(
          new BigNumber(Date.now()).plus(new BigNumber(duration!)).toString(),
        )
      : _.toSeconds(end);

  return new Stream(
    {
      ...Stream.base(),
      chainId,
      cancelable: cancelability!,
      transferable: transferability!,
      category: StreamCategory.LOCKUP_DYNAMIC,
      depositAmount: deposit,
      endTime,
      intactAmount: deposit,
      startTime,
      segments: [
        {
          id: "0",
          position: "0",
          amount: "0",
          milestone: "",
          endAmount: "0",
          endTime: (_.toNumber(endTime) - 1).toString(),
          startTime,
          startAmount: "0",
          exponent,
        },
        {
          id: "1",
          position: "1",
          amount: deposit,
          milestone: "",
          endAmount: deposit,
          endTime,
          startTime: (_.toNumber(endTime) - 1).toString(),
          startAmount: "0",
          exponent,
        },
      ],
    },
    streamToken,
  );
}
