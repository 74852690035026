import { BigNumber, _ } from "@sablier/v2-mixins";
import type { Translate } from "@sablier/v2-locales";
import policy from "./policy";

interface AmountValidProps {
  t: Translate;
  context?:
    | "allowance"
    | "form"
    | "generic"
    | "percentage"
    | "previously"
    | "steps"
    | "stream"
    | "wallet";

  max?: string;
  min?: string;
  value?: string;
}

export function validateAmount({
  t,
  context,
  min,
  max,
  value: source,
}: AmountValidProps): string | undefined {
  try {
    if (_.isNilOrEmptyString(source)) {
      return context === "steps"
        ? policy.steps.missing(t)
        : context === "percentage"
        ? policy.percentage.missing(t)
        : policy.amount.missing(t);
    }
    const value = new BigNumber(source);

    if (!value.isFinite()) {
      return context === "steps"
        ? policy.steps.missing(t)
        : context === "percentage"
        ? policy.percentage.missing(t)
        : policy.amount.missing(t);
    }

    if (!new RegExp(/^[0-9.-]+$/).test(source)) {
      return policy.amount.scientific(t);
    }

    if (
      !_.isNilOrEmptyString(min) &&
      value.isLessThanOrEqualTo(new BigNumber(min))
    ) {
      if (context === "steps") {
        return policy.steps.tooLow(t, _.toNumeralPrice(min));
      } else if (context === "form") {
        return policy.form.exact(t, _.toNumeralPrice(max));
      } else if (context === "percentage") {
        return policy.percentage.tooLow(t, _.toNumeralPrice(min, false, false));
      }
      return policy.amount.tooLow(t, _.toNumeralPrice(min));
    }

    if (!_.isNilOrEmptyString(max) && value.isGreaterThan(new BigNumber(max))) {
      if (context === "wallet") {
        return policy.amount.wallet(t, _.toNumeralPrice(max));
      } else if (context === "previously") {
        return policy.amount.previously(t, _.toNumeralPrice(max));
      } else if (context === "allowance") {
        return policy.allowance.tooLow(t, _.toNumeralPrice(max));
      } else if (context === "stream") {
        return policy.amount.stream(t, _.toNumeralPrice(max));
      } else if (context === "form") {
        return policy.form.exact(t, _.toNumeralPrice(max));
      } else if (context === "generic") {
        return policy.amount.generic(t);
      } else if (context === "percentage") {
        return policy.percentage.tooHigh(
          t,
          _.toNumeralPrice(max, false, false),
        );
      }

      return policy.amount.tooHigh(t, _.toNumeralPrice(max, false, false));
    }
  } catch (error) {
    return context === "steps"
      ? policy.steps.missing(t)
      : context === "percentage"
      ? policy.percentage.missing(t)
      : policy.amount.missing(t);
  }

  return undefined;
}
