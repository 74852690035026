import { BigNumber, _ } from "@sablier/v2-mixins";
import { Token } from "@sablier/v2-models";
import { assign, createMachine } from "xstate";
import type { IFlags, ISigner, IToken, IWagmiConfig } from "@sablier/v2-types";

type MachineContext = {
  approving?: boolean;
  error: string | undefined;
};

type MachineServices = {
  doApprove: { data: unknown };
  doCheck: { data: boolean };
};

type MachineEvents =
  | {
      type: "APPROVE";
      payload: {
        flags: IFlags;
        spender: string;
        library: IWagmiConfig;
        signer: ISigner;
        token: IToken;
        onSuccess?: () => void;
      };
    }
  | {
      type: "CHECK";
      payload: {
        allowance?: string;
        owner: string;
        spender: string;
        library: IWagmiConfig;
        signer: ISigner;
        token: IToken;
      };
    }
  | { type: "RESET" }
  | { type: "FAIL" };

const initialContext: MachineContext = {
  approving: false,
  error: undefined,
};

function create({ id }: { id: string }) {
  return createMachine(
    {
      tsTypes: {} as import("./index.typegen").Typegen0,
      id: `approveMachine-${id}`,
      initial: "idle",
      context: initialContext,
      predictableActionArguments: true,
      schema: {
        context: {} as MachineContext,
        events: {} as MachineEvents,
        services: {} as MachineServices,
      },
      states: {
        idle: {
          entry: "doContextClean",
          on: {
            CHECK: [
              {
                cond: "isPreparedForChecks",
                target: "checking",
              },
              {
                target: "denied",
              },
            ],
            FAIL: {
              target: "failed",
            },
          },
        },
        checking: {
          invoke: {
            id: "check",
            src: "doCheck",
            onDone: [
              {
                cond: "isResultTrue",
                target: "allowed",
              },
              {
                target: "denied",
              },
            ],
            onError: {
              actions: "doError",
              target: "failed",
            },
          },
          on: {
            RESET: {
              target: "idle",
            },
            FAIL: {
              target: "failed",
            },
          },
        },
        allowed: {
          on: {
            RESET: {
              target: "idle",
            },
            FAIL: {
              target: "failed",
            },
          },
        },
        denied: {
          on: {
            RESET: {
              target: "idle",
            },
            FAIL: {
              target: "failed",
            },
            APPROVE: {
              target: "approving",
            },
          },
        },
        approving: {
          entry: assign({ approving: () => true }),
          invoke: {
            id: "approve",
            src: "doApprove",
            onDone: {
              target: "allowed",
            },
            onError: {
              actions: "doError",
              target: "denied",
            },
          },
          on: {
            RESET: {
              target: "idle",
            },
          },
          exit: assign({ approving: () => false }),
        },
        failed: {
          on: {
            RESET: {
              target: "idle",
            },
          },
        },
      },
    },
    {
      actions: {
        doContextClean: assign({
          error: (_context, _event) => initialContext.error,
        }),
        doError: assign({
          error: (_context, event) => _.toString(_.get(event, "data.message")),
        }),
      },
      guards: {
        isPreparedForChecks: (_context, event): boolean => {
          const { owner, spender, signer, token } = event.payload;
          return !(
            _.isNil(signer) ||
            _.isNil(token) ||
            _.isNilOrEmptyString(owner) ||
            _.isNilOrEmptyString(spender) ||
            _.isNilOrEmptyString(token.address) ||
            _.isNilOrEmptyString(token.decimals)
          );
        },
        isResultTrue: (_context, event): boolean => {
          return !!_.get(event, "data");
        },
      },
      services: {
        doApprove: async (_context, event) => {
          const { flags, library, onSuccess, signer, spender, token } =
            event.payload;
          const result = await Token.doApproveFor(library, {
            address: token.address,
            decimals: token.decimals,
            flags,
            signer,
            spender,
          });

          if (_.isFunction(onSuccess)) {
            onSuccess();
          }

          return result;
        },
        doCheck: async (_context, event) => {
          const { allowance, spender, library, owner, signer, token } =
            event.payload;

          return Token.isAllowedFor(library, {
            address: token.address,
            allowance: new BigNumber(allowance || 0),
            decimals: token.decimals,
            isCached: true,
            owner,
            provider: signer,
            spender,
          });
        },
      },
    },
  );
}

const allowance = {
  /**
   * Multiple instances of this machine could be necessary,
   * so we're using an instance generator
   */
  create,
};

export default allowance;
