import Numeric from "./Numeric";
import Shell from "./Shell";
import Text from "./Text";

export type { ISInput } from "../particles";
export * as sides from "./sides";

const templates = {
  Numeric,
  Shell,
  Text,
};

export default templates;
