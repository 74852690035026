import {
  FormProvider,
  useFormContext,
  useFormField,
  useFormStore,
} from "@sablier/v2-contexts";
import { Locale } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { IStoreSelector, IToken } from "@sablier/v2-types";
import type { PropsWithChildren } from "react";

export interface IForm {
  amount: {
    id: string;
    isLoading: boolean;
    value?: string;
    warning?: string;
  };
  max: {
    id: string;
    label: Locale;
    value?: string;
  };
  token: {
    id: string;
    value?: IToken;
  };
}

const initial: IForm = {
  amount: {
    id: "amount",
    isLoading: false,
    value: undefined,
    warning: undefined,
  },
  max: {
    id: "max",
    label: "words.max",
    value: undefined,
  },
  token: {
    id: "token",
    value: undefined,
  },
};

function Provider({ children }: PropsWithChildren<unknown>) {
  return <FormProvider<IForm> initial={initial}>{children}</FormProvider>;
}

function useAccessor() {
  return useFormContext<IForm>().getState;
}

function useForm<Slice>(selector: IStoreSelector<IForm, Slice>): Slice {
  return useFormStore(selector);
}

function useField<I extends keyof IForm>(id: I) {
  return useFormField<IForm, I>(id);
}

export { useAccessor, useField, useForm };

export default Provider;
