import {
  DEFAULT_RESET_SLEEP,
  TOKEN_FAUCET_AMOUNT,
} from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { guards, policy } from "@sablier/v2-machines";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { isHostSafe } from "~/client/contexts/Web3";
import { useModalTransaction } from "~/client/hooks/modals";
import { toast } from "~/client/hooks/useToast";
import type { useMachineForm } from "@sablier/v2-hooks";
import type { Translate } from "@sablier/v2-locales";
import type {
  ISigner,
  IToken,
  IWagmiAddress,
  IWagmiConfig,
} from "@sablier/v2-types";
import helper from "../helper";
import { faucet as wording } from "../helper/wording";

export interface Check {
  api: {
    t: Translate;
  };
  token: IToken | undefined;
}
export interface Create extends Check {
  token: IToken | undefined;
  signer: ISigner | undefined;
  library: IWagmiConfig | undefined;
  api: {
    reset: () => void;
    t: Translate;
    doClose: ReturnType<typeof useModalTransaction>["doClose"];
    setOpen: ReturnType<typeof useModalTransaction>["setOpen"];
    updateData: ReturnType<typeof useModalTransaction>["updateData"];
  };
}

export interface Result {
  message?: string;
}

type Machine = Parameters<typeof useMachineForm<Check, Create, Result>>;

type onCheck = Parameters<Machine["0"]["onCheck"]>["0"];
type onProcess = Parameters<Machine["0"]["onProcess"]>["0"];
type onValidate = Parameters<Machine["0"]["onValidate"]>["0"];

export async function onCheck({ event }: onCheck): Promise<void> {
  const { token } = event.payload;
  const { t } = event.payload.api;

  const required = guards.validateToken({
    t,
    value: token,
  });

  if (!_.isNilOrEmptyString(required)) {
    throw new Error(required);
  }
}

export async function onValidate({ context }: onValidate): Promise<undefined> {
  const { api, library, signer } = context.payload;
  const { t } = api;

  api.setOpen(true, {
    status: "verify",
    title: wording.title(t),
    description: wording.confirm(t).description,
    isNotClosable: true,
  });
  try {
    await onCheck({ event: context });

    if (_.isNil(signer) || _.isNil(library)) {
      throw new Error(policy.signer.missing(t));
    }

    const chainId = signer.chain!.id;

    await guards.validateInputs(
      library,
      t,
      [
        {
          purpose: "signer",
          options: {
            value: signer,
          },
        },
        {
          purpose: "screening",
          options: {
            chainId,
            addresses: [signer.account!.address],
          },
        },
      ],
      chainId,
      { toast },
    );
  } catch (error) {
    vendors.crash.log(error);
    api.updateData({
      status: "fail",
      description: wording.fail(t).description,
      error: {
        message: _.toString(error),
        data: error,
      },
      isNotClosable: false,
    });
    throw error;
  }
  return undefined;
}

/**
 *  Machine state that actually triggers the transaction.
 *  It relies on defined, pre-validated values checked within the `onValidate` step.
 */

export async function onProcess({ context }: onProcess): Promise<void> {
  const { api, token, library, signer } = context.payload;
  const { t } = api;
  try {
    if (_.isNil(signer) || _.isNil(library) || _.isNil(token)) {
      throw new Error(policy.signer.missing(t));
    }

    const chainId = signer.chain!.id;
    const recipient = signer.account?.address;

    const value = _.toValuePrepared({
      humanized: TOKEN_FAUCET_AMOUNT.toString(),
      decimals: token.decimals,
    });

    const preview = wording.prepare(token, new BigNumber(value || "0"));

    const query = framework.contextualize(
      token.address,
      chainId,
      "faucet",
      "mint",
      [recipient as IWagmiAddress, _.toBigInt(value)],
    );

    api.updateData({
      status: "confirm",
      description: wording.send(t, true, preview.amount).description,
      isNotClosable: true,
    });

    const prepared = await helper.configure(library, {
      chainId,
      query,
      signer,
    });

    console.info("%c[pre-transaction]", "color: mediumslateblue", {
      query,
      prepared,
    });

    const transaction = await framework.write(library, { prepared });

    api.updateData({
      status: "pending",
      description: wording.send(t, false, preview.amount).description,
      hash: !isHostSafe ? transaction : undefined,
      isNotClosable: false,
    });

    const receipt = isHostSafe
      ? await framework.safeWait(library, { hash: transaction })
      : await framework.wait(library, {
          hash: transaction,
          onReplaced: (replaced) => {
            api.updateData({
              hash: replaced.transaction.hash,
            });
          },
        });

    console.info("%c[post-transaction]", "color: mediumslateblue", {
      transaction,
      receipt,
    });

    if (receipt.status === "reverted") {
      throw new Error(policy.error.reverted(t));
    }

    api.updateData({
      status: "success",
      description: wording.success(t, preview.amount).description,
      hash: receipt.transactionHash,
      isNotClosable: true,
    });

    await _.sleep(2 * 1000);

    void window.location.reload();

    api.reset();
  } catch (error) {
    vendors.crash.log(error);
    api.updateData({
      status: "fail",
      description: wording.fail(t).description,
      error: {
        message: policy.error.message(t, error),
        data: error,
      },
      isNotClosable: false,
    });

    throw error;
  }
}
