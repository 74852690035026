import { useCallback, useEffect, useState } from "react";
import copy from "copy-to-clipboard";

/** Extended functionality on top of https://github.com/danoc/react-use-clipboard */

export default function useCopy(
  value = "",
  duration = 1000,
): [boolean, (_event?: unknown) => void, (v?: string) => void] {
  const [isCopied, setIsCopied] = useState(false);

  const doCopy = useCallback(
    (v?: string) => {
      const text = (v ?? value) || "";
      const didCopy = copy(text);
      setIsCopied(didCopy);
    },
    [value],
  );

  /** Useful as an onClick value for buttons */
  const doCopyCallback = useCallback(() => {
    doCopy();
  }, [doCopy]);

  useEffect(() => {
    if (isCopied && duration) {
      const id = setTimeout(() => {
        setIsCopied(false);
      }, duration);

      return () => {
        clearTimeout(id);
      };
    }
  }, [isCopied, duration]);

  return [isCopied, doCopyCallback, doCopy];
}
