import { useCallback, useMemo } from "react";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Stepper } from "~/components/molecules";
import type { ISInput } from "../system/templates";
import Template, { sides } from "../system/templates";

export interface Props extends Omit<ISInput, "left" | "right"> {
  isEditable?: boolean;
  measure?: string;
  decimals?: number;
  max?: number;
  min?: number;
}

function AmountStepper({
  max = Number.MAX_VALUE,
  min = 0,
  decimals = 0,
  ...props
}: Props) {
  const { isDisabled: _isDisabled, isLocked, onBlur, onChange, value } = props;
  const { t } = useT();

  const isDisabled = useMemo(() => {
    if (_isDisabled || isLocked) {
      return true;
    }
    return false;
  }, [_isDisabled, isLocked]);

  const onMinus = useCallback(() => {
    let anchor = value;

    if (isDisabled) {
      return;
    }

    if (_.isNilOrEmptyString(anchor) || !_.isFinite(_.toNumber(anchor))) {
      anchor = min;
    }

    const current = _.toNumber(anchor);
    let next = current - 1;
    if (current <= min) {
      next = max;
    }
    if (current > max) {
      next = min;
    }

    if (onChange) {
      onChange(_.toString(next));
    }

    if (onBlur) {
      onBlur();
    }
  }, [isDisabled, max, min, onBlur, onChange, value]);

  const onPlus = useCallback(() => {
    let anchor = value;

    if (isDisabled) {
      return;
    }

    if (_.isNilOrEmptyString(anchor) || !_.isFinite(_.toNumber(anchor))) {
      anchor = max;
    }

    const current = _.toNumber(anchor);
    let next = current + 1;
    if (current >= max) {
      next = min;
    }
    if (current < min) {
      next = min;
    }
    if (onChange) {
      onChange(_.toString(next));
    }

    if (onBlur) {
      onBlur();
    }
  }, [isDisabled, max, min, onBlur, onChange, value]);

  const Left = useCallback(() => {
    return <Stepper onDown={onMinus} onUp={onPlus} />;
  }, [onMinus, onPlus]);

  const formatted = useMemo(() => {
    const left: ISInput["left"] = [
      {
        purpose: "custom",
        options: {
          value: Left,
        },
      },
    ];

    const right: ISInput["right"] = [];

    if (props.measure) {
      right.push(sides.resolution(props.measure));
    }

    if (props.isLoading) {
      right.unshift(sides.loader());
    }

    return {
      placeholder: t("form.placeholder.text"),
      ...props,
      isPreview: props.isPreview || !props.isEditable,
      left,
      max,
      min,
      right,
      decimals,
    };
  }, [Left, max, min, decimals, props, t]);

  return <Template.Numeric {...formatted} />;
}

export default AmountStepper;
