import { useMemo } from "react";
import { useTable } from "./index";
import useTableAccessors from "./useTableAccessors";

export default function useTableElements() {
  const { data } = useTable();
  const accessors = useTableAccessors();

  const isAnimated = useMemo(() => data.isAnimated, [data.isAnimated]);
  const isEmpty = useMemo(() => data.isEmpty, [data.isEmpty]);
  const isLoading = useMemo(() => data.isLoading, [data.isLoading]);

  const columns = useMemo(() => accessors.getColumns(), [accessors]);
  const rows = useMemo(() => accessors.getRows(), [accessors]);

  const options = useMemo(() => data.options || {}, [data.options]);
  const template = useMemo(() => accessors.getTemplate(), [accessors]);

  return {
    columns,
    isAnimated,
    isEmpty,
    isLoading,
    options,
    rows,
    template,
  };
}
