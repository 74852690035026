import { Input } from "@sablier/v2-components/organisms";
import { DEFAULT_DECIMALS } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import {
  useGroupFieldDuration,
  useGroupFieldEnd,
  useGroupFieldUnlock,
} from "./logic";

function GroupDuration({ streamId }: { streamId: string }) {
  const { unlockDuration, onClick } = useGroupFieldDuration(streamId);
  const { t } = useT();

  return (
    <Input.Duration
      id={unlockDuration.field.id}
      label={{ value: t("form.label.cliffDuration") }}
      onClick={onClick}
      placeholder={t("form.placeholder.cliffDuration")}
      value={unlockDuration.field!.value}
      warning={unlockDuration.field!.warning}
    />
  );
}

function GroupEnd({ streamId }: { streamId: string }) {
  const { unlockEnd, onClick, label } = useGroupFieldEnd(streamId);
  const { t } = useT();

  return (
    <Input.Moment
      id={unlockEnd.field.id}
      label={label}
      onClick={onClick}
      placeholder={t("form.placeholder.moment")}
      value={unlockEnd.field!.value}
      warning={unlockEnd.field!.warning}
    />
  );
}

function GroupUnlock({ streamId }: { streamId: string }) {
  const { unlock, maximum, onBlur, onChange, onMax, token } =
    useGroupFieldUnlock(streamId);
  const { t } = useT();

  return (
    <Input.Amount
      id={unlock.field.id}
      decimals={token.field.value?.decimals || DEFAULT_DECIMALS}
      label={{ value: t("structs.unlockLabel") }}
      max={maximum}
      onBlur={onBlur}
      onChange={onChange}
      onMax={onMax}
      placeholder={t("form.placeholder.amount")}
      token={token.field.value}
      value={unlock.field.value}
      warning={unlock.field.warning}
    />
  );
}

const Fields = {
  GroupDuration,
  GroupEnd,
  GroupUnlock,
};

export default Fields;
