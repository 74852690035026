import { useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import type { IToken, ITokenResolution } from "@sablier/v2-types";
import useTokens from "./useTokens";

export default function useToken({
  address,
  chainId,
  token,
}: ITokenResolution = {}): IToken | undefined {
  const { find } = useTokens(false);

  return useMemo(
    () => find({ address, chainId, token }),
    [address, chainId, find, token],
  );
}
