import { tags } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Base } from "../../system";

interface Props {
  className?: string;
  value?: string;
  purpose: string;
}

function Numeric({ className, value, purpose }: Props) {
  const { t } = useT();
  return (
    <Base
      className={className}
      cy={tags.warningAmount}
      description={t("warnings.amount.description", { value })}
      label={{ value: _.capitalize(t("warnings.amount.title", { purpose })) }}
    />
  );
}

export default Numeric;
