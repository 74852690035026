import { useMemo } from "react";
import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import type { ComponentProps, PropsWithChildren, ReactNode } from "react";
import Tooltip from "../Tooltip";

const WrapperPartial = styled.div<{ shouldCenter: boolean }>`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    align-items: ${(props) => (props.shouldCenter ? "center" : "flex-start")};
    width: 100%;
    column-gap: ${(props) => props.theme.sizes.edge};
  }
`;

const Left = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex: 1;
    align-items: center;
    min-height: ${(props) => props.theme.sizes.buttonMini};
    &:empty {
      display: none;
    }
  }
`;

const Right = styled.div`
  ${(props) => props.theme.styles.row}
`;

const Content = styled.div<{ maxWidth?: string }>`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: 6px;
    max-width: ${(props) => props.maxWidth || "100%"};
    &:empty {
      display: none;
    }
  }
`;

const Title = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    color: ${(props) => props.theme.colors.gray200};
    & > p {
      ${(props) => props.theme.styles.textParagraph}
      & {
        font-weight: 600;
      }
    }
  }
`;

const Description = styled.div`
  color: ${(props) => props.theme.colors.gray400};

  & > p {
    ${(props) => props.theme.styles.textParagraphMini}
    & {
      line-height: 1.5;
      word-break: normal;
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${Title} {
    &[data-small="true"] {
      display: none;
    }
  }

  ${(props) => props.theme.medias.maxSM} {
    ${Title}, ${Description} {
      p {
        line-height: 20pt;
      }
    }
    ${Title} {
      &:not([data-small="true"]) {
        display: none;
      }
      &[data-small="true"] {
        display: inherit;
      }
    }
  }
`;
export interface Props {
  className?: string;
  description?: string | ReactNode;
  descriptionTooltip?: string | ComponentProps<typeof Tooltip>["value"];
  isCentered?: boolean;
  left?: ReactNode;
  maxWidth?: number | string;
  title?: string;
  titleSmall?: string;
}

function Action({
  children,
  className,
  description,
  descriptionTooltip,
  left,
  isCentered = true,
  maxWidth,
  title,
  titleSmall,
}: PropsWithChildren<Props>) {
  const small = useMemo(() => titleSmall || title, [title, titleSmall]);

  return (
    <Wrapper
      className={className}
      shouldCenter={isCentered}
      data-component={"action"}
    >
      <Left>
        <Content maxWidth={maxWidth ? _.toSuffix(maxWidth, "px") : undefined}>
          {!_.isNilOrEmptyString(title) && (
            <Title>
              <p>{title}</p>
            </Title>
          )}
          {!_.isNilOrEmptyString(small) && (
            <Title data-small={"true"}>
              <p>{small}</p>
            </Title>
          )}
          {!_.isNilOrEmptyString(description) && (
            <Tooltip color={"dark300"} arrow value={descriptionTooltip}>
              <Description>
                <p>{description}</p>
              </Description>
            </Tooltip>
          )}
          {left ?? false}
        </Content>
      </Left>
      <Right>{children}</Right>
    </Wrapper>
  );
}

export default Action;
