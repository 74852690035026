import { useEffect } from "react";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import useAccount from "./useAccount";

export default function useMixpanel() {
  const { address, ens, chainId } = useAccount();

  /** Register the chain id the user is on. */
  useEffect(() => {
    if (!_.isNil(chainId)) {
      vendors.track.register({ "Chain Id": chainId });
      return () => {
        vendors.track.unregister("Chain Id");
      };
    }
    return undefined;
  }, [address, chainId, ens]);
}
