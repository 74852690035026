import styled from "styled-components";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Label } from "@sablier/v2-components/molecules";
import { _ } from "@sablier/v2-mixins";
import type { ThemeType } from "@sablier/v2-themes";
import type { ComponentProps } from "react";

interface IWrapper {
  content?: keyof ThemeType["colors"];
  label?: keyof ThemeType["colors"];
}

interface Props {
  className?: string;
  cy?: string;
  colors?: IWrapper;
  description: string;
  label: ComponentProps<typeof Label>;
}

export const Wrapper = styled.div<IWrapper>`
  ${(props) => props.theme.styles.column}

  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    width: 100%;
    padding: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    color: ${(props) => props.theme.colors[props.content || "yellow"]};
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.dark200};

    & > *[data-component="label"] {
      color: ${(props) => props.theme.colors[props.label || "yellow"]};
    }

    ${(props) => props.theme.medias.maxXS} {
      padding: calc(${(props) => props.theme.sizes.edge} * 1);
    }
  }
`;

export const Description = styled.div`
  & > p {
    ${(props) => props.theme.styles.textParagraph}
  }
`;

export function Base({
  children,
  className,
  colors,
  cy,
  description,
  label,
}: React.PropsWithChildren<Props>) {
  return (
    <Wrapper
      content={colors?.content}
      label={colors?.label}
      className={className}
      data-cy={cy}
    >
      <Label icon={ExclamationTriangleIcon} {...label} />
      <Description>
        <p>{description}</p>
      </Description>
      {children}
    </Wrapper>
  );
}
