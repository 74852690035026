import { _ } from "@sablier/v2-mixins";
import type { Translate } from "@sablier/v2-locales";
import policy from "./policy";

interface TextValidProps {
  t: Translate;
  blacklist?: string[];
  value: string | undefined;
  exact?: number;
  max?: number;
  min?: number;
}

export function validateText({
  blacklist,
  exact,
  max,
  min,
  t,
  value,
}: TextValidProps): string | undefined {
  try {
    if (_.isNilOrEmptyString(value)) {
      return policy.text.missing(t);
    }

    if (!_.isNil(max) && value.length > max) {
      return policy.text.tooHigh(t, max);
    }

    if (!_.isNil(min) && value.length <= min) {
      return policy.text.tooLow(t, min);
    }

    if (!_.isNil(exact) && value.length !== exact) {
      return policy.text.exact(t, exact);
    }

    if (!_.isNil(blacklist) && blacklist.length) {
      if (blacklist.map((b) => b.toLowerCase()).includes(value.toLowerCase())) {
        return policy.ens.forbidden(t);
      }
    }
  } catch (error) {
    return policy.ens.invalid(t);
  }

  return undefined;
}

interface URLValidProps {
  t: Translate;
  value: string;
}

export function validateURL({ t, value }: URLValidProps): string | undefined {
  try {
    new URL(value);
    return undefined;
  } catch (error) {
    return policy.url.invalid(t);
  }
}
