import {
  EXPONENT_DECIMALS,
  STEPPER_MAX_STEPS_GROUP,
  STEPPER_MIN_STEPS,
  StreamCategory,
} from "@sablier/v2-constants";
import { guards } from "@sablier/v2-machines";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Segment, Stream, Token, Tranche } from "@sablier/v2-models";
import type { ITranchedStepper } from "./config";
import type {
  IExtensionCertify,
  IExtensionCheck,
  IExtensionParamsSimulate,
  IPrecomputeParams,
  IPrecomputeResult,
} from "~/client/types";
import { UNLOCK_DURATION } from "./config";

type IExtension = ITranchedStepper;

export function certify({
  t,
  data,
  field,
}: IExtensionCertify): string | undefined {
  const fields = _.omit(data as IExtension, ["purpose"]);

  if (field === "steps") {
    return guards.validateAmount({
      t,
      context: "steps",
      max: (STEPPER_MAX_STEPS_GROUP + 1).toString() /** Strict < */,
      value: fields.steps.value,
      min: (STEPPER_MIN_STEPS - 1).toString() /** Strict > */,
    });
  }

  return undefined;
}

export function check({
  t,
  data,
  isLoadingIncluded = false,
  isWarningIncluded = false,
}: IExtensionCheck): string | undefined {
  const fields = _.omit(data as IExtension, ["purpose"]);

  const flags = guards.validateFormFlags({
    t,
    isWarningIncluded,
    isLoadingIncluded,
    value: fields,
  });

  if (!_.isNilOrEmptyString(flags)) {
    return flags;
  }

  const ids: (keyof typeof fields)[] = ["steps"];

  const required = guards.validateFormRequired({
    t,
    required: ids,
    value: { ...fields },
  });

  if (!_.isNilOrEmptyString(required)) {
    return required;
  }

  return undefined;
}

export function identify(stream: Stream): boolean {
  /**
   * Stepper streams will have their tranches interpreted as a set of
   * horizontal (cliff) + vertical (unlock) segments, so identification can be
   * performed on top of those items
   */
  if (
    stream.category === StreamCategory.LOCKUP_TRANCHED &&
    stream.segments.length &&
    stream.segments.length % 2 === 0
  ) {
    let areSegmentsEqual = true;
    let areAmountsEqual = true;
    const initialSegmentDuration = new BigNumber(stream.segments[0].duration);
    const initialSegmentAmount = new BigNumber(stream.segments[1].amount.raw);
    stream.segments.forEach((segment, index) => {
      if (
        index % 2 == 0 &&
        !new BigNumber(segment.duration).eq(initialSegmentDuration)
      ) {
        areSegmentsEqual = false;
      }
      if (
        index % 2 == 1 &&
        areAmountsEqual &&
        !new BigNumber(segment.amount.raw).eq(initialSegmentAmount)
      ) {
        areAmountsEqual = false;
      }
      if (index % 2 == 1) {
        if (
          !new BigNumber(segment.duration).isLessThanOrEqualTo(
            new BigNumber(1000),
          )
        ) {
          return false;
        }
      } else if (!segment.amount.raw.isZero()) {
        return false;
      }
    });
    return areSegmentsEqual && areAmountsEqual;
  }
  return false;
}

export function precomputeSingle({
  dependencies,
  extras,
}: IPrecomputeParams<"single">): IPrecomputeResult {
  const { amount, token } = dependencies;
  const { purpose: _purpose, ...extended } = extras as IExtension;
  const steps = new BigNumber(extended.steps.value || 0);
  const raw = _.toValuePrepared({
    humanized: amount,
    decimals: token!.decimals,
  });
  const increment = _.toValuePrepared({
    raw: new BigNumber(raw).dividedBy(steps),
    decimals: token?.decimals,
  });
  return {
    amount: _.toValuePrepared({
      raw: new BigNumber(increment).times(steps),
      decimals: token!.decimals,
    }),
  };
}

export function precomputeGroup({
  dependencies,
}: IPrecomputeParams<"group">): IPrecomputeResult {
  const { streams, token, signer } = dependencies;

  return {
    amount:
      streams
        ?.reduce((p, c) => {
          const { amount } = precomputeSingle({
            dependencies: {
              amount: c.amount,
              token,
              signer,
            },
            extras: c.extension,
          });
          return p.plus(new BigNumber(amount));
        }, new BigNumber(0))
        .toString() ?? "0",
  };
}

export const precompute = { group: precomputeGroup, single: precomputeSingle };

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */
export function simulate(
  params: IExtensionParamsSimulate & { timing: "duration" },
): Stream;
export function simulate(
  params: IExtensionParamsSimulate & { timing: "range" },
): Stream;
export function simulate(params: IExtensionParamsSimulate): Stream;

export function simulate({
  dependencies,
  timing,
  extras,
}: IExtensionParamsSimulate): Stream {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const { purpose: _purpose, ...extended } = extras as IExtension;
  const {
    amount,
    cancelability,
    transferability,
    chainId,
    duration,
    end,
    start,
    token,
  } = dependencies;

  const deposit = _.toValuePrepared({
    humanized: amount,
    decimals: token?.decimals,
  });

  const streamToken = new Token({
    address: token!.address,
    chainId,
    decimals: token!.decimals,
    name: token!.name,
    symbol: token!.symbol,
  });

  const startTime =
    timing === "duration"
      ? _.toSeconds(new BigNumber(Date.now()).toString())
      : _.toSeconds(start);

  const endTime =
    timing === "duration"
      ? _.toSeconds(
          new BigNumber(Date.now()).plus(new BigNumber(duration!)).toString(),
        )
      : _.toSeconds(end);

  const unlock = new BigNumber(UNLOCK_DURATION).dividedBy(1000);
  const steps = new BigNumber(extended.steps.value || 0);
  const totalD = new BigNumber(endTime).minus(new BigNumber(startTime));
  const segmentD = totalD.dividedBy(steps);
  const N = [...Array(steps.toNumber()).keys()];
  const N2 = [...Array(steps.toNumber() * 2).keys()];
  const increment = new BigNumber(deposit).dividedBy(steps);

  const segmentExponents = N.map(() => [
    _.toValuePrepared({ humanized: "1", decimals: EXPONENT_DECIMALS }),
    _.toValuePrepared({ humanized: "1", decimals: EXPONENT_DECIMALS }),
  ]).flat();
  const segmentAmounts = N.map(() => [
    new BigNumber(0),
    new BigNumber(increment),
  ]).flat();
  const segmentStartTime = N.map((i) => [
    new BigNumber(startTime).plus(segmentD.times(new BigNumber(i))),
    new BigNumber(startTime)
      .plus(segmentD.times(new BigNumber(i + 1)))
      .minus(unlock),
  ]).flat();
  const segmentEndTime = N.map((i) => [
    new BigNumber(startTime)
      .plus(segmentD.times(new BigNumber(i + 1)))
      .minus(unlock),
    new BigNumber(startTime).plus(segmentD.times(new BigNumber(i + 1))),
  ]).flat();

  const segmentStartAmount = N.map((i) => [
    increment.times(new BigNumber(i)),
    increment.times(new BigNumber(i)),
  ]).flat();

  const segmentEndAmount = N.map((i) => [
    increment.times(new BigNumber(i)),
    increment.times(new BigNumber(i + 1)),
  ]).flat();

  const segments = N2.map((i) => ({
    id: String(i),
    position: String(i),
    milestone: "",
    startTime: segmentStartTime[i].toString(),
    startAmount: segmentStartAmount[i].toString(),
    endTime: segmentEndTime[i].toString(),
    endAmount: segmentEndAmount[i].toString(),
    exponent: segmentExponents[i],
    amount: segmentAmounts[i].toString(),
  }));

  return new Stream(
    {
      ...Stream.base(),
      chainId,
      cancelable: cancelability!,
      transferable: transferability!,
      category: StreamCategory.LOCKUP_TRANCHED,
      depositAmount: deposit,
      endTime,
      intactAmount: deposit,
      startTime,
      tranches: N.map(
        (i) =>
          Tranche.fromSegments(
            new Segment(segments[i * 2], streamToken),
            new Segment(segments[i * 2 + 1], streamToken),
          ).payload,
      ),
    },
    streamToken,
  );
}
