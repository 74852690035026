import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import Button from "~/components/molecules/Button";
import type { ISInput } from "../system/templates";
import type { ComponentProps } from "react";
import * as particles from "../system/particles";
import Template from "../system/templates";

const ContainerPartial = styled(particles.Container)`
  width: 100%;
  height: auto;
`;

const Box = styled(particles.Box)`
  position: relative;
  width: 100%;
`;

const Field = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    width: 100%;
    height: ${(props) => props.theme.sizes.inputBox};
    padding-inline: calc(${(props) => props.theme.sizes.formEdge} * 1 / 2);
    & > * {
      height: ${(props) => props.theme.sizes.inputField};
    }
  }
`;

const Container = styled(ContainerPartial)``;

export interface Props
  extends Omit<ISInput, "left" | "onChange" | "onClick" | "right" | "value"> {
  className?: string;
  value: ComponentProps<typeof Button>;
}

function Action({ value, ...props }: Props) {
  return (
    <Template.Shell
      className={props.className}
      info={props.info}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      isLocked={props.isLocked}
      isPreview={props.isPreview}
      label={props.label}
      warning={props.warning}
    >
      <Container>
        <Box data-component={"box"}>
          <Field>
            <Button {...value} />
          </Field>
        </Box>
      </Container>
    </Template.Shell>
  );
}

export default Action;
