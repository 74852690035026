import Action from "./Action";
import Address from "./Address";
import Amount from "./Amount";
import AmountStepper from "./AmountStepper";
import ChainPicker from "./ChainPicker";
import CountryPicker from "./CountryPicker";
import Duration from "./Duration";
import FilePicker from "./FilePicker";
import Locked from "./Locked";
import LockedChange from "./LockedChange";
import LockedShape from "./LockedShape";
import Moment from "./Moment";
import MomentSwitch from "./MomentSwitch";
import Radio from "./Radio";
import Switch from "./Switch";
import Text from "./Text";
import TextList from "./TextList";
import TextSearch from "./TextSearch";
import TextStepper from "./TextStepper";
import TokenPicker from "./TokenPicker";
import Template from "./system/templates";

export type { ISInput } from "./system/templates";

const Input = {
  Template,
  /** ---- */
  Action,
  Address,
  Amount,
  AmountStepper,
  ChainPicker,
  CountryPicker,
  Duration,
  FilePicker,
  Moment,
  MomentSwitch,
  Locked,
  LockedChange,
  LockedShape,
  Radio,
  Switch,
  Text,
  TextList,
  TextSearch,
  TextStepper,
  TokenPicker,
};

export default Input;
