import { useCallback, useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import type {
  ITableAccessors,
  ITableCell,
  ITableColumn,
  ITableData,
  ITableRow,
} from "@sablier/v2-types";
import { useTable } from "./index";

/**
 * useAccessors
 * --------------------------------------------
 * Use accessors to pass down the ability to read data only on demand.
 * This way the cells should receive pointers to getters (accessors) not heavy props passed down from the Body/Row.
 */

export default function useAccessors(): ITableAccessors {
  const { data } = useTable();
  const { columns, rows, instructions } = useMemo(() => data, [data]);

  const getInstruction = useCallback(
    <T extends keyof ITableData["instructions"]>({ name }: { name: T }) => {
      if (
        !_.isNilOrEmptyString(name) &&
        Object.keys(instructions).includes(name) &&
        _.isFunction(instructions[name])
      ) {
        return instructions[name];
      }
      return undefined;
    },
    [instructions],
  );

  const getRow = useCallback(
    ({ id, x = 0 }: { id?: string; x?: number }): ITableRow => {
      if (!_.isNilOrEmptyString(id)) {
        const search = rows.find((row) => row.id === id);
        if (search) {
          return search;
        }
      }
      return rows[x];
    },
    [rows],
  );

  const getRows = useCallback(() => rows, [rows]);

  const getColumn = useCallback(
    ({ y }: { y: number }): ITableColumn => {
      const column = columns[y];
      return column;
    },
    [columns],
  );

  const getColumns = useCallback(() => columns, [columns]);

  const getCell = useCallback(
    ({
      row: _row,
      x,
      y,
    }: {
      row?: ITableRow;
      x: number;
      y: number;
    }): ITableCell => {
      const row = _row ?? getRow({ x });
      const cell = row.cells[y];
      return cell;
    },
    [getRow],
  );

  const getTemplate = useCallback(() => {
    return columns
      .map((column) => _.get(column, "weight") || "minmax(0, 1fr)")
      .reduce((prev, acc) => prev + acc + " ", "")
      .trim();
  }, [columns]);

  return {
    getCell,
    getColumn,
    getColumns,
    getInstruction,
    getRow,
    getRows,
    getTemplate,
  };
}
