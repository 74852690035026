import styled from "styled-components";
import { ReactComponent as AssetSafe } from "@sablier/v2-assets/brands/safe.svg";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { ThemeType } from "@sablier/v2-themes";
import Tooltip from "../Tooltip";

interface WrapperProps {
  color?: keyof ThemeType["colors"];
  isSelfPadded?: boolean;
}

const WrapperPartial = styled.div<WrapperProps>`
  ${(props) => props.theme.styles.row}
  & {
    gap: 2px;
    padding-top: ${(props) => (props.isSelfPadded ? "5px" : "0")};
  }
`;

const Prefix = styled.div`
  & > p {
    ${(props) => props.theme.styles.textParagraphMini}
    & {
      color: ${(props) => props.theme.colors.gray400};
    }
  }
`;

const Safe = styled.div`
  position: relative;
  width: 56px;
  height: 20px;

  svg,
  path {
    fill: ${(props) => props.theme.colors.gray400};
  }
`;

const Wrapper = styled(WrapperPartial)`
  &[data-purpose="safe"] {
    p {
      color: ${(props) => props.theme.colors[props.color || "gray400"]};
    }

    ${Safe} {
      svg,
      path {
        fill: ${(props) => props.theme.colors[props.color || "gray400"]};
      }
    }
  }
  &[data-purpose="tooltip"] {
    cursor: help;
  }
`;

interface Props {
  color?: keyof ThemeType["colors"];
  isTooltipShown?: boolean;
  isSelfPadded?: boolean;
  purpose: "safe" | "custom";
}

function Optimized({
  color,
  purpose,
  isTooltipShown = true,
  isSelfPadded = true,
}: Props) {
  const { t } = useT();

  return (
    <Tooltip
      value={
        isTooltipShown
          ? purpose === "safe"
            ? t("descriptions.vendors.safe.optimized")
            : undefined
          : undefined
      }
    >
      <Wrapper
        color={color}
        isSelfPadded={isSelfPadded}
        data-purpose={purpose}
        data-tooltip={isTooltipShown}
      >
        <Prefix>
          <p>{_.capitalize(t("structs.optimizedFor"))}</p>
        </Prefix>
        {purpose === "safe" ? (
          <Safe>
            <AssetSafe />
          </Safe>
        ) : (
          false
        )}
      </Wrapper>
    </Tooltip>
  );
}

export default Optimized;
