import styled from "styled-components";
import { Internal } from "~/components/atoms";
import type { ComponentProps } from "react";
import Icon from "../Icon";

const WrapperPartial = styled(Internal)`
  ${(props) => props.theme.styles.row}
  & {
    --background: ${(props) => props.theme.colors.dark150};
    --border: ${(props) => props.theme.colors.border};
    --color: ${(props) => props.theme.colors.gray400};
  }
`;

const Box = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    height: ${(props) => props.theme.sizes.tab}px;
    padding: 0 calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    color: var(--color);
    column-gap: calc(${(props) => props.theme.sizes.edge} * 2 / 3);
    border: 2px solid var(--border);
    border-right: none;
    border-top-left-radius: 6px;
    background-color: var(--background);
  }
`;

const Corner = styled.svg.attrs((props) => {
  return {
    ...props.theme.attributes.base,
    viewBox: `0 0 28 ${props.theme.sizes.tab}`,
  };
})`
  width: 28px;
  height: ${(props) => props.theme.sizes.tab}px;
`;

const Shape = styled.path.attrs((props) => {
  return {
    ...props.theme.attributes.base,
    d: `
    M -1 1
    L 5 1
    L 27 ${props.theme.sizes.tab - 1}
    L -1 ${props.theme.sizes.tab - 1}
    `,
  };
})`
  fill: var(--background);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  stroke: var(--border);
`;

const Left = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex-shrink: 0;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.transparent};
  }
`;

const Title = styled.div`
  ${(props) => props.theme.styles.row}
  & > p {
    ${(props) => props.theme.styles.textButton}
    & {
      &[data-purpose="short"] {
        display: none;
      }
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  &[data-active="true"] {
    --background: ${(props) => props.theme.colors.border};
    --color: ${(props) => props.theme.colors.primaryMiddle};

    ${Left} {
      background-color: ${(props) => props.theme.colors.dark500};
    }
  }
  &:not([data-active="true"]) {
    &:hover,
    &:active {
      --background: ${(props) => props.theme.colors.dark200};
      --color: ${(props) => props.theme.colors.gray100};
      cursor: pointer;

      ${Left}, ${Box}, ${Shape} {
        will-change: background-color, fill;
        transition: background-color 100ms, fill 100ms;
      }
    }
  }

  ${(props) => props.theme.medias.maxLG} {
    ${Box} {
      padding: 0 calc(${(props) => props.theme.sizes.edge} * 1);
      column-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 3);
    }
  }

  ${(props) => props.theme.medias.maxMD} {
    &:last-child {
      ${Box} {
        border-top-right-radius: 6px;
      }
    }
    &:first-child {
      ${Box} {
        border-top-left-radius: 6px;
      }
    }
    ${Box} {
      height: ${(props) => props.theme.sizes.tab - 10}px;
      padding-right: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
      padding-left: calc(${(props) => props.theme.sizes.edge} * 1);
      border-right: 2px solid var(--border);
      border-radius: 0;
    }
    ${Corner} {
      display: none;
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    ${Box} {
      justify-content: center;
      width: 100%;
      padding-right: calc(${(props) => props.theme.sizes.edge} * 1);
      column-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    }
    ${Title} {
      & > p {
        display: block;
        &:not([data-purpose="short"]) {
          display: none;
        }
      }
    }

    &:not([data-active="true"]) {
      ${Title} {
        display: none;
      }
    }
  }

  ${(props) => props.theme.medias.maxXS} {
    ${Title} {
      display: none;
    }
    ${Left} {
      --size: 20px;
      --thickness: 2.2px;
    }
  }
`;

interface Props {
  className?: string;
  icon: ComponentProps<typeof Icon>["value"];
  isActive?: boolean;
  title: string;
  titleShort?: string;
  cy?: string;
  to?: ComponentProps<typeof Internal>["value"];
  onClick?: () => void;
}

function Tab({
  className,
  icon,
  isActive = false,
  title,
  titleShort,
  onClick,
  cy,
  to,
}: Props) {
  return (
    <Wrapper
      className={className}
      data-cy={cy}
      data-active={isActive}
      onClick={onClick}
      to={to}
    >
      <Box>
        <Left>
          <Icon value={icon} />
        </Left>
        <Title>
          <p>{title}</p>
          <p data-purpose={"short"}>{titleShort ?? title}</p>
        </Title>
      </Box>
      <Corner>
        <Shape />
      </Corner>
    </Wrapper>
  );
}

export default Tab;
