import {
  QUERY_CACHE_TIME,
  REQUEST_ID,
  chains,
  requests,
} from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { QueryClient } from "@tanstack/react-query";
import { erc20Abi, erc20Abi_bytes32, getAddress, zeroAddress } from "viem";
import { readContracts } from "wagmi/actions";
import type { Translate } from "@sablier/v2-locales";
import type { IAddress, IWagmiAddress, IWagmiConfig } from "@sablier/v2-types";
import policy from "./policy";

interface IsBannedProps {
  t: Translate;
  address: IAddress | undefined;
  chainId: number | undefined;
  library: IWagmiConfig;
}

interface ScreeningProps {
  t: Translate;
  addresses: IAddress[];
  chainId: number | undefined;
}

interface AddressValidProps {
  t: Translate;
  blacklist?: IAddress[];
  isChecksummed?: boolean;
  isPrefixAllowed?: boolean;
  value: IAddress | undefined;
  whitelist?: IAddress[];
}

export function validateAddress({
  t,
  blacklist,
  whitelist,
  isChecksummed = false,
  isPrefixAllowed = false,
  value,
}: AddressValidProps): string | undefined {
  try {
    if (_.isNilOrEmptyString(value)) {
      return policy.address.missing(t);
    }

    if (!_.isEthereumAddress(value, isPrefixAllowed)) {
      return policy.address.invalid(t);
    }

    if (isChecksummed) {
      const checksummed = _.isPrefixedAddress(value)
        ? "0x".concat(value.split(":")[1])
        : value;

      if (checksummed !== getAddress(checksummed)) {
        throw policy.address.checksummed(t);
      }
    }

    if (!_.isNil(blacklist) && blacklist.length) {
      if (blacklist.map((b) => _.toAddress(b)).includes(_.toAddress(value))) {
        return policy.address.forbidden(t);
      }
    }

    if (!_.isNil(whitelist) && whitelist.length) {
      if (!whitelist.map((b) => _.toAddress(b)).includes(_.toAddress(value))) {
        return policy.address.forbidden(t);
      }
    }
  } catch (error) {
    return policy.address.missing(t, isChecksummed);
  }

  return undefined;
}

interface NameValidProps {
  t: Translate;
  address?: IAddress;
  blacklist?: IAddress[];
  chainId?: number;
  isResolved?: boolean;
  value: string | undefined;
}

export function validateName({
  t,
  address,
  blacklist,
  chainId,
  isResolved = false,
  value,
}: NameValidProps): string | undefined {
  try {
    if (_.isNilOrEmptyString(value)) {
      return policy.ens.missing(t);
    }

    if (!_.isEthereumName(value)) {
      return policy.ens.invalid(t);
    }

    if (chainId) {
      if (chainId !== chains.mainnet.chainId) {
        return policy.ens.supported(t);
      }
    }

    if (isResolved) {
      if (_.isNilOrEmptyString(address)) {
        return policy.ens.address(t, chains.mainnet.name);
      }

      if (!_.isNilOrEmptyString(validateAddress({ t, value: address }))) {
        return policy.ens.address(t, chains.mainnet.name);
      }
    }

    if (!_.isNil(blacklist) && blacklist.length) {
      if (blacklist.map((b) => b.toLowerCase()).includes(value.toLowerCase())) {
        return policy.ens.forbidden(t);
      }
    }
  } catch (error) {
    return policy.ens.invalid(t);
  }

  return undefined;
}

export function validateAccount({
  t,
  address,
  blacklist,
  chainId,
  value,
  isChecksummed,
  isPrefixAllowed,
  isResolved,
  whitelist,
}: AddressValidProps & NameValidProps) {
  if (zeroAddress === value) {
    return policy.address.zero(t);
  }
  if (_.isEthereumName(value)) {
    return validateName({ t, address, blacklist, chainId, isResolved, value });
  } else {
    if (!_.isEthereumAddress(value)) {
      return policy.address.invalid(t, " or ENS");
    }

    return validateAddress({
      t,
      blacklist,
      isChecksummed,
      isPrefixAllowed,
      value,
      whitelist,
    });
  }
}

// This guard is meant to use the TRM public API and our blacklist in order to see if an address is sanctioned by the OFAC.
export async function validateNotSanctioned({ t, address }: IsBannedProps) {
  const queryClient = new QueryClient();
  if (_.isNilOrEmptyString(address)) {
    return undefined;
  }
  try {
    const queryResponse = await queryClient.fetchQuery({
      queryKey: [...REQUEST_ID.trm, { unique: address }],
      queryFn: async () => requests.sanctionsScreeningTRM(address),
      staleTime: QUERY_CACHE_TIME,
      gcTime: QUERY_CACHE_TIME,
    });
    const addressMatch = queryResponse?.find(
      (r) => r.address === address ?? "",
    );
    if (addressMatch) {
      return addressMatch.isSanctioned
        ? policy.address.sanctioned(t)
        : undefined;
    }
  } catch (e) {
    return undefined;
  }
}

// This guard is meant to use the TRM private API in order to analyze the risk level of a specific address.
export async function validateNotAtRisk({
  t,
  addresses,
  chainId,
}: ScreeningProps) {
  const queryClient = new QueryClient();
  if (_.isEmpty(addresses)) {
    return undefined;
  }
  try {
    let chain = _.isNilOrEmptyString(chainId)
      ? undefined
      : chains[chainId!].trmIdentifier;
    // if the chain is not supported by the TRM API we check the addresses on mainnet
    if (_.isNilOrEmptyString(chain)) {
      chain = "ethereum";
    }
    const queryResponse = await queryClient.fetchQuery({
      queryKey: [...REQUEST_ID.trm, { unique: addresses }],
      queryFn: async () => requests.walletScreeningTRM(chain!, addresses),
      staleTime: QUERY_CACHE_TIME,
      gcTime: QUERY_CACHE_TIME,
      retry: false,
    });
    // an address should be consider with risk if it is part of an risk entity or it has no entity and contains at least a risk indicator
    const screenedAddresses = addresses.map((address) => {
      return {
        address,
        hasRisk: queryResponse?.some(
          (item) =>
            _.toLower(item.address) === _.toLower(address) &&
            (item.entities.some((entity) => entity.riskScoreLevel > 5) ||
              (_.isEmpty(item.entities) &&
                !_.isEmpty(item.addressRiskIndicators))),
        ),
      };
    });

    const addressMatch = screenedAddresses.find((r) => r.hasRisk);

    if (addressMatch) {
      return policy.address.risk(t, addressMatch.address);
    }
  } catch (e) {
    return undefined;
  }
}

/**
 * Check that
 */
export async function validateNotToken({
  t,
  address,
  chainId,
  library,
}: IsBannedProps) {
  try {
    if (_.isNilOrEmptyString(address)) {
      return undefined;
    }

    const reads = await readContracts(library, {
      allowFailure: true,
      contracts: [
        {
          address: address as IWagmiAddress,
          abi: erc20Abi,
          functionName: "decimals",
          chainId,
        },
        {
          address: address as IWagmiAddress,
          abi: erc20Abi,
          functionName: "name",
          chainId,
        },
        {
          address: address as IWagmiAddress,
          abi: erc20Abi,
          functionName: "symbol",
          chainId,
        },
        {
          address: address as IWagmiAddress,
          abi: erc20Abi_bytes32,
          functionName: "name",
          chainId,
        },
        {
          address: address as IWagmiAddress,
          abi: erc20Abi_bytes32,
          functionName: "symbol",
          chainId,
        },
      ],
    });

    const valid =
      reads &&
      !_.isNilOrEmptyString(reads[0].result) &&
      !_.isNilOrEmptyString(reads[1].result || reads[3].result) &&
      !_.isNilOrEmptyString(reads[2].result || reads[4].result);

    if (!valid) {
      throw new Error("Address validated as not a token.");
    }

    return policy.address.isToken(t);
  } catch (error) {
    return undefined;
  }
}
