import { useId } from "react";
import { useMachineInstantiator } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import { onCreate } from "~/client/machines/specialized/airstreamRecipients";
import type { Payload } from "~/client/machines/specialized/airstreamRecipients";

export default function useMachine() {
  const id = useId();

  const machine = useMachineInstantiator<Payload>({
    id,
    onCreate,
  });

  return {
    machine: machine[0],
    send: machine[1],
  };
}
