import { useT } from "@sablier/v2-locales";
import { useAirstreamWarning } from "~/client/hooks";
import type { Props } from "~/client/hooks/useAirstreamWarning";
import Account from "../../individual/Account";
import Depleted from "../../individual/AirstreamDepleted";
import Funded from "../../individual/AirstreamFunded";
import Chain from "../../individual/Chain";
import Connect from "../../individual/Connect";
import Early from "../../individual/Early";
import Expired from "../../individual/Expired";
import General from "../../individual/General";
import Geoblocked from "../../individual/Geoblocked";

function Bundle(props: Props) {
  const { t } = useT();
  const data = useAirstreamWarning(props);

  if (data.state === "early") {
    return <Early start={data.start} permission={data.permission} />;
  }

  if (data.state === "ended") {
    return <Expired end={data.end} permission={data.permission} />;
  }

  if (data.state === "connect") {
    return <Connect />;
  }

  if (data.state === "chain") {
    return <Chain entity={t("words.airstream")} toChainId={data.chainId} />;
  }

  if (data.state === "depleted") {
    return <Depleted permission={data.permission} />;
  }

  if (data.state === "funded") {
    return <Funded />;
  }

  if (data.state === "whitelist") {
    return <Account whitelist={data.whitelist} />;
  }

  if (data.state === "geoblocked") {
    return <Geoblocked />;
  }

  if (data.state === "undetailed") {
    return (
      <General
        label={{ value: t("structs.slowToIndex") }}
        description={t("errors.missingAirstreamDetails")}
      />
    );
  }

  return false;
}

export default Bundle;
