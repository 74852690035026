import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { _ } from "@sablier/v2-mixins";
import type { ITableData } from "@sablier/v2-types";

interface ITableContextInterface {
  data: ITableData;
  set: (payload?: ITableData) => void;
}

const initial: ITableData = {
  columns: [],
  rows: [],
  instructions: {},
  isLoading: true,
  isEmpty: false,
  isAnimated: false,
};

export const TableContext = createContext<ITableContextInterface>({
  set: _.noop,
  data: initial,
});

export default function Provider({ children }: PropsWithChildren<unknown>) {
  const value = useTableData();
  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
}

export function useTable() {
  return useContext(TableContext);
}

function useTableData() {
  const [data, setData] = useState<ITableData>(initial);

  const set = useCallback(
    (value?: ITableData) => setData({ ...initial, ...(value || {}) }),
    [],
  );

  return {
    data,
    set,
  };
}
