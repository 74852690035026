import { type Translate } from "@sablier/v2-locales";
import { BigNumber, _ } from "@sablier/v2-mixins";
import type { ITranchedBackweighted } from "./config";
import type { IExtensionDependencies, ISummaryExtension } from "~/client/types";

function summary(
  dependencies: IExtensionDependencies,
  extension: unknown,
  t: Translate,
): ISummaryExtension {
  const fields = extension as ITranchedBackweighted;
  const { end, start } = dependencies;

  const hasEmptyUnlocks = fields.unlocks.value.some((unlock) =>
    _.isNilOrEmptyString(unlock.percentage),
  );
  const isComplete =
    !_.isNilOrEmptyString(fields.years.value) &&
    !_.isNilOrEmptyString(end) &&
    !_.isNilOrEmptyString(start) &&
    !hasEmptyUnlocks;
  const isValid = _.isNilOrEmptyString(fields.years.warning) && isComplete;

  const years = new BigNumber(fields.years.value || 0);
  const unlocks = fields.unlocks.value;

  const milestones = unlocks.map((unlock, index) =>
    t("summaries.backweighted.yearUnlock", {
      percentage: unlock.percentage,
      date: _.toDuration(
        _.addCalendarUnit(start!, (index + 1).toString(), "year"),
        "date-only",
      )[0],
    }),
  );

  const isTimelock =
    unlocks.length === 1 && _.toNumber(unlocks[0].percentage) === 100;
  const isStepper =
    unlocks.length >= 2 &&
    !_.isNilOrEmptyString(unlocks[0].percentage) &&
    !unlocks.some(
      (u) => _.toNumber(u.percentage) !== _.toNumber(unlocks[0].percentage),
    );

  const looksLikeValue = isStepper
    ? _.capitalize(t("structs.stepUnlocks"))
    : isTimelock
    ? _.capitalize(t("words.timelock"))
    : "";
  return {
    isComplete,
    isValid,
    list:
      isStepper || isTimelock
        ? [
            {
              label: `${_.capitalize(t("structs.numberOfYears"))}:`,
              purpose: "text",
              value: years.toString(),
            },
            {
              label: `${_.capitalize(t("structs.looksLike"))}:`,
              purpose: "text",
              value: looksLikeValue,
            },
            {
              label: _.capitalize(t("words.year")),
              purpose: "scroller",
              value: milestones,
            },
          ]
        : [
            {
              label: `${_.capitalize(t("structs.numberOfYears"))}:`,
              purpose: "text",
              value: years.toString(),
            },
            {
              label: _.capitalize(t("words.year")),
              purpose: "scroller",
              value: milestones,
            },
          ],
  };
}

export default summary;
