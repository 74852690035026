import styled from "styled-components";
import {
  CalendarIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { Title } from "@sablier/v2-components/atoms";
import { Icon, Tooltip } from "@sablier/v2-components/molecules";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.row}

  & {
    gap: 6px;
    align-items: center;
    padding: 6px 8px;
    border-radius: 16px;
    background-color: ${(props) => props.theme.colors.primary10};
    p {
      padding-top: 1px;
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

const Box = styled.div`
  --size: 18px;
  --thickness: 2px;
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.colors.primary};
`;

const Wrapper = styled(WrapperPartial)`
  &[data-accent="gray"] {
    background-color: ${(props) => props.theme.colors.dark300};

    p,
    ${Box} {
      color: ${(props) => props.theme.colors.gray400};
    }
  }

  ${(props) => props.theme.medias.maxMD} {
    ${Box} {
      display: none;
    }
  }
`;

function Timezone({ accent }: { accent?: "gray" }) {
  const { t } = useT();
  return (
    <Tooltip
      value={t("descriptions.dates.settings", { zone: _.timezone().zone })}
      trigger={["click", "hover"]}
      triggerMobile={["click"]}
    >
      <Wrapper data-accent={accent}>
        <Box>
          <Icon value={CalendarIcon} />
        </Box>
        <Title
          isUnpadded
          isMini
          title={t("descriptions.dates.local")}
          titleShort={t("descriptions.dates.localShort", {
            zone: _.timezone().offset,
          })}
        />
        <Box>
          <Icon value={InformationCircleIcon} />
        </Box>
      </Wrapper>
    </Tooltip>
  );
}

export default Timezone;
