import { Fragment } from "react";
import styled from "styled-components";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { _ } from "@sablier/v2-mixins";
import type { ComponentProps } from "react";
import Internal from "../../atoms/Internal";
import Icon from "../Icon";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex-wrap: wrap;
    column-gap: 4px;
  }
`;

const Item = styled(Internal)`
  ${(props) => props.theme.styles.row}
  & {
    color: ${(props) => props.theme.colors.gray400};

    &:last-child {
      color: ${(props) => props.theme.colors.white};
    }
    & > p {
      ${(props) => props.theme.styles.textBreadcrumb};
    }
    &:not(:last-child) {
      cursor: pointer;
      &:hover,
      &:active {
        color: ${(props) => props.theme.colors.gray300};
        & > p {
          text-decoration: underline;
        }
      }
    }
    &[data-disabled="true"] {
      user-select: none;
      pointer-events: none;
    }
  }
`;

const Divider = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    --size: 16px;
    color: ${(props) => props.theme.colors.gray400};
  }
`;

interface Props {
  path: {
    isDisabled?: boolean;
    onClick?: () => void;
    toPage?: string;
    title: string;
    separator?: ComponentProps<typeof Icon>["value"];
  }[];
}

function Breadcrumbs({ path }: Props) {
  return (
    <Wrapper>
      {path.map((item, index) => (
        <Fragment key={item.title}>
          {index > 0 && (
            <Divider>
              <Icon value={item.separator || ChevronRightIcon} />
            </Divider>
          )}
          <Item
            data-disabled={
              item.isDisabled ||
              (_.isNilOrEmptyString(item.toPage) && _.isNil(item.onClick))
            }
            to={item.toPage}
            onClick={item.onClick}
          >
            <p>{item.title}</p>
          </Item>
        </Fragment>
      ))}
    </Wrapper>
  );
}

export default Breadcrumbs;
