import { useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import { tabs } from "~/client/constants";
import useAirstreamDashboardQuery from "./useAirstreamDashboardQuery";

export default function useAirstreamDashboardTab() {
  const { identifier, isReady } = useAirstreamDashboardQuery();

  const tab = useMemo(() => {
    return Object.values(tabs.airstream).find(
      (tab) => tab.identifier === identifier,
    );
  }, [identifier]);

  return {
    identifier,
    isReady,
    tab,
  };
}
