import { useMemo } from "react";
import { StreamCategory, StreamShape } from "@sablier/v2-constants";
import { Translate } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Stream } from "@sablier/v2-models";
import { vendors } from "@sablier/v2-utils";
import {
  IExtensionDependencies,
  IExtensionExclude,
  IExtensionOverridesGeneral,
  IExtensionOverridesIndividual,
  IExtensionParamsAirstream,
  IExtensionParamsGroup,
  IExtensionParamsSimulate,
  IExtensionResultAirstream,
  IExtensionResultStream,
} from "~/client/types";
import type { IExtensionHeadersField } from "./config";
import type { ITiming } from "@sablier/v2-types";
import type { ComponentType, FC } from "react";
import config, { dynamic, linear, ordered, tranched } from "./config";
import {
  Extension,
  ICheck,
  IExtension,
  IExtensionConstructorParams,
  IExtensionHeaders,
  IValidation,
} from "./setup";

function initialize(params: IExtensionConstructorParams): {
  extension: IExtension | undefined;
  overrides:
    | {
        general: IExtensionOverridesGeneral | undefined;
        individual: IExtensionOverridesIndividual | undefined;
      }
    | undefined;
} {
  if (!_.isNilOrEmptyString(params.shape)) {
    const result = Object.values(config).find(
      (extension) =>
        extension.shape.toLowerCase() === params.shape!.toLowerCase(),
    );
    if (result) {
      return {
        extension: result.initial(params),
        overrides: {
          general: result.overrides?.general?.(),
          individual: result.overrides?.individual?.(),
        },
      };
    }
  }
  return {
    extension: { purpose: undefined },
    overrides: {
      general: undefined,
      individual: undefined,
    },
  };
}

function reset(purpose?: Extension): IExtension | undefined {
  if (!_.isNilOrEmptyString(purpose)) {
    return config[purpose].initial({});
  }
  return undefined;
}

function Group({
  purpose,
  streamId,
}: {
  purpose?: Extension;
  streamId: string;
}) {
  if (!_.isNilOrEmptyString(purpose) && !_.isNil(config[purpose].Form.Group)) {
    const Group = config[purpose].Form.Group as ComponentType<{
      streamId: string;
    }>;
    return <Group streamId={streamId} />;
  }
  return <></>;
}

function GroupGeneral({ purpose }: { purpose?: Extension }) {
  if (
    !_.isNilOrEmptyString(purpose) &&
    !_.isNil(config[purpose].Form.GroupGeneral)
  ) {
    const GroupGeneral = config[purpose].Form.GroupGeneral as FC;
    return <GroupGeneral />;
  }
  return <></>;
}

function Airstream({ purpose }: { purpose?: Extension }) {
  if (
    !_.isNilOrEmptyString(purpose) &&
    !_.isNil(config[purpose].Form.Airstream)
  ) {
    const Airstream = config[purpose].Form.Airstream as FC;
    return <Airstream />;
  }
  return <></>;
}

function check({
  t,
  data,
  isLoadingIncluded,
  isWarningIncluded,
  timing,
}: ICheck): string | undefined {
  const { purpose } = data;
  if (!_.isNilOrEmptyString(purpose) && !_.isNil(config[purpose].check)) {
    return config[purpose].check!({
      t,
      data,
      isLoadingIncluded,
      isWarningIncluded,
      timing,
    });
  }
  return undefined;
}

function certify({
  t,
  data,
  duration,
  amount,
  start,
  end,
  field,
}: IValidation): string | undefined {
  const { purpose } = data;
  if (!_.isNilOrEmptyString(purpose) && !_.isNil(config[purpose].check)) {
    return config[purpose].certify!({
      t,
      data,
      duration,
      amount,
      start,
      end,
      field,
    });
  }
  return undefined;
}

function identify(stream: Stream): Extension | StreamShape | undefined {
  if (!_.isNil(stream)) {
    for (const key of ordered) {
      if (!_.isNil(config[key].identify) && config[key].identify(stream)) {
        return _.toString(key);
      }
    }
  }

  return undefined;
}

function isAirstreamed({ purpose }: { purpose: Extension }) {
  return (
    !_.isNilOrEmptyString(purpose) &&
    !_.isNil(config[purpose]) &&
    _.get(config[purpose], "process.airstream") &&
    config[purpose].process.airstream
  );
}

function processAirstream({
  dependencies,
  extras,
  library,
}: IExtensionParamsAirstream): Promise<IExtensionResultAirstream> | undefined {
  try {
    const purpose = extras?.purpose as Extension;

    if (
      !_.isNilOrEmptyString(purpose) &&
      !_.isNil(config[purpose]) &&
      _.get(config[purpose], "process.airstream") &&
      config[purpose].process.airstream
    ) {
      return config[purpose].process.airstream?.({
        dependencies,
        extras,
        library,
      });
    }
  } catch (error) {
    vendors.crash.log(error);
  }

  return undefined;
}

function processGroup({
  dependencies,
  purpose,
  timing,
  library,
}: IExtensionParamsGroup): Promise<IExtensionResultStream> | undefined {
  try {
    if (
      !_.isNilOrEmptyString(purpose) &&
      !_.isNil(config[purpose]) &&
      _.get(config[purpose], "process.group")
    ) {
      return config[purpose].process.group({
        dependencies,
        purpose,
        timing,
        library,
      });
    }
  } catch (error) {
    vendors.crash.log(error);
  }

  return undefined;
}

function summary(
  dependencies: IExtensionDependencies,
  t: Translate,
  extension?: IExtension,
) {
  try {
    const purpose = extension?.purpose;
    if (
      !_.isNilOrEmptyString(purpose) &&
      !_.isNil(config[purpose]) &&
      !_.isNil(config[purpose].summary)
    ) {
      return config[purpose].summary(dependencies, extension, t);
    }
  } catch (error) {
    vendors.crash.log(error);
  }

  return undefined;
}

function simulate({ dependencies, extras, timing }: IExtensionParamsSimulate) {
  try {
    const purpose = extras?.purpose as Extension;

    if (
      !_.isNilOrEmptyString(purpose) &&
      !_.isNil(config[purpose]) &&
      _.get(config[purpose], "simulate")
    ) {
      return config[purpose].simulate({ dependencies, extras, timing });
    }
  } catch (error) {
    vendors.crash.log(error);
  }

  return undefined;
}

function precomputeGroup({
  dependencies,
  timing,
  purpose,
  library,
}: IExtensionParamsGroup) {
  try {
    if (
      !_.isNilOrEmptyString(purpose) &&
      !_.isNil(config[purpose]) &&
      _.get(config[purpose], "precompute.group")
    ) {
      return config[purpose].precompute.group({
        purpose,
        dependencies,
        timing,
        library,
      });
    }
  } catch (error) {
    vendors.crash.log(error);
  }

  return undefined;
}

function excludes(purpose?: Extension): IExtensionExclude[] {
  try {
    if (!_.isNilOrEmptyString(purpose) && !_.isNil(config[purpose])) {
      return config[purpose].excludes || [];
    }
  } catch (error) {
    vendors.crash.log(error);
  }
  return [];
}

function categorize(purpose: Extension | undefined) {
  if (
    linear.map((i) => i.toLowerCase()).includes((purpose || "")?.toLowerCase())
  ) {
    return StreamCategory.LOCKUP_LINEAR;
  }

  if (
    tranched
      .map((i) => i.toLowerCase())
      .includes((purpose || "")?.toLowerCase())
  ) {
    return StreamCategory.LOCKUP_TRANCHED;
  }

  return StreamCategory.LOCKUP_DYNAMIC;
}

/**
 * Return a collection of expected columns (header and type) for extended shapes.
 * This utility will only focus on the additional columns, and will not include general values (e.g. address, amount)
 *
 * Type casting to a specific set of extension headers isn't supported yet.
 * The method will simply cast to the full set of possible headers.
 */

function headers({
  purpose,
  isDuration,
}: {
  purpose?: Extension;
  isDuration: boolean;
}): IExtensionHeaders<IExtensionHeadersField> {
  try {
    if (!_.isNilOrEmptyString(purpose) && !_.isNil(config[purpose])) {
      return (
        (config[purpose].headers(
          isDuration,
        ) as IExtensionHeaders<IExtensionHeadersField>) || []
      );
    }
  } catch (error) {
    vendors.crash.log(error);
  }
  return [];
}

function template({
  purpose,
  timing,
}: {
  purpose?: Extension;
  timing: ITiming;
}): string {
  try {
    if (!_.isNilOrEmptyString(purpose) && !_.isNil(config[purpose])) {
      return config[purpose].template[timing] || "";
    }
  } catch (error) {
    vendors.crash.log(error);
  }
  return "";
}

function warnings(extension?: IExtension) {
  try {
    const purpose = extension?.purpose;
    if (
      !_.isNilOrEmptyString(purpose) &&
      !_.isNil(config[purpose]) &&
      !_.isNil(config[purpose].warnings)
    ) {
      return config[purpose].warnings(extension);
    }
  } catch (error) {
    vendors.crash.log(error);
  }

  return undefined;
}

/**
 * 🤷‍♂️ | 🤷‍♂️ |
 * There's an interesting behavior with this hook, requiring a seemingly useless useMemo
 * Even if it's returning a constant object, it causes any effect that depends on it to update again and again.
 *
 * @returns Pair of utility methods {initialize, reset}
 */
function useExtensionUtilities() {
  return useMemo(
    () => ({
      initialize,
      reset,
    }),
    [],
  );
}

const bundle = {
  check,
  certify,
  headers,
  excludes,
  process: {
    airstream: processAirstream,
    group: processGroup,
  },
  initialize,
  isAirstreamed,
  summary,
  identify,
  simulate,
  precompute: {
    group: precomputeGroup,
  },
  template,
  warnings,
};

export {
  Airstream,
  Extension,
  Group,
  GroupGeneral,
  useExtensionUtilities,
  linear,
  tranched,
  dynamic,
  categorize,
};
export type { IExtension };
export default bundle;
