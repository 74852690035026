import { StreamShapes } from "@sablier/v2-constants";
import { Translate } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Stream } from "@sablier/v2-models";
import { useStreamCreateGroupAccessor } from "~/client/hooks";
import type { StreamShape } from "@sablier/v2-constants";
import type { ICSVCell, ITiming } from "@sablier/v2-types";
import type { ComponentType } from "react";
import type {
  IExtensionCertify,
  IExtensionCheck,
  IExtensionDependencies,
  IExtensionExclude,
  IExtensionOverridesGeneral,
  IExtensionOverridesIndividual,
  IExtensionParamsAirstream,
  IExtensionParamsGroup,
  IExtensionParamsSimulate,
  IExtensionParamsSingle,
  IExtensionResultAirstream,
  IExtensionResultStream,
  IPrecomputeResult,
  ISummaryExtension,
} from "~/client/types";

export const Extension = {
  LINEAR: StreamShapes.linear.id,
  CLIFF: StreamShapes.cliff.id,

  DYNAMIC_EXPONENTIAL: StreamShapes.dynamicExponential.id,
  DYNAMIC_CLIFF_EXPONENTIAL: StreamShapes.dynamicCliffExponential.id,
  DYNAMIC_UNLOCK_LINEAR: StreamShapes.dynamicUnlockLinear.id,
  DYNAMIC_UNLOCK_CLIFF: StreamShapes.dynamicUnlockCliff.id,

  TRANCHED_BACKWEIGHTED: StreamShapes.tranchedBackweighted.id,
  TRANCHED_STEPPER: StreamShapes.tranchedStepper.id,
  TRANCHED_MONTHLY: StreamShapes.tranchedMonthly.id,
  TRANCHED_TIMELOCK: StreamShapes.tranchedTimelock.id,

  /** Deprecated but kept for backwards compatibility */
  DYNAMIC_MONTHLY: StreamShapes.dynamicMonthly.id,
  DYNAMIC_STEPPER: StreamShapes.dynamicStepper.id,
  DYNAMIC_TIMELOCK: StreamShapes.dynamicTimelock.id,
};

export type Extension = (typeof Extension)[keyof typeof Extension];

export type IExtensionHeadersType = ICSVCell &
  ("date" | "duration" | "integer" | "amount" | "percentages");

export type IExtensionHeaders<T extends string = string> = {
  header: Exclude<T, "purpose">;
  type: IExtensionHeadersType;
}[];

export type IConfiguration = {
  /**
   * @description
   * Used inside the "Create Stream Group from CSV" feature, ensuring completely valid values for extended content
   *
   * [file: <E>/setup.ts] - certify()
   */
  certify: (params: IExtensionCertify) => string | undefined;
  /**
   * @description
   * Used inside the form machine's onCheck() step, superficially (and preferably synchronously) ensuring valid arguments
   *
   * [file: <E>/setup.ts] - check()
   */
  check: (params: IExtensionCheck) => string | undefined;
  /**
   * @description
   * Return a collection of expected columns (header and type) for extended shapes - to be used in the CSV files.
   * This utility will only focus on the additional columns, and will not include general group-form values (e.g. address, amount)
   *
   * Type casting to a specific set of extension headers isn't supported yet.
   * The method will return the proper headers but will simply cast to the full set of possible headers.
   * Proper narrowing or type casting will be required in each individual implementation.
   *
   * [file: <E>/config.ts] - headers()
   */
  headers: (isDuration: boolean) => IExtensionHeaders;
  /**
   * @description
   * Components to be rendered inside the extended section of the forms.
   *
   * [file: <E>/logic.ts] - logic required by the create-form
   * [file: <E>/fields.tsx] - UI required by the create-forms, uses logic
   * [file: <E>/form.tsx] - wrapper for the extended fields, uses fields
   */
  Form: {
    Airstream?: ComponentType<unknown>;
    Group?: ComponentType<{ streamId: string }>;
    GroupGeneral?: ComponentType<unknown>;
  };
  /**
   * @description
   * Defines the set of general field ids that should be excluded for specific extensions.
   * Example: Tranched Monthly doesn't make use of the general duration/start/end fields as it defines its own.
   *
   * [file: <E>/config.ts] - excludes[]
   */
  excludes?: IExtensionExclude[];
  /**
   * @description
   * The initial shape and set of fields specific to each extension.
   * It also receives a set of default values in case some items have to initialize with a non-null value.
   *
   * [file: <E>/setup.ts] - identify()
   */
  identify: (stream: Stream) => boolean;
  /**
   * @description
   * The initial shape and set of fields specific to each extension.
   * It also receives a set of default values in case some items have to initialize with a non-null value.
   *
   * @important
   * Each extension has to configure a type for itself.
   *
   * [file: <E>/config.ts] - initial() + I<Extension>
   *
   */
  initial(params: IExtensionConstructorParams): IExtension;
  /**
   * @description
   * For some shapes, the default values will be overridden.
   * This object will define a set of general overrides (for the general fields top form section) and an individual one (for group items).
   * Example: timelocks will have cancelability disabled by default (opposite of the general behavior).
   *
   * [file: <E>/config.ts] - overrides{}()
   */
  overrides?: {
    general?(): IExtensionOverridesGeneral | undefined;
    individual?(): IExtensionOverridesIndividual | undefined;
  };
  /**
   * @description
   * Used inside the form machine's onProcess() step to build the payload to-be-sent to the contracts.
   *
   * [file: <E>/process.ts] - processSingle(), processGroup()
   */
  process: {
    airstream?: (
      params: IExtensionParamsAirstream,
    ) => Promise<IExtensionResultAirstream>;
    group: (params: IExtensionParamsGroup) => Promise<IExtensionResultStream>;
    single: (params: IExtensionParamsSingle) => Promise<IExtensionResultStream>;
  };
  /**
   * @description
   * Defines a unique identifier for the stream shape (usually an alias of the @sablier/v2-types StreamShape ids)
   *
   * [file: <E>/config.ts] - shape
   */
  shape: StreamShape | string;
  /**
   * @description
   * Updates flags and defines a set of fields to be shown in the right-side summary of the form pages.
   * This augments the generic list of fields (e.g. start date, token)
   *
   * [file: <E>/summary.ts] - summary()
   */
  summary: (
    dependencies: IExtensionDependencies,
    extras: unknown,
    t: Translate,
  ) => ISummaryExtension | undefined;
  /**
   * @description
   * Creates a minimum viable Stream entity from the user provided form values.
   * This stream is then used inside the simulation Chart for a preview of the distribution curve.
   *
   * [file: <E>/setup.ts] - precomputeSingle(), precomputeGroup()
   */
  simulate: (params: IExtensionParamsSimulate) => Stream;
  /**
   * @description
   * Bundles some repetitive precomputed values such as prepared amounts or total amounts (in the case of groups).
   *
   * [file: <E>/setup.ts] - precomputeSingle(), precomputeGroup()
   */
  precompute: {
    single: (params: IExtensionParamsSingle) => IPrecomputeResult;
    group: (params: IExtensionParamsGroup) => IPrecomputeResult;
  };
  /**
   * @description
   * Links towards a set of CSV templates to be used with the "Create Stream Group from CSV" feature.
   *
   * [file: <E>/config.ts] - template{}
   */
  template: {
    duration: string | undefined;
    range: string | undefined;
  };
  /**
   * @description
   * Offers a way to aggregate warnings emitted by extended fields.
   *
   * [file: <E>/config.ts] - warnings()
   */
  warnings: (extras: unknown) => string[];
};

export interface IExtension {
  purpose?: Extension;
}

export interface IExtensionConstructorParams {
  shape?: StreamShape | string;
  cliffDuration?: string;
  cliffEnd?: string;
  unlock?: string;
  months?: string;
  years?: string;
  steps?: string;
}

export interface ICheck {
  t: Translate;
  data: IExtension;
  isLoadingIncluded: boolean;
  isWarningIncluded: boolean;
  timing: ITiming;
}

export interface IValidation {
  t: Translate;
  data: IExtension;
  amount: string | undefined;
  duration: string | undefined;
  start: string | undefined;
  end: string | undefined;
  field: string;
}

export function setup<E = unknown>(
  access: ReturnType<typeof useStreamCreateGroupAccessor<E>>,
  id: string,
) {
  const state = access();
  const update = state.api.updateFields;
  const streams = state.fields.streams.value;
  const stream = streams.find((s) => s.id === id)!;
  const index = streams.findIndex((s) => s.id == id);

  const isValid = !(_.isNil(stream) || index === -1);

  return {
    index,
    isValid,
    stream,
    streams,
    update,
  };
}
