import {
  FormProvider,
  useFormContext,
  useFormField,
  useFormStore,
} from "@sablier/v2-contexts";
import { _ } from "@sablier/v2-mixins";
import type { IStoreSelector } from "@sablier/v2-types";
import type { PropsWithChildren } from "react";

export interface IForm {
  geoblock: {
    id: string;
    value: string[] | undefined;
    warning?: string;
  };
  hide: {
    id: string;
    value?: boolean;
    warning?: string;
  };
  eligibilityLink: {
    id: string;
    value?: string;
    warning?: string;
  };
}

const initial: IForm = {
  geoblock: {
    id: "geoblock",
    value: undefined,
    warning: undefined,
  },
  hide: {
    id: "hide",
    value: undefined,
    warning: undefined,
  },
  eligibilityLink: {
    id: "eligibilityLink",
    value: undefined,
    warning: undefined,
  },
};

function Provider({ children }: PropsWithChildren<unknown>) {
  return <FormProvider<IForm> initial={initial}>{children}</FormProvider>;
}

function useAccessor() {
  return useFormContext<IForm>().getState;
}

function useForm<Slice>(selector: IStoreSelector<IForm, Slice>): Slice {
  return useFormStore(selector);
}

function useField<I extends keyof IForm>(id: I) {
  return useFormField<IForm, I>(id);
}

export { useAccessor, useField, useForm };

export default Provider;
