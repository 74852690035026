import { _ } from "@sablier/v2-mixins";
import { useShallow } from "zustand/react/shallow";
import useStore from "~/client/stores/expected";

export function useExpectedStoreList() {
  const set = useStore(useShallow((state) => state.api.setList));
  const list = useStore(useShallow((state) => state.list));

  return {
    set,
    list,
  };
}

export function useExpectedStoreItem(id?: string) {
  const { add, remove, update } = useStore((state) => ({
    add: state.api.addItem,
    remove: state.api.removeItem,
    update: state.api.updateItem,
  }));

  const item = useStore(
    useShallow((state) =>
      id
        ? state.list.find((item) => item.id.toLowerCase() === id.toLowerCase())
        : undefined,
    ),
  );

  return {
    add,
    item,
    remove,
    update,
  };
}

export function useExpectedStoreAccessor() {
  return useStore.getState;
}
