import nextDynamic from "next/dynamic";

/** ------- General ------- */

const Account = nextDynamic(() => import("./Account"), { ssr: false });
const Preflight = nextDynamic(() => import("./Preflight"), { ssr: false });

const ChainPicker = nextDynamic(() => import("./ChainPicker"), {
  ssr: false,
});
const ChainSwitcher = nextDynamic(() => import("./ChainSwitcher"), {
  ssr: false,
});

const DurationPicker = nextDynamic(() => import("./DurationPicker"), {
  ssr: false,
});
const Expected = nextDynamic(() => import("./Expected"), { ssr: false });
const MomentPicker = nextDynamic(() => import("./MomentPicker"), {
  ssr: false,
});
const Permissions = nextDynamic(() => import("./Permissions"), { ssr: false });
const TokenPicker = nextDynamic(() => import("./TokenPicker"), { ssr: false });
const Transaction = nextDynamic(() => import("./Transaction"), { ssr: false });

const SanctionAccount = nextDynamic(() => import("./SanctionAccount"), {
  ssr: false,
});
const SanctionAirstream = nextDynamic(() => import("./SanctionAirstream"), {
  ssr: false,
});
const SanctionStream = nextDynamic(() => import("./SanctionStream"), {
  ssr: false,
});

/** ------- Airstream ------- */

const AirstreamBypass = nextDynamic(() => import("./Airstream/Bypass"), {
  ssr: false,
});
const AirstreamEdit = nextDynamic(() => import("./Airstream/Edit"), {
  ssr: false,
});
const AirstreamHistory = nextDynamic(() => import("./Airstream/History"), {
  ssr: false,
});
const AirstreamRecipients = nextDynamic(
  () => import("./Airstream/Recipients"),
  {
    ssr: false,
  },
);
const AirstreamPrefund = nextDynamic(() => import("./Airstream/Prefund"), {
  ssr: false,
});
const AirstreamSearch = nextDynamic(() => import("./Airstream/Search"), {
  ssr: false,
});
const AirstreamTemplate = nextDynamic(() => import("./Airstream/Template"), {
  ssr: false,
});

const AirstreamGuide = nextDynamic(() => import("./Airstream/Guide"), {
  ssr: false,
});

/** ------- Stream ------- */
const Calldata = nextDynamic(() => import("./Calldata"), {
  ssr: false,
});
const StreamHistory = nextDynamic(() => import("./Stream/History"), {
  ssr: false,
});
const StreamLegend = nextDynamic(() => import("./Stream/Legend"), {
  ssr: false,
});
const StreamOptions = nextDynamic(() => import("./Stream/Options"), {
  ssr: false,
});
const StreamPreviewCSV = nextDynamic(() => import("./Stream/PreviewCSV"), {
  ssr: false,
});
const StreamSearch = nextDynamic(() => import("./Stream/Search"), {
  ssr: false,
});
const StreamShare = nextDynamic(() => import("./Stream/Share"), { ssr: false });
const StreamSimulator = nextDynamic(() => import("./Stream/Simulator"), {
  ssr: false,
});
const StreamTemplate = nextDynamic(() => import("./Stream/Template"), {
  ssr: false,
});
const UKDisclaimer = nextDynamic(() => import("./UKDisclaimer"), {
  ssr: false,
});

const CountryPicker = nextDynamic(() => import("./CountryPicker"), {
  ssr: false,
});

const StreamGuide = nextDynamic(() => import("./Stream/Guide"), {
  ssr: false,
});

/** ------- Targeted ------- */

function PageCreateCSV() {
  return (
    <>
      <StreamPreviewCSV />
    </>
  );
}

/** ------- All ------- */

function All() {
  return (
    <>
      <Account />
      <Calldata />
      <ChainPicker />
      <ChainSwitcher />
      <Permissions />
      <DurationPicker />
      <Expected />
      <Preflight />
      <MomentPicker />
      <TokenPicker />
      <Transaction />

      <SanctionAccount />
      <SanctionAirstream />
      <SanctionStream />

      <AirstreamBypass />
      <AirstreamGuide />
      <AirstreamEdit />
      <AirstreamHistory />
      <AirstreamPrefund />
      <AirstreamRecipients />
      <AirstreamSearch />
      <AirstreamTemplate />

      <StreamGuide />
      <StreamHistory />
      <StreamLegend />
      <StreamOptions />
      <StreamSearch />
      <StreamShare />
      <StreamSimulator />
      <StreamTemplate />
      <UKDisclaimer />
      <CountryPicker />
    </>
  );
}

export { PageCreateCSV };

export default All;
