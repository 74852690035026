import { memo, useMemo } from "react";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { ISInput } from "../system/templates";
import Template, { sides } from "../system/templates";

export interface Props extends Omit<ISInput, "onChange" | "right"> {}

function Locked(props: Props) {
  const { t } = useT();
  const formatted = useMemo(() => {
    const right: ISInput["right"] = [sides.lock()];

    if (props.isLoading) {
      right.unshift(sides.loader());
    }

    return {
      placeholder: t("form.placeholder.text"),
      ...props,
      right,
      isLocked: true,
      isDisabled: true,
    };
  }, [props, t]);

  return <Template.Text {...formatted} />;
}

export default memo(Locked, _.isEqual);
