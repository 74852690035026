import { useMemo } from "react";
import styled, { css } from "styled-components";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Icon } from "~/components/molecules";
import type { ISInput } from "../system/templates";
import type { IInputOption } from "@sablier/v2-types";
import Text from "../Text";
import Template, { sides } from "../system/templates";

const ContainerPartial = styled.div<{ isBordered?: boolean }>`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    width: 100%;
    padding: calc(${(props) => props.theme.sizes.edge} * 1);
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.dark100};

    & > *:last-child {
      *[data-component="box"] {
        --background: ${(props) => props.theme.colors.dark100};
      }
      *[data-component="field"] {
        --background: ${(props) => props.theme.colors.dark100};
      }
    }
  }

  ${(props) =>
    props.isBordered &&
    css`
      outline: 2px solid ${(props) => props.theme.colors.dark200};
    `}
`;

const Row = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex-wrap: wrap;
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    width: 100%;
    min-height: 24px;
  }
`;

const Title = styled.div`
  & > p {
    ${(props) => props.theme.styles.textInput}
    & {
      margin: 0;
      color: ${(props) => props.theme.colors.gray200};
      span {
        color: ${(props) => props.theme.colors.gray400};
      }
    }
  }
`;

const Item = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    align-items: center;
    min-height: 26px;
    padding: calc(${(props) => props.theme.sizes.edge} * 1 / 4)
      calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    column-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 4);
    border-radius: 20px;
    background-color: ${(props) => props.theme.colors.dark300};

    *[data-component="icon"] {
      color: ${(props) => props.theme.colors.gray400};
    }

    &[data-empty="true"] {
      min-width: 60px;
      border: 2px solid ${(props) => props.theme.colors.border};
      background-color: ${(props) => props.theme.colors.dark150};
      &:first-child {
        min-width: 100px;
      }
    }

    &:not([data-empty="true"]) {
      cursor: pointer;

      &:hover,
      &:active {
        background-color: ${(props) => props.theme.colors.dark200};
        ${Title} {
          opacity: 0.4;
        }
        *[data-component="icon"] {
          color: ${(props) => props.theme.colors.red};
        }
      }

      &[data-warning="true"] {
        ${Title} > p {
          color: ${(props) => props.theme.colors.yellow};
        }
      }
    }
  }
`;

const Container = styled(ContainerPartial)``;

export interface Props<T extends IInputOption>
  extends Omit<ISInput, "left" | "onChange" | "onClick" | "right" | "value"> {
  className?: string;
  current?: string;
  onChange: (value?: string) => void;
  onAdd: (value?: string) => void;
  onPrefill?: (value?: string) => void;
  onRemove: (value: T) => void;
  /** Keep the id unique so the current value's position can be successfully identified */
  value: T[];
}

function TextList<T extends IInputOption = IInputOption>({
  id,
  info,
  current,
  label,
  className,
  onAdd,
  onPrefill,
  onRemove,
  value = [],
  ...props
}: Props<T>) {
  const { t } = useT();
  const formatted = useMemo(() => {
    const right: ISInput["right"] = [
      sides.add(
        () => onAdd(current),
        t("structs.addID"),
        "",
        !_.isNilOrEmptyString(props.warning),
      ),
    ];

    if (onPrefill) {
      right.push(sides.prefill(() => onPrefill(), t("structs.fromSelection")));
    }

    if (props.isLoading) {
      right.unshift(sides.loader());
    }

    return {
      id: `${id}-text`,
      isBordered: true,
      right,
      value: current,
      ...props,
    };
  }, [current, props, id, onAdd, onPrefill, t]);

  return (
    <Template.Shell
      className={className}
      info={info}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      isLocked={props.isLocked}
      isPreview={props.isPreview}
      label={label}
    >
      <Container isBordered={props.isBordered}>
        {value.length ? (
          <Row>
            {value.map((item) => (
              <Item
                key={item.title}
                data-warning={!_.isNilOrEmptyString(item.warning)}
                onClick={() => onRemove(item)}
                title={t("words.remove")}
              >
                <Title>
                  <p>
                    {item.title}
                    {!_.isNilOrEmptyString(item.subtitle) ? (
                      <span> ({item.subtitle})</span>
                    ) : (
                      false
                    )}
                  </p>
                </Title>
                <Icon value={XMarkIcon} />
              </Item>
            ))}
          </Row>
        ) : (
          <></>
        )}
        <Text {...formatted} />
      </Container>
    </Template.Shell>
  );
}

export default TextList;
