import { createElement, useCallback, useEffect, useRef } from "react";
import { _ } from "@sablier/v2-mixins";
import { FixedSizeList as List } from "react-window";
import type { FC } from "react";

/** Re-implemented as per FedericoDiRosa/react-window-scroller and react-window's type definitions */

const windowScrollPositionKey = {
  y: "pageYOffset",
  x: "pageXOffset",
} as const;

const documentScrollPositionKey = {
  y: "scrollTop",
  x: "scrollLeft",
} as const;

const getScrollPosition = (axis: "x" | "y") =>
  window[windowScrollPositionKey[axis]] ||
  document.documentElement[documentScrollPositionKey[axis]] ||
  document.body[documentScrollPositionKey[axis]] ||
  0;

interface Arguments {
  scrollOffset: number;
  scrollUpdateWasRequested: boolean;
}

interface Details {
  elementRef: ReturnType<typeof useRef<List>>;
  containerRef: ReturnType<typeof useRef<HTMLElement>>;
  style: {
    width: string;
    height: string;
    display: string;
  };
  onScroll: ({ scrollOffset, scrollUpdateWasRequested }: Arguments) => void;
}

type Props = {
  children: FC<Details>;
  throttleTime?: number;
};

export default function Scroller({ children, throttleTime = 10 }: Props) {
  const elementRef = useRef<List>();
  const containerRef = useRef<HTMLElement>();

  useEffect(() => {
    const handleWindowScroll = _.throttle(() => {
      const { offsetTop = 0 } = containerRef.current || { offsetTop: 0 };

      const scrollTop = getScrollPosition("y") - offsetTop;
      /** Here, `scrollTo` is a proprietary method of `FixedSizeList` not a DOM scroll method*/

      if (elementRef.current) {
        elementRef.current.scrollTo(scrollTop);
      }
    }, throttleTime);

    window.addEventListener("scroll", handleWindowScroll);
    window.addEventListener("resize", handleWindowScroll);
    return () => {
      handleWindowScroll.cancel();
      window.removeEventListener("scroll", handleWindowScroll);
      window.removeEventListener("resize", handleWindowScroll);
    };
  }, [throttleTime]);

  const onScroll = useCallback(
    ({ scrollOffset, scrollUpdateWasRequested }: Arguments) => {
      if (!scrollUpdateWasRequested) {
        return;
      }
      const top = getScrollPosition("y");
      const { offsetTop = 0 } = containerRef.current || {};

      scrollOffset += Math.min(top, offsetTop);

      if (scrollOffset !== top) {
        window.scrollTo(0, scrollOffset);
      }
    },
    [],
  );

  return createElement(children, {
    elementRef,
    containerRef,
    style: {
      width: "100%",
      height: "100%",
      display: "block",
    },
    onScroll,
  });
}
