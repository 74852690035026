import { UserCircleIcon } from "@heroicons/react/24/outline";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Base } from "../../system";

interface Props {
  className?: string;
  permission: string | undefined;
}

function DepletedCampaign({ className }: Props) {
  const { t } = useT();

  return (
    <Base
      className={className}
      description={t("warnings.airstreamDepleted.description")}
      label={{
        icon: UserCircleIcon,
        value: t("warnings.airstreamDepleted.title"),
      }}
    />
  );
}

export default DepletedCampaign;
