import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Input } from "@sablier/v2-components/organisms";
import {
  MONTHLY_MAX_UNLOCKS_GROUP,
  MONTHLY_MIN_UNLOCKS,
} from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import {
  useGroupFieldInitial,
  useGroupFieldMonths,
  useGroupFieldStart,
} from "./logic";

function GroupStart({ streamId }: { streamId: string }) {
  const { onClick, start, label } = useGroupFieldStart(streamId);
  const { t } = useT();

  return (
    <Input.Moment
      id={`${start.field.id}-${streamId}`}
      label={label}
      onClick={onClick}
      placeholder={t("form.placeholder.moment")}
      value={start.field.value}
      warning={start.field.warning}
    />
  );
}

function GroupMonths({ streamId }: { streamId: string }) {
  const { label, months, measure, onBlur, onChange, warning } =
    useGroupFieldMonths(streamId);
  const { t } = useT();

  return (
    <Input.AmountStepper
      id={months.field.id}
      min={MONTHLY_MIN_UNLOCKS}
      max={MONTHLY_MAX_UNLOCKS_GROUP}
      isEditable
      measure={measure}
      label={label}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={t("form.placeholder.months")}
      value={months.field!.value}
      warning={warning}
    />
  );
}

function GroupInitial({ streamId }: { streamId: string }) {
  const { initial, isDisabled, onChange, placeholder, value, info } =
    useGroupFieldInitial(streamId);
  const { t } = useT();

  return (
    <Input.Switch
      id={initial.field.id}
      isDisabled={isDisabled}
      label={{
        value: t("structs.firstUnlock"),
        isIconLast: true,
        icon: InformationCircleIcon,
        tooltip: {
          value: t("descriptions.initial"),
        },
      }}
      placeholder={placeholder}
      labelOn={_.capitalize(t("structs.atStart"))}
      labelOff={_.capitalize(t("structs.endOfFirstMonth"))}
      onChange={onChange}
      value={value}
      info={info}
    />
  );
}

const Fields = {
  GroupMonths,
  GroupStart,
  GroupInitial,
};

export default Fields;
