import { useMemo } from "react";
import styled from "styled-components";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { _ } from "@sablier/v2-mixins";
import { Internal, Shimmer } from "~/components/atoms";
import { Breadcrumbs } from "~/components/molecules";
import type { ComponentProps, PropsWithChildren, ReactNode } from "react";
import Icon from "../Icon";

const WrapperPartial = styled.header`
  ${(props) => props.theme.styles.row}
  & {
    align-items: flex-end;
    width: 100%;
    padding: calc(${(props) => props.theme.sizes.edge} * 2) 0;
  }
`;

const Left = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    row-gap: ${(props) => props.theme.sizes.edge};
  }
`;

const Controller = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    margin: 4px 0;
    column-gap: calc(${(props) => props.theme.sizes.edge} * 1);
  }
`;

const Back = styled(Internal)`
  ${(props) => props.theme.styles.row}
  & {
    flex-shrink: 0;
    justify-content: center;
    width: 42px;
    height: 42px;
    color: ${(props) => props.theme.colors.gray400};
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.dark300};
    cursor: pointer;

    &:hover,
    &:active {
      background-color: ${(props) => props.theme.colors.dark500};
    }
  }
`;

const Title = styled.div`
  & > h1 {
    ${(props) => props.theme.styles.row}
    ${(props) => props.theme.styles.textSection}
    & {
      gap: 12px;
      color: ${(props) => props.theme.colors.white};
    }

    &[data-short="true"] {
      display: none;
    }
  }
`;

const Right = styled.div`
  flex-shrink: 0;
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxLG} {
    ${Title} > h1 {
      line-height: 32pt;
    }
  }
  ${(props) => props.theme.medias.maxMD} {
    padding: calc(${(props) => props.theme.sizes.edge} * 3 / 2) 0;

    ${Controller} {
      gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      margin: 4px 0;
    }

    ${Title} > h1 {
      line-height: 24pt;
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    padding: calc(${(props) => props.theme.sizes.edge} * 3 / 2) 0;

    ${Title} {
      & > h1:not([data-short="true"]) {
        display: none;
      }
      & > h1[data-short="true"] {
        display: inherit;
      }
    }
  }
`;
interface Props {
  back?: string | (() => void);
  backIcon?: ComponentProps<typeof Icon>["value"];
  className?: string;
  isLoading?: boolean;
  path?: ComponentProps<typeof Breadcrumbs>["path"];
  title: string | ReactNode;
  titleShort?: string;
}

function Header({
  back,
  backIcon = ArrowLeftIcon,
  children,
  className,
  isLoading = false,
  path,
  title,
  titleShort,
}: PropsWithChildren<Props>) {
  const internal = useMemo(() => {
    if (_.isString(back)) {
      return { to: back };
    }
    if (_.isFunction(back)) {
      return { onClick: back };
    }
    return {};
  }, [back]);

  return (
    <Wrapper className={className} data-component={"header"}>
      <Left>
        {path && <Breadcrumbs path={path} />}
        <Controller>
          {back && (
            <Back {...internal}>
              <Icon
                purpose={"heroicon"}
                value={backIcon}
                size={20}
                thickness={2.6}
              />
            </Back>
          )}
          <Title data-component={"title"}>
            {isLoading ? (
              <Shimmer
                purpose={"value"}
                background={"dark200"}
                foreground={"dark400"}
                height={40}
                width={174}
              />
            ) : (
              <>
                <h1>{title}</h1>
                <h1 data-short={true}>{titleShort || title}</h1>
              </>
            )}
          </Title>
        </Controller>
      </Left>
      <Right>{children}</Right>
    </Wrapper>
  );
}

export default Header;
