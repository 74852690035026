import { useCallback, useMemo } from "react";
import {
  HISTORY_PAGE_SIZE,
  MAX_SUBGRAPH_ID,
  QUERY_CACHE_TIME,
  REQUEST_ID,
} from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { Airstream } from "@sablier/v2-models";
import { client, queries } from "@sablier/v2-subgraphs";
import { useInfiniteQuery } from "@tanstack/react-query";

interface Props {
  id?: string;
  key?: string[];
  first?: number;
}

export default function useRequestAirstreamActions({
  id = "_",
  key = REQUEST_ID.airstreamActions,
  first = HISTORY_PAGE_SIZE,
}: Props) {
  const filter = useMemo(
    () =>
      Airstream.doFormatFilter({
        chainId: Airstream.doSplitIdentifier(id).chainId,
      }),
    [id],
  );

  const endpoint = useMemo(
    () =>
      client.getEndpointByChain({
        chainId: filter.chainId,
        feature: "airstream",
      }),
    [filter],
  );

  const paginated = useCallback(
    async (first: number, subgraphId = MAX_SUBGRAPH_ID) => {
      const variables = {
        chainId: filter.chainId!,
        first,
        airstreamId: id,
        subgraphId,
      };
      return client.request(
        endpoint,
        queries.airstream.getActions_ByAirstream,
        variables,
      );
    },
    [endpoint, id, filter.chainId],
  );

  const {
    data,
    isLoading,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [...key, { unique: id }],
    queryFn: ({ pageParam }) => paginated(first, pageParam.toString()),
    initialPageParam: MAX_SUBGRAPH_ID,
    staleTime: QUERY_CACHE_TIME,
    gcTime: QUERY_CACHE_TIME,
    getNextPageParam: (lastPage) => {
      return lastPage.actions.length === HISTORY_PAGE_SIZE
        ? lastPage.actions[lastPage.actions.length - 1].subgraphId
        : undefined; /** Return undefined to say there are no pages available */
    },
  });

  return useMemo(
    () => ({
      error,
      isLoading,
      data,
      hasNextPage,
      fetchNextPage,
      isFetchingNextPage,
    }),
    [data, error, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading],
  );
}
