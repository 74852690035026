import styled from "styled-components";
import type { PropsWithChildren } from "react";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    width: 100%;
    column-gap: 4px;
  }
`;

const Title = styled.div`
  flex: 1;
  & > p {
    ${(props) => props.theme.styles.textParagraph}
    & {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

const Value = styled.div`
  ${(props) => props.theme.styles.row}
  & > p {
    ${(props) => props.theme.styles.textParagraph}
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxSM} {
    ${Value} {
      text-align: right;
    }
  }
`;

interface Props {
  title: string;
  value?: string;
}

function Info({ children, title, value }: PropsWithChildren<Props>) {
  return (
    <Wrapper>
      <Title>
        <p>{title}</p>
      </Title>
      <Value>{value ? <p>{value}</p> : children ?? false}</Value>
    </Wrapper>
  );
}

export default Info;
