import { Translate } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { IAddress, ISigner } from "@sablier/v2-types";
import { validateAddress } from "./address";
import policy from "./policy";

interface SignerValidProps {
  t: Translate;
  blacklist?: IAddress[];
  expected?: IAddress[];
  chainId?: number;
  value: ISigner | undefined;
}

export async function validateSigner({
  t,
  blacklist,
  expected: expectedAddresses,
  chainId: expectedChainId,
  value,
}: SignerValidProps): Promise<string | undefined> {
  try {
    if (_.isNilOrEmptyString(value)) {
      return policy.signer.missing(t);
    }

    const address = _.toAddress(value.account?.address);
    const chainId = value.chain?.id;

    if (_.isNilOrEmptyString(address) || _.isNilOrEmptyString(chainId)) {
      return policy.signer.invalid(t);
    }

    if (!_.isNilOrEmptyString(expectedChainId)) {
      if (
        _.toString(chainId).toLowerCase() !==
        _.toString(expectedChainId).toLowerCase()
      ) {
        return policy.signer.invalid(t);
      }
    }

    if (!_.isNilOrEmptyString(expectedAddresses) && expectedAddresses.length) {
      if (
        !expectedAddresses
          .map((a) => _.toAddress(a))
          .includes(_.toAddress(address))
      ) {
        return policy.signer.match(t);
      }
    }
    if (!_.isNil(blacklist) && blacklist.length) {
      if (
        !_.isNilOrEmptyString(validateAddress({ t, blacklist, value: address }))
      ) {
        return policy.signer.forbidden(t);
      }
    }
  } catch (_error) {
    return policy.signer.invalid(t);
  }

  return undefined;
}
