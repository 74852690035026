import Fields from "./fields";

function Group({ streamId }: { streamId: string }) {
  return <Fields.GroupUnlock streamId={streamId} />;
}
const Form = {
  Group,
};

export default Form;
