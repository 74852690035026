import styled from "styled-components";
import { Animated } from "@sablier/v2-components/molecules";
import { useFT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { HTMLAttributes, PropsWithChildren, ReactNode } from "react";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    justify-content: flex-end;
    align-items: center;
  }
`;

const Box = styled.div`
  position: relative;
  width: 100%;
  padding: ${(props) => props.theme.sizes.formEdge};
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.dark200};
  overflow: hidden;
`;

const Top = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    min-height: ${(props) => props.theme.sizes.buttonMini};
    padding-bottom: ${(props) => props.theme.sizes.formEdge};
    border-bottom: 2px solid ${(props) => props.theme.colors.border};
    &:empty {
      &:after {
        content: "";
        height: 20px;
      }
    }
  }
`;

const Title = styled.div`
  flex: 1;
  min-height: 20px;
  & > h2 {
    ${(props) => props.theme.styles.textMenu}
  }
`;

const Extra = styled.div`
  ${(props) => props.theme.styles.row}
`;

const Content = styled(Animated)`
  ${(props) => props.theme.styles.column}
  & {
    width: 100%;
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxXL} {
    ${Box} {
      padding: ${(props) => props.theme.sizes.edge};
      ${Top} {
        padding-top: calc(
          ${(props) => props.theme.sizes.formEdge} -
            ${(props) => props.theme.sizes.edge}
        );
      }
    }
  }
`;

interface Props {
  attributes?: HTMLAttributes<HTMLDivElement>;
  className?: string;
  extra?: ReactNode;
  isTopHidden?: boolean | "preserve";
  title?: string;
  cy?: string;
}

function Card({
  className,
  children,
  cy,
  extra,
  isTopHidden = false,
  title: _title,
}: PropsWithChildren<Props>) {
  const title = useFT(_title, "words.details");

  return (
    <Wrapper className={className} data-cy={cy}>
      <Box data-component={"card"}>
        {!isTopHidden ? (
          <Top>
            <Title data-component={"title"}>
              {!_.isNilOrEmptyString(title) && <h2>{title}</h2>}
            </Title>
            <Extra>{extra}</Extra>
          </Top>
        ) : isTopHidden === "preserve" ? (
          <Top />
        ) : (
          false
        )}

        <Content duration={150} easing={"ease-out"}>
          {children}
        </Content>
      </Box>
    </Wrapper>
  );
}

export default Card;
