import type { ITranchedStepper } from "./config";
import { IConfiguration } from "../../system/setup";
import { headers, initial, shape, template, warnings } from "./config";
import Form from "./form";
import { process } from "./process";
import { certify, check, identify, precompute, simulate } from "./setup";
import summary from "./summary";

const extension: IConfiguration = {
  check,
  certify,
  headers,
  identify,
  process,
  Form,
  initial,
  shape,
  summary,
  simulate,
  precompute,
  template,
  warnings,
};

export type { ITranchedStepper };
export { extension };
