import styled from "styled-components";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { _ } from "@sablier/v2-mixins";
import ButtonPartial from "../Button";

interface WrapperProps {
  size: {
    height?: number | string;
    width?: number | string;
  };
}

const Wrapper = styled.div<WrapperProps>`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: 2px;
    width: ${(props) => props.size.width || "26px"};
    height: ${(props) => props.size.height || props.theme.sizes.inputField};
    & > * {
      flex: 1;
      width: 100%;
      min-width: 0 !important;
      min-height: 0 !important;
    }
  }
`;

const Button = styled(ButtonPartial)`
  padding: 0;
`;

export interface Props {
  height?: number | string;
  onDown?: () => void;
  onUp?: () => void;
  width?: number | string;
}

function Stepper({ height, onDown, onUp, width }: Props) {
  return (
    <Wrapper
      size={{
        height: height && _.toSuffix(height, "px"),
        width: width && _.toSuffix(width, "px"),
      }}
    >
      <Button
        accent={"input"}
        appearance={"solid"}
        isMini
        left={ChevronUpIcon}
        onClick={onUp}
        title={""}
      />
      <Button
        accent={"input"}
        appearance={"solid"}
        isMini
        left={ChevronDownIcon}
        onClick={onDown}
        title={""}
      />
    </Wrapper>
  );
}

export default Stepper;
