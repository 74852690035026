import { QUERY_CACHE_ENS_TIME, chains } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { IWagmiAddress } from "@sablier/v2-types";
import { useEnsName as useWagmiEnsName } from "wagmi";

type Props = {
  address: string | undefined;
  chainId: number | undefined;
  enabled?: boolean;
  gcTime?: number;
  staleTime?: number;
};

export default function useResolvedENS({
  address,
  gcTime = QUERY_CACHE_ENS_TIME,
  chainId = chains.mainnet.chainId,
  enabled = true,
  staleTime = QUERY_CACHE_ENS_TIME,
}: Props) {
  const result = useWagmiEnsName({
    address: address as IWagmiAddress,
    chainId,
    query: {
      gcTime,
      enabled:
        !!enabled &&
        [chains.mainnet.chainId, chains.sepolia.chainId].includes(chainId),
      staleTime,
    },
  });

  return result;
}
