import { useMemo } from "react";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import useResolvedAddress from "./useResolvedAddress";
import useShortAddress from "./useShortAddress";

export default function useResolvedTypedAddress(
  value: string | undefined,
  chainId: number | undefined,
  isEnabled = true,
) {
  const source = useMemo(() => value, [value]);
  const { t } = useT();

  const isENS = useMemo(() => {
    if (!_.isEthereumName(source)) {
      return false;
    }
    return true;
  }, [source]);

  const {
    data: resolved,
    isLoading,
    isError,
  } = useResolvedAddress({
    chainId,
    name: source,
    enabled: isENS && isEnabled,
  });

  const address = useMemo(
    () => (isENS ? resolved || undefined : source),
    [isENS, resolved, source],
  );
  const short = useShortAddress(address);

  const preview = useMemo(() => {
    if (_.isNilOrEmptyString(source) || !isENS) {
      return short || source;
    } else {
      return `${short || `${t("words.resolving")}...`} (${source})`;
    }
  }, [isENS, short, source, t]);

  return useMemo(
    () => ({
      address,
      ens: isENS ? source : undefined,
      isENS,
      isError,
      isLoading,
      preview,
      short,
    }),
    [address, isLoading, isError, isENS, preview, short, source],
  );
}
