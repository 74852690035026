import { useMemo } from "react";
import styled from "styled-components";
import type { ISInput } from "../system/templates";
import type { IToken, ITokenDisplay } from "@sablier/v2-types";
import Template, { sides } from "../system/templates";

const Templated = styled(Template.Text)`
  *[data-component="sides"][data-on="left"] {
    *[data-component="side"][data-purpose="token"] {
      p {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

export interface Props
  extends Omit<ISInput, "left" | "right" | "onChange" | "onClick" | "value"> {
  onChange: () => void;
  value?: Partial<ITokenDisplay> & {
    name?: string;
    raw?: IToken;
    isLocal?: boolean;
  };
}

function TokenPicker(props: Props) {
  const formatted: ISInput = useMemo(() => {
    const source = props.value?.raw ? props.value?.raw : props.value;
    const tokenLabel = source?.name || source?.symbol;
    const isLocal = source?.isLocal ?? false;

    const left: ISInput["left"] = [sides.token(tokenLabel, source?.image, 26)];

    const right: ISInput["right"] = isLocal
      ? [sides.addIcon(), sides.choose(props.onChange as () => void)]
      : [sides.choose(props.onChange as () => void)];

    if (props.isLoading) {
      right.unshift(sides.loader());
    }

    return {
      ...props,
      isPreview: "soft",
      left,
      onClick: props.onChange,
      placeholder: tokenLabel ? undefined : props.placeholder,
      right,
      value: "",
    };
  }, [props]);

  return <Templated {...formatted} />;
}

export default TokenPicker;
