import { memo, useMemo } from "react";
import styled, { css } from "styled-components";
import { useT } from "@sablier/v2-locales";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { rgba } from "polished";
import type { IMilliseconds, IToken } from "@sablier/v2-types";
import Button from "../Button";
import Tooltip from "../Tooltip";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    grid-gap: calc(${(props) => props.theme.sizes.edge});
    width: 100%;
    height: 36px;
    max-width: ${(props) => props.theme.sizes.streamCircle}px;
    & > div {
      height: 100% !important;
    }
  }
`;

const Box = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    flex: 1;
    justify-content: center;
    height: 100%;
    padding: 12px 12px;
    overflow: hidden;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  border: 2px solid ${(props) => rgba(props.theme.colors.dark500, 1)};
  border-radius: 4px;
  background-color: ${(props) => rgba(props.theme.colors.dark500, 0.2)};
  backdrop-filter: blur(5.5px);
`;

const Light = styled.div<{ left?: boolean; right?: boolean; width?: string }>`
  position: absolute;
  width: ${(props) => `${props.width || "10"}%`};
  height: 10px;
  background-color: ${(props) => props.theme.colors.gray200};
  opacity: 0.3;
  filter: blur(20px);
  & {
    ${(props) =>
      props.left &&
      css`
        left: -40px;
        opacity: 0.5;
      `}

    ${(props) =>
      props.right &&
      css`
        right: -15px;
      `}
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    z-index: 20;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }
`;

const Line = styled.div<{ progress?: string; isActive?: boolean }>`
  position: absolute;
  left: 0;
  width: ${(props) => props.progress || 100}%;
  height: 2px;
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.gray300 : props.theme.colors.dark500};
`;

const Marker = styled.div`
  width: 2px;
  height: 100%;
`;

const Milestone = styled.div<{ progress?: string; isActive?: boolean }>`
  ${(props) => props.theme.styles.row}
  & {
    position: absolute;
    left: ${(props) => props.progress || 100}%;
    z-index: 10;
    height: 100%;

    &:before {
      position: absolute;
      left: -20px;
      content: "";
      width: 40px;
      height: 40px;
    }

    &:after {
      position: absolute;
      left: -6px;
      content: "";
      width: 14px;
      height: 24px;
      border-radius: 2px;
      background: ${(props) => props.theme.colors.white};
      opacity: 0;
      transition: opacity 150ms;
    }

    &:last-child {
      margin-left: -2px;
    }

    :hover {
      &:after {
        mix-blend-mode: overlay;
        opacity: 0.1;
        transition: opacity 150ms;
      }
    }

    ${Marker} {
      background-color: ${(props) =>
        props.isActive
          ? props.theme.colors.gray200
          : props.theme.colors.dark700};
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxSM} {
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  }
`;

function Label({ amount, moment }: { amount: string; moment: string }) {
  const { t } = useT();
  return (
    <>
      <b>{amount}</b>
      <span>
        {" "}
        {t("words.on")} {moment}
      </span>
    </>
  );
}

interface Point {
  isActive?: boolean;
  moment: IMilliseconds;
  percentage?: string;
  value: string;
}

interface Props {
  end: IMilliseconds;
  progress?: string;
  token?: IToken;
  segments?: Point[];
  start: IMilliseconds;
  value?: string;
}

function percentage(
  point: IMilliseconds | undefined,
  start: IMilliseconds,
  end: IMilliseconds,
) {
  const duration = new BigNumber(end).minus(new BigNumber(start));
  const passed = new BigNumber(point || "0").minus(new BigNumber(start));
  return passed.times(100).dividedBy(duration);
}

function process({
  end,
  progress: _progress = "0",
  token,
  segments = [],
  start,
  value,
}: Props) {
  const progress = (() => {
    const value = new BigNumber(_progress);
    if (value.isFinite() && !value.isZero()) {
      return BigNumber.min(value, 100);
    }
    return new BigNumber(0);
  })();

  const milestones: Point[] = [];

  milestones.push({
    isActive: !progress.isZero(),
    moment: start,
    percentage: "0",
    value: "0",
  });

  segments.forEach((item) => {
    const x = percentage(item.moment, start, end);
    const delta = x.minus(
      new BigNumber(milestones[milestones.length - 1].percentage || "0"),
    );
    const cost = new BigNumber(item.value).minus(
      new BigNumber(milestones[milestones.length - 1].value || "0"),
    );

    if (milestones.length && delta.isPositive()) {
      const segment: Point = {
        isActive: progress.isGreaterThanOrEqualTo(x),
        moment: item.moment,
        percentage: x.toFixed(4),
        value: _.toNumeralPrice(item.value),
      };

      if (cost.isPositive()) {
        if (delta.isLessThanOrEqualTo(new BigNumber(0.05))) {
          milestones.pop();
        }

        if (x.isLessThan(new BigNumber(99.9))) {
          milestones.push(segment);
        }
      }
    }
  }, []);

  milestones.push({
    isActive: progress.isGreaterThanOrEqualTo(new BigNumber(99.5)),
    moment: end,
    percentage: "100",
    value: _.toNumeralPrice(value),
  });

  return {
    progress,
    milestones: milestones.map((m) => ({
      ...m,
      value: [token?.symbol, m.value].filter((i) => i).join(" "),
    })),
  };
}

function Timeline(props: Props & { onDynamic?: () => void }) {
  const { progress, milestones } = useMemo(() => process(props), [props]);

  const light = useMemo(
    () => BigNumber.min(new BigNumber(70), progress).toFixed(2),
    [progress],
  );

  const isDone = useMemo(
    () => progress.isEqualTo(new BigNumber(100)),
    [progress],
  );
  const approximated = useMemo(() => progress.toFixed(1), [progress]);
  const { t } = useT();

  return (
    <Wrapper>
      {props.onDynamic && (
        <Button
          accent={"iconic"}
          appearance={"outline"}
          isMini
          onClick={props.onDynamic}
          title={t("words.dynamic")}
        />
      )}
      <Box>
        {!isDone && (
          <>
            <Light left width={light} />
            <Light right />
          </>
        )}
        <Background />
        <Content>
          <Line />
          <Line isActive progress={approximated} />
          {milestones.map((milestone) => (
            <Tooltip
              key={milestone.percentage}
              value={
                <Label
                  amount={milestone.value}
                  moment={_.toDuration(milestone.moment, "date")[0]}
                />
              }
            >
              <Milestone
                progress={milestone.percentage}
                isActive={milestone.isActive}
              >
                <Marker />
              </Milestone>
            </Tooltip>
          ))}
        </Content>
      </Box>
    </Wrapper>
  );
}

function Sizer({
  onDynamic,
  value,
}: {
  value?: Props | undefined;
  onDynamic?: () => void;
}) {
  if (_.isNil(value)) {
    return <Wrapper />;
  }
  return <Timeline {...value} onDynamic={onDynamic} />;
}

export default memo(Sizer);
