import { PropsWithChildren, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import autoAnimate from "@formkit/auto-animate";
import { _ } from "@sablier/v2-mixins";

const Wrapper = styled.div``;

type Props =
  | {
      className?: string;
      duration?: never;
      easing?: never;
    }
  | {
      className?: string;
      duration: number;
      easing: "linear" | "ease-in" | "ease-out" | "ease-in-out";
    };

function Animated({
  children,
  className,
  easing,
  duration,
}: PropsWithChildren<Props>) {
  const animated = useRef(null);

  const options = useMemo(
    () =>
      !_.isNilOrEmptyString(easing) && !_.isNilOrEmptyString(duration)
        ? { easing, duration }
        : undefined,
    [duration, easing],
  );

  useEffect(() => {
    animated.current && autoAnimate(animated.current, options);
  }, [animated, options]);

  return (
    <Wrapper className={className} ref={animated}>
      {children}
    </Wrapper>
  );
}

export default Animated;
