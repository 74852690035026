import { useCallback } from "react";
import { REQUEST_ID } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { Token } from "@sablier/v2-models";
import { vendors } from "@sablier/v2-utils";
import { useQueryClient } from "@tanstack/react-query";
import type { IAddress } from "@sablier/v2-types";
import { useAirstreamStoreAccessor } from "./store/airstream";
import { useStreamStoreAccessor } from "./store/stream";

export default function useResetter() {
  const airstream = useAirstreamStoreAccessor();
  const stream = useStreamStoreAccessor();
  const client = useQueryClient();

  const all = useCallback(async () => {
    try {
      /** Cleanup: token allowances, balances (see wagmi's useBalance) */
      Token.reset();
      await client.resetQueries({ queryKey: REQUEST_ID.tokenBalance });

      /** Cleanup: stream store, stream query cache */
      stream().api.reset([]);
      await client.resetQueries({ queryKey: REQUEST_ID.stream });

      /** Cleanup: airstream store, airstream query cache */
      airstream().api.reset([]);
      await client.resetQueries({ queryKey: REQUEST_ID.airstream });
    } catch (error) {
      vendors.crash.log(error);
    }
  }, [airstream, stream, client]);

  const own = useCallback(async () => {
    try {
      /** Cleanup: token allowances, balances (see wagmi's useBalance) */
      Token.reset();
      await client.resetQueries({ queryKey: REQUEST_ID.tokenBalance });

      /** Cleanup: owned stream store (omit general results like search), owned streams query cache */
      stream().api.reset(["search", "preview"]);
      await client.resetQueries({ queryKey: REQUEST_ID.streamListOwned });

      /** Cleanup: owned airstream store (omit general results like search), owned airstreams query cache */
      airstream().api.reset(["search", "preview"]);
      await client.resetQueries({ queryKey: REQUEST_ID.airstreamListOwned });
      await client.resetQueries({ queryKey: REQUEST_ID.airstreamListEligible });
      await client.resetQueries({
        queryKey: REQUEST_ID.airstreamListClaimable,
      });
    } catch (error) {
      vendors.crash.log(error);
    }
  }, [airstream, stream, client]);

  const search = useCallback(
    async (feature: "streams" | "airstreams") => {
      try {
        /** Cleanup: token allowances, balances (see wagmi's useBalance) */
        Token.reset();
        await client.resetQueries({ queryKey: REQUEST_ID.tokenBalance });

        if (feature === "streams") {
          /** Cleanup: searched stream store (omit owned or preview results), searched streams query cache */
          stream().api.reset(["owned", "preview"]);
          await client.resetQueries({ queryKey: REQUEST_ID.streamListSearch });
        }

        if (feature === "airstreams") {
          /** Cleanup: searched stream store (omit owned or preview results), searched streams query cache */
          airstream().api.reset(["owned", "eligible", "preview"]);
          await client.resetQueries({
            queryKey: REQUEST_ID.airstreamListSearch,
          });
        }
      } catch (error) {
        vendors.crash.log(error);
      }
    },
    [airstream, stream, client],
  );

  const searchAirstreams = useCallback(() => search("airstreams"), [search]);
  const searchStreams = useCallback(() => search("streams"), [search]);

  const detailsAirstream = useCallback(
    async (address: IAddress, chainId: number | string) => {
      await client.resetQueries({
        queryKey: [
          ...REQUEST_ID.airstreamDetails,
          { unique: [chainId, address] },
        ],
      });
    },
    [client],
  );

  const proxy = useCallback(async () => {
    try {
      await own();
      await client.resetQueries({ queryKey: REQUEST_ID.proxy });
    } catch (error) {
      vendors.crash.log(error);
    }
  }, [client, own]);

  const token = useCallback(async () => {
    try {
      /** Cleanup: token allowances, balances (see wagmi's useBalance) */
      Token.reset();
      await client.resetQueries({ queryKey: REQUEST_ID.tokenBalance });
    } catch (error) {
      vendors.crash.log(error);
    }
  }, [client]);

  return {
    all,
    detailsAirstream,
    own,
    searchAirstreams,
    searchStreams,
    proxy,
    token,
  };
}
