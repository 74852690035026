import styled from "styled-components";
import Fields from "./fields";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: calc(${(props) => props.theme.sizes.edge} * 1);

  &[data-single="true"] {
    grid-template-columns: 1fr;
  }

  ${(props) => props.theme.medias.maxMD} {
    grid-template-columns: 1fr;
  }

  & > * {
    grid-column: span 2;
    &:nth-child(1),
    &:nth-child(2) {
      grid-column: span 1;
    }
  }
`;

function Group({ streamId }: { streamId: string }) {
  return (
    <Wrapper>
      <Fields.GroupStart streamId={streamId} />
      <Fields.GroupMonths streamId={streamId} />
      <Fields.GroupInitial streamId={streamId} />
    </Wrapper>
  );
}
const Form = {
  Group,
};

export default Form;
