import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import { Internal } from "~/components/atoms";
import type { ComponentProps, PropsWithChildren } from "react";
import Icon from "../Icon";
import Label from "../Label";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    width: 100%;
    column-gap: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
  }
`;

const Frame = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex-shrink: 0;
    justify-content: center;
    width: 60px;
    height: 60px;
    color: ${(props) => props.theme.colors.dark600};
    border-radius: 18px;
    background-color: ${(props) => props.theme.colors.dark100};
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    row-gap: 6px;
    justify-content: center;
  }
`;

const Placeholder = styled.div<{ height: number; width: number }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.dark300};
`;

const Value = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    min-height: 28px;
    p,
    & > p {
      ${(props) => props.theme.styles.textParagraph}
      & {
        font-weight: 500px;
        word-break: normal;
      }
    }

    &[data-to="true"] {
      &:hover,
      &:active {
        p {
          text-decoration: underline;
        }
      }
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxXS} {
    align-items: flex-start;
    ${Frame} {
      --size: 18px;
      --thickness: 2.2px;
      width: 36px;
      height: 36px;
      border-radius: 6px;
    }
  }

  ${(props) => props.theme.medias.max(290)} {
    align-items: flex-start;
    ${Frame} {
      --size: 18px;
      --thickness: 2.2px;
      width: 20px;
      height: 20px;
      border-radius: 0px;
      background: transparent;
    }
  }
`;

function Loading({ className }: { className?: string }) {
  return (
    <Wrapper className={className}>
      <Frame data-component={"frame"} />
      <Content data-component={"content"}>
        <Placeholder height={18} width={60} />
        <Value data-component={"value"}>
          <Placeholder height={18} width={100} />
        </Value>
      </Content>
    </Wrapper>
  );
}

interface Props {
  className?: string;
  label?: string;
  labelIcon?: ComponentProps<typeof Label>["icon"];
  icon?: ComponentProps<typeof Icon>["value"];
  iconPurpose?: ComponentProps<typeof Icon>["purpose"];
  isLoading?: boolean;
  toPage?: string;
  value?: string;
}

export default function Detail({
  children,
  className,
  label,
  labelIcon,
  icon,
  iconPurpose = "heroicon",
  isLoading = false,
  toPage,
  value,
}: PropsWithChildren<Props>) {
  if (isLoading) {
    return <Loading className={className} />;
  }
  return (
    <Wrapper className={className}>
      <Frame data-component={"frame"}>
        {!_.isNil(icon) && (
          <Icon purpose={iconPurpose} value={icon} size={24} thickness={2} />
        )}
      </Frame>
      <Content data-component={"content"}>
        {label && !_.isNilOrEmptyString(label) ? (
          <Label value={label} icon={labelIcon} isIconLast />
        ) : (
          false
        )}
        {toPage ? (
          <Internal to={toPage}>
            <Value data-to={true}>
              {_.isString(value) ? <p>{value}</p> : children}
            </Value>
          </Internal>
        ) : (
          <Value data-component={"value"}>
            {_.isString(value) ? <p>{value}</p> : children}
          </Value>
        )}
      </Content>
    </Wrapper>
  );
}
