import { EXPONENT_DECIMALS, StreamCategory } from "@sablier/v2-constants";
import { guards } from "@sablier/v2-machines";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Stream, Token } from "@sablier/v2-models";
import type { IDynamicUnlockCliff } from "./config";
import type {
  IExtensionCertify,
  IExtensionCheck,
  IExtensionParamsSimulate,
  IPrecomputeParams,
  IPrecomputeResult,
} from "~/client/types";
import { EXPECTED_SEGMENTS, UNLOCK_DURATION } from "./config";

type IExtension = IDynamicUnlockCliff;

export function certify({
  t,
  data,
  amount,
  duration,
  start,
  end,
  field,
}: IExtensionCertify): string | undefined {
  const fields = _.omit(data as IExtension, ["purpose"]);

  if (field === "unlockDuration") {
    return guards.validateDuration({
      t,
      purpose: "cliff",
      max: duration,
      min: "0",
      value: fields.unlockDuration.value,
    });
  }

  if (field === "unlockEnd") {
    return guards.validateMoment({
      t,
      purpose: "cliff",
      min: start,
      value: fields.unlockEnd.value,
      max: end,
    });
  }

  if (field === "unlock") {
    return guards.validateAmount({
      t,
      context: "wallet",
      max: amount,
      value: fields.unlock.value,
      min: "0",
    });
  }

  return undefined;
}

export function check({
  t,
  data,
  isLoadingIncluded = false,
  isWarningIncluded = false,
  timing,
}: IExtensionCheck): string | undefined {
  const fields = _.omit(data as IExtension, ["purpose"]);

  const flags = guards.validateFormFlags({
    t,
    isWarningIncluded,
    isLoadingIncluded,
    value: fields,
  });

  if (!_.isNilOrEmptyString(flags)) {
    return flags;
  }

  const ids: (keyof typeof fields)[] =
    timing === "duration"
      ? ["unlockDuration", "unlock"]
      : ["unlockEnd", "unlock"];

  const required = guards.validateFormRequired({
    t,
    required: ids,
    value: fields,
  });

  if (!_.isNilOrEmptyString(required)) {
    return required;
  }

  return undefined;
}

export function identify(stream: Stream): boolean {
  return (
    stream.category === StreamCategory.LOCKUP_DYNAMIC &&
    stream.segments.length === EXPECTED_SEGMENTS &&
    new BigNumber(stream.segments[0].duration).isEqualTo(
      new BigNumber(UNLOCK_DURATION),
    ) &&
    stream.segments[1].amount.raw.isZero() &&
    new BigNumber(stream.segments[2].duration).isEqualTo(
      new BigNumber(UNLOCK_DURATION),
    ) &&
    stream.segments[3].exponent.humanized.isEqualTo(new BigNumber(1))
  );
}

export function precomputeSingle({
  dependencies,
}: IPrecomputeParams<"single">): IPrecomputeResult {
  const { amount, token } = dependencies;

  return {
    amount: _.toValuePrepared({
      decimals: token!.decimals,
      humanized: amount,
    }),
  };
}

export function precomputeGroup({
  dependencies,
}: IPrecomputeParams<"group">): IPrecomputeResult {
  const { streams, token } = dependencies;

  return {
    amount: _.toValuePrepared({
      humanized: streams?.reduce(
        (p, c) => p.plus(new BigNumber(c.amount || 0)),
        new BigNumber(0),
      ),
      decimals: token?.decimals,
    }),
  };
}

export const precompute = { group: precomputeGroup, single: precomputeSingle };

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */
export function simulate(
  params: IExtensionParamsSimulate & { timing: "duration" },
): Stream;
export function simulate(
  params: IExtensionParamsSimulate & { timing: "range" },
): Stream;
export function simulate(params: IExtensionParamsSimulate): Stream;

export function simulate({
  dependencies,
  extras,
  timing,
}: IExtensionParamsSimulate): Stream {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const { purpose: _purpose, ...extended } = extras as IExtension;
  const {
    amount,
    cancelability,
    transferability,
    chainId,
    duration,
    end,
    start,
    token,
  } = dependencies;

  const initialDeposit = _.toValuePrepared({
    humanized: amount,
    decimals: token?.decimals,
  });

  const initialUnlock = _.toValuePrepared({
    decimals: token!.decimals,
    humanized: extended.unlock.value,
  });

  const remainingDeposit = new BigNumber(initialDeposit)
    .minus(new BigNumber(initialUnlock))
    .toString();

  const unlock = new BigNumber(UNLOCK_DURATION).dividedBy(1000);

  const streamToken = new Token({
    address: token!.address,
    chainId,
    decimals: token!.decimals,
    name: token!.name,
    symbol: token!.symbol,
  });

  const defaultExp = _.toValuePrepared({
    humanized: "1",
    decimals: EXPONENT_DECIMALS,
  });

  const streamStartTime =
    timing === "duration"
      ? _.toSeconds(new BigNumber(Date.now()).toString())
      : _.toSeconds(start);

  const cliffStartTime = (_.toNumber(streamStartTime) + 1).toString();

  const endTime =
    timing === "duration"
      ? _.toSeconds(
          new BigNumber(Date.now()).plus(new BigNumber(duration!)).toString(),
        )
      : _.toSeconds(end);

  const cliffTime =
    timing === "duration"
      ? _.toSeconds(
          new BigNumber(Date.now())
            .plus(new BigNumber(extended.unlockDuration.value!))
            .toString(),
        )
      : _.toSeconds(extended.unlockEnd.value);

  const cliffD = new BigNumber(cliffTime).minus(new BigNumber(cliffStartTime));
  const totalD = new BigNumber(endTime).minus(new BigNumber(cliffStartTime));
  const cliffAmount = new BigNumber(remainingDeposit)
    .times(cliffD)
    .dividedBy(totalD);
  const remainingAmount = new BigNumber(remainingDeposit).minus(cliffAmount);

  return new Stream(
    {
      ...Stream.base(),
      chainId,
      cancelable: cancelability!,
      transferable: transferability!,
      category: StreamCategory.LOCKUP_DYNAMIC,
      depositAmount: initialDeposit,
      endTime,
      intactAmount: initialDeposit,
      startTime: streamStartTime,
      segments: [
        {
          id: "0",
          position: "0",
          amount: initialUnlock,
          milestone: "",
          endAmount: initialUnlock,
          endTime: cliffStartTime,
          startTime: streamStartTime,
          startAmount: "0",
          exponent: defaultExp,
        },
        {
          id: "1",
          position: "1",
          amount: "0",
          milestone: "",
          endAmount: initialUnlock,
          endTime: cliffTime,
          startTime: cliffStartTime,
          startAmount: initialUnlock,
          exponent: defaultExp,
        },
        {
          id: "2",
          position: "2",
          amount: cliffAmount.toString(),
          milestone: "",
          endAmount: cliffAmount.plus(new BigNumber(initialUnlock)).toString(),
          endTime: new BigNumber(cliffTime).plus(unlock).toString(),
          startTime: cliffTime,
          startAmount: initialUnlock,
          exponent: defaultExp,
        },
        {
          id: "3",
          position: "3",
          amount: remainingAmount.toString(),
          milestone: "",
          endAmount: initialDeposit,
          endTime,
          startTime: new BigNumber(cliffTime).plus(unlock).toString(),
          startAmount: cliffAmount
            .plus(new BigNumber(initialUnlock))
            .toString(),
          exponent: defaultExp,
        },
      ],
    },
    streamToken,
  );
}
