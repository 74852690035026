import { REBASING_TOKENS } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import type { Translate } from "@sablier/v2-locales";
import type { IToken } from "@sablier/v2-types";
import { validateAddress } from "./address";
import policy from "./policy";

interface TokenValidProps {
  t: Translate;
  value: Pick<IToken, "address" | "chainId"> | undefined;
}

export function validateToken({ t, value }: TokenValidProps) {
  try {
    if (_.isNilOrEmptyString(value)) {
      return policy.token.missing(t);
    }

    const warning = validateAddress({ t, value: value.address });
    if (warning) {
      return warning;
    }

    if (
      REBASING_TOKENS.some(
        (t) =>
          t.chainId === value.chainId &&
          _.toLower(t.address) === _.toLower(value.address),
      )
    ) {
      return policy.token.rebasing(t);
    }
  } catch (_e) {
    return policy.token.missing(t);
  }
  return undefined;
}
