import {
  DEFAULT_BROKER_ADDRESS,
  DEFAULT_BROKER_FEE_PERCENTAGE,
  PERCENTAGE_DECIMALS,
} from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { contractor, peripheral } from "~/client/utils";
import type { ITranchedStepper } from "./config";
import type { IAddress, ISeconds, IWagmiAddress } from "@sablier/v2-types";
import type {
  IExtensionParamsAirstream,
  IExtensionParamsGroup,
  IExtensionParamsSingle,
  IExtensionResultAirstreamLT,
  IExtensionResultDurationsLT,
  IExtensionResultTimestampsLT,
} from "~/client/types";
import { precompute } from "./setup";

type IExtension = ITranchedStepper;

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */

async function processSingle(
  params: IExtensionParamsSingle & { timing: "duration" },
): Promise<{
  batch: IExtensionResultDurationsLT;
}>;
async function processSingle(
  params: IExtensionParamsSingle & { timing: "range" },
): Promise<{
  batch: IExtensionResultTimestampsLT;
}>;
async function processSingle(params: IExtensionParamsSingle): Promise<{
  batch: IExtensionResultTimestampsLT | IExtensionResultDurationsLT;
}>;

async function processSingle({
  dependencies,
  extras,
  timing,
}: IExtensionParamsSingle): Promise<{
  batch: IExtensionResultTimestampsLT | IExtensionResultDurationsLT;
}> {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const { purpose, ...extended } = extras as IExtension;
  const {
    address,
    amount,
    cancelability,
    transferability,
    chainId,
    duration,
    end,
    sender,
    start,
    token,
  } = dependencies;

  const batch: IAddress = peripheral(chainId, "batch").address;
  const lockup: IAddress = contractor(
    chainId!,
    purpose,
    token?.address,
  ).address;

  const steps = new BigNumber(extended.steps.value || 0);
  const raw = _.toValuePrepared({
    humanized: amount,
    decimals: token!.decimals,
  });
  const increment = _.toValuePrepared({
    raw: new BigNumber(raw).dividedBy(steps),
    decimals: token?.decimals,
  });
  const { amount: deposit } = precompute.single({ dependencies, extras });

  /**
   * ------------------------------
   * Setup dependencies: DYNAMIC
   * ------------------------------
   */

  const N = [...Array(steps.toNumber()).keys()];
  const trancheAmounts = N.map(() =>
    _.toBigInt(
      _.toValuePrepared({
        raw: increment,
        decimals: token!.decimals,
      }),
    ),
  );

  /**
   * ------------------------------
   * Prepare transaction parameters
   * ------------------------------
   */

  if (timing === "duration") {
    type Inputs = IExtensionResultDurationsLT["inputs"];

    const travel: ISeconds = _.toSeconds(
      new BigNumber(duration || "0").dividedBy(steps),
    );
    const trancheDurations = N.map(() => _.toNumber(travel));

    const tranches = N.map((i) => ({
      amount: trancheAmounts[i],
      duration: trancheDurations[i],
    }));

    const inputs: Inputs = [
      lockup as IWagmiAddress,
      token!.address as IWagmiAddress,
      [
        {
          sender: sender as IWagmiAddress,
          recipient: address as IWagmiAddress,
          totalAmount: _.toBigInt(deposit),
          tranches,
          cancelable: !!cancelability,
          transferable: !!transferability,
          broker: {
            account: DEFAULT_BROKER_ADDRESS as IWagmiAddress,
            fee: _.toBigInt(DEFAULT_BROKER_FEE_PERCENTAGE),
          },
        },
      ],
    ];

    const data = framework.contextualize(
      batch,
      chainId!,
      "batch",
      "createWithDurationsLT",
      inputs,
    );

    return {
      batch: data,
    };
  } else {
    type Inputs = IExtensionResultTimestampsLT["inputs"];

    const startTime = _.toSeconds(start);
    const endTime: ISeconds = _.toSeconds(end);
    const travel: ISeconds = new BigNumber(endTime)
      .minus(new BigNumber(startTime))
      .dividedBy(steps)
      .toString();

    /** Durations in pairs of two: a flat surface (the step cliff) and a vertical line (the step unlock) lasting for 1s */
    const trancheTimestamps = N.map((i) =>
      _.toNumber(
        new BigNumber(startTime)
          .plus(new BigNumber(travel).times(i + 1))
          .toFixed(0),
      ),
    );

    const tranches = N.map((i) => ({
      amount: trancheAmounts[i],
      timestamp: trancheTimestamps[i],
    }));

    const inputs: Inputs = [
      lockup as IWagmiAddress,
      token!.address as IWagmiAddress,
      [
        {
          sender: sender as IWagmiAddress,
          recipient: address as IWagmiAddress,
          totalAmount: _.toBigInt(deposit),
          startTime: _.toNumber(startTime),
          tranches,
          cancelable: !!cancelability,
          transferable: !!transferability,
          broker: {
            account: DEFAULT_BROKER_ADDRESS as IWagmiAddress,
            fee: _.toBigInt(DEFAULT_BROKER_FEE_PERCENTAGE),
          },
        },
      ],
    ];

    const data = framework.contextualize(
      batch,
      chainId!,
      "batch",
      "createWithTimestampsLT",
      inputs,
    );

    return {
      batch: data,
    };
  }
}

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */

async function processGroup(
  params: IExtensionParamsGroup & { timing: "duration" },
): Promise<{
  batch: IExtensionResultDurationsLT;
}>;
async function processGroup(
  params: IExtensionParamsGroup & { timing: "range" },
): Promise<{
  batch: IExtensionResultTimestampsLT;
}>;
async function processGroup(params: IExtensionParamsGroup): Promise<{
  batch: IExtensionResultDurationsLT | IExtensionResultTimestampsLT;
}>;

async function processGroup({
  dependencies,
  purpose,
  timing,
  library,
}: IExtensionParamsGroup): Promise<{
  batch: IExtensionResultDurationsLT | IExtensionResultTimestampsLT;
}> {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const {
    cancelability,
    transferability,
    chainId,
    sender,
    token,
    streams,
    signer,
  } = dependencies;

  const batch: IAddress = peripheral(chainId, "batch").address;
  const lockup = contractor(chainId, purpose, token?.address).address;
  /**
   * ------------------------------
   * Prepare transaction parameters
   * ------------------------------
   */

  if (timing === "duration") {
    type Inputs = IExtensionResultDurationsLT["inputs"];

    const params: Inputs["2"] = await Promise.all(
      streams?.map(async (stream) => {
        const { address, amount, duration, end, start } = stream;

        const single = await processSingle({
          dependencies: {
            address,
            amount,
            cancelability,
            transferability,
            duration,
            end,
            token,
            start,
            chainId,
            sender,
            signer,
          },
          extras: stream.extension,
          timing: "duration",
          library,
        });

        return single.batch.inputs[2][0];
      }) || [],
    );

    const inputs: Inputs = [
      lockup as IWagmiAddress,
      token!.address as IWagmiAddress,
      params,
    ] as const;

    const data = framework.contextualize(
      batch,
      chainId!,
      "batch",
      "createWithDurationsLT",
      inputs,
    );

    return {
      batch: data,
    };
  } else {
    type Inputs = IExtensionResultTimestampsLT["inputs"];

    const params: Inputs["2"] = await Promise.all(
      streams?.map(async (stream) => {
        const { address, amount, duration, end, start } = stream;

        const single = await processSingle({
          dependencies: {
            address,
            amount,
            cancelability,
            transferability,
            duration,
            end,
            token,
            start,
            chainId,
            sender,
            signer,
          },
          extras: stream.extension,
          timing: "range",
          library,
        });

        return single.batch.inputs[2][0];
      }) || [],
    );

    const inputs: Inputs = [
      lockup as IWagmiAddress,
      token!.address as IWagmiAddress,
      params,
    ];

    const data = framework.contextualize(
      batch,
      chainId!,
      "batch",
      "createWithTimestampsLT",
      inputs,
    );

    return {
      batch: data,
    };
  }
}

async function processAirstream({
  dependencies,
  extras,
}: IExtensionParamsAirstream): Promise<{
  factory: IExtensionResultAirstreamLT;
}> {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const { purpose, ...extended } = extras as IExtension;
  const {
    calldata,
    cancelability,
    expiration,
    name,
    chainId,
    duration,
    sender,
    transferability,
    token,
  } = dependencies;

  const factory: IAddress = peripheral(chainId, "merkleLockupFactory").address;
  const lockup: IAddress = contractor(
    chainId!,
    purpose,
    token?.address,
  ).address;

  const steps = new BigNumber(extended.steps.value || 0);
  const raw = _.toValuePrepared({
    humanized: "1",
    decimals: PERCENTAGE_DECIMALS,
  });

  const N = [...Array(steps.toNumber()).keys()];

  const increment = _.toValuePrepared({
    raw: new BigNumber(raw).dividedBy(steps),
    decimals: PERCENTAGE_DECIMALS,
  });

  const tranchedPercentages = N.slice(0, -1).map(() =>
    _.toBigInt(
      _.toValuePrepared({
        raw: increment,
        decimals: PERCENTAGE_DECIMALS,
      }),
    ),
  );

  const percentageBeforeFinal = new BigNumber(increment).times(steps.minus(1));
  const percentageFinal = new BigNumber(raw).minus(percentageBeforeFinal);

  /** The last percentage has to account for rounding errors (e.g. 10 / 3 => [3.333, 3.333, __3.334__]) */
  tranchedPercentages.push(
    _.toBigInt(
      _.toValuePrepared({
        raw: percentageFinal,
        decimals: PERCENTAGE_DECIMALS,
      }),
    ),
  );

  const travel: ISeconds = _.toValuePrepared({
    raw: new BigNumber(duration || "0").dividedBy(steps),
    decimals: 0,
  });
  const trancheDurations = N.map(() => _.toNumber(travel));

  /**
   * ------------------------------
   * Prepare transaction parameters
   * ------------------------------
   */

  type Inputs = IExtensionResultAirstreamLT["inputs"];

  const tranches = N.map((i) => ({
    unlockPercentage: tranchedPercentages[i],
    duration: trancheDurations[i],
  }));

  const inputs: Inputs = [
    {
      asset: token!.address as IWagmiAddress,
      cancelable: cancelability!,
      expiration: _.toNumber(expiration),
      initialAdmin: sender as IWagmiAddress,
      ipfsCID: calldata.cid,
      merkleRoot: calldata.root as IWagmiAddress,
      name: name ?? "",
      transferable: transferability!,
    },
    lockup as IWagmiAddress,
    tranches,
    _.toBigInt(calldata.total),
    _.toBigInt(calldata.recipients),
  ];

  const data = framework.contextualize(
    factory,
    chainId,
    "merkleLockupFactory",
    "createMerkleLT",
    inputs,
  );

  return {
    factory: data,
  };
}

export const process = {
  airstream: processAirstream,
  group: processGroup,
  single: processSingle,
};
