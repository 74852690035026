import { Label } from "~/components/molecules";
import type { Props as SideProps } from "./Side";
import type { IInput, IInputValue } from "@sablier/v2-types";
import type { ComponentProps } from "react";

export * from "./Blocks";
export { default as Side } from "./Side";
export { default as Sides } from "./Sides";
export type { Props as SideProps } from "./Side";

export type ISInput<TValue = IInputValue> = IInput<
  SideProps,
  ComponentProps<typeof Label>,
  TValue
>;
