import {
  DEFAULT_BROKER_ADDRESS,
  DEFAULT_BROKER_FEE_PERCENTAGE,
} from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { contractor, peripheral } from "~/client/utils";
import type { ITranchedBackweighted } from "./config";
import type {
  IAddress,
  IMilliseconds,
  ISeconds,
  IWagmiAddress,
} from "@sablier/v2-types";
import type {
  IExtensionParamsGroup,
  IExtensionParamsSingle,
  IExtensionResultDurationsLT,
  IExtensionResultTimestampsLT,
} from "~/client/types";
import { precompute } from "./setup";

type IExtension = ITranchedBackweighted;

/**
 * ------------------------------
 * Explicit function overloads not needed.
 * ------------------------------
 */

async function processSingle(
  params: IExtensionParamsSingle & { timing: "duration" },
): Promise<{
  batch: IExtensionResultDurationsLT;
}>;
async function processSingle(
  params: IExtensionParamsSingle & { timing: "range" },
): Promise<{
  batch: IExtensionResultTimestampsLT;
}>;
async function processSingle(params: IExtensionParamsSingle): Promise<{
  batch: IExtensionResultTimestampsLT | IExtensionResultDurationsLT;
}>;

async function processSingle({
  dependencies,
  extras,
}: IExtensionParamsSingle): Promise<{
  batch: IExtensionResultTimestampsLT | IExtensionResultDurationsLT;
}> {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const { purpose, ...extended } = extras as IExtension;
  const {
    address,
    amount,
    cancelability,
    transferability,
    chainId,
    sender,
    start,
    token,
  } = dependencies;

  const batch: IAddress = peripheral(chainId, "batch").address;
  const lockup: IAddress = contractor(
    chainId!,
    purpose,
    token?.address,
  ).address;

  const years = new BigNumber(extended.years.value || 0);
  const unlocks = extended.unlocks.value;
  const raw = _.toValuePrepared({
    humanized: amount,
    decimals: token!.decimals,
  });

  const { amount: deposit } = precompute.single({ dependencies, extras });

  /**
   * ------------------------------
   * Setup dependencies: DYNAMIC
   * ------------------------------
   */

  const N = [...Array(years.toNumber()).keys()];
  const trancheAmounts = unlocks.map((unlock) =>
    _.toBigInt(
      _.toValuePrepared({
        raw: new BigNumber(raw)
          .multipliedBy(new BigNumber(unlock.percentage ?? "0"))
          .dividedBy(new BigNumber(100)),
        decimals: token!.decimals,
      }),
    ),
  );

  /**
   * ------------------------------
   * Prepare transaction parameters
   * ------------------------------
   */

  type Inputs = IExtensionResultTimestampsLT["inputs"];

  const startTime: ISeconds = _.toValuePrepared({
    humanized: start,
    decimals: -3,
  });

  const trancheTimestamps = N.map((i) => {
    const milestone: IMilliseconds = _.addCalendarUnit(
      start!,
      (i + 1).toString(),
      "year",
    );
    const horizontal: ISeconds = _.toValuePrepared({
      humanized: milestone,
      decimals: -3,
    });

    return _.toNumber(horizontal);
  }).flat();

  const tranches = N.map((i) => ({
    amount: trancheAmounts[i],
    timestamp: trancheTimestamps[i],
  }));

  const inputs: Inputs = [
    lockup as IWagmiAddress,
    token!.address as IWagmiAddress,
    [
      {
        sender: sender as IWagmiAddress,
        recipient: address as IWagmiAddress,
        totalAmount: _.toBigInt(deposit),
        startTime: _.toNumber(startTime),
        tranches,
        cancelable: !!cancelability,
        transferable: !!transferability,
        broker: {
          account: DEFAULT_BROKER_ADDRESS as IWagmiAddress,
          fee: _.toBigInt(DEFAULT_BROKER_FEE_PERCENTAGE),
        },
      },
    ],
  ];

  const data = framework.contextualize(
    batch,
    chainId!,
    "batch",
    "createWithTimestampsLT",
    inputs,
  );

  return {
    batch: data,
  };
}

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */

async function processGroup(
  params: IExtensionParamsGroup & { timing: "duration" },
): Promise<{
  batch: IExtensionResultDurationsLT;
}>;
async function processGroup(
  params: IExtensionParamsGroup & { timing: "range" },
): Promise<{
  batch: IExtensionResultTimestampsLT;
}>;
async function processGroup(params: IExtensionParamsGroup): Promise<{
  batch: IExtensionResultDurationsLT | IExtensionResultTimestampsLT;
}>;

async function processGroup({
  dependencies,
  purpose,
  library,
}: IExtensionParamsGroup): Promise<{
  batch: IExtensionResultDurationsLT | IExtensionResultTimestampsLT;
}> {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const {
    cancelability,
    transferability,
    chainId,
    sender,
    token,
    streams,
    signer,
  } = dependencies;

  const batch: IAddress = peripheral(chainId, "batch").address;
  const lockup = contractor(chainId, purpose, token?.address).address;
  /**
   * ------------------------------
   * Prepare transaction parameters
   * ------------------------------
   */

  type Inputs = IExtensionResultTimestampsLT["inputs"];

  const params: Inputs["2"] = await Promise.all(
    streams?.map(async (stream) => {
      const { address, amount, duration, end, start } = stream;

      const single = await processSingle({
        dependencies: {
          address,
          amount,
          cancelability,
          transferability,
          duration,
          end,
          token,
          start,
          chainId,
          sender,
          signer,
        },
        extras: stream.extension,
        timing: "range",
        library,
      });

      return single.batch.inputs[2][0];
    }) || [],
  );

  const inputs: Inputs = [
    lockup as IWagmiAddress,
    token!.address as IWagmiAddress,
    params,
  ];

  const data = framework.contextualize(
    batch,
    chainId!,
    "batch",
    "createWithTimestampsLT",
    inputs,
  );

  return {
    batch: data,
  };
}

export const process = {
  group: processGroup,
  single: processSingle,
};
