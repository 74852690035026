import { useMemo } from "react";
import styled, { css } from "styled-components";
import TokenUnknown from "@sablier/v2-assets/tokens/custom-unknown-light.svg";
import { _ } from "@sablier/v2-mixins";
import { IImage } from "@sablier/v2-types";
import type { ThemeType } from "@sablier/v2-themes";
import Icon from "../Icon";

interface WrapperProps {
  sizeIcon?: number | string;
  sizeSymbol?: number | string;
  color?: keyof ThemeType["colors"];
}

const WrapperPartial = styled.div<WrapperProps>`
  ${(props) => props.theme.styles.row}
  & {
    height: var(--size, ${(props) => props.sizeIcon});
    column-gap: var(
      --gap,
      ${(props) =>
        props.sizeIcon && parseInt(props.sizeIcon + "", 10) < 26
          ? "4px"
          : "6px"}
    );
  }
`;

const Value = styled.div`
  & > p {
    ${(props) => props.theme.styles.textElement}
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${Value} > p {
    ${(props) =>
      props.color &&
      css`
        color: ${props.theme.colors[props.color] || props.color};
      `}

    ${(props) =>
      props.sizeSymbol &&
      css`
        font-size: ${props.sizeSymbol};
      `}
  }
`;

export interface Props {
  className?: string;
  empty?: keyof ThemeType["colors"];
  color?: keyof ThemeType["colors"];
  image?: IImage;
  isFallbackShown?: boolean;
  isIconShown?: boolean;
  /** Size of icon or size of [icon, title] */
  size?: number | [number, number];
  symbol?: string;
}

function Token({
  className,
  color,
  empty,
  image,
  isIconShown = true,
  isFallbackShown = false,
  symbol,
  size = 18,
}: Props) {
  const [sizeIcon, sizeSymbol] = useMemo(() => {
    if (_.isArray(size)) {
      return [_.toNumber(_.get(size, "0")), _.toNumber(_.get(size, "1"))];
    }
    return [_.toNumber(size), undefined];
  }, [size]);

  return (
    <Wrapper
      className={className}
      color={color}
      sizeIcon={sizeIcon && _.toSuffix(sizeIcon, "px")}
      sizeSymbol={sizeSymbol && _.toSuffix(sizeSymbol, "pt")}
    >
      {isIconShown && (
        <Icon
          purpose={image || isFallbackShown ? "image" : "empty"}
          value={image || isFallbackShown ? image ?? TokenUnknown : empty}
          size={sizeIcon}
        />
      )}
      {symbol && (
        <Value>
          <p>{symbol}</p>
        </Value>
      )}
    </Wrapper>
  );
}

export default Token;
