import { useMemo } from "react";
import { REQUEST_ID } from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { _ } from "@sablier/v2-mixins";
import { Airstream } from "@sablier/v2-models";
import { useQuery } from "@tanstack/react-query";
import { useConfig } from "wagmi";
import type { Output } from "@sablier/v2-contracts";
import type { IAirstreamId, IWagmiConfig } from "@sablier/v2-types";

interface Props {
  key?: string[];
  id: IAirstreamId | undefined;
  isEnabled?: boolean;
}

async function request({ id, library }: Props & { library: IWagmiConfig }) {
  const { address, chainId } = Airstream.doSplitIdentifier(id);

  if (
    !_.expect(id, "id") ||
    !_.expect(chainId, "chainId") ||
    !_.expect(address, "address")
  ) {
    throw new Error("Misconfigured (stream onchain).");
  }

  const queries = [
    /** Common attribute for MLL and MLT */
    framework.contextualize(address, chainId, "merkleLL", "admin", []),
    /** Common attribute for MLL and MLT */
    framework.contextualize(address, chainId, "merkleLL", "ASSET", []),
    framework.contextualize(address, chainId, "merkleLL", "LOCKUP_LINEAR", []),
    framework.contextualize(
      address,
      chainId,
      "merkleLT",
      "LOCKUP_TRANCHED",
      [],
    ),
  ];

  const previews = await framework.preview({ queries });
  const results = await framework.read(library, { previews });

  const admin = results[0].result as Output<"merkleLL", "admin"> | undefined;
  const asset = results[1].result as Output<"merkleLL", "ASSET"> | undefined;

  const LL = results[2].result as
    | Output<"merkleLL", "LOCKUP_LINEAR">
    | undefined;
  const LT = results[2].result as
    | Output<"merkleLT", "LOCKUP_TRANCHED">
    | undefined;

  const lockup = _.isNilOrEmptyString(LL) ? LT : LL;

  return {
    admin,
    asset,
    lockup,
  };
}

export default function useRequestAirstreamOnchain({
  key = REQUEST_ID.airstreamOnchain,
  id,
  isEnabled,
}: Props) {
  const library = useConfig();

  const { data, error, isLoading } = useQuery({
    queryKey: [...key, { unique: { id } }],
    queryFn: async () => request({ id, library }),
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: isEnabled,
    retry: false,
  });

  const isMissing = useMemo(() => {
    if (isLoading) {
      return false;
    }

    return (
      !_.isNilOrEmptyString(error) ||
      _.isNilOrEmptyString(data) ||
      _.isNilOrEmptyString(data?.admin)
    );
  }, [data, error, isLoading]);

  return {
    data,
    error,
    isLoading,
    isMissing,
  };
}
