import { gql } from "../../generated/airstream-envio/gql";

/**
 * AND (4)
 *
 * +==============================================================+
 * |                                                              |
 * |     █████╗ ███╗   ██╗██████╗      ██╗    ██╗  ██╗    ██╗     |
 * |    ██╔══██╗████╗  ██║██╔══██╗    ██╔╝    ██║  ██║    ╚██╗    |
 * |    ███████║██╔██╗ ██║██║  ██║    ██║     ███████║     ██║    |
 * |    ██╔══██║██║╚██╗██║██║  ██║    ██║     ╚════██║     ██║    |
 * |    ██║  ██║██║ ╚████║██████╔╝    ╚██╗         ██║    ██╔╝    |
 * |    ╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝      ╚═╝         ╚═╝    ╚═╝     |
 * |                                                              |
 * +==============================================================+
 *
 */

export const getAirstreams_ByAliveByAdminByIdsByName = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByAdminByIdsByName(
    $first: Int!
    $skip: Int!
    $admin: String!
    $airstreamIds: [String!]
    $subgraphId: numeric!
    $chainId: numeric!
    $currentTimestamp: numeric!
    $name: String!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { admin: { _eq: $admin } }
          { id: { _in: $airstreamIds } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
          {
            _or: [
              { expiration: { _gt: $currentTimestamp } }
              { expires: { _eq: false } }
            ]
          }
          { name: { _iregex: $name } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByAdminByAssetByName = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByAdminByAssetByName(
    $first: Int!
    $skip: Int!
    $chainId: numeric!
    $admin: String!
    $asset: String!
    $subgraphId: numeric!
    $excludeIds: [String!]
    $currentTimestamp: numeric!
    $name: String!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { admin: { _eq: $admin } }
          { id: { _nin: $excludeIds } }
          { asset_id: { _iregex: $asset } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
          {
            _or: [
              { expiration: { _gt: $currentTimestamp } }
              { expires: { _eq: false } }
            ]
          }
          { name: { _iregex: $name } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

/**
 * AND (3)
 *
 * +==============================================================+
 * |                                                              |
 * |     █████╗ ███╗   ██╗██████╗      ██╗    ██████╗     ██╗     |
 * |    ██╔══██╗████╗  ██║██╔══██╗    ██╔╝    ╚════██╗    ╚██╗    |
 * |    ███████║██╔██╗ ██║██║  ██║    ██║      █████╔╝     ██║    |
 * |    ██╔══██║██║╚██╗██║██║  ██║    ██║      ╚═══██╗     ██║    |
 * |    ██║  ██║██║ ╚████║██████╔╝    ╚██╗    ██████╔╝    ██╔╝    |
 * |    ╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝      ╚═╝    ╚═════╝     ╚═╝     |
 * |                                                              |
 * +==============================================================+
 */

export const getAirstreams_ByAdminByIdsByName = gql(/* GraphQL */ `
  query getAirstreams_ByAdminByIdsByName(
    $first: Int!
    $skip: Int!
    $admin: String!
    $airstreamIds: [String!]
    $subgraphId: numeric!
    $chainId: numeric!
    $name: String!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { admin: { _eq: $admin } }
          { id: { _in: $airstreamIds } }
          { chainId: { _eq: $chainId } }
          { subgraphId: { _lt: $subgraphId } }
          { name: { _iregex: $name } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAdminByAssetByName = gql(/* GraphQL */ `
  query getAirstreams_ByAdminByAssetByName(
    $first: Int!
    $skip: Int!
    $chainId: numeric!
    $admin: String!
    $asset: String!
    $excludeIds: [String!]
    $subgraphId: numeric!
    $name: String!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { admin: { _eq: $admin } }
          { id: { _nin: $excludeIds } }
          { asset_id: { _iregex: $asset } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
          { name: { _iregex: $name } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByAdminByName = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByAdminByName(
    $first: Int!
    $skip: Int!
    $admin: String!
    $chainId: numeric!
    $subgraphId: numeric!
    $excludeIds: [String!]
    $currentTimestamp: numeric!
    $name: String!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { admin: { _eq: $admin } }
          { id: { _nin: $excludeIds } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
          {
            _or: [
              { expiration: { _gt: $currentTimestamp } }
              { expires: { _eq: false } }
            ]
          }
          { name: { _iregex: $name } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByIdsByName = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByIdsByName(
    $first: Int!
    $skip: Int!
    $chainId: numeric!
    $airstreamIds: [String!]
    $subgraphId: numeric!
    $currentTimestamp: numeric!
    $name: String!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { id: { _in: $airstreamIds } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
          {
            _or: [
              { expiration: { _gt: $currentTimestamp } }
              { expires: { _eq: false } }
            ]
          }
          { name: { _iregex: $name } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByAssetByName = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByAssetByName(
    $first: Int!
    $skip: Int!
    $chainId: numeric!
    $asset: String!
    $subgraphId: numeric!
    $excludeIds: [String!]
    $currentTimestamp: numeric!
    $name: String!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { asset_id: { _iregex: $asset } }
          { id: { _nin: $excludeIds } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
          {
            _or: [
              { expiration: { _gt: $currentTimestamp } }
              { expires: { _eq: false } }
            ]
          }
          { name: { _iregex: $name } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByAdminByIds = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByAdminByIds(
    $first: Int!
    $skip: Int!
    $admin: String!
    $airstreamIds: [String!]
    $subgraphId: numeric!
    $chainId: numeric!
    $currentTimestamp: numeric!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { admin: { _eq: $admin } }
          { id: { _in: $airstreamIds } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
          {
            _or: [
              { expiration: { _gt: $currentTimestamp } }
              { expires: { _eq: false } }
            ]
          }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByAdminByAsset = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByAdminByAsset(
    $first: Int!
    $skip: Int!
    $chainId: numeric!
    $admin: String!
    $asset: String!
    $subgraphId: numeric!
    $excludeIds: [String!]
    $currentTimestamp: numeric!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { admin: { _eq: $admin } }
          { id: { _nin: $excludeIds } }
          { asset_id: { _iregex: $asset } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
          {
            _or: [
              { expiration: { _gt: $currentTimestamp } }
              { expires: { _eq: false } }
            ]
          }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

/**
 * AND (2)
 *
 * +==============================================================+
 * |                                                              |
 * |     █████╗ ███╗   ██╗██████╗      ██╗    ██████╗     ██╗     |
 * |    ██╔══██╗████╗  ██║██╔══██╗    ██╔╝    ╚════██╗    ╚██╗    |
 * |    ███████║██╔██╗ ██║██║  ██║    ██║      █████╔╝     ██║    |
 * |    ██╔══██║██║╚██╗██║██║  ██║    ██║     ██╔═══╝      ██║    |
 * |    ██║  ██║██║ ╚████║██████╔╝    ╚██╗    ███████╗    ██╔╝    |
 * |    ╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝      ╚═╝    ╚══════╝    ╚═╝     |
 * |                                                              |
 * +==============================================================+
 */

export const getAirstreams_ByAliveByName = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByName(
    $first: Int!
    $skip: Int!
    $chainId: numeric!
    $currentTimestamp: numeric!
    $excludeIds: [String!]
    $subgraphId: numeric!
    $name: String!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { subgraphId: { _lt: $subgraphId } }
          { id: { _nin: $excludeIds } }
          { chainId: { _eq: $chainId } }
          {
            _or: [
              { expiration: { _gt: $currentTimestamp } }
              { expires: { _eq: false } }
            ]
          }
          { name: { _iregex: $name } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAdminByName = gql(/* GraphQL */ `
  query getAirstreams_ByAdminByName(
    $first: Int!
    $skip: Int!
    $admin: String!
    $subgraphId: numeric!
    $chainId: numeric!
    $excludeIds: [String!]
    $asset: String # Required for compatibility
    $name: String!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { admin: { _eq: $admin } }
          { id: { _nin: $excludeIds } }
          { chainId: { _eq: $chainId } }
          { subgraphId: { _lt: $subgraphId } }
          { name: { _iregex: $name } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByIdsByName = gql(/* GraphQL */ `
  query getAirstreams_ByIdsByName(
    $first: Int!
    $skip: Int!
    $airstreamIds: [String!]
    $subgraphId: numeric!
    $chainId: numeric!
    $asset: String # Required for compatibility
    $name: String!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { id: { _in: $airstreamIds } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
          { name: { _iregex: $name } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByCIDsByName = gql(/* GraphQL */ `
  query getAirstreams_ByCIDsByName(
    $first: Int!
    $skip: Int!
    $airstreamCIDs: [String!]
    $subgraphId: numeric!
    $chainId: numeric!
    $excludeIds: [String!]
    $asset: String # Required for compatibility
    $name: String!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { ipfsCID: { _in: $airstreamCIDs } }
          { id: { _nin: $excludeIds } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
          { name: { _iregex: $name } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAssetByName = gql(/* GraphQL */ `
  query getAirstreams_ByAssetByName(
    $first: Int!
    $skip: Int!
    $asset: String!
    $subgraphId: numeric!
    $excludeIds: [String!]
    $chainId: numeric!
    $name: String!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { asset_id: { _iregex: $asset } }
          { id: { _nin: $excludeIds } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
          { name: { _iregex: $name } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAdminByIds = gql(/* GraphQL */ `
  query getAirstreams_ByAdminByIds(
    $first: Int!
    $skip: Int!
    $admin: String!
    $airstreamIds: [String!]
    $subgraphId: numeric!
    $chainId: numeric!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { admin: { _eq: $admin } }
          { id: { _in: $airstreamIds } }
          { chainId: { _eq: $chainId } }
          { subgraphId: { _lt: $subgraphId } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAdminByAsset = gql(/* GraphQL */ `
  query getAirstreams_ByAdminByAsset(
    $first: Int!
    $skip: Int!
    $chainId: numeric!
    $admin: String!
    $asset: String!
    $excludeIds: [String!]
    $subgraphId: numeric!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { admin: { _eq: $admin } }
          { id: { _nin: $excludeIds } }
          { asset_id: { _iregex: $asset } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByAdmin = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByAdmin(
    $first: Int!
    $skip: Int!
    $admin: String!
    $chainId: numeric!
    $subgraphId: numeric!
    $excludeIds: [String!]
    $currentTimestamp: numeric!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { admin: { _eq: $admin } }
          { id: { _nin: $excludeIds } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
          {
            _or: [
              { expiration: { _gt: $currentTimestamp } }
              { expires: { _eq: false } }
            ]
          }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByIds = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByIds(
    $first: Int!
    $skip: Int!
    $chainId: numeric!
    $airstreamIds: [String!]
    $subgraphId: numeric!
    $currentTimestamp: numeric!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { id: { _in: $airstreamIds } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
          {
            _or: [
              { expiration: { _gt: $currentTimestamp } }
              { expires: { _eq: false } }
            ]
          }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAliveByAsset = gql(/* GraphQL */ `
  query getAirstreams_ByAliveByAsset(
    $first: Int!
    $skip: Int!
    $chainId: numeric!
    $asset: String!
    $subgraphId: numeric!
    $excludeIds: [String!]
    $currentTimestamp: numeric!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { asset_id: { _iregex: $asset } }
          { id: { _nin: $excludeIds } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
          {
            _or: [
              { expiration: { _gt: $currentTimestamp } }
              { expires: { _eq: false } }
            ]
          }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

/**
 * ONE
 *
 * +===================================+
 * |                                   |
 * |     ██████╗ ███╗   ██╗███████╗    |
 * |    ██╔═══██╗████╗  ██║██╔════╝    |
 * |    ██║   ██║██╔██╗ ██║█████╗      |
 * |    ██║   ██║██║╚██╗██║██╔══╝      |
 * |    ╚██████╔╝██║ ╚████║███████╗    |
 * |     ╚═════╝ ╚═╝  ╚═══╝╚══════╝    |
 * |                                   |
 * +===================================+
 */

export const getAirstreams_ByAlive = gql(/* GraphQL */ `
  query getAirstreams_ByAlive(
    $first: Int!
    $skip: Int!
    $chainId: numeric!
    $currentTimestamp: numeric!
    $excludeIds: [String!]
    $subgraphId: numeric!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { subgraphId: { _lt: $subgraphId } }
          { id: { _nin: $excludeIds } }
          { chainId: { _eq: $chainId } }
          {
            _or: [
              { expiration: { _gt: $currentTimestamp } }
              { expires: { _eq: false } }
            ]
          }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAdmin = gql(/* GraphQL */ `
  query getAirstreams_ByAdmin(
    $first: Int!
    $skip: Int!
    $admin: String!
    $subgraphId: numeric!
    $chainId: numeric!
    $excludeIds: [String!]
    $asset: String # Required for compatibility
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { admin: { _eq: $admin } }
          { id: { _nin: $excludeIds } }
          { chainId: { _eq: $chainId } }
          { subgraphId: { _lt: $subgraphId } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByIds = gql(/* GraphQL */ `
  query getAirstreams_ByIds(
    $first: Int!
    $skip: Int!
    $airstreamIds: [String!]
    $subgraphId: numeric!
    $chainId: numeric!
    $asset: String # Required for compatibility
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { id: { _in: $airstreamIds } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByCIDs = gql(/* GraphQL */ `
  query getAirstreams_ByCIDs(
    $first: Int!
    $skip: Int!
    $airstreamCIDs: [String!]
    $subgraphId: numeric!
    $chainId: numeric!
    $excludeIds: [String!]
    $asset: String # Required for compatibility
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { ipfsCID: { _in: $airstreamCIDs } }
          { id: { _nin: $excludeIds } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByAsset = gql(/* GraphQL */ `
  query getAirstreams_ByAsset(
    $first: Int!
    $skip: Int!
    $asset: String!
    $subgraphId: numeric!
    $excludeIds: [String!]
    $chainId: numeric!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { asset_id: { _iregex: $asset } }
          { id: { _nin: $excludeIds } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstreams_ByName = gql(/* GraphQL */ `
  query getAirstreams_ByName(
    $first: Int!
    $skip: Int!
    $subgraphId: numeric!
    $chainId: numeric!
    $excludeIds: [String!]
    $asset: String # Required for compatibility
    $name: String!
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { id: { _nin: $excludeIds } }
          { chainId: { _eq: $chainId } }
          { subgraphId: { _lt: $subgraphId } }
          { name: { _iregex: $name } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

/**
 * GENERAL
 *
 * +===================================================================+
 * |                                                                   |
 * |     ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ██╗         |
 * |    ██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗██║         |
 * |    ██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║██║         |
 * |    ██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║██║         |
 * |    ╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║███████╗    |
 * |     ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝    |
 * |                                                                   |
 * +===================================================================+
 */

export const getAirstreams = gql(/* GraphQL */ `
  query getAirstreams(
    $first: Int!
    $skip: Int!
    $subgraphId: numeric!
    $chainId: numeric!
    $excludeIds: [String!]
    $asset: String # Required for compatibility
  ) {
    Campaign(
      limit: $first
      offset: $skip
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { id: { _nin: $excludeIds } }
          { subgraphId: { _lt: $subgraphId } }
          { chainId: { _eq: $chainId } }
        ]
      }
    ) {
      ...CampaignFragment
    }
  }
`);

export const getAirstream_ById = gql(/* GraphQL */ `
  query getAirstream_ById($airstreamId: String!) {
    Campaign(where: { id: { _eq: $airstreamId } }) {
      ...CampaignFragment
    }
  }
`);

export const getActions_ByAirstream = gql(/* GraphQL */ `
  query getActions_ByAirstream(
    $first: Int!
    $airstreamId: String!
    $subgraphId: numeric!
  ) {
    Action(
      limit: $first
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        _and: [
          { campaign_id: { _eq: $airstreamId } }
          { subgraphId: { _lt: $subgraphId } }
        ]
      }
    ) {
      ...ActionFragment
      campaign {
        id
        asset {
          ...AssetFragment
        }
      }
    }
  }
`);

export const getMetadata_ByAirstream = gql(/* GraphQL */ `
  query getMetadata_ByAirstream(
    $airstreamId: String!
    $airstreamIdClone: String!
    $dayFrom: numeric!
    $dayTo: numeric!
  ) {
    Campaign(where: { id: { _eq: $airstreamId } }) {
      id
      asset {
        ...AssetFragment
      }
      activities(
        limit: 7
        where: { _and: [{ day: { _gte: $dayFrom, _lte: $dayTo } }] }
      ) {
        ...ActivityFragment
      }
      actions(
        limit: 5
        distinct_on: [subgraphId]
        order_by: { subgraphId: desc }
      ) {
        ...ActionFragment
      }
    }
    Action(
      limit: 10
      distinct_on: [subgraphId]
      order_by: { subgraphId: desc }
      where: {
        campaign_id: { _eq: $airstreamIdClone }
        category: { _eq: "Claim" }
      }
    ) {
      ...ActionFragment
    }
    First: Action(
      limit: 1
      distinct_on: [subgraphId]
      order_by: { subgraphId: asc, timestamp: asc }
      where: {
        campaign_id: { _eq: $airstreamIdClone }
        category: { _eq: "Claim" }
      }
    ) {
      ...ActionFragment
    }
  }
`);
