import { DEFAULT_RESET_SLEEP } from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { guards, policy } from "@sablier/v2-machines";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Airstream, type IAirstream } from "@sablier/v2-models";
import { vendors } from "@sablier/v2-utils";
import { pages } from "~/client/constants";
import { isHostSafe } from "~/client/contexts/Web3";
import { toast } from "~/client/hooks/useToast";
import type { useMachineForm } from "@sablier/v2-hooks";
import type { Translate } from "@sablier/v2-locales";
import type {
  IAddress,
  ISigner,
  IToken,
  IWagmiAddress,
  IWagmiConfig,
} from "@sablier/v2-types";
import type { IForm } from "~/client/contexts/Form/Airstream/Fund";
import type { useModalTransaction } from "~/client/hooks/modals";
import helper from "../helper";
import { airstreamFund as wording } from "../helper/wording";

export interface Check {
  fields: IForm;
  api: {
    t: Translate;
  };
}
export interface Create extends Check {
  fields: IForm;
  library: IWagmiConfig | undefined;
  signer: ISigner | undefined;
  airstream: IAirstream | { address: IAddress; token: IToken };
  api: {
    reset: () => void;
    t: Translate;
    setOpen: ReturnType<typeof useModalTransaction>["setOpen"];
    updateData: ReturnType<typeof useModalTransaction>["updateData"];
    cache: (data: { hash: string }) => void;
  };
}

export interface Result {
  message?: string;
}

type Machine = Parameters<typeof useMachineForm<Check, Create, Result>>;

type onCheck = Parameters<Machine["0"]["onCheck"]>["0"];
type onProcess = Parameters<Machine["0"]["onProcess"]>["0"];
type onValidate = Parameters<Machine["0"]["onValidate"]>["0"];

export async function onCheck({ event }: onCheck): Promise<void> {
  const { ...fields } = event.payload.fields;
  const { t } = event.payload.api;

  const flags = guards.validateFormFlags({
    t,
    isLoadingIncluded: true,
    isWarningIncluded: true,
    value: fields,
  });

  if (!_.isNilOrEmptyString(flags)) {
    throw new Error(flags);
  }

  const ids: (keyof typeof fields)[] = ["amount", "max", "token"];

  const required = guards.validateFormRequired({
    t,
    required: ids,
    value: fields,
  });

  if (!_.isNilOrEmptyString(required)) {
    throw new Error(required);
  }
}

export async function onValidate({ context }: onValidate): Promise<undefined> {
  const { api, library, signer } = context.payload;
  const { t } = api;

  if (_.isNil(signer) || _.isNil(library)) {
    throw new Error(policy.signer.missing(t));
  }

  api.setOpen(true, {
    status: "verify",
    title: wording.title(t),
    description: wording.confirm(t).description,
    isNotClosable: true,
  });

  try {
    await onCheck({ event: context });

    const chainId = signer.chain!.id;

    await guards.validateInputs(
      library,
      t,
      [
        {
          purpose: "signer",
          options: {
            value: signer,
          },
        },
        {
          purpose: "screening",
          options: {
            chainId,
            addresses: [signer.account!.address],
          },
        },
      ],
      chainId,
      { toast },
    );
  } catch (error) {
    vendors.crash.log(error);
    api.updateData({
      status: "fail",
      description: wording.fail(t).description,
      error: {
        message: _.toString(error),
        data: error,
      },
      isNotClosable: false,
    });
    throw error;
  }
}

/**
 *  Machine state that actually triggers the transaction.
 *  It relies on defined, pre-validated values checked within the `onValidate` step.
 */

export async function onProcess({ context }: onProcess): Promise<void> {
  const { api, fields, airstream, library, signer } = context.payload;
  const { t } = api;

  let query = undefined;

  try {
    if (_.isNil(signer) || _.isNil(library)) {
      throw new Error(policy.signer.missing(t));
    }

    const chainId = signer.chain!.id;
    const airstreamAddress = airstream.address;
    const token = airstream.token;

    const value = _.toValuePrepared({
      humanized: fields.amount.value,
      decimals: airstream.token.decimals,
    });

    const preview = wording.prepare(
      airstream.token,
      new BigNumber(value || "0"),
    );

    query = framework.contextualize(
      token!.address,
      chainId!,
      "token",
      "transfer",
      [airstreamAddress as IWagmiAddress, _.toBigInt(value)],
    );

    api.updateData({
      status: "confirm",
      description: wording.send(
        t,
        true,
        _.toShortAddress(airstreamAddress),
        preview.amount,
      ).description,
      isNotClosable: true,
    });

    const prepared = await helper.configure(library, {
      chainId,
      query,
      signer,
    });

    console.info("%c[pre-transaction]", "color: mediumslateblue", {
      query,
      prepared,
    });

    const transaction = await framework.write(library, { prepared });

    api.updateData({
      status: "pending",
      description: wording.send(
        t,
        false,
        _.toShortAddress(airstreamAddress),
        preview.amount,
      ).description,
      hash: !isHostSafe ? transaction : undefined,
      isNotClosable: false,
    });

    const receipt = isHostSafe
      ? await framework.safeWait(library, { hash: transaction })
      : await framework.wait(library, {
          hash: transaction,
          onReplaced: (replaced) => {
            api.updateData({
              hash: replaced.transaction.hash,
            });
          },
        });

    console.info("%c[post-transaction]", "color: mediumslateblue", {
      transaction,
      receipt,
    });

    if (receipt.status === "reverted") {
      throw new Error(policy.error.reverted(t));
    }

    await _.sleep(DEFAULT_RESET_SLEEP);

    if (api.cache) {
      api.cache({ hash: receipt.transactionHash });
    }

    api.updateData({
      status: "success",
      description: wording.success(t, airstreamAddress, preview.amount)
        .description,
      hash: receipt.transactionHash,
      footer: [
        {
          to: pages.airstream.profile.builder(
            Airstream.doGenerateId(airstreamAddress, token.chainId),
          ),
          title: "View Airstream",
        },
      ],
      isNotClosable: false,
    });

    api.reset();
  } catch (error) {
    void helper.debug(
      {
        query,
        signer,
      },
      vendors.crash.log(error),
    );

    api.updateData({
      status: "fail",
      description: wording.fail(t).description,
      error: {
        message: policy.error.message(t, error),
        data: error,
      },
      isNotClosable: false,
    });

    throw error;
  }
}
