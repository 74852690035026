import { BigNumber, _ } from "@sablier/v2-mixins";
import type { ITranchedStepper } from "./config";
import type { Translate } from "@sablier/v2-locales";
import type { IExtensionDependencies, ISummaryExtension } from "~/client/types";

function summary(
  dependencies: IExtensionDependencies,
  extension: unknown,
  t: Translate,
): ISummaryExtension {
  const fields = extension as ITranchedStepper;
  const { amount, duration, end, start, timing, token } = dependencies;

  const isComplete = !_.isNilOrEmptyString(fields.steps.value);
  const isValid = _.isNilOrEmptyString(fields.steps.warning);

  const durationTotal = (() => {
    if (timing === "duration") {
      return new BigNumber(duration || 0);
    }

    return new BigNumber(end || 0).minus(new BigNumber(start || 0)).abs();
  })();

  const steps = new BigNumber(fields.steps.value || 0);
  const stepDuration = steps.eq(0)
    ? undefined
    : durationTotal.div(steps).integerValue();
  const stepAmount = new BigNumber(amount || 0).div(steps);

  const stepFor = !_.isNilOrEmptyString(stepDuration)
    ? _.toDuration(stepDuration.toNumber(), "time")[0]
    : undefined;

  const stepOf = stepAmount.toString();

  return {
    isComplete,
    isValid,
    list: [
      {
        label: `${_.capitalize(t("words.steps"))}:`,
        purpose: "text",
        value: steps.toString(),
      },
      {
        label: `${_.capitalize(t("structs.stepFor"))}:`,
        purpose: "text",
        value: stepFor,
      },
      {
        label: `${_.capitalize(t("structs.stepUnlocks"))}:`,
        purpose: "amount",
        token,
        value: stepOf,
      },
    ],
  };
}

export default summary;
