import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import type { ComponentProps, PropsWithChildren, ReactNode } from "react";
import Tooltip from "../../Tooltip";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    width: 100%;
    column-gap: 4px;
  }
  :not([data-tooltip="false"]) {
    cursor: default;
  }
`;

const Title = styled.div`
  & > p {
    ${(props) => props.theme.styles.textElement}
    & {
      color: ${(props) => props.theme.colors.gray400};
    }
  }
`;

const Position = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex: 1;
    align-items: center;
    min-height: 20pt;
  }
`;

const Value = styled.div`
  ${(props) => props.theme.styles.ellipsisAdaptive}

  & > p {
    ${(props) => props.theme.styles.textParagraph}

    & {
      vertical-align: middle;
    }
  }
  :not([data-invalid="false"]) > p {
    font-style: italic;
    overflow: visible;
  }
`;

interface Props {
  title: string;
  value?: string | ReactNode;
  isInvalid?: boolean;
  tooltip?: ComponentProps<typeof Tooltip>;
}

function Detail({
  children,
  isInvalid = false,
  title,
  value,
  tooltip = {},
}: PropsWithChildren<Props>) {
  return (
    <Tooltip {...tooltip} maxWidth={"400px"}>
      <Wrapper
        data-component={"detail"}
        data-tooltip={!_.isNilOrEmptyString(tooltip?.value)}
      >
        <Title data-component={"title"}>
          <p>{title}</p>
        </Title>
        <Position>
          <Value data-invalid={isInvalid}>
            {value ? <p>{value}</p> : children ?? false}
          </Value>
        </Position>
      </Wrapper>
    </Tooltip>
  );
}

export default Detail;
