import styled, { css } from "styled-components";
import { _ } from "@sablier/v2-mixins";
import type { ComponentProps } from "react";

export const Container = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: ${(props) => props.theme.sizes.inputBox};
    & > * {
      z-index: 20;
    }
  }
`;

export const Box = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    --color: ${(props) => props.theme.colors.white};
    --background: ${(props) => props.theme.colors.dark100};
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: var(
      --background,
      ${(props) => props.theme.colors.dark100}
    );
  }
`;

export const Highlight = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  outline: 1px solid ${(props) => props.theme.colors.transparent};
  pointer-events: none;
`;

interface PartialFieldProps {
  isBordered?: boolean;
  isDisabled?: boolean;
  isPlaceholder?: boolean;
  isPreview?: boolean | "soft";
}

const PartialFieldBase = styled.input.attrs(
  (props) => props.theme.attributes.noAutofill,
)``;

const PartialField = styled(PartialFieldBase)<PartialFieldProps>`
  ${(props) => props.theme.styles.row}
  ${(props) => props.theme.styles.textInput}
  & {
    ${css`
      position: relative;
      flex: 1;
      width: 100%;
      height: ${(props) => props.theme.sizes.inputField};
      min-width: 0;
      padding: 0 ${(props) => props.theme.sizes.inputEdge};
      color: var(--color, ${(props) => props.theme.colors.white});
      border: none;
      background: transparent;
      outline: none;
      overflow: hidden;
      appearance: none;
      -ms-overflow-style: none;
      scrollbar-width: none;

      :-webkit-autofill,
      :-webkit-autofill:active,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus {
        border: none;
        border-color: transparent !important;
        border-image-width: 0px !important;
        background-color: transparent !important;
        outline: none;
        outline-color: transparent !important;
        -webkit-box-shadow: 0 0 0 30px
          var(--background, ${(props) => props.theme.colors.dark100}) inset !important;

        animation: none !important;
        -webkit-text-fill-color: var(
          --color,
          ${(props) => props.theme.colors.white}
        ) !important;
      }
      ~ ${Box} {
        cursor: text;
      }
    `}

    ${(props) =>
      props.isPlaceholder &&
      css`
        ${props.theme.styles.textPlaceholder}
        & {
          flex-wrap: nowrap;
        }
      `}

    ${(props) =>
      props.isBordered &&
      css`
        & ~ ${Highlight} {
          outline: 2px solid ${(props) => props.theme.colors.dark200};
        }
      `}

      ${(props) =>
      props.isPreview &&
      css`
        ${(props) => props.theme.styles.ellipsis}
        & {
          height: auto;
          min-height: 18px;
          white-space: nowrap;
        }
      `}

    ${(props) =>
      props.isPreview === true &&
      !props.onClick &&
      css`
        cursor: default;
        & ~ ${Box}, p {
          cursor: default;
        }
      `}

    ${(props) =>
      !props.isPreview &&
      css`
        &:active,
        &:focus {
          & ~ ${Highlight}, &:hover ~ ${Highlight} {
            outline: 2px solid ${(props) => props.theme.colors.inputBorder};
            box-shadow: 0px 0px 3px ${(props) => props.theme.colors.inputBorder};
          }
        }
      `}

      ${(props) =>
      !!props.onClick &&
      !!props.isPreview &&
      css`
        cursor: pointer;
        & ~ ${Box} {
          cursor: pointer;
        }
      `}
  }

  &::placeholder {
    ${(props) => props.theme.styles.textPlaceholder}
    & {
      opacity: 1;
      appearance: none;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }

  ${(props) => props.theme.medias.maxMD} {
    padding: 0 ${(props) => props.theme.sizes.inputEdgeSmall};
  }

  ${(props) => props.theme.medias.maxXXS} {
    max-width: 200px;
    margin-right: auto;
  }
`;

export const Field = (
  props: ComponentProps<typeof PartialField> & {
    isDisabled?: boolean;
    isPreview?: boolean | "soft";
  },
) => {
  if (props.isPreview === true || props.isDisabled) {
    return (
      <PartialField
        as={"p"}
        id={props.id}
        data-component={"field"}
        isBordered={props.isBordered}
        isPlaceholder={_.isNilOrEmptyString(props.value)}
        isPreview
        isDisabled={props.isDisabled}
        onBlur={props.onBlur}
        onClick={props.onClick}
        onFocus={props.onFocus}
      >
        {props.value || props.placeholder}
      </PartialField>
    );
  }
  return <PartialField data-component={"field"} {...props} />;
};

interface WrapperProps {
  isLoading?: boolean;
  isLocked?: boolean;
  isPreview?: boolean | "soft";
  isDisabled?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: ${(props) => props.theme.sizes.inputMargin};
    width: 100%;
    color: ${(props) => props.theme.colors.dark700};
  }

  ${(props) =>
    props.isLocked &&
    css`
      &,
      ${PartialField} {
        color: ${(props) => props.theme.colors.gray400};
        cursor: default !important;
      }

      ${Box} {
        background-color: ${(props) => props.theme.colors.dark200};
        outline: 2px solid ${(props) => props.theme.colors.dark050};
        outline-offset: -2px;
        cursor: default !important;
      }
      ${Highlight} {
        display: none;
      }
    `}

  ${(props) =>
    !props.isDisabled &&
    (!props.isPreview || props.isPreview === "soft") &&
    css`
      * {
        &:hover {
          & ~ ${Highlight} {
            outline: 2px solid ${(props) => props.theme.colors.inputBorder};
            box-shadow: 0px 0px 3px ${(props) => props.theme.colors.inputBorder};
          }
        }
      }
    `}
`;
