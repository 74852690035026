import { useState } from "react";
import styled from "styled-components";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import { _ } from "@sablier/v2-mixins";
import { ITokenDisplay } from "@sablier/v2-types";
import type { PropsWithChildren } from "react";
import Amount from "../../Amount";
import Icon from "../../Icon";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    width: 100%;
    padding: ${(props) => props.theme.sizes.edge};
    column-gap: 4px;
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.dark300};
  }
`;

const Top = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    width: 100%;
    cursor: pointer;
  }
`;

const Title = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    --size: 16px;
    flex: 1;
    margin-right: 6px;
    color: ${(props) => props.theme.colors.gray400};
    column-gap: 6px;
    & > p {
      ${(props) => props.theme.styles.textElement}
    }
  }
`;

const Toggle = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    justify-content: center;
    margin-left: 8px;
  }
`;

const Total = styled.div`
  ${(props) => props.theme.styles.row}
`;

const Body = styled.div`
  width: 100%;
  margin-top: calc(${(props) => props.theme.sizes.edge} * 1);
  border-top: 2px solid ${(props) => props.theme.colors.dark500};
`;

const List = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    width: 100%;
    padding: calc(${(props) => props.theme.sizes.edge} * 1 / 2) 0;
  }
`;

const Wrapper = styled(WrapperPartial)`
  &:not([data-open="true"]) {
    ${Body} {
      display: none;
    }
  }

  ${(props) => props.theme.medias.maxMD} {
    padding: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
  }
`;

interface Props {
  className?: string;
  isComplete: boolean | "hidden";
  title: string;
  isInitiallyOpen?: boolean;
  total?: {
    isExpected?: boolean;
    token?: ITokenDisplay;
    value?: string;
  };
}

function Card({
  children,
  className,
  isComplete = false,
  isInitiallyOpen = false,
  title,
  total,
}: PropsWithChildren<Props>) {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  return (
    <Wrapper className={className} data-open={isOpen}>
      <Top onClick={() => setIsOpen(!isOpen)}>
        <Title>
          <p>{title}</p>
          {isComplete !== "hidden" &&
            (isComplete ? (
              <Icon value={CheckIcon} />
            ) : (
              <Icon value={LockClosedIcon} />
            ))}
        </Title>
        <Total>
          {total && total.isExpected && !_.isNil(total.token) ? (
            <Amount token={total?.token} size={18} value={total?.value} />
          ) : (
            false
          )}
        </Total>
        <Toggle>
          {isOpen ? (
            <Icon value={ChevronUpIcon} />
          ) : (
            <Icon value={ChevronDownIcon} />
          )}
        </Toggle>
      </Top>
      <Body>
        <List>{children}</List>
      </Body>
    </Wrapper>
  );
}

export default Card;
