import {
  DEFAULT_BROKER_ADDRESS,
  DEFAULT_BROKER_FEE_PERCENTAGE,
} from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { _ } from "@sablier/v2-mixins";
import { IAddress, IWagmiAddress } from "@sablier/v2-types";
import { contractor, peripheral } from "~/client/utils";
import type { ILinear } from "./config";
import type {
  IExtensionParamsAirstream,
  IExtensionParamsGroup,
  IExtensionParamsSingle,
  IExtensionResultAirstreamLL,
  IExtensionResultDurationsLL,
  IExtensionResultTimestampsLL,
} from "~/client/types";
import { precompute } from "./setup";

type IExtension = ILinear;

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */

async function processSingle(
  params: IExtensionParamsSingle & { timing: "duration" },
): Promise<{
  batch: IExtensionResultDurationsLL;
}>;
async function processSingle(
  params: IExtensionParamsSingle & { timing: "range" },
): Promise<{
  batch: IExtensionResultTimestampsLL;
}>;
async function processSingle(params: IExtensionParamsSingle): Promise<{
  batch: IExtensionResultDurationsLL | IExtensionResultTimestampsLL;
}>;

async function processSingle({
  dependencies,
  extras,
  timing,
}: IExtensionParamsSingle): Promise<{
  batch: IExtensionResultDurationsLL | IExtensionResultTimestampsLL;
}> {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const { purpose } = extras as IExtension;
  const {
    address,
    cancelability,
    transferability,
    chainId,
    duration,
    end,
    sender,
    start,
    token,
  } = dependencies;

  const batch: IAddress = peripheral(chainId, "batch").address;
  const lockup: IAddress = contractor(chainId, purpose, token?.address).address;

  const { amount: deposit } = precompute.single({ dependencies });

  const cliffDuration = "0";
  const totalDuration = _.toSeconds(duration);
  const startTime = _.toSeconds(start);
  const endTime = _.toSeconds(end);

  /**
   * ------------------------------
   * Prepare transaction parameters
   * ------------------------------
   */

  if (timing === "duration") {
    type Inputs = IExtensionResultDurationsLL["inputs"];

    const inputs: Inputs = [
      lockup as IWagmiAddress,
      token!.address as IWagmiAddress,
      [
        {
          sender: sender as IWagmiAddress,
          recipient: address as IWagmiAddress,
          totalAmount: _.toBigInt(deposit),

          cancelable: !!cancelability,
          transferable: !!transferability,
          durations: {
            cliff: _.toNumber(cliffDuration),
            total: _.toNumber(totalDuration),
          },
          broker: {
            account: DEFAULT_BROKER_ADDRESS as IWagmiAddress,
            fee: _.toBigInt(DEFAULT_BROKER_FEE_PERCENTAGE),
          },
        },
      ],
    ];

    const data = framework.contextualize(
      batch,
      chainId!,
      "batch",
      "createWithDurationsLL",
      inputs,
    );

    return {
      batch: data,
    };
  } else {
    type Inputs = IExtensionResultTimestampsLL["inputs"];

    const inputs: Inputs = [
      lockup as IWagmiAddress,
      token!.address as IWagmiAddress,
      [
        {
          sender: sender as IWagmiAddress,
          recipient: address as IWagmiAddress,
          totalAmount: _.toBigInt(deposit),

          cancelable: !!cancelability,
          transferable: !!transferability,
          timestamps: {
            start: _.toNumber(startTime),
            cliff: _.toNumber(cliffDuration),
            end: _.toNumber(endTime),
          },
          broker: {
            account: DEFAULT_BROKER_ADDRESS as IWagmiAddress,
            fee: _.toBigInt(DEFAULT_BROKER_FEE_PERCENTAGE),
          },
        },
      ],
    ] as const;

    const data = framework.contextualize(
      batch,
      chainId!,
      "batch",
      "createWithTimestampsLL",
      inputs,
    );

    return {
      batch: data,
    };
  }
}
/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */

async function processGroup(
  params: IExtensionParamsGroup & { timing: "duration" },
): Promise<{
  batch: IExtensionResultDurationsLL;
}>;
async function processGroup(
  params: IExtensionParamsGroup & { timing: "range" },
): Promise<{
  batch: IExtensionResultTimestampsLL;
}>;
async function processGroup(params: IExtensionParamsGroup): Promise<{
  batch: IExtensionResultDurationsLL | IExtensionResultTimestampsLL;
}>;

async function processGroup({
  dependencies,
  purpose,
  timing,
  library,
}: IExtensionParamsGroup): Promise<{
  batch: IExtensionResultDurationsLL | IExtensionResultTimestampsLL;
}> {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const {
    cancelability,
    transferability,
    chainId,
    sender,
    token,
    streams,
    signer,
  } = dependencies;

  const batch: IAddress = peripheral(chainId, "batch").address;
  const lockup = contractor(chainId, purpose, token?.address).address;

  /**
   * ------------------------------
   * Prepare transaction parameters
   * ------------------------------
   */
  if (timing === "duration") {
    type Inputs = IExtensionResultDurationsLL["inputs"];

    const params: Inputs["2"] = await Promise.all(
      streams?.map(async (stream) => {
        const { address, amount, duration, end, start } = stream;

        const single = await processSingle({
          dependencies: {
            address,
            amount,
            cancelability,
            transferability,
            duration,
            end,
            token,
            start,
            chainId,
            sender,
            signer,
          },
          extras: stream.extension,
          timing: "duration",
          library,
        });

        return single.batch.inputs[2][0];
      }) || [],
    );

    const inputs: Inputs = [
      lockup as IWagmiAddress,
      token!.address as IWagmiAddress,
      params,
    ] as const;

    const data = framework.contextualize(
      batch,
      chainId!,
      "batch",
      "createWithDurationsLL",
      inputs,
    );

    return {
      batch: data,
    };
  } else {
    type Inputs = IExtensionResultTimestampsLL["inputs"];

    const params: Inputs["2"] = await Promise.all(
      streams?.map(async (stream) => {
        const { address, amount, duration, end, start } = stream;

        const single = await processSingle({
          dependencies: {
            address,
            amount,
            cancelability,
            transferability,
            duration,
            end,
            token,
            start,
            chainId,
            sender,
            signer,
          },
          extras: stream.extension,
          timing: "range",
          library,
        });

        return single.batch.inputs[2][0];
      }) || [],
    );

    const inputs: Inputs = [
      lockup as IWagmiAddress,
      token!.address as IWagmiAddress,
      params,
    ];

    const data = framework.contextualize(
      batch,
      chainId!,
      "batch",
      "createWithTimestampsLL",
      inputs,
    );
    return {
      batch: data,
    };
  }
}

async function processAirstream({
  dependencies,
  extras,
}: IExtensionParamsAirstream): Promise<{
  factory: IExtensionResultAirstreamLL;
}> {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const { purpose } = extras as IExtension;
  const {
    calldata,
    cancelability,
    expiration,
    transferability,
    chainId,
    duration,
    name,
    sender,
    token,
  } = dependencies;

  const factory: IAddress = peripheral(chainId, "merkleLockupFactory").address;
  const lockup: IAddress = contractor(
    chainId!,
    purpose,
    token?.address,
  ).address;

  /**
   * ------------------------------
   * Prepare transaction parameters
   * ------------------------------
   */

  type Inputs = IExtensionResultAirstreamLL["inputs"];

  const inputs: Inputs = [
    {
      asset: token!.address as IWagmiAddress,
      cancelable: cancelability!,
      expiration: _.toNumber(expiration),
      initialAdmin: sender as IWagmiAddress,
      ipfsCID: calldata.cid,
      merkleRoot: calldata.root as IWagmiAddress,
      name: name ?? "",
      transferable: transferability!,
    },

    lockup as IWagmiAddress,
    {
      cliff: 0,
      total: _.toNumber(duration),
    },
    _.toBigInt(calldata.total),
    _.toBigInt(calldata.recipients),
  ];

  const data = framework.contextualize(
    factory,
    chainId,
    "merkleLockupFactory",
    "createMerkleLL",
    inputs,
  );

  return {
    factory: data,
  };
}

export const process = {
  airstream: processAirstream,
  group: processGroup,
  single: processSingle,
};
