import { add, remove, removeAll } from "@sablier/v2-components/organisms/Toast";

function useToast() {
  return { add, remove, removeAll };
}

const toast = { add, remove, removeAll };
export { toast };

export default useToast;
