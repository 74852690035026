import { createMachine } from "xstate";

type MachineContext = unknown | never;

type MachineEvents = { type: "OPEN" } | { type: "CLOSE" };

const initialContext = {};

const machine = createMachine({
  tsTypes: {} as import("./index.typegen").Typegen0,
  id: "dropdownMachine",
  initial: "closed",
  context: initialContext,
  predictableActionArguments: true,
  schema: {
    context: {} as MachineContext,
    events: {} as MachineEvents,
  },
  states: {
    closed: {
      on: {
        OPEN: "open",
      },
    },
    open: {
      on: {
        CLOSE: "closed",
      },
    },
  },
});

export default machine;
