import styled from "styled-components";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { _ } from "@sablier/v2-mixins";
import type { ComponentProps, MouseEvent } from "react";
import Button from "../Button";

const Wrapper = styled.div<{ size?: number | string }>`
  ${(props) => props.theme.styles.row}
  & {
    height: ${(props) => props.size || props.theme.sizes.buttonMini};
    padding: 2px;
    column-gap: 2px;
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.dark200};
    & > * {
      height: calc(
        ${(props) => props.size || props.theme.sizes.buttonMini} - 4px
      ) !important;
    }
  }
`;

export interface Props {
  iconOff?: ComponentProps<typeof Button>["right"];
  iconOn?: ComponentProps<typeof Button>["right"];
  id: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  labelOn?: string;
  labelOff?: string;
  onChange?: (e?: MouseEvent<HTMLElement>) => void;
  size?: number | string;
}

function SwitchBox({
  iconOn = CheckIcon,
  iconOff = XMarkIcon,
  id,
  isChecked,
  isDisabled,
  labelOn = "On",
  labelOff = "Off",
  onChange,
  size,
}: Props) {
  return (
    <Wrapper id={id} onClick={onChange} size={size && _.toSuffix(size, "px")}>
      <Button
        appearance={"solid"}
        accent={isChecked ? "dark600" : "input"}
        isMini
        isPreview={isChecked || isDisabled}
        right={isChecked ? iconOn : undefined}
        title={labelOn}
      />
      <Button
        appearance={"solid"}
        accent={!isChecked ? "dark600" : "input"}
        isPreview={!isChecked || isDisabled}
        isMini
        right={!isChecked ? iconOff : undefined}
        title={labelOff}
      />
    </Wrapper>
  );
}

export default SwitchBox;
