import { _ } from "@sablier/v2-mixins";
import { PERMISSION_ID } from "~/client/constants";
import usePermissionsStore from "~/client/stores/permissions";
import type { Analytics } from "@vercel/analytics/react";
import type { SpeedInsights } from "@vercel/speed-insights/next";

const beforeSendAnalytics: Parameters<typeof Analytics>[0]["beforeSend"] = (
  event,
) => {
  const permissions = usePermissionsStore.getState().permissions;

  if (_.has(permissions, PERMISSION_ID.vercel)) {
    if (permissions[PERMISSION_ID.vercel] === true) {
      return event;
    }
  }
  return null;
};

const beforeSendSpeedInsights: Parameters<
  typeof SpeedInsights
>[0]["beforeSend"] = (event) => {
  const permissions = usePermissionsStore.getState().permissions;

  if (_.has(permissions, PERMISSION_ID.vercel)) {
    if (permissions[PERMISSION_ID.vercel] === true) {
      return event;
    }
  }
  return null;
};

const vendors = {
  vercel: {
    beforeSendSpeedInsights,
    beforeSendAnalytics,
  },
};
export default vendors;
