import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { ArrowRightIcon, FireIcon } from "@heroicons/react/24/outline";
import { StreamShape, routes } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Stream } from "@sablier/v2-models";
import { getTheme } from "@sablier/v2-themes";
import { vendors } from "@sablier/v2-utils";
import { Internal, StreamThumbnail as Thumbnail } from "~/components/atoms";
import type { IGalleryStream } from "@sablier/v2-types";
import type { ComponentProps } from "react";
import Button from "../Button";

const theme = getTheme();

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    grid-column: span 1;
  }
`;

const Card = styled(Internal)`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    flex: 1;
    row-gap: calc(${(props) => props.theme.sizes.edge} * 2);
    align-items: center;
    width: 100%;
    padding: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    border-radius: 6px;
    background: ${(props) => props.theme.gradients.dark};
    cursor: pointer;
    overflow: hidden;

    &:before {
      position: absolute;
      top: -100px;
      left: -100px;
      content: "";
      z-index: ${(props) => props.theme.sizes.zIndexUnder};
      width: 280px;
      height: 280px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.dark1000};
      opacity: 0.1;
      filter: blur(100px);
      will-change: opacity;
      transition: opacity 150ms;
    }

    * {
      cursor: pointer !important;
    }

    & > * {
      z-index: ${(props) => props.theme.sizes.zIndexOver};
    }
  }
`;

const Deck = styled.div`
  ${(props) => props.theme.styles.column}
  ${(props) => props.theme.animations.deck};
  & {
    position: relative;
    row-gap: 6px;
    align-items: center;
    width: 100%;
    margin-top: 6px;
    opacity: 0;

    &:before,
    &:after {
      position: relative;
      content: "";
      width: 85%;
      height: 3px;
      border-radius: 2px;
      background-color: ${(props) => props.theme.colors.dark300};
      will-change: transform;
      transition: transform 150ms;
    }

    &:after {
      width: 70%;
    }
  }
`;

const Top = styled.div`
  width: 100%;
  height: 120px;
`;

const Content = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    width: 100%;
  }
`;

const Title = styled.div`
  & > p {
    ${(props) => props.theme.styles.textElement}
    & {
      text-transform: capitalize;
    }
  }
`;

const Description = styled.div`
  flex: 1;
  & > p {
    min-height: 40pt;
    ${(props) => props.theme.styles.textParagraph}
    & {
      color: ${(props) => props.theme.colors.gray400};
    }
  }
`;

const Footer = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    width: 100%;
    column-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    div[data-component="button"] {
      cursor: pointer !important;
    }

    & > *:first-child {
      position: relative;
      &:before {
        ${(props) => props.theme.styles.shinny}
        & {
          --top: 0px;
          --end: ${(props) => props.theme.colors.dark500};
          --highlight: ${(props) => props.theme.colors.dark1000};
          width: calc(100% - 8px);
          opacity: 0;
          will-change: opacity;

          transition: opacity 150ms;
        }
      }
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  &:hover,
  &:active {
    ${Card} {
      &:before {
        opacity: 0.5;
        will-change: opacity;
        transition: opacity 150ms;
      }
    }
    ${Footer} {
      & > *:first-child {
        position: relative;
        &:before {
          opacity: 1;
          will-change: opacity;
          transition: opacity 150ms;
        }
      }
    }
    ${Deck} {
      &:before,
      &:after {
        background-color: ${(props) => props.theme.colors.dark500};
        transform: translateY(2px);
        will-change: transform;
        transition: transform 150ms;
      }
    }
  }

  ${(props) => props.theme.medias.maxMD} {
    ${Card} {
      row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
      padding: calc(${(props) => props.theme.sizes.edge} * 1);
    }
    ${Content} {
      p {
        min-height: 0;
        line-height: 20pt;
      }
    }
  }

  ${(props) => props.theme.medias.between(props.theme.sizes.deviceXXS, 420)} {
    ${Card} {
      ${(props) => props.theme.styles.row}
      & {
        gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
        align-items: flex-start;
        padding: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      }
    }
    ${Content} {
      gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      ${Footer} {
        margin-top: 4px;
      }
    }
    ${Top} {
      width: 100px;
      height: 60px;
      padding: 6px;
      border-radius: 4px;
      background-color: ${(props) => props.theme.colors.dark100};
    }
  }
`;

export interface Props extends Omit<IGalleryStream, "type" | "subtype"> {
  className?: string;
  to?: ComponentProps<typeof Internal>["to"];
  shape: StreamShape;
}

function StreamCard({
  className,
  description: _description,
  isRecommended = false,
  shape,
}: Props) {
  const { t } = useT();
  const title = useMemo(() => t(Stream.findShape(shape).title), [shape, t]);
  const description = useMemo(
    () => t(Stream.findShape(shape).description),
    [shape, t],
  );

  const to = useMemo(() => {
    return routes.client.pages.createGroup.builder({ shape });
  }, [shape]);

  const onClick = useCallback(() => {
    vendors.track.log((events) => events.selectGroupStream(shape));
  }, [shape]);

  return (
    <Wrapper onClick={onClick} className={className}>
      <Card to={to}>
        <Top>
          <Thumbnail
            shape={shape}
            sizes={{
              height: theme.sizes.streamThumbnailHeight,
              width: theme.sizes.streamThumbnailWidth,
            }}
          />
        </Top>
        <Content>
          <Title>
            <p>{_.startCase(title)}</p>
          </Title>
          <Description>
            <p>{description}</p>
          </Description>
          <Footer>
            <Button
              accent={"dark500"}
              appearance={"solid"}
              isMini
              isPreview
              title={t("structs.pickThis")}
              titleShort={t("words.pick")}
              right={ArrowRightIcon}
            />
            {isRecommended && (
              <Button
                accent={"hot"}
                appearance={"solid"}
                isMini
                isPreview
                title={t("words.popular")}
                titleShort={t("words.hot")}
                left={FireIcon}
              />
            )}
          </Footer>
        </Content>
      </Card>
      <Deck />
    </Wrapper>
  );
}

export default StreamCard;
