import { EXPONENT_DECIMALS } from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import type { IMilliseconds, ISeconds, IValue } from "@sablier/v2-types";
import Segment from "../Segment";
import Token from "../Token";

type Params = {
  id: string;
  position: string;
  /** --------------- */
  amount: string;
  timestamp: ISeconds;
  /** --------------- */
  endAmount: string;
  endTime: ISeconds;
  startAmount: string;
  startTime: ISeconds;

  /** --------------- */
  percentage?: string;
};

export default class Tranche {
  readonly id: string;
  readonly position: number;
  readonly token: Token;
  /** --------------- */
  readonly amount: IValue;
  readonly timestamp: string;

  /** --------------- */
  readonly endAmount: IValue;
  readonly endTime: IMilliseconds;
  readonly startAmount: IValue;
  readonly startTime: IMilliseconds;
  readonly duration: IMilliseconds;

  readonly payload: Params;

  _percentage?: string;

  constructor(params: Params, token: Token) {
    this.payload = params;

    this.id = params.id;
    this.position = new BigNumber(params.position).toNumber();
    this.token = token;

    /** --------------- */
    this.amount = _.toValue({
      decimals: token.decimals,
      raw: params.amount,
    });
    this.timestamp = params.timestamp;

    /** --------------- */
    this.endAmount = _.toValue({
      decimals: token.decimals,
      raw: params.endAmount,
    });
    this.endTime = _.toMilliseconds(params.endTime);
    this.startAmount = _.toValue({
      decimals: token.decimals,
      raw: params.startAmount,
    });
    this.startTime = _.toMilliseconds(params.startTime);

    this.duration = new BigNumber(this.endTime)
      .minus(new BigNumber(this.startTime))
      .toString();

    this._percentage = params.percentage;
  }

  toSegments(): [Segment, Segment] {
    const cliff = new Segment(
      {
        id: `tranched-${this.position * 2}`,
        position: `${this.position * 2}`,
        amount: "0",
        startAmount: this.payload.startAmount,
        endAmount: this.payload.startAmount,
        exponent: _.toValuePrepared({
          humanized: "1",
          decimals: EXPONENT_DECIMALS,
        }),
        startTime: this.payload.startTime,
        endTime: new BigNumber(this.payload.endTime).minus(1).toString(),
        milestone: new BigNumber(this.payload.endTime).minus(1).toString(),
      },
      this.token,
      false,
    );

    const unlock = new Segment(
      {
        id: `tranched-${this.position * 2 + 1}`,
        position: `${this.position * 2 + 1}`,
        amount: this.payload.amount,
        startAmount: this.payload.startAmount,
        endAmount: this.payload.endAmount,
        exponent: _.toValuePrepared({
          humanized: "1",
          decimals: EXPONENT_DECIMALS,
        }),
        startTime: new BigNumber(this.payload.endTime).minus(1).toString(),
        endTime: this.payload.endTime,
        milestone: this.payload.endTime,
      },
      this.token,
      false,
    );

    return [cliff, unlock];
  }

  get percentage() {
    return this._percentage;
  }

  static fromSegments(horizontal: Segment, vertical: Segment) {
    return new Tranche(
      {
        id: `segmented-${horizontal.position / 2}`,
        position: `${horizontal.position / 2}`,
        amount: vertical.payload.amount,
        startAmount: vertical.payload.startAmount,
        endAmount: vertical.payload.endAmount,
        startTime: horizontal.payload.startTime,
        endTime: vertical.payload.endTime,
        timestamp: vertical.payload.endTime,
      },
      horizontal.token,
    );
  }
}
