import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import Tooltip from "../../Tooltip";

const Wrapper = styled.div<{ weight?: number }>`
  & {
    display: inline-flex;
    align-items: center;
    p {
      ${(props) => props.theme.styles.textParagraph}
      & {
        display: inline-flex;
        color: ${(props) => props.theme.colors.gray100};
        font-weight: ${(props) => props.weight || 500};
        line-height: 27px !important;
      }
    }
  }
`;

interface Props {
  value?: string;
  tooltip?: string;
  weight?: number;
}

function Content({ tooltip, value, weight }: Props) {
  return (
    <Tooltip value={tooltip}>
      <Wrapper weight={weight}>
        <p>{value}</p>
      </Wrapper>
    </Tooltip>
  );
}

export default Content;
