import airstreamConfig, { endpoint as airstreamEndpoint } from "./airstream";
import protocolConfig, { endpoint as protocolEndpoint } from "./protocol";

const configs = {
  protocol: {
    config: protocolConfig,
    endpoint: protocolEndpoint,
  },
  airstream: {
    config: airstreamConfig,
    endpoint: airstreamEndpoint,
  },
};

export default configs;
