import { memo } from "react";
import styled from "styled-components";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { _ } from "@sablier/v2-mixins";
import Icon from "../Icon";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    width: 100%;
    min-height: 24px;
    column-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 4);

    &[data-wrapped="true"] {
      padding: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      border-radius: 6px;
      background-color: ${(props) => props.theme.colors.dark300};
    }
  }
`;

const IconContainer = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex-shrink: 0;
    justify-content: center;
    width: 24px;
    height: 24px;
    color: ${(props) => props.theme.colors.yellow};
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.dark400};
  }
`;

const Content = styled.div`
  & > p {
    ${(props) => props.theme.styles.textParagraphMini}
    & {
      color: ${(props) => props.theme.colors.yellow};
      word-wrap: break-word;
    }
  }
`;

export interface Props {
  isIconHidden?: boolean;
  isPresizing?: boolean;
  isWrapped?: boolean;
  value?: string | null | number | boolean;
  cy?: string;
}

function Warning({
  isIconHidden = false,
  isPresizing = false,
  isWrapped = false,
  cy,
  value,
}: Props) {
  if (_.isNilOrEmptyString(value) || value === false) {
    if (isPresizing) {
      return <Wrapper />;
    }
    return false;
  }

  return (
    <Wrapper data-cy={cy} data-wrapped={isWrapped}>
      {!isIconHidden ? (
        <IconContainer>
          <Icon purpose={"heroicon"} value={ExclamationCircleIcon} size={18} />
        </IconContainer>
      ) : (
        false
      )}

      <Content>
        <p>{value}</p>
      </Content>
    </Wrapper>
  );
}

export default memo(Warning);
