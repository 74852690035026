import { useEffect, useMemo } from "react";
import { REQUEST_ID } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { Stream, Token } from "@sablier/v2-models";
import { client, queries } from "@sablier/v2-subgraphs";
import { useQuery } from "@tanstack/react-query";
import type { ISearchStream } from "@sablier/v2-models";

interface Props {
  id?: string;
  key?: string[];
  isEnabled?: boolean;
  onSuccess: (result: ISearchStream) => void;
  onError?: (error: unknown, result: ISearchStream) => void;
  networkMode?: "always" | "online" | "offlineFirst";
}

export default function useRequestStreamItem({
  id,
  key = REQUEST_ID.streamItem,
  onError = _.noop,
  onSuccess = _.noop,
  isEnabled = false,
  networkMode = "online",
}: Props) {
  const filter = useMemo(
    () =>
      Stream.doFormatFilter({
        chainId: Stream.doSplitIdentifier(id).chainId,
        streamIds: [id || "_"],
      }),
    [id],
  );

  const endpoint = useMemo(
    () =>
      client.getEndpointByChain({
        chainId: filter.chainId,
        feature: "protocol",
      }),
    [filter],
  );

  const variables = useMemo(() => {
    const chainId = filter.chainId;
    const streamId = _.isNil(filter.streamIds)
      ? "_"
      : filter.streamIds[0].toLowerCase();

    return {
      chainId,
      streamId,
    };
  }, [filter]);

  const { data, error, isLoading } = useQuery({
    queryKey: [...key, { unique: variables }],
    queryFn: async () => {
      if (!Stream.isId(variables.streamId)) {
        throw new Error("Misconfigured (stream offchain)");
      }
      return client.request(
        endpoint,
        queries.protocol.getStream_ById,
        variables,
        {},
        (data) => _.isNilOrEmptyString(data?.stream),
      );
    },
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: isEnabled,
    networkMode,
    retry: false,
  });

  /** -------------- On Success -------------- */
  /** Checks should run against a valid `data` */

  useEffect(() => {
    if (!_.isNilOrEmptyString(data)) {
      const search: ISearchStream = {
        filter,
        options: {
          first: 1,
          isComplete: true,
        },
        streams: [],
      };

      if (!_.isNil(data) && !_.isNil(data.stream)) {
        const token = new Token(data.stream.asset);
        const stream = new Stream(data.stream, token);
        search.streams.push(stream);
      }

      onSuccess(search);
    }
  }, [data, error, filter, onSuccess]);

  /** -------------- On Error -------------- */
  /** Checks should run against the `error` */

  useEffect(() => {
    if (!_.isNilOrEmptyString(error)) {
      const search: ISearchStream = {
        filter,
        options: {
          first: 1,
          error: _.get(error, "message") ?? _.toString(error),
          isComplete: true,
        },
        streams: [],
      };

      onError(error, search);
    }
  }, [error, filter, onError]);

  return useMemo(
    () => ({
      error,
      isLoading: isLoading && isEnabled,
    }),
    [error, isLoading, isEnabled],
  );
}
