import { memo, useMemo } from "react";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { ISInput } from "../system/templates";
import Template, { sides } from "../system/templates";

export interface Props extends Omit<ISInput, "right"> {
  resolution?: string;
}

function LockedChange(props: Props) {
  const { t } = useT();
  const formatted = useMemo(() => {
    const right: ISInput["right"] = [];

    if (props.onChange && _.isFunction(props.onChange)) {
      right.push(sides.change(props.onChange));
    }

    if (props.isLoading) {
      right.unshift(sides.loader());
    } else {
      right.unshift(sides.resolutionLock(props.resolution));
    }

    return {
      placeholder: t("form.placeholder.text"),
      ...props,
      right,
      isLocked: true,
      isDisabled: true,
    };
  }, [props, t]);

  return <Template.Text {...formatted} />;
}

export default memo(LockedChange, _.isEqual);
