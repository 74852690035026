import { _ } from "@sablier/v2-mixins";
import { Abi } from "abitype";
import { getAddress } from "viem";
import { simulateContract } from "wagmi/actions";
import type { Name, PrimitiveInputs, Purpose, Query } from "../types/helper";
import type {
  ISigner,
  ISignerOrProvider,
  IWagmiAddress,
  IWagmiConfig,
} from "@sablier/v2-types";
import type { SimulateContractParameters } from "wagmi/actions";

export function contextualize<P extends Purpose, M extends Name<P>>(
  address: IWagmiAddress | string,
  chainId: number,
  purpose: P,
  method: M,
  inputs: PrimitiveInputs<P, M>,
): Query<P, M> {
  return {
    address: getAddress(_.toAddress(address) as IWagmiAddress),
    chainId,
    purpose,
    method,
    inputs,
  };
}

export interface PrepareProps {
  query: ReturnType<typeof contextualize>;
  signer: ISigner;
  gasLimit?: number;
}

export async function prepare(
  library: IWagmiConfig,
  { query, signer, gasLimit }: PrepareProps,
) {
  const abis = (await import("../abis")).default;

  const simulation: SimulateContractParameters = {
    account: getAddress(_.toAddress(signer.account?.address) as IWagmiAddress),
    abi: abis[query.purpose] as Abi,
    address: query.address,
    chainId: query.chainId,
    functionName: query.method,
    args: query.inputs,
    gas: !_.isNil(gasLimit) ? _.toBigInt(gasLimit) : undefined,
    value: 0n,
  };

  return simulateContract(library, simulation);
}

export interface PreviewProps {
  queries: ReturnType<typeof contextualize>[];
  provider?: ISignerOrProvider;
}

export async function preview({ queries }: PreviewProps) {
  const abis = (await import("../abis")).default;

  return queries.map((query) => ({
    abi: abis[query.purpose],
    address: getAddress(query.address),
    chainId: query.chainId,
    functionName: query.method,
    args: query.inputs,
    purpose: query.purpose,
  }));
}
