import { useEffect } from "react";
import { _ } from "@sablier/v2-mixins";
import { useRouter } from "next/router";
import { tabs } from "~/client/constants";
import useStreamDashboardQuery from "./useStreamDashboardQuery";

const dashboard = tabs.dashboard;

export default function useStreamDashboardWatcher(reroute = false) {
  const router = useRouter();
  const { identifier, isReady, isFallback } = useStreamDashboardQuery();

  useEffect(() => {
    if (isReady && isFallback && reroute) {
      void router.replace(dashboard.all.builder(), undefined, {
        shallow: true,
      });
    }
  }, [identifier, isReady, isFallback, router, reroute]);
}
