import { useMemo } from "react";
import { useT } from "@sablier/v2-locales";
import type { ISInput } from "../system/templates";
import Template, { sides } from "../system/templates";

export interface Props extends Omit<ISInput, "left" | "right"> {}

function Text(props: Props) {
  const { t } = useT();
  const formatted = useMemo(() => {
    const right: ISInput["right"] = [];

    if (props.isLoading) {
      right.unshift(sides.loader());
    }

    return {
      placeholder: t("form.placeholder.text"),
      right,
      ...props,
    };
  }, [props, t]);

  return <Template.Text {...formatted} />;
}

export default Text;
