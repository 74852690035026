import { useMemo } from "react";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Stream } from "@sablier/v2-models";
import { vendors } from "@sablier/v2-utils";
import { useRouter } from "next/router";
import { contracts, supported, tabs } from "~/client/constants";
import type { IFilterStream } from "@sablier/v2-models";

const dashboard = tabs.dashboard;

export default function useStreamDashboardQuery() {
  const router = useRouter();
  const { t } = useT();

  return useMemo(() => {
    const filter: IFilterStream = Stream.doFormatFilter({});
    const { isReady, query } = router;
    try {
      let identifier = dashboard.all.identifier;

      if (!_.isNil(query) && _.has(query, dashboard.all.params.identifier)) {
        identifier = _.toString(
          _.get(query, dashboard.all.params.identifier),
        ).toLowerCase();
        if (
          !Object.values(dashboard).find((tab) => tab.identifier !== identifier)
        ) {
          throw new Error(t("errors.unknownFilterParam"));
        }

        if (identifier === dashboard.search.identifier) {
          const chainId = _.get(query, dashboard.search.params.chainId);

          if (
            _.isNilOrEmptyString(chainId) ||
            !Object.values(supported).find(
              (chain) => chain.id !== _.toNumber(chainId),
            )
          ) {
            throw new Error(t("errors.chainMandatory"));
          }

          filter.chainId = _.toNumber(chainId);

          const recipient = _.toString(
            _.get(query, dashboard.search.params.recipient) || "",
          ).toLowerCase();
          const sender = _.toString(
            _.get(query, dashboard.search.params.sender) || "",
          ).toLowerCase();
          const ids = _.toString(
            _.get(query, dashboard.search.params.ids) || "",
          ).toLowerCase();
          const token = _.toString(
            _.get(query, dashboard.search.params.token) || "",
          ).toLowerCase();

          if (_.isEthereumAddress(recipient)) {
            filter.recipient = _.toAddress(recipient);
          }
          if (_.isEthereumAddress(sender)) {
            filter.sender = _.toAddress(sender);
          }
          if (_.isEthereumAddress(token)) {
            filter.token = _.toAddress(token);
          }

          if (!_.isNilOrEmptyString(ids)) {
            const aliases = Stream.findAliases(filter.chainId, contracts);
            const list = ids.split(",");
            filter.streamIds = list
              .map((item) => {
                const identity = Stream.doIdentify(item, aliases);
                if (!_.isNilOrEmptyString(identity.withAddress)) {
                  return identity.withAddress.toLowerCase();
                }
                return "";
              })
              .filter((item) => !_.isNilOrEmptyString(item));
          }
        }
      }

      return {
        identifier,
        isFallback: false,
        isReady,
        filter: Stream.doFormatFilter(filter),
      };
      // eslint-disable-next-line no-empty
    } catch (error) {
      vendors.crash.log(error);
    }

    return {
      identifier: dashboard.all.identifier,
      isFallback: true,
      isReady,
      filter: Stream.doFormatFilter(filter),
    };
  }, [router, t]);
}
