import { createContext } from "react";
import { Toast } from "@sablier/v2-components/organisms";
import { default as ModalCards } from "~/client/components/organisms/ModalCards";
import { default as ModalCovers } from "~/client/components/organisms/ModalCovers";
import { default as ModalLists } from "~/client/components/organisms/ModalLists";
import { useWatcher } from "~/client/hooks";
import type { PropsWithChildren } from "react";

export const Context = createContext<unknown>({});

export default function WatcherProvider({
  children,
}: PropsWithChildren<unknown>) {
  const { isSanctioned } = useWatcher();

  return (
    <Context.Provider value={{}}>
      {!isSanctioned && children}
      <ModalCards />
      <ModalCovers />
      <ModalLists />
      <Toast />
    </Context.Provider>
  );
}
