import type { IDynamicCliffExponential } from "../dynamic/CliffExponential";
import type { IDynamicExponential } from "../dynamic/Exponential";
import type { IDynamicUnlockCliff } from "../dynamic/UnlockCliff";
import type { IDynamicUnlockLinear } from "../dynamic/UnlockLinear";
import type { ICliff } from "../linear/Cliff";
import type { ILinear } from "../linear/Linear";
import type { ITranchedBackweighted } from "../tranched/Backweighted";
import type { ITranchedMonthly } from "../tranched/Monthly";
import type { ITranchedStepper } from "../tranched/Stepper";
import type { ITranchedTimelock } from "../tranched/Timelock";
import { extension as DynamicCliffExponential } from "../dynamic/CliffExponential";
import { extension as DynamicExponential } from "../dynamic/Exponential";
import { extension as DynamicMonthly } from "../dynamic/Monthly";
import { extension as DynamicStepper } from "../dynamic/Stepper";
import { extension as DynamicTimelock } from "../dynamic/Timelock";
import { extension as DynamicUnlockCliff } from "../dynamic/UnlockCliff";
import { extension as DynamicUnlockLinear } from "../dynamic/UnlockLinear";
import { extension as Cliff } from "../linear/Cliff";
import { extension as Linear } from "../linear/Linear";
import { extension as TranchedBackweighted } from "../tranched/Backweighted";
import { extension as TranchedMonthly } from "../tranched/Monthly";
import { extension as TranchedStepper } from "../tranched/Stepper";
import { extension as TranchedTimelock } from "../tranched/Timelock";
import { Extension, IConfiguration } from "./setup";

const config: { [key: Extension]: IConfiguration } = {
  [Extension.CLIFF]: Cliff,
  [Extension.LINEAR]: Linear,

  [Extension.DYNAMIC_CLIFF_EXPONENTIAL]: DynamicCliffExponential,
  [Extension.DYNAMIC_EXPONENTIAL]: DynamicExponential,
  [Extension.DYNAMIC_UNLOCK_LINEAR]: DynamicUnlockLinear,
  [Extension.DYNAMIC_UNLOCK_CLIFF]: DynamicUnlockCliff,

  [Extension.TRANCHED_BACKWEIGHTED]: TranchedBackweighted,
  [Extension.TRANCHED_TIMELOCK]: TranchedTimelock,
  [Extension.TRANCHED_MONTHLY]: TranchedMonthly,
  [Extension.TRANCHED_STEPPER]: TranchedStepper,
  /**
   * ------------------------------------------------------------
   * Deprecated shapes must be kept for backwards compatibility,
   * especially for `identify()` and `simulate()`
   */

  /** @deprecated in favor of extensions/tranched/Timelock */
  [Extension.DYNAMIC_TIMELOCK]: DynamicTimelock,
  /** @deprecated in favor of extensions/tranched/Monthly */
  [Extension.DYNAMIC_MONTHLY]: DynamicMonthly,
  /** @deprecated in favor of extensions/tranched/Stepper */
  [Extension.DYNAMIC_STEPPER]: DynamicStepper,
};
/** Used to prioritize identifying stream shapes (some shapes may look similar) */
const ordered: (keyof typeof config)[] = [
  Extension.CLIFF,
  Extension.LINEAR,

  Extension.TRANCHED_BACKWEIGHTED,
  Extension.TRANCHED_MONTHLY,
  Extension.TRANCHED_TIMELOCK,
  Extension.TRANCHED_STEPPER,

  Extension.DYNAMIC_MONTHLY,
  Extension.DYNAMIC_TIMELOCK,
  Extension.DYNAMIC_UNLOCK_CLIFF,
  Extension.DYNAMIC_UNLOCK_LINEAR,
  Extension.DYNAMIC_CLIFF_EXPONENTIAL,
  Extension.DYNAMIC_STEPPER,
  Extension.DYNAMIC_EXPONENTIAL,
];

type IExtensionForm =
  | ICliff
  | ILinear
  | IDynamicCliffExponential
  | IDynamicExponential
  | ITranchedTimelock
  | IDynamicUnlockLinear
  | IDynamicUnlockCliff
  | ITranchedStepper
  | ITranchedMonthly
  | ITranchedBackweighted;

type IExtensionHeadersField = Exclude<
  | keyof ICliff
  | keyof ILinear
  | keyof IDynamicCliffExponential
  | keyof IDynamicExponential
  | keyof IDynamicUnlockLinear
  | keyof IDynamicUnlockCliff
  | keyof ITranchedStepper
  | keyof ITranchedMonthly
  | keyof ITranchedBackweighted
  | keyof ITranchedTimelock,
  "purpose"
>;

const linear: Extension[] = [Extension.LINEAR, Extension.CLIFF];
const tranched: Extension[] = [
  Extension.TRANCHED_BACKWEIGHTED,
  Extension.TRANCHED_STEPPER,
  Extension.TRANCHED_MONTHLY,
  Extension.TRANCHED_TIMELOCK,
];

const dynamic: Extension[] = [
  Extension.DYNAMIC_EXPONENTIAL,
  Extension.DYNAMIC_CLIFF_EXPONENTIAL,
  /** ---------------------------- */
  Extension.DYNAMIC_EXPONENTIAL,
  Extension.DYNAMIC_STEPPER,
  Extension.DYNAMIC_MONTHLY,
  Extension.DYNAMIC_TIMELOCK,
  Extension.DYNAMIC_UNLOCK_LINEAR,
  Extension.DYNAMIC_UNLOCK_CLIFF,
];

export type { IExtensionForm, IExtensionHeadersField };
export { linear, dynamic, tranched, ordered };
export default config;
