import { useMemo } from "react";
import styled from "styled-components";
import type { ISInput } from "../system/templates";
import Template, { sides } from "../system/templates";

const Templated = styled(Template.Text)`
  *[data-component="sides"][data-on="left"] {
    *[data-component="side"][data-purpose="chain"] {
      p {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

export interface Props
  extends Omit<ISInput, "left" | "right" | "onChange" | "onClick" | "value"> {
  onChange: () => void;
  value?: string[];
}

function formatValue(countries: string[]): string {
  if (countries.length > 3) {
    const firstThreeCountries = countries.slice(0, 3);
    const remainingCountriesCount = countries.length - 3;
    return `${firstThreeCountries.join(
      ", ",
    )} and ${remainingCountriesCount} more`;
  } else {
    return countries.join(", ");
  }
}

function CountryPicker(props: Props) {
  const formatted: ISInput = useMemo(() => {
    const value = props.value ? formatValue(props.value) : undefined;

    const right: ISInput["right"] = [
      sides.choose(props.onChange as () => void),
    ];

    if (props.isLoading) {
      right.unshift(sides.loader());
    }

    return {
      ...props,
      isPreview: "soft",
      onClick: props.onChange,
      placeholder: props.placeholder,
      right,
      value,
    };
  }, [props]);

  return <Templated {...formatted} />;
}

export default CountryPicker;
