import styled from "styled-components";
import { External } from "@sablier/v2-components/atoms";
import { links } from "@sablier/v2-constants";
import { Trans, useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";

const WrapperPartial = styled.div`
  position: fixed;
  bottom: calc(${(props) => props.theme.sizes.edge} * 1);
  z-index: ${(props) => props.theme.sizes.zIndexOver};
  width: 100%;
`;

const Container = styled.div`
  ${(props) => props.theme.styles.contained}
  & {
    width: 100%;
  }
`;

const Box = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: 2px;
    width: 100%;
    padding: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    color: ${(props) => props.theme.colors.gray100};
    border: 2px solid ${(props) => props.theme.colors.border};
    border-left: 2px solid ${(props) => props.theme.colors.purple};
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.dark100};
    p {
      ${(props) => props.theme.styles.textParagraphMini}
    }

    a > p {
      text-decoration: underline;
    }
    a {
      font-weight: 700;
      text-decoration: underline;
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxLG} {
    position: relative;
    bottom: 0;
    margin-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
    ${Box} {
      flex-direction: column;
      gap: 0;
      align-items: flex-start;
      border-radius: 8px;
      p {
        line-height: 1.5;
      }
    }
  }
`;

function Disclaimer() {
  const { t } = useT();
  return (
    <Wrapper>
      <Container>
        <Box>
          <p>
            <b>{_.capitalize(t("words.disclaimer"))}:</b>{" "}
            <span>
              <Trans
                i18nKey={`descriptions.airstream.disclaimer`}
                components={[
                  <External key={0} to={links.legal.risk} />,
                  <External key={1} to={links.legal.terms} />,
                  <External key={2} to={links.legal.privacy} />,
                ]}
              />
            </span>
          </p>
        </Box>
      </Container>
    </Wrapper>
  );
}

export default Disclaimer;
