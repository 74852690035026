import { useEffect, useMemo, useRef, useState } from "react";
import { _ } from "@sablier/v2-mixins";

interface Props {
  preset?: { height?: number; width?: number };
}

export default function useContainerSize(props?: Props) {
  const { preset } = useMemo(() => props || {}, [props]);
  const container = useRef<HTMLDivElement>(null);
  const [sizes, setSizes] = useState<{ height: number; width: number }>({
    height: preset?.height || 0,
    width: preset?.width || 0,
  });

  /**
   * Effects
   * ---------
   * Measure the height and width  of the parent container to define the size of the box
   */

  useEffect(() => {
    const measure = () => {
      if (_.isWindow() && container && container.current && !preset) {
        const sizes = container.current.getBoundingClientRect();
        setSizes({ height: sizes.height, width: sizes.width });
      }
    };

    if (_.isWindow() && !preset) {
      window.addEventListener("resize", measure);
      measure();
    }

    return () => {
      if (_.isWindow() && !preset) {
        window.removeEventListener("resize", measure);
      }
    };
  }, [container, preset]);

  return {
    ref: container,
    sizes,
  };
}
