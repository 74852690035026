import { useRef } from "react";
import { form } from "@sablier/v2-machines";
import { _ } from "@sablier/v2-mixins";
import { useMachine } from "@xstate/react";

type Generator<Check, Create, Result, Preprocess> = typeof form.create<
  Check,
  Create,
  Result,
  Preprocess
>;
type Arguments<Check, Create, Result, Preprocess> = Parameters<
  Generator<Check, Create, Result, Preprocess>
>;
type Configuration<Check, Create, Result, Preprocess> = Arguments<
  Check,
  Create,
  Result,
  Preprocess
>["0"];

function useMachineForm<Check, Create, Result, Preprocess = undefined>(
  configuration: Configuration<Check, Create, Result, Preprocess>,
) {
  const reference = useRef(
    form.create<Check, Create, Result, Preprocess>(configuration),
  );

  return useMachine(reference.current);
}

export default useMachineForm;
