import { StreamCategory } from "@sablier/v2-constants";
import { guards } from "@sablier/v2-machines";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Stream, Token } from "@sablier/v2-models";
import type { ICliff } from "./config";
import type {
  IExtensionCertify,
  IExtensionCheck,
  IExtensionParamsSimulate,
  IPrecomputeParams,
  IPrecomputeResult,
} from "~/client/types";

type IExtension = ICliff;

export function certify({
  t,
  data,
  duration,
  start,
  end,
  field,
}: IExtensionCertify): string | undefined {
  const fields = _.omit(data as IExtension, ["purpose"]);

  if (field === "cliffDuration") {
    return guards.validateDuration({
      t,
      purpose: "cliff",
      max: duration,
      min: "0",
      value: fields.cliffDuration.value,
    });
  }

  if (field === "cliffEnd") {
    return guards.validateMoment({
      t,
      purpose: "cliff",
      min: start,
      value: fields.cliffEnd.value,
      max: end,
    });
  }

  return undefined;
}

export function check({
  t,
  data,
  isLoadingIncluded = false,
  isWarningIncluded = false,
  timing,
}: IExtensionCheck): string | undefined {
  const fields = _.omit(data as IExtension, ["purpose"]);
  const flags = guards.validateFormFlags({
    t,
    isWarningIncluded,
    isLoadingIncluded,
    value: fields,
  });

  if (!_.isNilOrEmptyString(flags)) {
    return flags;
  }

  const ids: (keyof typeof fields)[] =
    timing === "duration" ? ["cliffDuration"] : ["cliffEnd"];
  const required = guards.validateFormRequired({
    t,
    required: ids,
    value: {
      cliffDuration: fields.cliffDuration,
      cliffEnd: fields.cliffEnd,
    },
  });

  if (!_.isNilOrEmptyString(required)) {
    return required;
  }

  return undefined;
}

export function identify(stream: Stream): boolean {
  return stream.category === StreamCategory.LOCKUP_LINEAR && stream.cliff;
}

export function precomputeSingle({
  dependencies,
}: IPrecomputeParams<"single">): IPrecomputeResult {
  const { amount, token } = dependencies;

  return {
    amount: _.toValuePrepared({
      decimals: token!.decimals,
      humanized: amount,
    }),
  };
}

export function precomputeGroup({
  dependencies,
}: IPrecomputeParams<"group">): IPrecomputeResult {
  const { streams, token } = dependencies;

  return {
    amount: _.toValuePrepared({
      humanized: streams?.reduce(
        (p, c) => p.plus(new BigNumber(c.amount || 0)),
        new BigNumber(0),
      ),
      decimals: token?.decimals,
    }),
  };
}

export const precompute = { group: precomputeGroup, single: precomputeSingle };

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */
export function simulate(
  params: IExtensionParamsSimulate & { timing: "duration" },
): Stream;
export function simulate(
  params: IExtensionParamsSimulate & { timing: "range" },
): Stream;
export function simulate(params: IExtensionParamsSimulate): Stream;

export function simulate({
  dependencies,
  extras,
  timing,
}: IExtensionParamsSimulate): Stream {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  //const { purpose } = extras as IExtension;
  const {
    amount,
    cancelability,
    transferability,
    chainId,
    duration,
    end,
    start,
    token,
  } = dependencies;

  const { purpose: _purpose, ...extended } = extras as IExtension;

  const deposit = _.toValuePrepared({
    humanized: amount,
    decimals: token?.decimals,
  });

  const streamToken = new Token({
    address: token!.address,
    chainId,
    decimals: token!.decimals,
    name: token!.name,
    symbol: token!.symbol,
  });

  const startTime =
    timing === "duration"
      ? _.toSeconds(new BigNumber(Date.now()).toString())
      : _.toSeconds(start);

  const endTime =
    timing === "duration"
      ? _.toSeconds(
          new BigNumber(Date.now()).plus(new BigNumber(duration!)).toString(),
        )
      : _.toSeconds(end);

  const cliffTime =
    timing === "duration"
      ? _.toSeconds(
          new BigNumber(Date.now())
            .plus(new BigNumber(extended.cliffDuration.value!))
            .toString(),
        )
      : _.toSeconds(extended.cliffEnd.value);

  return new Stream(
    {
      ...Stream.base(),
      chainId,
      cancelable: cancelability!,
      transferable: transferability!,
      category: StreamCategory.LOCKUP_LINEAR,
      cliff: true,
      depositAmount: deposit,
      endTime,
      cliffTime,
      intactAmount: deposit,
      startTime,
    },
    streamToken,
  );
}
