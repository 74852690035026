import { createElement, memo } from "react";
import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import Amount from "~/components/molecules/Amount";
import Button from "~/components/molecules/Button";
import Chain from "~/components/molecules/Chain";
import Icon from "~/components/molecules/Icon";
import Token from "~/components/molecules/Token";
import type { ExoticComponent, FC, ReactNode } from "react";
import type { Props as AmountProps } from "~/components/molecules/Amount";
import type { Props as ButtonProps } from "~/components/molecules/Button";
import type { Props as ChainProps } from "~/components/molecules/Chain";
import type { Props as IconProps } from "~/components/molecules/Icon";
import type { Props as TokenProps } from "~/components/molecules/Token";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    flex-shrink: 0;
    justify-content: center;
    width: auto;
    min-width: 18px;

    & > *[data-purpose="spinner"] {
      color: ${(props) => props.theme.colors.yellow};
    }
  }
`;

type SidePropsAmount = {
  purpose: "amount";
  options: AmountProps;
};

type SidePropsButton = {
  purpose: "button";
  options: ButtonProps;
};

type SidePropsChain = {
  purpose: "chain";
  options: ChainProps;
};

type SidePropsCustom = {
  purpose: "custom";
  options: {
    value:
      | JSX.Element
      | FC
      | ReactNode
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | ExoticComponent<any>;
  };
};

type SidePropsIcon = {
  purpose: "icon";
  options: IconProps;
};

type SidePropsToken = {
  purpose: "token";
  options: TokenProps;
};

export type Props =
  | SidePropsAmount
  | SidePropsButton
  | SidePropsCustom
  | SidePropsChain
  | SidePropsIcon
  | SidePropsToken;

function renderCustomSide(value: SidePropsCustom["options"]["value"]) {
  if (typeof value === "string") {
    return value;
  }
  if (typeof value === "function") {
    return createElement(value as FC, {});
  }

  return undefined;
}

function Side(props: Props) {
  return (
    <Wrapper data-component={"side"} data-purpose={props.purpose}>
      {props.purpose === "amount" && <Amount {...props.options} />}
      {props.purpose === "button" && <Button {...props.options} />}
      {props.purpose === "chain" && <Chain {...props.options} />}
      {props.purpose === "custom"
        ? renderCustomSide(props.options.value)
        : false}
      {props.purpose === "icon" && <Icon {...props.options} />}
      {props.purpose === "token" && <Token {...props.options} />}
    </Wrapper>
  );
}

export default memo(Side, _.isEqual);
