import styled from "styled-components";
import type { PropsWithChildren } from "react";
import Base from "../Card";
import Details from "../Details";

const WrapperPartial = styled(Base)`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    width: 100%;
    & > *[data-component="card"] {
      border-radius: 8px;
      background-color: ${(props) => props.theme.colors.dark200};
    }
    *[data-component="frame"] {
      margin-right: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    }
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    row-gap: calc(${(props) => props.theme.sizes.formEdge} * 1);
    width: 100%;
    & > *:first-child {
      margin-right: auto;
    }

    *[data-component="content"] {
      color: ${(props) => props.theme.colors.gray200};
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${Content} {
    &[data-purpose="fail"] {
      *[data-component="frame"],
      *[data-component="label"] {
        color: ${(props) => props.theme.colors.red};
      }
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    p {
      line-height: 20pt;
    }

    & > * {
      padding: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
    }

    ${Content} > * {
      gap: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
      align-items: flex-start;
    }
  }
`;

interface Props {
  isHidden?: boolean;
  className?: string;
  label?: string;
  purpose?: "duration" | "fail" | "info" | "warning";
  value?: string;
}

function Ensurance({
  className,
  children,
  label,
  isHidden = false,
  purpose = "warning",
  value,
}: PropsWithChildren<Props>) {
  if (isHidden) {
    return false;
  }

  return (
    <Wrapper className={className} isTopHidden>
      <Content data-purpose={purpose}>
        {purpose === "duration" && (
          <Details.Duration label={label} value={value}>
            {children}
          </Details.Duration>
        )}
        {purpose === "info" && (
          <Details.Info label={label} value={value}>
            {children}
          </Details.Info>
        )}
        {["fail", "warning"].includes(purpose) && (
          <Details.Ensurance label={label} value={value}>
            {children}
          </Details.Ensurance>
        )}
      </Content>
    </Wrapper>
  );
}

export default Ensurance;
