import { UserCircleIcon } from "@heroicons/react/24/outline";
import { tags } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Base } from "../../system";

interface Props {
  className?: string;
  permission: string | undefined;
}

function Depleted({ className }: Props) {
  const { t } = useT();

  return (
    <Base
      className={className}
      description={t("warnings.depleted.description")}
      cy={tags.warningDepleted}
      label={{
        icon: UserCircleIcon,
        value: t("warnings.depleted.title"),
      }}
    />
  );
}

export default Depleted;
