import {
  confirmations,
  delog,
  encode,
  encodeQuery,
  erroneous,
  fuel,
  read,
  safeAllowAndWrite,
  safeSign,
  safeWait,
  safeWrite,
  sign,
  signMessage,
  wait,
  write,
} from "./interact";
import { contextualize, prepare, preview } from "./remap";

/**
 * A facade meant to abstract away interactions with
 * the `viem` web3 library
 */

const framework = {
  contextualize,
  confirmations,
  delog,
  encode,
  encodeQuery,
  erroneous,
  fuel,
  read,
  safeAllowAndWrite,
  safeSign,
  safeWait,
  safeWrite,
  sign,
  signMessage,
  wait,
  write,
  prepare,
  preview,
};

export default framework;
