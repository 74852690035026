import { useMemo } from "react";
import { REQUEST_ID } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { Airstream } from "@sablier/v2-models";
import { client, queries } from "@sablier/v2-subgraphs";
import { useQuery } from "@tanstack/react-query";

interface Props {
  id?: string;
  key?: string[];
  isEnabled?: boolean;
  networkMode?: "always" | "online" | "offlineFirst";
}

export default function useRequestAirstreamMetadata({
  id,
  key = REQUEST_ID.airstreamItemMetadata,
  isEnabled = false,
  networkMode = "online",
}: Props) {
  const filter = useMemo(
    () =>
      Airstream.doFormatFilter({
        chainId: Airstream.doSplitIdentifier(id).chainId,
        airstreamIds: [id || "_"],
      }),
    [id],
  );

  const endpoint = useMemo(
    () =>
      client.getEndpointByChain({
        chainId: filter.chainId,
        feature: "airstream",
      }),
    [filter],
  );

  const variables = useMemo(() => {
    const chainId = filter.chainId;
    const airstreamId = _.isNil(filter.airstreamIds)
      ? "_"
      : filter.airstreamIds[0].toLowerCase();

    const dayTo = Math.floor(Date.now() / (60 * 60 * 24 * 1000));

    return {
      airstreamId,
      airstreamIdClone: airstreamId,
      chainId,
      dayFrom: dayTo - 16,
      dayTo,
    };
  }, [filter]);

  const { data, error, isLoading } = useQuery({
    queryKey: [...key, { unique: variables }],
    queryFn: async () => {
      if (!Airstream.isId(variables.airstreamId)) {
        throw new Error("Misconfigured (airstream metadata)");
      }
      return client.request(
        endpoint,
        queries.airstream.getMetadata_ByAirstream,
        variables,
      );
    },
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: isEnabled,
    networkMode,
    retry: false,
  });

  return useMemo(
    () => ({
      data,
      error,
      isLoading: isLoading && isEnabled,
    }),
    [data, error, isLoading, isEnabled],
  );
}
