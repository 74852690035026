import {
  EXPONENT_DECIMALS,
  MONTHLY_MAX_UNLOCKS_GROUP,
  MONTHLY_MIN_UNLOCKS,
  StreamCategory,
} from "@sablier/v2-constants";
import { guards } from "@sablier/v2-machines";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Segment, Stream, Token, Tranche } from "@sablier/v2-models";
import type { ITranchedMonthly } from "./config";
import type {
  IExtensionCertify,
  IExtensionCheck,
  IExtensionParamsSimulate,
  IPrecomputeParams,
  IPrecomputeResult,
} from "~/client/types";
import { UNLOCK_DURATION } from "./config";

type IExtension = ITranchedMonthly;

export function certify({
  t,
  data,
  field,
}: IExtensionCertify): string | undefined {
  const fields = _.omit(data as IExtension, ["purpose"]);

  if (field === "months") {
    return guards.validateAmount({
      t,
      context: "steps",
      max: (MONTHLY_MAX_UNLOCKS_GROUP + 1).toString() /** Strict < */,
      value: fields.months.value,
      min: (MONTHLY_MIN_UNLOCKS - 1).toString() /** Strict > */,
    });
  }

  return undefined;
}

export function check({
  t,
  data,
  isLoadingIncluded = false,
  isWarningIncluded = false,
}: IExtensionCheck): string | undefined {
  const fields = _.omit(data as IExtension, ["purpose"]);

  const flags = guards.validateFormFlags({
    t,
    isWarningIncluded,
    isLoadingIncluded,
    value: fields,
  });

  if (!_.isNilOrEmptyString(flags)) {
    return flags;
  }

  const ids: (keyof typeof fields)[] = ["months"];

  const required = guards.validateFormRequired({
    t,
    required: ids,
    value: { ...fields },
  });

  if (!_.isNilOrEmptyString(required)) {
    return required;
  }

  return undefined;
}

export function identify(stream: Stream): boolean {
  if (
    stream.category === StreamCategory.LOCKUP_TRANCHED &&
    stream.segments.length
  ) {
    let areSegmentsEqual = true;
    let areAmountsEqual = true;
    const initialSegmentDuration = new BigNumber(stream.segments[0].duration);
    const initialSegmentAmount = new BigNumber(stream.segments[1].amount.raw);

    if (stream.segments.length % 2 === 0) {
      stream.segments.forEach((segment, index) => {
        if (
          index % 2 == 0 &&
          !new BigNumber(segment.duration).eq(initialSegmentDuration)
        ) {
          areSegmentsEqual = false;
        }
        if (
          index % 2 == 1 &&
          areAmountsEqual &&
          !new BigNumber(segment.amount.raw).eq(initialSegmentAmount)
        ) {
          areAmountsEqual = false;
        }
        if (index % 2 == 1) {
          if (
            !new BigNumber(segment.duration).isLessThanOrEqualTo(
              new BigNumber(1000),
            )
          ) {
            return false;
          }
        } else if (!segment.amount.raw.isZero()) {
          return false;
        }
      });
      return !areSegmentsEqual && areAmountsEqual;
    }
  }
  return false;
}

export function precomputeSingle({
  dependencies,
  extras,
}: IPrecomputeParams<"single">): IPrecomputeResult {
  const { amount, token } = dependencies;
  const { purpose: _purpose, ...extended } = extras as IExtension;
  const hasInitialUnlock = extended.initial.value ?? false;
  const unlocks = hasInitialUnlock
    ? new BigNumber(extended.months.value || 0).plus(new BigNumber(1))
    : new BigNumber(extended.months.value || 0);
  const raw = _.toValuePrepared({
    humanized: amount,
    decimals: token!.decimals,
  });
  const increment = _.toValuePrepared({
    raw: new BigNumber(raw).dividedBy(unlocks),
    decimals: token?.decimals,
  });
  return {
    amount: _.toValuePrepared({
      raw: new BigNumber(increment).times(unlocks),
      decimals: token!.decimals,
    }),
  };
}

export function precomputeGroup({
  dependencies,
}: IPrecomputeParams<"group">): IPrecomputeResult {
  const { streams, token, signer } = dependencies;

  return {
    amount:
      streams
        ?.reduce((p, c) => {
          const { amount } = precomputeSingle({
            dependencies: {
              amount: c.amount,
              token,
              signer,
            },
            extras: c.extension,
          });
          return p.plus(new BigNumber(amount));
        }, new BigNumber(0))
        .toString() ?? "0",
  };
}

export const precompute = { group: precomputeGroup, single: precomputeSingle };

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */
export function simulate(
  params: IExtensionParamsSimulate & { timing: "duration" },
): Stream;
export function simulate(
  params: IExtensionParamsSimulate & { timing: "range" },
): Stream;
export function simulate(params: IExtensionParamsSimulate): Stream;

export function simulate({
  dependencies,
  extras,
}: IExtensionParamsSimulate): Stream {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const { purpose: _purpose, ...extended } = extras as IExtension;
  const { amount, cancelability, transferability, chainId, end, start, token } =
    dependencies;

  const deposit = _.toValuePrepared({
    humanized: amount,
    decimals: token?.decimals,
  });

  const streamToken = new Token({
    address: token!.address,
    chainId,
    decimals: token!.decimals,
    name: token!.name,
    symbol: token!.symbol,
  });

  const hasInitialUnlock = extended.initial.value ?? false;
  const startTime = _.toSeconds(start);
  const endTime = _.toSeconds(end);

  const unlock = new BigNumber(UNLOCK_DURATION).dividedBy(1000);
  const unlocks = new BigNumber(extended.months.value || 0);
  const N = [...Array(unlocks.toNumber()).keys()];
  const N2 = [...Array(unlocks.toNumber() * 2).keys()];
  const increment = hasInitialUnlock
    ? new BigNumber(deposit).dividedBy(unlocks.plus(new BigNumber(1)))
    : new BigNumber(deposit).dividedBy(unlocks);
  const segmentExponents = N.map(() => [
    _.toValuePrepared({ humanized: "1", decimals: EXPONENT_DECIMALS }),
    _.toValuePrepared({ humanized: "1", decimals: EXPONENT_DECIMALS }),
  ]).flat();
  const segmentAmounts = N.map(() => [
    new BigNumber(0),
    new BigNumber(increment),
  ]).flat();

  // if it has an initial unlock all the traditional segments should be delayed with a second
  const segmentStartTime = N.map((i) => {
    const firstMilestone = _.addCalendarUnit(start!, i.toString(), "month");
    const secondMilestone = _.addCalendarUnit(
      start!,
      (i + 1).toString(),
      "month",
    );
    return [
      i === 0 && hasInitialUnlock
        ? new BigNumber(_.toSeconds(firstMilestone)).plus(unlock)
        : new BigNumber(_.toSeconds(firstMilestone)),
      new BigNumber(_.toSeconds(secondMilestone)).minus(unlock),
    ];
  }).flat();

  const segmentEndTime = N.map((i) => {
    const milestone = _.addCalendarUnit(start!, (i + 1).toString(), "month");
    return [
      new BigNumber(_.toSeconds(milestone)).minus(unlock),
      new BigNumber(_.toSeconds(milestone)),
    ];
  }).flat();

  // if it has an initial unlock all the segments start amounts should be increased with the value corresponding to the increment
  const segmentStartAmount = hasInitialUnlock
    ? N.map((i) => [
        increment.times(new BigNumber(i + 1)),
        increment.times(new BigNumber(i + 1)),
      ]).flat()
    : N.map((i) => [
        increment.times(new BigNumber(i)),
        increment.times(new BigNumber(i)),
      ]).flat();

  // if it has an initial unlock all the segments end amounts should be increased with the value corresponding to the increment
  const segmentEndAmount = hasInitialUnlock
    ? N.map((i) => [
        increment.times(new BigNumber(i + 1)),
        increment.times(new BigNumber(i + 2)),
      ]).flat()
    : N.map((i) => [
        increment.times(new BigNumber(i)),
        increment.times(new BigNumber(i + 1)),
      ]).flat();

  let segments = N2.map((i) => ({
    id: hasInitialUnlock ? String(i + 1) : String(i),
    position: hasInitialUnlock ? String(i + 1) : String(i),
    milestone: "",
    startTime: segmentStartTime[i].toString(),
    startAmount: segmentStartAmount[i].toString(),
    endTime: segmentEndTime[i].toString(),
    endAmount: segmentEndAmount[i].toString(),
    exponent: segmentExponents[i],
    amount: segmentAmounts[i].toString(),
  }));

  if (hasInitialUnlock) {
    segments = [
      {
        id: "0",
        position: "0",
        milestone: "",
        startTime,
        startAmount: "0",
        endTime: new BigNumber(startTime).plus(unlock).toString(),
        endAmount: increment.toString(),
        exponent: segmentExponents[0],
        amount: increment.toString(),
      },
      ...segments,
    ];
  }

  return hasInitialUnlock
    ? new Stream(
        {
          ...Stream.base(),
          chainId,
          cancelable: cancelability!,
          transferable: transferability!,
          category: StreamCategory.LOCKUP_DYNAMIC,
          depositAmount: deposit,
          endTime,
          intactAmount: deposit,
          startTime,
          segments,
        },
        streamToken,
      )
    : new Stream(
        {
          ...Stream.base(),
          chainId,
          cancelable: cancelability!,
          transferable: transferability!,
          category: StreamCategory.LOCKUP_TRANCHED,
          depositAmount: deposit,
          endTime,
          intactAmount: deposit,
          startTime,
          tranches: N.map(
            (i) =>
              Tranche.fromSegments(
                new Segment(segments[i * 2], streamToken),
                new Segment(segments[i * 2 + 1], streamToken),
              ).payload,
          ),
        },
        streamToken,
      );
}
