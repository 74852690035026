import { useId } from "react";
import { useMachineFacilitator } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import {
  onCheck,
  onCreate,
} from "~/client/machines/specialized/airstreamEligibility";
import type {
  Check,
  Create,
  Transitive,
} from "~/client/machines/specialized/airstreamEligibility";

export default function useMachine() {
  const id = useId();

  const machine = useMachineFacilitator<Check, Create, Transitive>({
    id,
    onCheck,
    onCreate,
  });

  return {
    machine: machine[0],
    send: machine[1],
    onCheck,
    onCreate,
  };
}
