import { useMemo } from "react";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { ISInput } from "../system/templates";
import Template, { sides } from "../system/templates";

export interface Props extends Omit<ISInput, "left" | "right"> {
  onClear?: () => void;
}

function TextSearch(props: Props) {
  const { t } = useT();
  const formatted: ISInput = useMemo(() => {
    const left: ISInput["left"] = [sides.search()];

    const right: ISInput["right"] = [];
    if (props.onClear && !_.isNilOrEmptyString(props.value)) {
      right.push(sides.clear(props.onClear));
    }

    return {
      placeholder: t("form.placeholder.text"),
      ...props,
      left,
      right,
    };
  }, [props, t]);

  return <Template.Text {...formatted} />;
}

export default TextSearch;
