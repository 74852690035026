import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { _ } from "@sablier/v2-mixins";
import type { PropsWithChildren } from "react";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    color: ${(props) => props.theme.colors.secondaryDesaturated};
    column-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  }
`;

const Container = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex: 1;
    height: 22px;
  }
`;

const Bar1D = styled.svg.attrs((props) => props.theme.attributes.base)`
  width: 100%;
  height: 6px;
`;

const Bar2D = styled.svg.attrs((props) => props.theme.attributes.base)`
  width: 100%;
  height: 20px;
`;

const Path = styled.path.attrs((props) => props.theme.attributes.base)`
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 6px;
  stroke: ${(props) => props.theme.colors.gray200};
`;

const Filling = styled(Path)<{ end?: number }>`
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 6px;
  stroke: currentColor;

  ${(props) =>
    !_.isNilOrEmptyString(props.end) &&
    props.end < 3.5 &&
    css`
      display: none;
    `};
`;

const Cutoff = styled(Path)`
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 6px;
  stroke: ${(props) => props.theme.colors.transparent};
`;

const Percentage = styled.div`
  width: 42px;

  & > p {
    ${(props) => props.theme.styles.textElement}
    color:  ${(props) => props.theme.colors.gray100};
  }
`;

export interface Props {
  className?: string;
  percentage?: number;
  purpose?: "cliff" | "linear" | "dynamic" | "tranched" | string;
  size?: number;
}

function ProgressBar({
  className,
  children,
  percentage = 40,
  purpose = "linear",
  size = 122,
}: PropsWithChildren<Props>) {
  const container = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(size);

  const sizes = useMemo(() => {
    const length = width;
    const progress = (percentage / 100) * width;

    return {
      length: Number.isFinite(length) ? length : 100,
      percentage,
      progress: Number.isFinite(progress) ? progress : 100,
    };
  }, [percentage, width]);

  const label = useMemo(() => _.toPercentage(percentage), [percentage]);

  /**
   * Effects
   * ---------
   * Measure the width of the parent container to define the length of the progress svg and path(s)
   * We do this due to our need to preserve the aspect ratio while also scaling only certain parts of the shape
   */

  useEffect(() => {
    const measure = () => {
      if (_.isWindow() && container && container.current) {
        const sizes = container.current.getBoundingClientRect();
        setWidth(sizes.width);
      }
    };

    if (_.isWindow()) {
      window.addEventListener("resize", measure);
      measure();
    }

    return () => {
      if (_.isWindow()) {
        window.removeEventListener("resize", measure);
      }
    };
  }, []);

  const renderLinear = useCallback(() => {
    return (
      <Bar1D viewBox={`0 0 ${sizes.length} 6`}>
        {sizes.progress < sizes.length && (
          <Path d={`M 3 3 L ${sizes.length - 3} 3`} />
        )}
        <Filling d={`M 3 3 L ${sizes.progress - 3} 3`} />
      </Bar1D>
    );
  }, [sizes]);

  const renderCliff = useCallback(() => {
    return (
      <Bar2D viewBox={`0 0 ${sizes.length} 20`}>
        {sizes.progress < sizes.length && (
          <>
            <Path d={`M 3 17 L 30 17 L 40 3 L ${sizes.length - 3} 3`} />
          </>
        )}
        <Cutoff d={`M 3 17 L 30 17 L 40 3 L 46 3`} />
        <Filling
          d={`M 3 17 L 30 17 L 40 3 L ${sizes.length - 3} 3`}
          strokeDasharray={`${sizes.progress + 3}, ${sizes.length + 3}`}
        />
      </Bar2D>
    );
  }, [sizes]);

  const renderTranched = useCallback(() => {
    const cap = 3; /** Half of the stroke */
    const gap = 6;
    const segment = (sizes.length - 1 * gap) / 2;

    let filler = sizes.percentage;
    const s1 = Math.max(filler > 50 ? 100 : (filler * 100) / 50, 0);
    filler -= 50;
    const s2 = Math.max(filler > 50 ? 100 : (filler * 100) / 50, 0);

    return (
      <Bar1D viewBox={`0 0 ${sizes.length} 6`}>
        <Path d={`M ${cap} ${cap} L ${segment - cap} ${cap}`} />

        <Path
          d={`M ${cap} ${cap} L ${segment - cap} ${cap}`}
          transform={`translate(${segment * 1 + gap * 1} 0)`}
        />

        <Filling
          d={`M ${cap} ${cap} L ${(segment - cap) * (s1 / 100)} ${cap}`}
          end={s1}
        />
        <Filling
          d={`M ${cap} ${cap} L ${(segment - cap) * (s2 / 100)} ${cap}`}
          end={s2}
          transform={`translate(${segment * 1 + gap * 1} 0)`}
        />
      </Bar1D>
    );
  }, [sizes]);

  const renderDynamic = useCallback(() => {
    const cap = 3; /** Half of the stroke */
    const gap = 6;
    const segment = (sizes.length - 2 * gap) / 3;

    let filler = sizes.percentage;
    const s1 = Math.max(filler > 33.33 ? 100 : (filler * 100) / 33.33, 0);
    filler -= 33.33;
    const s2 = Math.max(filler > 33.33 ? 100 : (filler * 100) / 33.33, 0);
    filler -= 33.33;
    const s3 = Math.max(filler > 33.33 ? 100 : (filler * 100) / 33.33, 0);

    return (
      <Bar1D viewBox={`0 0 ${sizes.length} 6`}>
        <Path d={`M ${cap} ${cap} L ${segment - cap} ${cap}`} />
        <Path
          d={`M ${cap} ${cap} L ${segment - cap} ${cap}`}
          transform={`translate(${segment + gap} 0)`}
        />
        <Path
          d={`M ${cap} ${cap} L ${segment - cap} ${cap}`}
          transform={`translate(${segment * 2 + gap * 2} 0)`}
        />

        <Filling
          d={`M ${cap} ${cap} L ${(segment - cap) * (s1 / 100)} ${cap}`}
          end={s1}
        />
        <Filling
          d={`M ${cap} ${cap} L ${(segment - cap) * (s2 / 100)} ${cap}`}
          end={s2}
          transform={`translate(${segment + gap} 0)`}
        />
        <Filling
          d={`M ${cap} ${cap} L ${(segment - cap) * (s3 / 100)} ${cap}`}
          end={s3}
          transform={`translate(${segment * 2 + gap * 2} 0)`}
        />
      </Bar1D>
    );
  }, [sizes]);

  return (
    <Wrapper className={className} data-component={"progress-bar"}>
      <Container ref={container}>
        {purpose === "cliff" && renderCliff()}
        {purpose === "linear" && renderLinear()}
        {purpose === "dynamic" && renderDynamic()}
        {purpose === "tranched" && renderTranched()}
      </Container>
      {children}
      <Percentage data-component={"progress-label"}>
        <p>{label}</p>
      </Percentage>
    </Wrapper>
  );
}
export default ProgressBar;
