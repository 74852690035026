import { useCallback, useMemo } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Button } from "@sablier/v2-components/molecules";
import { tags } from "@sablier/v2-constants";
import { useChainData } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { useAccount, useChain } from "~/client/hooks";
import { Base } from "../../system";

interface Props {
  entity?: string;
  className?: string;
  toChainId?: number;
}

function Chain({ className, entity, toChainId }: Props) {
  const { connector, isConnected } = useAccount();
  const { switchTo } = useChain();
  const { chain: toChain } = useChainData(toChainId);
  const { t } = useT();

  const description = useMemo(() => {
    if (!isConnected) {
      return t("warnings.chain.connect");
    }

    if (_.isNilOrEmptyString(toChain)) {
      return t("warnings.chain.supported");
    } else {
      return t("warnings.chain.expected", {
        entity: entity || t("words.stream"),
        name: toChain.name,
      });
    }
  }, [entity, isConnected, toChain, t]);

  const onClick = useCallback(() => {
    if (switchTo && toChainId) {
      (async () => {
        try {
          await switchTo({ chainId: toChainId, connector });
        } catch (error) {
          vendors.crash.log(error);
        }
      })();
    }
  }, [connector, toChainId, switchTo]);

  return (
    <Base
      className={className}
      cy={tags.warningChain}
      description={description}
      label={{
        icon: ExclamationTriangleIcon,
        value: t("warnings.chain.title"),
      }}
    >
      <Button
        accent={"warning"}
        appearance={"outline"}
        isMini
        onClick={onClick}
        title={t("warnings.chain.action")}
      />
    </Base>
  );
}

export default Chain;
