import { _ } from "@sablier/v2-mixins";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import type { IStorePermissions } from "~/client/types";
import { PERMISSION_ID, PERSIST_ID } from "../constants";

export const initial: Omit<IStorePermissions, "api"> = {
  permissions: {
    ...Object.keys(PERMISSION_ID).reduce((p, c) => ({ ...p, [c]: false }), {}),
    seen: false,
  },
};

const useStore = create<IStorePermissions>()(
  devtools(
    persist(
      (set) => ({
        ...initial,
        api: {
          update: (value: Record<string, boolean>) => {
            set((prev) => ({
              ...prev,
              permissions: {
                ...prev.permissions,
                ...value,
              },
            }));
          },
          toggle: (key: string) =>
            set((prev) => {
              const clone = _.cloneDeep(prev);
              clone.permissions[key] = !prev.permissions[key];
              return clone;
            }),
        },
      }),
      {
        name: PERSIST_ID.permissions,
        partialize: (state) => ({ permissions: state.permissions }),
      },
    ),
  ),
);

export default useStore;
