import { useMemo } from "react";
import { REQUEST_ID } from "@sablier/v2-constants";
import { useRequestStreamOnchain } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Stream } from "@sablier/v2-models";
import { contracts } from "~/client/constants";
import { classifier } from "~/client/utils";
import useStreamCurrent from "./useStreamCurrent";

export default function useStreamCurrentOnchain() {
  const { t } = useT();
  const { id, isLoading, isMissing } = useStreamCurrent();

  const { address, purpose, chainId, tokenId } = useMemo(() => {
    const { source: address, chainId, tokenId } = Stream.doSplitIdentifier(id);
    return {
      address,
      purpose: classifier(chainId, address, "core"),
      chainId,
      tokenId,
    };
  }, [id]);

  const isEnabled = useMemo(
    () =>
      !_.isNilOrEmptyString(id) && !_.isNilOrEmptyString(purpose) && isMissing,
    [id, purpose, isMissing],
  );

  const title = useMemo(() => {
    if (!_.isNilOrEmptyString(id)) {
      const aliases = Stream.findAliases(chainId, contracts);
      const { withAlias, withAddress } = Stream.doIdentify(id, aliases);

      if (
        !(_.isNilOrEmptyString(withAddress) && _.isNilOrEmptyString(withAlias))
      ) {
        return `${_.capitalize(t("words.stream"))} ${_.toUpper(
          withAlias || withAddress,
        )}`;
      }
    }
    return _.capitalize(t("words.stream"));
  }, [chainId, id, t]);

  const {
    data,
    error,
    isLoading: isLoadingOnchain,
    isMissing: isMissingOnchain,
  } = useRequestStreamOnchain({
    key: REQUEST_ID.streamOnchain,
    id,
    purpose: purpose!,
    isEnabled,
  });

  return {
    address,
    chainId,
    data,
    error,
    isLoading: isLoading || isLoadingOnchain,
    isMissing: isMissingOnchain,
    title,
    tokenId,
  };
}
