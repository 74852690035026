import { _ } from "@sablier/v2-mixins";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import type { IModal, IStoreModal, RecursivePartial } from "@sablier/v2-types";

const useStore = create<IStoreModal>()(
  devtools(
    immer((set) => ({
      items: {},
      api: {
        reset: () =>
          set((state) => {
            state.items = {};
          }),
        open: <M extends IModal>(
          id: string,
          payload: Omit<M, "id" | "isOpen">,
        ) =>
          set((state) => {
            state.items[id] = state.items[id] || {};
            state.items[id].isOpen = true;
            state.items[id].data = payload.data;
          }),

        close: (id: string) =>
          set((state) => {
            state.items[id] = state.items[id] || {};
            state.items[id].isOpen = false;
          }),
        toggle: (id: string, isOpen: boolean) =>
          set((state) => {
            state.items[id] = state.items[id] || {};
            state.items[id].isOpen = isOpen;
          }),
        set: <M extends IModal>(id: string, payload: Omit<M, "id">) =>
          set((state) => {
            state.items[id] = { id, ...payload };
          }),

        update: <M extends IModal>(
          id: string,
          payload: { data: RecursivePartial<Omit<M, "id">>; isOpen?: boolean },
        ) =>
          set((state) => {
            if (!_.isNil(state.items[id])) {
              _.mergeWithLogic(state.items[id].data, payload.data);

              if (!_.isNil(payload.isOpen)) {
                state.items[id].isOpen = payload.isOpen;
              }
            }
          }),
      },
    })),
  ),
);

export default useStore;
