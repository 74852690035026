import { _ } from "@sablier/v2-mixins";
import { castDraft } from "immer";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import type { IStoreForm } from "@sablier/v2-types";

const useStore = <F>(initial: F) =>
  create<IStoreForm<F>>()(
    devtools(
      immer((set) => ({
        fields: initial,
        api: {
          resetFields: (keys) =>
            set((state) => {
              if (keys.length === 0) {
                state.fields = castDraft(initial);
              } else {
                const reset = keys
                  .map((key) => ({ [key]: initial[key] }))
                  .reduce((prev, current) => ({ ...prev, ...current }), {});
                state.fields = {
                  ...state.fields,
                  ...reset,
                };
              }
            }),
          setFields: (fields) =>
            set((state) => {
              state.fields = {
                ...state.fields,
                ...fields,
              };
            }),
          updateFields: (fields) =>
            set((state) => {
              _.mergeWithLogic(state.fields, fields);
            }),
        },
      })),
    ),
  );

export type IFields = { [key: string]: object };
export type IStoreCreator<F> = ReturnType<typeof useStore<F>>;

export default useStore;
