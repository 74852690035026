import nextDynamic from "next/dynamic";

const StreamCancel = nextDynamic(() => import("./Stream/Cancel"), {
  ssr: false,
});
const StreamCart = nextDynamic(() => import("./Stream/Cart"), {
  ssr: false,
});
const StreamRenounce = nextDynamic(() => import("./Stream/Renounce"), {
  ssr: false,
});
const StreamTransfer = nextDynamic(() => import("./Stream/Transfer"), {
  ssr: false,
});
const StreamWithdraw = nextDynamic(() => import("./Stream/Withdraw"), {
  ssr: false,
});

const AirstreamClawback = nextDynamic(() => import("./Airstream/Clawback"), {
  ssr: false,
});

const AirstreamFund = nextDynamic(() => import("./Airstream/Fund"), {
  ssr: false,
});

const AirstreamEligibility = nextDynamic(
  () => import("./Airstream/Eligibility"),
  {
    ssr: false,
  },
);

function All() {
  return (
    <>
      <AirstreamClawback />
      <AirstreamEligibility />
      <AirstreamFund />

      <StreamCart />
      <StreamRenounce />
      <StreamCancel />
      <StreamTransfer />
      <StreamWithdraw />
    </>
  );
}

export default All;
