import { useMemo } from "react";
import { useLocalTokensStore } from "./store";
import useChain from "./useChain";

export default function useTokensLocal(byChainId?: boolean | number) {
  const { id: currentChainId } = useChain();

  const chainId = useMemo(() => {
    if (byChainId === true) {
      return currentChainId;
    }
    return byChainId || currentChainId;
  }, [byChainId, currentChainId]);

  const { local, api } = useLocalTokensStore();
  const { addLocal, removeLocal } = api;

  const list = useMemo(
    () =>
      local
        .map((l) => {
          l.isLocal = true;
          return l;
        })
        .filter((item) => !byChainId || item.chainId === chainId),
    [chainId, byChainId, local],
  );

  return {
    addLocal,
    list,
    removeLocal,
  };
}
