import { EXPONENT_DECIMALS, StreamCategory } from "@sablier/v2-constants";
import { guards } from "@sablier/v2-machines/guards";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Stream, Token } from "@sablier/v2-models";
import type { IDynamicUnlockLinear } from "./config";
import type {
  IExtensionCertify,
  IExtensionCheck,
  IExtensionParamsSimulate,
  IPrecomputeParams,
  IPrecomputeResult,
} from "~/client/types";
import { EXPECTED_SEGMENTS, UNLOCK_DURATION } from "./config";

type IExtension = IDynamicUnlockLinear;

export function certify({
  t,
  data,
  amount,
  field,
}: IExtensionCertify): string | undefined {
  const fields = _.omit(data as IExtension, ["purpose"]);

  if (field === "unlock") {
    return guards.validateAmount({
      t,
      context: "wallet",
      max: amount,
      value: fields.unlock.value,
      min: "0",
    });
  }

  return undefined;
}

export function check({
  t,
  data,
  isLoadingIncluded = false,
  isWarningIncluded = false,
}: IExtensionCheck): string | undefined {
  const fields = _.omit(data as IExtension, ["purpose"]);

  const flags = guards.validateFormFlags({
    t,
    isWarningIncluded,
    isLoadingIncluded,
    value: fields,
  });

  if (!_.isNilOrEmptyString(flags)) {
    return flags;
  }

  const ids: (keyof typeof fields)[] = ["unlock"];

  const required = guards.validateFormRequired({
    t,
    required: ids,
    value: { ...fields },
  });

  if (!_.isNilOrEmptyString(required)) {
    return required;
  }

  return undefined;
}

export function identify(stream: Stream): boolean {
  return (
    stream.category === StreamCategory.LOCKUP_DYNAMIC &&
    stream.segments.length === EXPECTED_SEGMENTS &&
    new BigNumber(stream.segments[0].duration).isEqualTo(
      new BigNumber(UNLOCK_DURATION),
    ) &&
    stream.segments[1].exponent.humanized.isEqualTo(new BigNumber(1))
  );
}

export function precomputeSingle({
  dependencies,
}: IPrecomputeParams<"single">): IPrecomputeResult {
  const { amount, token } = dependencies;

  return {
    amount: _.toValuePrepared({
      decimals: token!.decimals,
      humanized: amount,
    }),
  };
}

export function precomputeGroup({
  dependencies,
}: IPrecomputeParams<"group">): IPrecomputeResult {
  const { streams, token } = dependencies;

  return {
    amount: _.toValuePrepared({
      humanized: streams?.reduce(
        (p, c) => p.plus(new BigNumber(c.amount || 0)),
        new BigNumber(0),
      ),
      decimals: token?.decimals,
    }),
  };
}

export const precompute = { group: precomputeGroup, single: precomputeSingle };

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */
export function simulate(
  params: IExtensionParamsSimulate & { timing: "duration" },
): Stream;
export function simulate(
  params: IExtensionParamsSimulate & { timing: "range" },
): Stream;
export function simulate(params: IExtensionParamsSimulate): Stream;

export function simulate({
  dependencies,
  extras,
  timing,
}: IExtensionParamsSimulate): Stream {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const { purpose: _purpose, ...extended } = extras as IExtension;
  const {
    amount,
    cancelability,
    transferability,
    chainId,
    duration,
    end,
    start,
    token,
  } = dependencies;

  const exponent = _.toValuePrepared({
    humanized: "1",
    decimals: EXPONENT_DECIMALS,
  });

  const deposit = _.toValuePrepared({
    humanized: amount,
    decimals: token?.decimals,
  });

  const initialUnlock = _.toValuePrepared({
    decimals: token!.decimals,
    humanized: extended.unlock.value,
  });

  const remainingAmount = new BigNumber(deposit)
    .minus(new BigNumber(initialUnlock))
    .toString();

  const streamToken = new Token({
    address: token!.address,
    chainId,
    decimals: token!.decimals,
    name: token!.name,
    symbol: token!.symbol,
  });

  const startTime =
    timing === "duration"
      ? _.toSeconds(new BigNumber(Date.now()).toString())
      : _.toSeconds(start);

  const endTime =
    timing === "duration"
      ? _.toSeconds(
          new BigNumber(Date.now()).plus(new BigNumber(duration!)).toString(),
        )
      : _.toSeconds(end);

  return new Stream(
    {
      ...Stream.base(),
      chainId,
      cancelable: cancelability!,
      transferable: transferability!,
      category: StreamCategory.LOCKUP_DYNAMIC,
      depositAmount: deposit,
      endTime,
      intactAmount: deposit,
      startTime,
      segments: [
        {
          id: "0",
          position: "0",
          amount: initialUnlock,
          milestone: "",
          endAmount: initialUnlock,
          endTime: (_.toNumber(startTime) + 1).toString(),
          startTime,
          startAmount: "0",
          exponent,
        },
        {
          id: "1",
          position: "1",
          amount: remainingAmount,
          milestone: "",
          endAmount: deposit,
          endTime,
          startTime: (_.toNumber(startTime) + 1).toString(),
          startAmount: initialUnlock,
          exponent,
        },
      ],
    },
    streamToken,
  );
}
