import { EXPONENT_DECIMALS, StreamCategory } from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { Stream, Token } from "@sablier/v2-models";
import type { IDynamicStepper } from "./config";
import type {
  IExtensionCertify,
  IExtensionCheck,
  IExtensionParamsSimulate,
  IPrecomputeParams,
  IPrecomputeResult,
} from "~/client/types";
import { UNLOCK_DURATION } from "./config";

type IExtension = IDynamicStepper;

export function certify(_params: IExtensionCertify): string | undefined {
  throw new Error("Deprecated implementation in favor of Tranched Stepper.");
}

export function check(_params: IExtensionCheck): string | undefined {
  throw new Error("Deprecated implementation in favor of Tranched Stepper.");
}

export function identify(stream: Stream): boolean {
  if (
    stream.category === StreamCategory.LOCKUP_DYNAMIC &&
    stream.segments.length % 2 === 0
  ) {
    stream.segments.forEach((segment, index) => {
      if (index % 2 == 1) {
        if (
          !new BigNumber(segment.duration).isLessThanOrEqualTo(
            new BigNumber(1000),
          )
        ) {
          return false;
        }
      } else if (!segment.amount.raw.isZero()) {
        return false;
      }
    });
    return true;
  }
  return false;
}

export function precomputeSingle(
  _props: IPrecomputeParams<"single">,
): IPrecomputeResult {
  throw new Error("Deprecated implementation in favor of Tranched Stepper.");
}

export function precomputeGroup(
  _props: IPrecomputeParams<"group">,
): IPrecomputeResult {
  throw new Error("Deprecated implementation in favor of Tranched Stepper.");
}

export const precompute = { group: precomputeGroup, single: precomputeSingle };

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */
export function simulate(
  params: IExtensionParamsSimulate & { timing: "duration" },
): Stream;
export function simulate(
  params: IExtensionParamsSimulate & { timing: "range" },
): Stream;
export function simulate(params: IExtensionParamsSimulate): Stream;

export function simulate({
  dependencies,
  timing,
  extras,
}: IExtensionParamsSimulate): Stream {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const { purpose: _purpose, ...extended } = extras as IExtension;
  const {
    amount,
    cancelability,
    transferability,
    chainId,
    duration,
    end,
    start,
    token,
  } = dependencies;

  const deposit = _.toValuePrepared({
    humanized: amount,
    decimals: token?.decimals,
  });

  const streamToken = new Token({
    address: token!.address,
    chainId,
    decimals: token!.decimals,
    name: token!.name,
    symbol: token!.symbol,
  });

  const startTime =
    timing === "duration"
      ? _.toSeconds(new BigNumber(Date.now()).toString())
      : _.toSeconds(start);

  const endTime =
    timing === "duration"
      ? _.toSeconds(
          new BigNumber(Date.now()).plus(new BigNumber(duration!)).toString(),
        )
      : _.toSeconds(end);

  const unlock = new BigNumber(UNLOCK_DURATION).dividedBy(1000);
  const steps = new BigNumber(extended.steps.value || 0);
  const totalD = new BigNumber(endTime).minus(new BigNumber(startTime));
  const segmentD = totalD.dividedBy(steps);
  const N = [...Array(steps.toNumber()).keys()];
  const N2 = [...Array(steps.toNumber() * 2).keys()];
  const increment = new BigNumber(deposit).dividedBy(steps);
  const segmentExponents = N.map(() => [
    _.toValuePrepared({ humanized: "1", decimals: EXPONENT_DECIMALS }),
    _.toValuePrepared({ humanized: "1", decimals: EXPONENT_DECIMALS }),
  ]).flat();
  const segmentAmounts = N.map(() => [
    new BigNumber(0),
    new BigNumber(increment),
  ]).flat();
  const segmentStartTime = N.map((i) => [
    new BigNumber(startTime).plus(segmentD.times(new BigNumber(i))),
    new BigNumber(startTime)
      .plus(segmentD.times(new BigNumber(i + 1)))
      .minus(unlock),
  ]).flat();
  const segmentEndTime = N.map((i) => [
    new BigNumber(startTime)
      .plus(segmentD.times(new BigNumber(i + 1)))
      .minus(unlock),
    new BigNumber(startTime).plus(segmentD.times(new BigNumber(i + 1))),
  ]).flat();
  const segmentStartAmount = N.map((i) => [
    increment.times(new BigNumber(i)),
    increment.times(new BigNumber(i)),
  ]).flat();
  const segmentEndAmount = N.map((i) => [
    increment.times(new BigNumber(i)),
    increment.times(new BigNumber(i + 1)),
  ]).flat();

  const segments = N2.map((i) => ({
    id: String(i),
    position: String(i),
    milestone: "",
    startTime: segmentStartTime[i].toString(),
    startAmount: segmentStartAmount[i].toString(),
    endTime: segmentEndTime[i].toString(),
    endAmount: segmentEndAmount[i].toString(),
    exponent: segmentExponents[i],
    amount: segmentAmounts[i].toString(),
  }));

  return new Stream(
    {
      ...Stream.base(),
      chainId,
      cancelable: cancelability!,
      transferable: transferability!,
      category: StreamCategory.LOCKUP_DYNAMIC,
      depositAmount: deposit,
      endTime,
      intactAmount: deposit,
      startTime,
      segments,
    },
    streamToken,
  );
}
