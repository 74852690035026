import { useMemo } from "react";
import { QUERY_CACHE_TIME, REQUEST_ID } from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { _ } from "@sablier/v2-mixins";
import { useQuery } from "@tanstack/react-query";
import { zeroAddress } from "viem";
import { useConfig } from "wagmi";
import type { Output } from "@sablier/v2-contracts";
import type { IWagmiAddress, IWagmiConfig } from "@sablier/v2-types";

async function getProxy(params: {
  owner: string | undefined;
  registry: string | undefined;
  chainId: number | undefined;
  library: IWagmiConfig;
}): Promise<string | null> {
  const { owner, registry, library, chainId } = params;

  if (_.isNilOrEmptyString(registry)) {
    return null;
  }

  const query = framework.contextualize(
    registry!,
    chainId!,
    "registry",
    "getProxy",
    [owner as IWagmiAddress],
  );

  const previews = await framework.preview({ queries: [query] });
  const results = await framework.read(library, { previews });

  const output = results[0].result as
    | Output<"registry", "getProxy">
    | undefined;
  return output || null;
}

interface Params {
  chainId: number | undefined;
  registry: string | undefined;
  owner: string | undefined;
}

export default function useRequestProxy(params: Params) {
  const { owner, registry, chainId } = params;

  const library = useConfig();
  const isEnabled = useMemo(
    () =>
      _.isEthereumAddress(owner) &&
      _.isEthereumAddress(registry) &&
      !_.isNilOrEmptyString(chainId),
    [owner, registry, chainId],
  );

  const { data, isLoading, error } = useQuery<string | null>({
    queryKey: [...REQUEST_ID.proxy, { unique: `${owner}-${chainId}` }],
    queryFn: async () => getProxy({ ...params, library }),
    staleTime: QUERY_CACHE_TIME,
    gcTime: QUERY_CACHE_TIME,
    retry: false,
    enabled: isEnabled,
  });

  const value = useMemo(() => {
    return _.toAddress(data) !== _.toAddress(zeroAddress)
      ? _.toAddress(data)
      : undefined;
  }, [data]);

  return useMemo(
    () => ({
      error,
      isLoading: isLoading && isEnabled,
      owner,
      value,
    }),
    [error, isEnabled, isLoading, owner, value],
  );
}
