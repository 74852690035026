import { StreamShape, ZERO } from "@sablier/v2-constants";
import {
  StreamCategory,
  StreamStatus,
  StreamVersion,
} from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import type {
  IAddress,
  IMilliseconds,
  ISeconds,
  IStreamAlias,
  IStreamId,
  IValue,
} from "@sablier/v2-types";
import Segment from "../Segment";
import StreamAction from "../StreamAction";
import Token from "../Token";
import Tranche from "../Tranche";

/**
 * ------------------------------
 * The "Attributes" part of the Stream class will
 * include only data fields (and getters/setters)
 * ------------------------------
 * Inheritance is not used here for OOP purposes,
 * but for readability (smaller class parts)
 * ------------------------------
 */

export type Params = {
  id: string;
  alias: string;
  tokenId: string;
  chainId: number;
  /** --------------- */
  cancelable: boolean;
  canceled: boolean;
  transferable: boolean;
  category: string | StreamCategory;
  contract: {
    address: IAddress;
  };
  cliff?: boolean;
  depositAmount: string;
  endTime: ISeconds;
  funder?: IAddress;
  intactAmount: string;
  sender: string;
  startTime: ISeconds;
  recipient: IAddress;
  withdrawnAmount: string;

  proxied: boolean;
  proxender?: string;
  /** --------------- */
  renounceTime?: ISeconds;
  canceledTime?: ISeconds;
  cliffTime?: ISeconds;
  cliffAmount?: string;
  subgraphId?: string;
  segments: {
    id: string;
    position: string;
    /** --------------- */
    amount: string;
    exponent: string;
    milestone: ISeconds;
    /** --------------- */
    endAmount: string;
    endTime: ISeconds;
    startAmount: string;
    startTime: ISeconds;
  }[];
  tranches?: {
    id: string;
    position: string;
    /** --------------- */
    amount: string;
    timestamp: ISeconds;
    /** --------------- */
    endAmount: string;
    endTime: ISeconds;
    startAmount: string;
    startTime: ISeconds;
  }[];
  batch?: {
    label?: string | null;
    size: string;
  };
  position?: string | null;
  hash?: string;
  timestamp?: ISeconds;
  version?: string | StreamVersion;
};

export default class Attributes {
  /**
   * ------------------------------
   * NATIVE ATTRIBUTES
   * ------------------------------
   */

  readonly payload: Params;

  readonly id: IStreamId;
  readonly alias: IStreamAlias;
  readonly chainId: number;
  readonly tokenId: string;
  /* --------------- */
  readonly category: StreamCategory;
  readonly contract: IAddress;
  readonly depositAmount: IValue;
  readonly endTime: IMilliseconds;
  readonly funder: IAddress;
  readonly intactAmount: IValue;
  readonly isCancelable: boolean;
  readonly isCanceled: boolean;
  readonly isTransferable: boolean;
  readonly wasCancelable: boolean;
  readonly recipient: IAddress;
  readonly sender: IAddress;
  readonly startTime: IMilliseconds;
  readonly token: Token;
  readonly version: StreamVersion;
  readonly withdrawnAmount: IValue;
  /* --------------- */
  subgraphId?: string;
  renounceTime?: IMilliseconds;
  canceledTime?: IMilliseconds;
  cliff: boolean;
  cliffTime: IMilliseconds;
  cliffAmount: IValue;
  proxied: boolean;
  proxender: IAddress | undefined;

  batch?: {
    label: string | undefined;
    size: number;
    position: number;
  };
  hash?: string;
  timestamp?: IMilliseconds;
  /* --------------- */
  actions: StreamAction[] = [];
  segments: Segment[] = [];
  tranches: Tranche[] = [];

  /**
   * ------------------------------
   * DERIVED | PRIVATE ATTRIBUTES
   * ------------------------------
   *
   * Most of these attributes (e.g. status)
   * will be kept fresh by the Stream.doUpdate() method.
   */

  readonly duration: IMilliseconds;
  readonly cliffDuration: IMilliseconds;
  readonly cliffPercentage: BigNumber;
  readonly intactAmountPercentage: BigNumber;
  readonly withdrawnAmountPercentage: BigNumber;

  protected _returnableAmount: IValue;
  protected _returnableAmountPercentage: BigNumber;
  /** See @sablier/v2-constants/src/stream/index.ts for an explanation of the logic for Stream Statuses  */
  protected _status: StreamStatus;
  protected _streamedAmount: IValue;
  protected _streamedAmountPercentage: BigNumber;
  protected _streamedAmountEstimate: IValue;
  protected _streamedAmountEstimatePercentage: BigNumber;
  protected _streamedDuration: IMilliseconds;
  protected _streamedDurationPercentage: BigNumber;
  protected _timeSinceCliff: IMilliseconds;
  protected _timeSinceEnd: IMilliseconds;
  protected _withdrawableAmount: IValue;
  protected _withdrawableAmountPercentage: BigNumber;

  private _shape?: StreamShape;
  readonly instantiated: IMilliseconds;

  constructor(params: Params, token: Token) {
    /**
     * ------------------------------
     * NATIVE ASSIGNMENTS
     * ------------------------------
     */

    this.payload = params;
    this.id = params.id.toLowerCase();
    this.chainId = _.toNumber(params.chainId);
    this.tokenId = params.tokenId;
    this.token = token;
    this.instantiated = Date.now().toString();

    /** --------------- */

    this.category = params.category as StreamCategory;
    this.contract = params.contract.address;
    this.depositAmount = _.toValue({
      decimals: token.decimals,
      raw: params.depositAmount,
    });
    this.endTime = _.toMilliseconds(params.endTime);
    this.funder = _.toAddress(params.funder);
    this.recipient = _.toAddress(params.recipient);
    this.sender = _.toAddress(params.sender);
    this.startTime = _.toMilliseconds(params.startTime);

    this.isCancelable = params.cancelable;
    this.isCanceled = params.canceled;
    this.isTransferable = params.transferable;
    this.wasCancelable = params.canceled || params.cancelable;
    this.version = (params.version as StreamVersion) || StreamVersion.V22;

    this.intactAmount = _.toValue({
      decimals: token.decimals,
      raw: params.intactAmount,
    });
    this.withdrawnAmount = _.toValue({
      decimals: token.decimals,
      raw: params.withdrawnAmount,
    });

    if (this.startTime === this.endTime) {
      this.endTime = new BigNumber(this.startTime).plus(1).toString();
    }

    /** --------------- */
    this.alias = _.toAlias(params.alias);

    this.subgraphId = params.subgraphId;
    this.hash = params.hash;

    this.cliff = !!params.cliff;

    this.cliffAmount = _.toValue({
      decimals: token.decimals,
      raw: params.cliffAmount,
    });
    this.cliffTime = _.toMilliseconds(params.cliffTime);
    this.renounceTime = _.toMilliseconds(params.renounceTime);
    this.canceledTime = _.toMilliseconds(params.canceledTime);
    this.timestamp = _.toMilliseconds(params.timestamp);

    this.batch =
      params.batch && !_.isNilOrEmptyString(params.batch.label)
        ? {
            label: params.batch.label || undefined,
            size: _.toNumber(params.batch.size),
            position: _.toNumber(params.position),
          }
        : undefined;

    this.proxied = params.proxied;
    this.proxender = !_.isNilOrEmptyString(params.proxender)
      ? _.toAddress(params.proxender)
      : undefined;

    this.segments = params.segments
      .map((data) => new Segment(data, token))
      .sort((a, b) => new BigNumber(a.startTime).minus(b.startTime).toNumber());

    this.tranches = (params.tranches || [])
      .map((data) => new Tranche(data, token))
      .sort((a, b) => new BigNumber(a.startTime).minus(b.startTime).toNumber());

    /**
     * ------------------------------
     * DERIVED ASSIGNMENTS
     * ------------------------------
     */

    const zero = ZERO(this.token.decimals);

    this.duration = new BigNumber(this.endTime)
      .minus(new BigNumber(this.startTime))
      .toString();

    this.cliffDuration = new BigNumber(this.cliffTime)
      .minus(new BigNumber(this.startTime))
      .toString();
    this.cliffPercentage = new BigNumber(this.cliffDuration)
      .times(100)
      .dividedBy(this.duration);

    this.withdrawnAmountPercentage = this.withdrawnAmount.raw
      .dividedBy(this.depositAmount.raw)
      .times(new BigNumber(100));
    this.intactAmountPercentage = this.intactAmount.raw
      .dividedBy(this.depositAmount.raw)
      .times(new BigNumber(100));

    this._returnableAmount = zero;
    this._returnableAmountPercentage = zero.raw;
    this._streamedAmount = zero;
    this._streamedAmountPercentage = zero.raw;
    this._streamedAmountEstimate = zero;
    this._streamedAmountEstimatePercentage = zero.raw;
    this._streamedDuration = zero.raw.toString();
    this._streamedDurationPercentage = zero.raw;
    this._timeSinceCliff = zero.raw.toString();
    this._timeSinceEnd = zero.raw.toString();
    this._withdrawableAmount = zero;
    this._withdrawableAmountPercentage = zero.raw;
    this._status = StreamStatus.STREAMING;

    if (this.category === StreamCategory.LOCKUP_TRANCHED) {
      /**
       * For compatibility across the app, tranched streams will receive fake "segments"
       * stemming from the tranches (horizontal cliff segment, vertical unlock segment)
       */

      this.segments = this.tranches
        .map((tranche) => tranche.toSegments())
        .flat();
    }
  }

  get returnableAmount() {
    return this._returnableAmount;
  }

  get returnableAmountPercentage() {
    return this._returnableAmountPercentage;
  }

  get status() {
    return this._status;
  }

  get streamedAmount() {
    return this._streamedAmount;
  }

  get streamedAmountPercentage() {
    return this._streamedAmountPercentage;
  }

  get streamedAmountEstimate() {
    return this._streamedAmountEstimate;
  }

  get streamedAmountEstimatePercentage() {
    return this._streamedAmountEstimatePercentage;
  }

  get streamedDuration() {
    return this._streamedDuration;
  }

  get streamedDurationPercentage() {
    return this._streamedDurationPercentage;
  }

  get timeSinceCliff() {
    return this._timeSinceCliff;
  }

  get timeSinceEnd() {
    return this._timeSinceEnd;
  }

  get withdrawableAmount() {
    return this._withdrawableAmount;
  }

  get withdrawableAmountPercentage() {
    return this._withdrawableAmountPercentage;
  }

  get shape() {
    return this._shape;
  }

  set shape(type: StreamShape | undefined) {
    this._shape = type;
  }

  get isDepleted() {
    return (
      this.status === StreamStatus.DEPLETED_CANCELED ||
      this.status === StreamStatus.DEPLETED_SETTLED
    );
  }

  get isAlive() {
    return (
      this.status === StreamStatus.PENDING ||
      this.status === StreamStatus.STREAMING
    );
  }

  get isCliffing() {
    return (
      this.status === StreamStatus.STREAMING &&
      this.cliff &&
      this.category === StreamCategory.LOCKUP_LINEAR &&
      this.streamedAmountPercentage.isLessThanOrEqualTo(this.cliffPercentage)
    );
  }

  get isSettled() {
    return (
      this.status === StreamStatus.SETTLED ||
      this.status === StreamStatus.DEPLETED_SETTLED
    );
  }

  get isBatched() {
    return !_.isNil(this.batch) && !_.isNilOrEmptyString(this.batch.position);
  }
}
