import { useEffect } from "react";
import { useModals } from "@sablier/v2-contexts";
import { _ } from "@sablier/v2-mixins";
import { useRouter } from "next/router";

/**
 * Similar to `useNextRouterConfirm`, this hook adds custom logic to the router events.
 * As the user walks away from the page, a cleanup is performed: modals (cards, covers etc.) are closed.
 * This cleanup happens once the route change is complete (not when it starts).
 */

function useNextRouterCleanup() {
  const router = useRouter();
  const { doReset } = useModals();

  useEffect(() => {
    if (_.isWindow()) {
      const handleBrowseAway = () => {
        doReset();
      };

      /** https://nextjs.org/docs/pages/api-reference/functions/use-router#routerevents */
      router.events.on("routeChangeComplete", handleBrowseAway);
      return () => {
        router.events.off("routeChangeComplete", handleBrowseAway);
      };
    }
  }, [router.events, doReset]);
}

export default useNextRouterCleanup;
