import { Translate } from "@sablier/v2-locales";
import type { IDynamicMonthly } from "./config";
import type { IExtensionDependencies } from "~/client/types";
import { IConfiguration } from "../../system/setup";
import { initial, shape } from "./config";
import { process } from "./process";
import { certify, check, identify, precompute, simulate } from "./setup";

function summary(
  _dependencies: IExtensionDependencies,
  _extension: unknown,
  _t: Translate,
) {
  return undefined;
}

const Form = {
  Group: undefined,
};

const extension: IConfiguration = {
  check,
  headers: (_isDuration: boolean) => [],
  certify,
  identify,
  precompute,
  process,
  Form,
  initial,
  shape,
  summary,
  simulate,
  template: { duration: "", range: "" },
  warnings: (_extension: unknown) => [],
};

export type { IDynamicMonthly };
export { extension };
