import { useCallback, useEffect, useMemo } from "react";
import { useT } from "@sablier/v2-locales";
import { guards } from "@sablier/v2-machines";
import { _ } from "@sablier/v2-mixins";
import {
  useStreamCreateGroupAccessor,
  useStreamCreateGroupField,
} from "~/client/hooks";
import { setup } from "../../system/setup";
import { IDynamicUnlockLinear } from "./config";

type IExtension = IDynamicUnlockLinear;

function useExtendedGroupAccess() {
  return useStreamCreateGroupAccessor<IExtension>();
}

function useExtendedStream(id: string) {
  const streams = useStreamCreateGroupField("streams");
  const stream = useMemo(
    () => streams.field.value.find((s) => s.id === id),
    [id, streams],
  );

  return stream as typeof stream & { extension: IExtension };
}

export function useGroupFieldUnlock(id: string) {
  const access = useExtendedGroupAccess();
  const stream = useExtendedStream(id);
  const token = useStreamCreateGroupField("token");
  const amount = useMemo(() => ({ field: stream.amount }), [stream]);
  const extension = useMemo(() => stream.extension, [stream]);
  const { t } = useT();

  const unlock = useMemo(() => ({ field: stream.extension.unlock }), [stream]);

  const maximum = useMemo(
    () => ({
      label: `${t("words.max")}:`,
      value:
        !_.isNilOrEmptyString(amount.field.value) && amount.field.value !== "0"
          ? _.toNumeralPrice(amount.field.value, false)
          : undefined,
    }),
    [amount, t],
  );

  const onBlur = useCallback(() => {
    const { index, isValid, stream, streams, update } = setup(access, id);
    if (!isValid) {
      return;
    }

    const amount = stream.amount.value;
    const extension = stream.extension;
    const unlock = stream.extension.unlock.value;
    const edited = _.isNilOrEmptyString(unlock)
      ? {
          ...stream,
          extension: {
            ...extension,
            unlock: {
              ...extension.unlock,
              warning: undefined,
            },
          },
        }
      : {
          ...stream,
          extension: {
            ...extension,
            unlock: {
              ...extension.unlock,
              warning: guards.validateAmount({
                t,
                context: "wallet",
                max: amount,
                value: unlock,
                min: "0",
              }),
            },
          },
        };

    const cloned = [...streams];
    cloned.splice(index, 1, edited);

    update({
      streams: {
        value: cloned,
      },
    });
  }, [access, id, t]);

  const onChange = useCallback(
    (value: string | undefined) => {
      const { index, isValid, stream, streams, update } = setup(access, id);

      if (!isValid) {
        return;
      }
      const edited = {
        ...stream,
        extension: {
          ...stream.extension,
          unlock: {
            ...stream.extension.unlock,
            warning: undefined,
            value,
          },
        },
      };

      const newStreams = [...streams];
      newStreams.splice(index, 1, edited);
      update({
        streams: {
          value: newStreams,
        },
      });
    },
    [access, id],
  );

  const onMax = useCallback(() => {
    const { index, isValid, stream, streams, update } = setup(access, id);

    if (!isValid) {
      return;
    }

    const edited = {
      ...stream,
      extension: {
        ...extension,
        unlock: {
          ...extension.unlock,
          value: stream.amount.value,
        },
      },
    };

    const cloned = [...streams];
    cloned.splice(index, 1, edited);

    update({
      streams: {
        value: cloned,
      },
    });

    onBlur();
  }, [access, extension, id, onBlur]);

  useEffect(() => {
    onBlur();
  }, [onBlur]);

  return {
    unlock,
    maximum,
    onBlur,
    onChange,
    onMax,
    token,
  };
}
