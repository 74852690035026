import { ComponentProps, useMemo } from "react";
import styled, { css } from "styled-components";
import { SwitchBox } from "~/components/molecules";
import type { ISInput } from "../system/templates";
import Template, { sides } from "../system/templates";

const Templated = styled(Template.Text)`
  ${(props) =>
    props.isDisabled &&
    css`
      *,
      input,
      p {
        cursor: default !important;
      }
    `}

  *[data-component="sides"][data-on="left"] {
    *[data-component="side"][data-purpose="token"] {
      p {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

export interface Props
  extends Omit<ISInput, "left" | "right" | "onClick" | "value"> {
  onChange?: (value: boolean) => void;
  labelOn?: ComponentProps<typeof SwitchBox>["labelOn"];
  labelOff?: ComponentProps<typeof SwitchBox>["labelOff"];
  value?: {
    isChecked?: boolean;
    title: string | undefined;
  };
}

function Switch(props: Props) {
  const formatted: ISInput = useMemo(() => {
    const onClick = () => {
      if (props.onChange) {
        props.onChange(!props.value?.isChecked);
      }
    };

    const right: ISInput["right"] = [
      {
        purpose: "custom",
        options: {
          value: () => (
            <SwitchBox
              labelOn={props.labelOn}
              labelOff={props.labelOff}
              id={`side-${props.id}`}
              isChecked={props.value?.isChecked}
              isDisabled={props.isDisabled}
              onChange={onClick}
            />
          ),
        },
      },
    ];

    if (props.isDisabled) {
      right.unshift(sides.lock());
    }

    if (props.isLoading) {
      right.unshift(sides.loader());
    }

    return {
      ...props,
      isPreview: props.isDisabled ? true : ("soft" as const),
      onClick,
      placeholder: props.value?.title ? undefined : props.placeholder,
      right,
      value: props.value?.title ?? "",
    };
  }, [props]);

  return <Templated {...formatted} />;
}

export default Switch;
