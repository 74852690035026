import { EXPONENT_DECIMALS } from "@sablier/v2-constants";
import {
  DEFAULT_BROKER_ADDRESS,
  DEFAULT_BROKER_FEE_PERCENTAGE,
} from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { contractor, peripheral } from "~/client/utils";
import type { IDynamicUnlockCliff } from "./config";
import type { IAddress, IWagmiAddress } from "@sablier/v2-types";
import type {
  IExtensionParamsGroup,
  IExtensionParamsSingle,
  IExtensionResultDurationsLD,
  IExtensionResultTimestampsLD,
} from "~/client/types";
import { EXPECTED_SEGMENTS, UNLOCK_DURATION } from "./config";
import { precompute } from "./setup";

type IExtension = IDynamicUnlockCliff;

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */
async function processSingle(
  params: IExtensionParamsSingle & { timing: "duration" },
): Promise<{
  batch: IExtensionResultDurationsLD;
}>;
async function processSingle(
  params: IExtensionParamsSingle & { timing: "range" },
): Promise<{
  batch: IExtensionResultTimestampsLD;
}>;
async function processSingle(params: IExtensionParamsSingle): Promise<{
  batch: IExtensionResultTimestampsLD | IExtensionResultDurationsLD;
}>;

async function processSingle({
  dependencies,
  extras,
  timing,
}: IExtensionParamsSingle): Promise<{
  batch: IExtensionResultTimestampsLD | IExtensionResultDurationsLD;
}> {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const { purpose, ...extended } = extras as IExtension;
  const {
    address,
    cancelability,
    transferability,
    chainId,
    duration,
    end,
    sender,
    start,
    token,
  } = dependencies;

  const batch: IAddress = peripheral(chainId, "batch").address;
  const lockup: IAddress = contractor(
    chainId!,
    purpose,
    token?.address,
  ).address;

  const { amount: initialDeposit } = precompute.single({ dependencies });
  const initialUnlock = _.toValuePrepared({
    decimals: token!.decimals,
    humanized: extended.unlock.value,
  });
  const remainingDeposit = new BigNumber(initialDeposit)
    .minus(new BigNumber(initialUnlock))
    .toString();

  const unlock = new BigNumber(UNLOCK_DURATION).dividedBy(1000);

  const endTime = _.toSeconds(end);
  const streamStartTime = _.toSeconds(start);
  const cliffStartTime = new BigNumber(streamStartTime).plus(unlock);
  const cliffTime = _.toSeconds(extended.unlockEnd.value);

  const totalDelta = new BigNumber(end || 0).minus(
    new BigNumber(start || 0).plus(unlock),
  );
  const cliffDelta = new BigNumber(extended.unlockEnd.value || 0).minus(
    new BigNumber(start || 0).plus(unlock),
  );

  /** Compute durations based on actual given durations or post-processed deltas (from dates) */
  const totalDuration = _.toSeconds(
    timing === "duration" ? duration : totalDelta,
  );
  const cliffDuration = _.toSeconds(
    timing === "duration" ? extended.unlockDuration.value : cliffDelta,
  );

  /**
   * ------------------------------
   * Setup dependencies: DYNAMIC
   * ------------------------------
   */

  /** Amounts: a flat surface (the cliff), a vertical line (the unlock) and the curve (exponent) */
  const cliffPercentage = new BigNumber(cliffDuration)
    .dividedBy(new BigNumber(totalDuration))
    .times(new BigNumber(100));
  const cliffAmount = _.toValuePrepared({
    raw: new BigNumber(remainingDeposit).times(cliffPercentage).dividedBy(100),
    decimals: token?.decimals,
  });
  const linearAmount = _.toValuePrepared({
    raw: new BigNumber(remainingDeposit).minus(cliffAmount),
    decimals: token?.decimals,
  });
  const segmentAmounts = [
    _.toBigInt(initialUnlock),
    _.toBigInt(0),
    _.toBigInt(cliffAmount),
    _.toBigInt(linearAmount),
  ] as const;

  /** Exponents keep everything constant (power of 1), except the final segment which is curved */
  const segmentExponents = [
    _.toBigInt(
      _.toValuePrepared({ humanized: "1", decimals: EXPONENT_DECIMALS }),
    ),
    _.toBigInt(
      _.toValuePrepared({ humanized: "1", decimals: EXPONENT_DECIMALS }),
    ),
    _.toBigInt(
      _.toValuePrepared({ humanized: "1", decimals: EXPONENT_DECIMALS }),
    ),
    _.toBigInt(
      _.toValuePrepared({ humanized: "1", decimals: EXPONENT_DECIMALS }),
    ),
  ] as const;

  /**
   * ------------------------------
   * Prepare transaction parameters
   * ------------------------------
   */

  if (timing === "duration") {
    type Inputs = IExtensionResultDurationsLD["inputs"];

    const segmentCliff = new BigNumber(cliffDuration).minus(unlock);

    const segmentExponential = new BigNumber(totalDuration)
      .minus(segmentCliff)
      .minus(unlock)
      .minus(unlock);
    const segmentDurations = [
      _.toNumber(unlock),
      _.toNumber(_.toValuePrepared({ raw: segmentCliff, decimals: 0 })),
      _.toNumber(unlock),
      _.toNumber(_.toValuePrepared({ raw: segmentExponential, decimals: 0 })),
    ] as const;

    const segments = [...Array(EXPECTED_SEGMENTS).keys()].map((i) => ({
      amount: segmentAmounts[i],
      exponent: segmentExponents[i],
      duration: segmentDurations[i],
    }));

    const inputs: Inputs = [
      lockup as IWagmiAddress,
      token!.address as IWagmiAddress,
      [
        {
          sender: sender as IWagmiAddress,
          recipient: address as IWagmiAddress,
          totalAmount: _.toBigInt(initialDeposit),
          segments,
          cancelable: !!cancelability,
          transferable: !!transferability,
          broker: {
            account: DEFAULT_BROKER_ADDRESS as IWagmiAddress,
            fee: _.toBigInt(DEFAULT_BROKER_FEE_PERCENTAGE),
          },
        },
      ],
    ];

    const data = framework.contextualize(
      batch,
      chainId!,
      "batch",
      "createWithDurationsLD",
      inputs,
    );

    return {
      batch: data,
    };
  } else {
    type Inputs = IExtensionResultTimestampsLD["inputs"];

    const segmentTimestamps = [
      _.toNumber(cliffStartTime),
      _.toNumber(cliffTime),
      _.toNumber(cliffTime) + unlock.toNumber(),
      _.toNumber(endTime),
    ] as const;

    const segments = [...Array(EXPECTED_SEGMENTS).keys()].map((i) => ({
      amount: segmentAmounts[i],
      exponent: segmentExponents[i],
      timestamp: segmentTimestamps[i],
    }));

    const inputs: Inputs = [
      lockup as IWagmiAddress,
      token!.address as IWagmiAddress,
      [
        {
          sender: sender as IWagmiAddress,
          recipient: address as IWagmiAddress,
          totalAmount: _.toBigInt(initialDeposit),
          startTime: _.toNumber(streamStartTime),
          segments,
          cancelable: !!cancelability,
          transferable: !!transferability,
          broker: {
            account: DEFAULT_BROKER_ADDRESS as IWagmiAddress,
            fee: _.toBigInt(DEFAULT_BROKER_FEE_PERCENTAGE),
          },
        },
      ],
    ];

    const data = framework.contextualize(
      batch,
      chainId!,
      "batch",
      "createWithTimestampsLD",
      inputs,
    );

    return {
      batch: data,
    };
  }
}

/**
 * ------------------------------
 * Explicit function overloads
 * ------------------------------
 */

async function processGroup(
  params: IExtensionParamsGroup & { timing: "duration" },
): Promise<{
  batch: IExtensionResultDurationsLD;
}>;
async function processGroup(
  params: IExtensionParamsGroup & { timing: "range" },
): Promise<{
  batch: IExtensionResultTimestampsLD;
}>;
async function processGroup(params: IExtensionParamsGroup): Promise<{
  batch: IExtensionResultDurationsLD | IExtensionResultTimestampsLD;
}>;

async function processGroup({
  dependencies,
  purpose,
  timing,
  library,
}: IExtensionParamsGroup): Promise<{
  batch: IExtensionResultDurationsLD | IExtensionResultTimestampsLD;
}> {
  /**
   * ------------------------------
   * Setup dependencies
   * ------------------------------
   */

  const {
    cancelability,
    transferability,
    chainId,
    sender,
    token,
    streams,
    signer,
  } = dependencies;

  const batch: IAddress = peripheral(chainId, "batch").address;
  const lockup = contractor(chainId, purpose, token?.address).address;

  /**
   * ------------------------------
   * Prepare transaction parameters
   * ------------------------------
   */

  if (timing === "duration") {
    type Inputs = IExtensionResultDurationsLD["inputs"];

    const params: Inputs["2"] = await Promise.all(
      streams?.map(async (stream) => {
        const { address, amount, duration, end, start } = stream;

        const single = await processSingle({
          dependencies: {
            address,
            amount,
            cancelability,
            transferability,
            duration,
            end,
            token,
            start,
            chainId,
            sender,
            signer,
          },
          extras: stream.extension,
          timing: "duration",
          library,
        });

        return single.batch.inputs[2][0];
      }) || [],
    );

    const inputs: Inputs = [
      lockup as IWagmiAddress,
      token!.address as IWagmiAddress,
      params,
    ] as const;

    const data = framework.contextualize(
      batch,
      chainId!,
      "batch",
      "createWithDurationsLD",
      inputs,
    );

    return {
      batch: data,
    };
  } else {
    type Inputs = IExtensionResultTimestampsLD["inputs"];

    const params: Inputs["2"] = await Promise.all(
      streams?.map(async (stream) => {
        const { address, amount, duration, end, start } = stream;

        const single = await processSingle({
          dependencies: {
            address,
            amount,
            cancelability,
            transferability,
            duration,
            end,
            token,
            start,
            chainId,
            sender,
            signer,
          },
          extras: stream.extension,
          timing: "range",
          library,
        });

        return single.batch.inputs[2][0];
      }) || [],
    );

    const inputs: Inputs = [
      lockup as IWagmiAddress,
      token!.address as IWagmiAddress,
      params,
    ];

    const data = framework.contextualize(
      batch,
      chainId!,
      "batch",
      "createWithTimestampsLD",
      inputs,
    );

    return {
      batch: data,
    };
  }
}

export const process = { group: processGroup, single: processSingle };
