import { useCallback, useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import { Token } from "@sablier/v2-models";
import type { IToken, ITokenResolution } from "@sablier/v2-types";
import useTokensLocal from "./useTokensLocal";
import useTokensWhitelisted from "./useTokensWhitelisted";

type Props = ITokenResolution & { list: IToken[]; isLoading: boolean };

function find({
  address,
  chainId,
  list,
  isLoading,
  token = undefined,
}: Props): IToken | undefined {
  if (
    isLoading ||
    _.isNil(list) ||
    (_.isNil(token) && _.isNilOrEmptyString(address))
  ) {
    return undefined;
  }

  const source = token || { address, chainId };
  const found = list.find((token) => {
    if (_.toAddress(token.address) === _.toAddress(source.address)) {
      if (!_.isNilOrEmptyString(source.chainId)) {
        return token.chainId === source.chainId;
      }
      return true;
    }
    return false;
  });

  if (!_.isNil(found)) {
    return new Token({
      ...source,
      ...found,
    });
  }

  if (!_.isNil(token)) {
    return new Token(token);
  }

  return undefined;
}

/**
 *
 * @param isByConnectedChain Make this false if you want to later resolve/find tokens by address & chain
 * @returns
 */
export default function useTokens(byChainId?: boolean | number) {
  const { list: local } = useTokensLocal(byChainId);
  const { isLoading, list: whitelisted } = useTokensWhitelisted(byChainId);

  const list = useMemo(() => [...local, ...whitelisted], [local, whitelisted]);

  const resolver = useCallback(
    ({ address, chainId, token }: ITokenResolution) => {
      if (_.isNil(token)) {
        return find({ address, chainId, isLoading, list });
      }

      return find({ isLoading, list, token });
    },
    [list, isLoading],
  );

  return {
    isLoading,
    list,
    find: resolver,
  };
}
