import { _ } from "@sablier/v2-mixins";
import {
  RequestMiddleware,
  ResponseMiddleware,
  resolveRequestDocument,
} from "graphql-request";
import type * as airstream_graph from "../documents/airstream/queries";
import type * as protocol_graph from "../documents/protocol/queries";
import type { OperationDefinitionNode } from "graphql";
import * as airstream_envio from "../documents/airstream-envio/queries";
import * as protocol_envio from "../documents/protocol-envio/queries";

type Envio = string;
type Singular = string;
type Plural = string;

const names: Record<Envio, [Singular, Plural]> = {
  Action: ["action", "actions"],
  Asset: ["asset", "assets"],
  Activity: ["activity", "activities"],
  Batch: ["batch", "batches"],
  Campaign: ["campaign", "campaigns"],
  Contract: ["contract", "contracts"],
  Factory: ["factory", "factories"],
  Segment: ["segment", "segments"],
  Stream: ["stream", "streams"],
  Tranche: ["tranche", "tranches"],
  First: ["first", "firsts"],
};

export const SUBGRAPH_HEADER_VENDOR = "x-graph-vendor";

/** --------------- Compatibility Checks --------------- */

type MustHaveKeys<V, S extends Record<keyof V, unknown>> = S;

export type KeysProtocolEnvio = MustHaveKeys<
  typeof protocol_graph,
  typeof protocol_envio
>;
export type KeysAirstreamEnvio = MustHaveKeys<
  typeof airstream_graph,
  typeof airstream_envio
>;

/** --------------- Middleware --------------- */

const requestMiddleware: RequestMiddleware = async (request) => {
  if (request.headers && SUBGRAPH_HEADER_VENDOR in request.headers) {
    let body = String(request.body);
    let variables = request.variables || {};

    /** Swap the request string/body to comply with Envio's API (Hasura) */
    if (request.headers?.[SUBGRAPH_HEADER_VENDOR] === "envio") {
      const query = request.operationName;
      if (query) {
        let document = undefined;
        if (query in airstream_envio) {
          document = airstream_envio[query as keyof typeof airstream_envio];
        }
        if (query in protocol_envio) {
          document = protocol_envio[query as keyof typeof protocol_envio];
        }
        if (document) {
          /** Strip unnecessary variables from the document */

          const definition = (document.definitions.find(
            (d) => d.kind === "OperationDefinition",
          ) || {}) as OperationDefinitionNode;
          const necessary =
            definition.variableDefinitions
              ?.filter((d) => d.kind === "VariableDefinition")
              .map((d) => d.variable.name.value) || Object.keys(variables);

          variables = _.pick(variables, necessary);

          /** Stringify the new document and replace the request body with it */
          try {
            const resolved = resolveRequestDocument(document);
            const previous = JSON.parse(body);

            body = JSON.stringify({
              ...previous,
              query: resolved.query,
              variables,
            });
          } catch (error) {
            console.error(error);
          }
        }
      }
    }

    /** Cleanup request headers to eliminate the vendor flag */

    const headers = { ...(request.headers || {}) };
    delete headers[SUBGRAPH_HEADER_VENDOR];

    const formatted: ReturnType<RequestMiddleware> = {
      ...request,
      headers,
      body,
      variables,
    };

    return formatted;
  }
  return request;
};

const responseMiddleware: ResponseMiddleware = (_result) => {};

const resultMiddleware = async <T>(response: T) => {
  if (!(response instanceof Error)) {
    if (response && typeof response === "object") {
      return reformat(response) as T;
    }
  }

  return response;
};

/** --------------- Utilities --------------- */

/**
 * Identify capitalized result keys as specific elements defining an ENVIO response.
 * If so, reformat the result to look exactly like the one given by The Graph.
 * Only works for minimum-depth objects (extensive nesting will need recursive formatting).
 */
function reformat(source: object) {
  let value = { ...source };

  Object.keys(value).map((_key) => {
    const key = _key as keyof typeof value;
    if (key in names) {
      const additions = names[key];
      const replicate = value[key];

      const isArray = Array.isArray(replicate);
      const isEmpty = isArray && (replicate as Array<object>).length === 0;

      const plural = isArray ? replicate : [];
      const single = isArray
        ? !isEmpty
          ? replicate[0]
          : undefined
        : replicate;

      value = {
        ...value,
        [additions[0]]: reassign(single),
        [additions[1]]: plural.map((item) => reassign(item)),
      };
    }
  });

  return value;
}

function reassign(source: object | undefined) {
  if (!source) {
    return undefined;
  }

  const value = JSON.stringify(source);

  return JSON.parse(value);
}

/** --------------- Exports --------------- */

const middleware = {
  requestMiddleware,
  responseMiddleware,
};

export { resultMiddleware };

export default middleware;
