import { Input } from "@sablier/v2-components/organisms";
import { useT } from "@sablier/v2-locales";
import { useGroupFieldDuration, useGroupFieldEnd } from "./logic";

function GroupDuration({ streamId }: { streamId: string }) {
  const { cliffDuration, onClick } = useGroupFieldDuration(streamId);
  const { t } = useT();

  return (
    <Input.Duration
      id={cliffDuration.field.id}
      label={{ value: t("form.label.cliffDuration") }}
      onClick={onClick}
      placeholder={t("form.placeholder.cliffDuration")}
      value={cliffDuration.field!.value}
      warning={cliffDuration.field!.warning}
    />
  );
}

function GroupEnd({ streamId }: { streamId: string }) {
  const { cliffEnd, onClick, label } = useGroupFieldEnd(streamId);
  const { t } = useT();

  return (
    <Input.Moment
      id={cliffEnd.field.id}
      label={label}
      onClick={onClick}
      placeholder={t("form.placeholder.moment")}
      value={cliffEnd.field!.value}
      warning={cliffEnd.field!.warning}
    />
  );
}

const Fields = {
  GroupDuration,
  GroupEnd,
};

export default Fields;
