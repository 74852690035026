import { createContext, useContext, useEffect, useMemo } from "react";
import { RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { DEFAULT_CHAIN_ID, chains } from "@sablier/v2-constants";
import { getCookie } from "cookies-next";
import {
  WagmiProvider,
  cookieToInitialState,
  useChainId,
  useConnect,
} from "wagmi";
import { Disclaimer } from "~/client/components/molecules";
import { WAGMI_COOKIE_STORAGE } from "~/client/constants";
import type { IContext } from "./logic";
import type { PropsWithChildren } from "react";
import { configure } from "./connectors";
import useConfig, {
  initial,
  isHostCypress,
  isHostSafe,
  isHostUnstable,
} from "./logic";

export { isHostSafe, isHostCypress, isHostUnstable };

const { config } = configure({
  first: DEFAULT_CHAIN_ID,
  isHostSafe,
  isHostCypress,
  isHostUnstable,
});

const Context = createContext<IContext>(initial);

export function useWeb3Context(): IContext {
  return useContext(Context);
}

/**
 * ------------------------------
 * Context Providers
 * ------------------------------
 */

function Web3StateProvider({ children }: PropsWithChildren<unknown>) {
  const value: IContext = useConfig();

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

function AutoConnectProvider({ children }: PropsWithChildren<unknown>) {
  const { connect, connectors } = useConnect();

  useEffect(() => {
    if (isHostSafe) {
      for (const connector of connectors) {
        if (connector.id === "safe") {
          connect({ connector });
          break;
        }
      }
    }

    if (isHostCypress) {
      for (const connector of connectors) {
        if (connector.id === "mock") {
          connect({ chainId: chains.sepolia.chainId, connector });
          break;
        }
      }
    }

    /**
     * For other hosts the auto-connect is handled by `reconnectOnMount`
     */
  }, [connect, connectors]);

  return <>{children}</>;
}

function Web3ChainProvider({ children }: PropsWithChildren<unknown>) {
  const chainId = useChainId();

  return (
    <RainbowKitProvider
      appInfo={{
        appName: "Sablier V2",
        disclaimer: Disclaimer.Wallet,
      }}
      initialChain={chainId}
      theme={darkTheme()}
    >
      <Web3StateProvider>
        <AutoConnectProvider>{children}</AutoConnectProvider>
      </Web3StateProvider>
    </RainbowKitProvider>
  );
}

export default function Web3Provider({ children }: PropsWithChildren<unknown>) {
  const initialState = useMemo(
    () => cookieToInitialState(config, getCookie(WAGMI_COOKIE_STORAGE)),
    [],
  );

  return (
    <WagmiProvider
      config={config}
      reconnectOnMount={!isHostSafe && !isHostCypress}
      initialState={initialState}
    >
      <Web3ChainProvider>{children}</Web3ChainProvider>
    </WagmiProvider>
  );
}
