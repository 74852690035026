import { StreamShapes } from "@sablier/v2-constants";
import type { IExtensionConstructorParams } from "../../system/setup";
import type { IMilliseconds } from "@sablier/v2-types/utils";
import { Extension } from "../../system/setup";

export interface IDynamicTimelock {
  purpose: Extension;
}

export function initial(
  _params: IExtensionConstructorParams,
): IDynamicTimelock {
  return { purpose: Extension.DYNAMIC_TIMELOCK };
}

export const shape = StreamShapes.dynamicTimelock.id;
export const UNLOCK_DURATION: IMilliseconds = "1000";
export const EXPECTED_SEGMENTS = 2;
