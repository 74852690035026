import { _ } from "@sablier/v2-mixins";

interface Props {
  guard: () => string | undefined | Promise<string | undefined> | unknown;
}

export async function isValid({ guard }: Props): Promise<boolean> {
  const message = await guard();
  return _.isNilOrEmptyString(message);
}
