import { _ } from "@sablier/v2-mixins";
import { useShallow } from "zustand/react/shallow";
import useStore from "~/client/stores/stream";

export function useStreamStorePreview() {
  const set = useStore(useShallow((state) => state.api.setPreview));
  const preview = useStore(useShallow((state) => state.preview));

  return {
    set,
    preview,
  };
}

export function useStreamStoreOwned() {
  const set = useStore(useShallow((state) => state.api.setOwned));
  const search = useStore(useShallow((state) => state.owned));

  return {
    set,
    search,
  };
}

export function useStreamStoreSearch() {
  const set = useStore(useShallow((state) => state.api.setSearch));
  const search = useStore(useShallow((state) => state.search));

  return {
    set,
    search,
  };
}

export function useStreamStoreAccessor() {
  return useStore.getState;
}
