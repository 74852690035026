import { _ } from "@sablier/v2-mixins";
import { assign, createMachine } from "xstate";
import type { IEligibility } from "@sablier/v2-types";

type IFacilitated<Transitive> = {
  status: IEligibility;
  transitive: Transitive | undefined;
};

type MachineContext<Transitive> = {
  error: string | undefined;
  /** Between checking and creating, a transitive dataset will be cached. */
  status: IEligibility;
  transitive: Transitive | undefined;
};

type MachineServices<Transitive> = {
  doCheck: { data: IFacilitated<Transitive> };
  doCreate: { data: unknown };
};

type MachineEvents<Check, Create> =
  | {
      type: "CHECK";
      payload: Check & { soft?: boolean };
    }
  | {
      type: "CREATE";
      payload: Create;
    }
  | { type: "RESET" };

interface Props<Check, Create, Transitive> {
  id: string;
  onCheck: ({
    context,
    event,
  }: {
    context?: MachineContext<Transitive>;
    event: { payload: Check & { soft?: boolean } };
  }) => Promise<IFacilitated<Transitive>>;
  onCreate: ({
    context,
    event,
  }: {
    context: MachineContext<Transitive>;
    event: { payload: Create };
  }) => Promise<void>;
}

/**
 *
 * Machine template for creating resources in their absence.
 *
 * Example: checking eligibility for airstreams and claiming
 */

function create<Check, Create, Transitive>({
  id,
  onCheck,
  onCreate,
}: Props<Check, Create, Transitive>) {
  return createMachine(
    {
      tsTypes: {} as import("./index.typegen").Typegen0,
      id: `facilitator-${id}`,
      initial: "idle",
      context: {
        status: "idle",
        transitive: undefined,
        error: undefined,
      },
      predictableActionArguments: true,
      schema: {
        context: {} as MachineContext<Transitive>,
        events: {} as MachineEvents<Check, Create>,
        services: {} as MachineServices<Transitive>,
      },
      states: {
        idle: {
          entry: "doClean",
          on: {
            CHECK: {
              target: "checking",
            },
          },
        },
        checking: {
          entry: "doClean",
          invoke: {
            id: "check",
            src: "doCheck",
            onDone: {
              actions: "doCache",
              target: "checked",
            },
            onError: {
              actions: "doError",
              target: "failed",
            },
          },
          on: {
            RESET: {
              target: "idle",
            },
            CHECK: {
              /**
               * While checking, dependencies might update.
               * Checks have to be always up-to-date.
               * This self-transitions makes sure the state will always resolve with the latest dataset.
               */
              target: "checking",
            },
          },
        },
        checked: {
          on: {
            CREATE: {
              target: "creating",
            },
            CHECK: {
              target: "checking",
            },
            RESET: {
              target: "idle",
            },
          },
        },
        creating: {
          invoke: {
            id: "create",
            src: "doCreate",
            onDone: {
              target: "created",
            },
            onError: {
              actions: "doError",
              target: "failed",
            },
          },
          on: {
            RESET: {
              target: "idle",
            },
          },
        },
        created: {
          on: {
            RESET: {
              target: "idle",
            },
          },
        },
        failed: {
          on: {
            RESET: {
              target: "idle",
            },
            CHECK: {
              target: "checking",
            },
            CREATE: {
              target: "creating",
            },
          },
        },
      },
    },
    {
      actions: {
        doClean: assign({
          error: (_context, _event) => undefined,
          status: (_context, _event) => "idle" as const,
          transitive: (_context, _event) => undefined,
        }),
        doCache: assign({
          error: (_context, _event) => undefined,
          status: (_context, event) => event.data.status,
          transitive: (_context, event) => event.data.transitive,
        }),
        doError: assign({
          error: (_context, event) => _.toString(_.get(event, "data.message")),
        }),
      },
      services: {
        doCheck: async (context, event) => {
          return onCheck({ context, event });
        },
        doCreate: async (context, event) => {
          return onCreate({ context, event });
        },
      },
    },
  );
}

const instantiator = {
  /**
   * Multiple instances of this machine could be necessary,
   * so we're using an instance generator
   */
  create,
};

export default instantiator;
