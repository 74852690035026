import { useRef } from "react";
import { facilitator } from "@sablier/v2-machines";
import { _ } from "@sablier/v2-mixins";
import { useMachine } from "@xstate/react";

type Generator<Check, Create, Transitive> = typeof facilitator.create<
  Check,
  Create,
  Transitive
>;
type Arguments<Check, Create, Transitive> = Parameters<
  Generator<Check, Create, Transitive>
>;
type Configuration<Check, Create, Transitive> = Arguments<
  Check,
  Create,
  Transitive
>["0"];

function useMachineFacilitator<Check, Create, Transitive>(
  configuration: Configuration<Check, Create, Transitive>,
) {
  const reference = useRef(
    facilitator.create<Check, Create, Transitive>(configuration),
  );

  return useMachine(reference.current);
}

export default useMachineFacilitator;
