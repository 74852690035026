import {
  DEFAULT_CHAIN_ID,
  StreamShapes,
  ZERO,
  chains,
} from "@sablier/v2-constants";
import { StreamCategory, StreamStatus } from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { hslToColorString } from "polished";
import { encodePacked, keccak256 } from "viem";
import type { Params } from "./Attributes";
import type { Translate } from "@sablier/v2-locales";
import type {
  IAddress,
  IAlias,
  IMilliseconds,
  IOptions,
  IStreamAlias,
  IStreamId,
  IValue,
  IWagmiAddress,
} from "@sablier/v2-types";
import Segment from "../Segment";
import Token from "../Token";
import Attributes from "./Attributes";

export type Preview = {
  cancelable: string;
  cancelableHistorical: string;
  evolution: string;
  cliffAmount: string | undefined;
  cliffDurationWorded: string;
  isCliffing: boolean;
  progressBox: "linear" | "cliff" | "dynamic" | "tranched";
  shape: string;
  status: string;
  timeSinceCliff: string;
  timeSinceCliffPrefix: string;
  timeSinceCliffWorded: string;
  timeSinceEnd: string;
  timeSinceEndPrefix: string;
  timeSinceEndWorded: string;
  title: string;
  transferable: string;
};

export type Identity = {
  /** The address of the stream contract */
  address?: IAddress;
  /** The alias of the stream contract */
  alias?: IAlias;
  /** Identifier with the contract address */
  withAddress?: IStreamId;
  /** Identifier with the contract alias */
  withAlias?: IStreamAlias;
};

export interface IFilterStream {
  chainId?: number;
  streamIds?: IStreamId[];
  sender?: IAddress;
  recipient?: IAddress;
  proxy?: IAddress;
  token?: IAddress;
}

export interface ISearchStream {
  filter: IFilterStream;
  options: IOptions;
  streams: Functionality[];
}

/**
 * ------------------------------
 * The "Functionality" part of the Stream class will
 * include only methods that work on top of the Attributes.
 * ------------------------------
 * Inheritance is not used here for OOP purposes,
 * but for readability (smaller class parts)
 * ------------------------------
 */
export default class Functionality extends Attributes {
  constructor(params: Params, token: Token) {
    super(params, token);
    this.doUpdate();
  }

  /**
   * Test that a given string is a valid IStreamId
   */
  static isId(value: IStreamId | string | undefined) {
    if (!_.isNilOrEmptyString(value)) {
      if (value.includes("-") && value.split("-").length === 3) {
        const parts = value.split("-");
        if (
          _.isEthereumAddress(parts[0]) &&
          new BigNumber(parts[1]).isFinite() &&
          new BigNumber(parts[2]).isFinite()
        ) {
          if (
            Object.values(chains).find(
              (chain) => chain.chainId.toString() === parts[1],
            )
          ) {
            return true;
          }
        }
      }
    }

    return false;
  }

  /**
   * Test that a given string is a valid IStreamAlias
   */
  static isAlias(
    value: IStreamAlias | string | undefined,
    supported?: { alias: IAlias }[],
  ) {
    if (!_.isNilOrEmptyString(value)) {
      if (
        value.includes("-") &&
        (value.split("-").length === 3 || value.split("-").length === 4)
      ) {
        const parts = value.split("-");
        if (
          _.isString(parts[0]) &&
          new BigNumber(parts[1]).isFinite() &&
          new BigNumber(parts[2]).isFinite()
        ) {
          if (!_.isNilOrEmptyString(supported)) {
            return !_.isNilOrEmptyString(
              supported.find(
                (item) => item.alias.toLowerCase() === parts[0].toLowerCase(),
              ),
            );
          }
        }
      }
    }

    return false;
  }

  static doSplitIdentifier(value: IStreamAlias | IStreamId | undefined): {
    source?: string;
    chainId?: number;
    tokenId?: string;
  } {
    try {
      if (!_.isNilOrEmptyString(value)) {
        const parts = value.split("-");
        if (parts.length === 3) {
          return {
            source: _.toString(parts[0]).toLowerCase(),
            chainId: _.toNumber(parts[1]),
            tokenId: _.toString(parts[2]),
          };
        }
      }
    } catch (error) {
      vendors.crash.log(error);
    }
    return {
      source: undefined,
      chainId: undefined,
      tokenId: undefined,
    };
  }

  static doIdentify(
    value: IStreamAlias | IStreamId,
    aliases: { alias: IAlias; address: IAddress }[],
  ): Identity {
    if (Functionality.isAlias(value, aliases)) {
      const withAlias = _.toAlias(value);
      const parts = Functionality.doSplitIdentifier(value);
      const alias = parts.source;

      const address = aliases.find(
        (item) => item.alias.toLowerCase() === alias?.toLowerCase(),
      )?.address;
      const withAddress = address
        ? `${_.toAddress(address)}-${parts.chainId}-${parts.tokenId}`
        : undefined;

      return {
        address,
        alias,
        withAddress,
        withAlias,
      };
    } else {
      const parts = Functionality.doSplitIdentifier(value);
      const withAddress = value.toLowerCase();
      const address = _.toAddress(parts.source);

      const alias = aliases?.find(
        (item) => _.toAddress(item.address) === address,
      )?.alias;
      const withAlias = alias
        ? `${alias.toLowerCase()}-${parts.chainId}-${parts.tokenId}`
        : undefined;

      return {
        address,
        alias,
        withAddress,
        withAlias,
      };
    }
  }

  static doGenerateId(
    address: IAddress,
    chainId: number,
    tokenId: string,
  ): IStreamId {
    return `${_.toAddress(address)}-${chainId}-${tokenId}`;
  }

  static doGenerateAlias(
    alias: IAlias,
    chainId: number,
    tokenId: string,
  ): IStreamAlias {
    return _.toAlias(`${alias}-${chainId}-${tokenId}`)!;
  }

  static doFormatFilter({
    chainId,
    sender,
    recipient,
    streamIds,
    token,
  }: {
    chainId?: number | string | undefined;
    sender?: IAddress | undefined;
    recipient?: IAddress | undefined;
    streamIds?: IStreamId[] | undefined;
    token?: IAddress | undefined;
  } = {}): IFilterStream {
    const filter: IFilterStream = {
      chainId: undefined,
      sender: undefined,
      recipient: undefined,
      streamIds: undefined,
      token: undefined,
    };

    if (!_.isNilOrEmptyString(chainId)) {
      filter.chainId = _.toNumber(chainId) || undefined;
    }

    if (!_.isNilOrEmptyString(sender)) {
      filter.sender = _.toAddress(sender);
    }

    if (!_.isNilOrEmptyString(recipient)) {
      filter.recipient = _.toAddress(recipient);
    }

    if (!_.isNilOrEmptyString(streamIds)) {
      if (_.isArray(streamIds) && streamIds.length > 0) {
        filter.streamIds = streamIds.map((item) => item || "");
      }
    }

    if (!_.isNilOrEmptyString(token)) {
      filter.token = _.toAddress(token);
    }

    return filter;
  }

  static findRate(
    amount: string | undefined,
    duration: IMilliseconds | undefined,
  ) {
    try {
      return {
        second: new BigNumber(1000).times(amount || 0).dividedBy(duration || 1),
        minute: new BigNumber(1000 * 60)
          .times(amount || 0)
          .dividedBy(duration || 1),
        hour: new BigNumber(1000 * 60 * 60)
          .times(amount || 0)
          .dividedBy(duration || 1),
        day: new BigNumber(1000 * 60 * 60 * 24)
          .times(amount || 0)
          .dividedBy(duration || 1),
      } as const;
    } catch (_e) {
      return {
        second: ZERO,
        minute: ZERO,
        hour: ZERO,
        day: ZERO,
      } as const;
    }
  }

  doUpdate() {
    const now = Date.now();
    const end = !this.isCanceled ? this.endTime : this.canceledTime;
    const cutoff = BigNumber.min(new BigNumber(now), new BigNumber(end || 0));

    this._streamedAmount = this.findStreamedAmount(_.toString(now));
    this._streamedAmountPercentage = this.streamedAmount.raw
      .dividedBy(this.depositAmount.raw)
      .times(new BigNumber(100));

    this._streamedAmountEstimate = this.findStreamedAmount(
      _.toString(now),
      true,
    );
    this._streamedAmountEstimatePercentage = this._streamedAmountEstimate.raw
      .dividedBy(this.depositAmount.raw)
      .times(new BigNumber(100));

    this._streamedDuration = BigNumber.max(
      new BigNumber(0),
      new BigNumber(cutoff || 0).minus(this.startTime),
    ).toString();

    this._streamedDurationPercentage = new BigNumber(this._streamedDuration)
      .dividedBy(this.duration)
      .times(new BigNumber(100));

    this._returnableAmount = _.toValue({
      decimals: this.token.decimals,
      raw: this.depositAmount.raw.minus(this.streamedAmount.raw),
    });

    this._returnableAmountPercentage = new BigNumber(100).minus(
      this.streamedAmountPercentage,
    );

    this._withdrawableAmount = _.toValue({
      decimals: this.token.decimals,
      raw: this._streamedAmount.raw.minus(this.withdrawnAmount.raw),
    });
    this._withdrawableAmountPercentage = this._streamedAmountPercentage.minus(
      this.withdrawnAmountPercentage,
    );

    this._timeSinceEnd = new BigNumber(now)
      .minus(new BigNumber(end || 0))
      .toString();
    this._timeSinceCliff = new BigNumber(now)
      .minus(new BigNumber(this.cliffTime || 0))
      .toString();

    if (this.category === StreamCategory.LOCKUP_LINEAR) {
      if (!this.cliff) {
        this.segments = Segment.generateLinearSet(this);
      } else {
        const cliffDurationBn = new BigNumber(this.cliffTime)
          .minus(new BigNumber(this.startTime))
          .dividedBy(1000);
        const durationBn = new BigNumber(this.endTime)
          .minus(new BigNumber(this.startTime))
          .dividedBy(1000);
        const cliffAmount = this.depositAmount.raw
          .times(cliffDurationBn)
          .dividedBy(durationBn);
        this.segments = Segment.generateCliffSet(this, { cliff: cliffAmount });
      }
    }

    this._status = this.findStatus(now);
  }

  /**
   * Generates a pseudo-random HSL color by hashing together the `chainId`, the `sablier` address,
   * and the `streamId`. This will be used as the accent color for the SVG.
   *
   * See v2-core/.../SablierV2NFTDescriptor.sol - generateAccentColor for context
   */

  findAccent() {
    /**
     * Hash the parameters to generate a pseudo-random bit field, which will be used as entropy.
     * | Hue     | Saturation | Lightness | -> Roles
     * | [31:16] | [15:8]     | [7:0]     | -> Bit positions
     */

    const bitField = new BigNumber(
      keccak256(
        encodePacked(
          ["uint256", "address", "uint256"],
          [
            _.toBigInt(this.chainId),
            this.contract as IWagmiAddress,
            _.toBigInt(this.tokenId),
          ],
        ),
      ),
    ).mod(2 ** 32);

    /**
     * The hue is a degree on a color wheel, so its range is [0, 360).
     * Shifting 16 bits to the right means using the bits at positions [31:16].
     */

    const hue = new BigNumber(bitField.dividedToIntegerBy(2 ** 16).modulo(360))
      .mod(2 ** 256)
      .toNumber();

    /**
     * The saturation is a percentage where 0% is grayscale and 100%, but here the
     * range is bounded to [20,100] to make the colors more lively.
     * Shifting 8 bits to the risk and applying an 8-bit mask means using the bits at positions [15:8].
     */

    const saturation = new BigNumber(
      bitField.dividedToIntegerBy(2 ** 8).mod(2 ** 8),
    )
      .modulo(80)
      .plus(20)
      .toNumber();

    /**
     * The lightness is typically a percentage between 0% (black) and 100% (white), but here the range
     * is bounded to [30,100] to avoid dark colors.
     * Applying an 8-bit mask means using the bits at positions [7:0].
     */

    const lightness = new BigNumber(bitField.mod(2 ** 8))
      .modulo(70)
      .plus(30)
      .toNumber();

    /**
     * Convert HSL to RGB to pack is easier as a URI parameter
     */

    const set = {
      hue: Math.trunc(hue),
      saturation: Math.trunc(saturation) / 100,
      lightness: Math.trunc(lightness) / 100,
    } as const;

    return hslToColorString(set);
  }

  static findAliases(
    chainId: number | undefined,
    contracts:
      | Record<number, Record<string, { alias: IAlias; address: IAddress }>>
      | undefined,
  ) {
    if (_.isNilOrEmptyString(chainId) || _.isNil(contracts)) {
      return [];
    }

    return Object.values(contracts[chainId] || {}).map((item) => ({
      address: _.toAddress(item.address),
      alias: item.alias.toLowerCase(),
    }));
  }

  findSegmentsAugmented() {
    if (!this.segments.length) {
      return this.segments;
    }

    const last = this.segments[this.segments.length - 1];
    const segments = this.segments.filter(
      (segment) =>
        this.category !== StreamCategory.LOCKUP_TRANCHED ||
        !(
          segment.amount.humanized.isEqualTo(new BigNumber(0)) &&
          new BigNumber(segment.duration).isLessThanOrEqualTo(
            new BigNumber(1000),
          )
        ),
    );

    const milestone = new BigNumber(last.payload.milestone)
      .plus(24 * 60 * 60 + 1)
      .toString();

    const suffix = new Segment(
      {
        id: "suffix",
        position: (last.position + 1).toString(),
        /* ------- */
        milestone,
        amount: "0",
        exponent: "0",
        /* ------- */
        endAmount: last.payload.endAmount,
        startAmount: last.payload.endAmount,

        startTime: last.payload.endTime,
        endTime: new BigNumber(last.payload.endTime)
          .plus(24 * 60 * 60 + 1)
          .toString(),
      },
      last.token,
      true,
    );

    return [...segments, suffix];
  }

  findStatus(time?: number): StreamStatus {
    /**
     * Check for a canceled stream
     */
    if (this.isCanceled) {
      if (this.intactAmount.raw.isEqualTo(new BigNumber(0))) {
        return StreamStatus.DEPLETED_CANCELED;
      }
      return StreamStatus.CANCELED;
    }

    /*
     * Check for a settled stream
     */

    if (this.streamedDurationPercentage.isEqualTo(new BigNumber(100))) {
      if (this.intactAmount.raw.isEqualTo(new BigNumber(0))) {
        return StreamStatus.DEPLETED_SETTLED;
      }
      return StreamStatus.SETTLED;
    }

    /**
     * Check for a pending stream that has not started yet
     */
    if (_.toDayjs(time?.toString()).isBefore(_.toDayjs(this.startTime))) {
      return StreamStatus.PENDING;
    }

    return StreamStatus.STREAMING;
  }

  findTimeline() {
    const timeline = {
      end: this.endTime,
      progress: this.streamedDurationPercentage.toString(),
      start: this.startTime,
      segments:
        this.segments.map((segment) => ({
          moment: segment.endTime,
          value: segment.endAmount.humanized.toString(),
        })) || [],
      token: this.token,
      value: this.depositAmount.humanized.toString(),
    };

    return timeline;
  }

  /**
   *
   * @param {IMilliseconds} time The moment at which to compute a streamed amount
   * @param {boolean} isSimulated  Flag that tells the system to simulate amounts regardless of cliffs or cancellations (a.k.a. What if...)
   * @returns
   */

  findStreamedAmount(time: IMilliseconds, isSimulated = false): IValue {
    const milestoneD = _.toDayjs(time);
    const milestoneBN = new BigNumber(time);
    const endD = _.toDayjs(this.endTime);
    const endBN = new BigNumber(this.endTime);
    const startD = _.toDayjs(this.startTime);
    const startBN = new BigNumber(this.startTime);
    const zero = ZERO(this.token.decimals);

    if (milestoneD.isBefore(startD)) {
      return zero;
    }
    if (this.isCanceled && !isSimulated) {
      /** W: Increases with every withdrawn */
      const withdrawn = this.withdrawnAmount.raw;
      /** L: Decreases with every withdraw, initially set to the "recipient amount" upon cancellation */
      const locked = this.intactAmount.raw;
      /** Total: Streamed value */
      return _.toValue({
        decimals: this.token.decimals,
        raw: withdrawn.plus(locked),
      });
    }
    if (milestoneD.isAfter(endD)) {
      return this.depositAmount;
    }

    const amountBN = this.depositAmount.raw;

    switch (this.category) {
      case StreamCategory.LOCKUP_LINEAR: {
        if (this.cliff) {
          const cliffBN = new BigNumber(this.cliffTime);
          if (milestoneBN.minus(cliffBN).isLessThanOrEqualTo(zero.raw)) {
            return _.toValue({
              decimals: this.token.decimals,
              raw: zero.raw,
            });
          }
        }

        const distanceBN = milestoneBN.minus(startBN);
        const durationBN = endBN.minus(startBN);
        /** Compute the rate per millisecond for the entire stream */
        const rateBN = amountBN.dividedBy(durationBN);
        const expectedBN = rateBN.times(distanceBN);
        const streamedBN = BigNumber.min(expectedBN, this.depositAmount.raw);

        return _.toValue({
          decimals: this.token.decimals,
          raw: streamedBN,
        });
      }

      case StreamCategory.LOCKUP_DYNAMIC:
      case StreamCategory.LOCKUP_TRANCHED: {
        let expectedBN = zero.raw;
        for (const segment of this.segments) {
          const segmentStartBN = new BigNumber(segment.startTime);
          const segmentEndBN = new BigNumber(segment.endTime);
          if (milestoneBN.isGreaterThanOrEqualTo(segmentEndBN)) {
            expectedBN = expectedBN.plus(segment.amount.raw);
          } else {
            if (milestoneBN.isEqualTo(segmentStartBN)) {
              break;
            }
            const durationBN = segmentEndBN.minus(segmentStartBN);
            const distanceBN = milestoneBN.minus(segmentStartBN);
            const base = distanceBN.dividedBy(durationBN);
            const localAmount = segment.amount.raw.multipliedBy(
              /**
               * We perform this check due to possible fractional exponents (which aren't supported by the default BigNumber.pow())
               * The issue was patched after the Exactly + Steakhut incident. Although an edge-case (the exponent was not padded with
               * a realistic number of decimals resulting in a fractional exponent) the UI should still handle this use-case or fail gracefully.
               */

              segment.exponent.humanized.isInteger()
                ? base.pow(segment.exponent.humanized)
                : _.powWithFractional({
                    base,
                    exponent: segment.exponent.humanized,
                  }),
            );
            expectedBN = expectedBN.plus(localAmount);
            break;
          }
        }
        const streamedBN = BigNumber.min(expectedBN, this.depositAmount.raw);
        return _.toValue({ decimals: this.token.decimals, raw: streamedBN });
      }
      default:
        break;
    }

    return zero;
  }

  static findShape(
    shape?: string,
  ): (typeof StreamShapes)["linear"] & { isUnknown?: boolean } {
    if (!_.isNil(shape)) {
      const entry = Object.entries(StreamShapes).find(
        ([key, value]) =>
          key.toLowerCase() === _.toString(shape).toLowerCase() ||
          value.id.toLowerCase() === _.toString(shape).toLowerCase(),
      );

      if (!_.isNilOrEmptyString(entry)) {
        const [, value] = entry;
        return value;
      }
    }

    return StreamShapes.unknown;
  }

  static base() {
    return {
      alias: "",
      id: "",
      tokenId: "",
      chainId: DEFAULT_CHAIN_ID,
      cancelable: false,
      transferable: false,
      canceled: false,
      proxied: true,
      contract: {
        address: "",
      },
      sender: "",
      recipient: "",
      proxender: "",
      withdrawnAmount: "0",
      segments: [],
      tranches: [],
    };
  }

  /**
   *
   * ----------------------------------------------------------------------------
   *
   */

  findPreview(t: Translate): Preview {
    const cancelable = this.wasCancelable
      ? t("structs.canBeCanceled")
      : t("structs.cannotBeCanceled");

    const cancelableHistorical =
      this.isCanceled || this.isSettled ? t("structs.notAnymore") : cancelable;

    const transferable = this.isTransferable
      ? t("structs.canBeTransferred")
      : t("structs.cannotBeTransferred");

    const shape = (() => {
      const found = Functionality.findShape(this.shape);
      if (!found.isUnknown) {
        return t(found.title);
      }
      return this.category;
    })();

    const evolution = (() => {
      if (this.category === StreamCategory.LOCKUP_LINEAR) {
        if (this.cliff) {
          return _.startCase(
            `${t("words.cliff")} ~${
              _.toDuration(this.cliffDuration, "time-estimate")[0]
            }`,
          );
        }
        return _.startCase(t("structs.lockupLinear"));
      }

      if (this.category === StreamCategory.LOCKUP_TRANCHED) {
        _.startCase(t("structs.lockupTranched"));
      }

      return _.startCase(t("structs.lockupDynamic"));
    })();

    const cliffAmount = this.cliff
      ? `${this.token.symbol}  ${_.toNumeralPrice(this.cliffAmount.humanized)}`
      : undefined;

    const cliffDurationWorded =
      this.category === StreamCategory.LOCKUP_LINEAR && this.cliff
        ? `${
            _.toDuration(this.cliffDuration, "time-estimate")[0]
          } (${_.toPercentage(this.cliffPercentage.toString())})`
        : "";

    const timeSinceCliff = _.toDayjs(this.cliffTime).from(
      _.toDayjs(
        new BigNumber(this.timeSinceCliff).plus(this.cliffTime).toString(),
      ),
    );

    const timeSinceCliffPrefix = `${
      new BigNumber(this.timeSinceCliff).isNegative()
        ? t("structs.cliffIs")
        : t("structs.cliffWas")
    }`;

    const timeSinceCliffWorded = `${timeSinceCliffPrefix} ${timeSinceCliff}`;

    const timeSinceEnd = _.toDayjs(this.endTime).from(
      _.toDayjs(new BigNumber(this.timeSinceEnd).plus(this.endTime).toString()),
    );

    const timeSinceEndPrefix = {
      [StreamStatus.STREAMING]: t("structs.streamWillEnd"),
      [StreamStatus.CANCELED]: t("structs.streamWasCanceled"),
      [StreamStatus.DEPLETED_CANCELED]: t("structs.streamWasCanceled"),
      [StreamStatus.SETTLED]: t("structs.streamIsSettled"),
      [StreamStatus.DEPLETED_SETTLED]: t("structs.streamIsDepleted"),
      [StreamStatus.PENDING]: t("structs.streamIsPending"),
    }[this.status];

    const timeSinceEndWorded = `${timeSinceEndPrefix} ${timeSinceEnd}`;

    const title = !_.isNil(this.alias)
      ? `#${this.alias}`
      : `#${this.chainId}-${this.tokenId}`;

    const isCliffing = this.isCliffing;

    const status = (() => {
      switch (this.status) {
        case StreamStatus.CANCELED:
        case StreamStatus.DEPLETED_CANCELED:
          return _.capitalize(t("words.canceled"));
        case StreamStatus.SETTLED:
          return _.capitalize(t("words.settled"));
        case StreamStatus.DEPLETED_SETTLED:
          return _.capitalize(t("words.depleted"));
        case StreamStatus.PENDING:
          return _.capitalize(t("words.starting"));
        case StreamStatus.STREAMING:
          return _.capitalize(t("words.streaming"));
        default:
          return "";
      }
    })();

    const progressBox = (() => {
      if (this.category === StreamCategory.LOCKUP_LINEAR) {
        if (this.cliff) {
          return "cliff" as const;
        }
        return "linear" as const;
      }

      if (this.category === StreamCategory.LOCKUP_TRANCHED) {
        return "tranched" as const;
      }

      return "dynamic" as const;
    })();

    return {
      cancelable,
      cancelableHistorical,
      cliffAmount,
      cliffDurationWorded,
      evolution,
      isCliffing,
      progressBox,
      shape,
      timeSinceCliff,
      timeSinceCliffPrefix,
      timeSinceCliffWorded,
      timeSinceEnd,
      timeSinceEndPrefix,
      timeSinceEndWorded,
      title,
      status,
      transferable,
    };
  }
}
