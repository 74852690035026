import { useMemo } from "react";
import styled, { css } from "styled-components";
import { _ } from "@sablier/v2-mixins";
import type { ITokenDisplay } from "@sablier/v2-types";
import type { ComponentProps } from "react";
import Token from "../Token";
import Tooltip from "../Tooltip";

const Wrapper = styled.div<{ sizeDivider?: number | string }>`
  ${(props) => props.theme.styles.row}
  & {
    column-gap: var(--gap, ${(props) => props.sizeDivider});
  }
`;

const Value = styled.div<{ sizeValue?: number | string }>`
  & > p {
    ${(props) => props.theme.styles.textElement}
  }

  ${(props) =>
    props.sizeValue &&
    css`
      p {
        font-size: ${props.sizeValue};
      }
    `}
`;

export interface Props {
  empty?: ComponentProps<typeof Token>["empty"];
  isFallbackShown?: boolean;
  isFormatted?: boolean;
  isValueHidden?: boolean;
  token?: ITokenDisplay;
  tooltip?: string;
  size?: ComponentProps<typeof Token>["size"];
  value?: string | number;
}

function Amount({
  empty,
  isFallbackShown = true,
  isFormatted = false,
  isValueHidden = false,
  token,
  tooltip,
  size,
  value: raw,
}: Props) {
  const value = useMemo(() => {
    if (isFormatted) {
      return _.toString(raw);
    }
    return _.toNumeralPrice(raw);
  }, [isFormatted, raw]);

  const sizeValue = useMemo(() => {
    if (_.isArray(size)) {
      return _.toNumber(_.get(size, "1"));
    }
    return undefined;
  }, [size]);

  const sizeDivider = useMemo(() => {
    if (sizeValue && sizeValue > 10) {
      return sizeValue / 2;
    }
    return 4;
  }, [sizeValue]);

  return (
    <Tooltip value={tooltip}>
      <Wrapper sizeDivider={sizeDivider && _.toSuffix(sizeDivider, "px")}>
        <Token
          empty={empty}
          image={token?.image}
          isFallbackShown={isFallbackShown}
          symbol={token?.symbol}
          size={size}
        />
        {!isValueHidden ? (
          <Value
            sizeValue={sizeValue && _.toSuffix(sizeValue, "pt")}
            title={_.toString(raw)}
          >
            <p>{value}</p>
          </Value>
        ) : (
          false
        )}
      </Wrapper>
    </Tooltip>
  );
}

export default Amount;
