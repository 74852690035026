import { useMemo } from "react";
import { whitelist } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import type { IToken } from "@sablier/v2-types";

function priority(token: IToken) {
  const list = whitelist.tokens[token.chainId] || [];
  return list.findIndex(
    (w) => _.toAddress(w.address) === _.toAddress(token.address),
  );
}

export default function useTokenSearch(
  list: IToken[],
  query?: string,
): IToken[] {
  const sorted = useMemo(() => {
    if (_.isNilOrEmptyString(query)) {
      return list;
    }
    const cleanup = _.toString(query).trim().toLowerCase();

    const exact: IToken[] = [];
    const occurrence: IToken[] = [];

    list.forEach((token) => {
      const address = _.toAddress(token.address);
      const name = token.name.toLowerCase();
      const symbol = token.symbol.toLowerCase();

      if (name === cleanup || symbol === cleanup || address === cleanup) {
        return exact.push(token);
      } else {
        const indexOfName = name.indexOf(cleanup);
        const indexOfSymbol = symbol.indexOf(cleanup);

        if (indexOfName > -1 || indexOfSymbol > -1) {
          return occurrence.push(token);
        }
      }
    });

    return [
      ...exact,
      ...[...(occurrence || [])].sort((a, b) => {
        const indexOfSymbolA = a.symbol.toLowerCase().indexOf(cleanup);
        const indexOfSymbolB = b.symbol.toLowerCase().indexOf(cleanup);

        /** If the symbols aren't conclusive, order a in front of b (positive) if the occurrence is earlier */
        if (indexOfSymbolA === indexOfSymbolB) {
          /** If the names aren't conclusive order a in front of b (positive) by string comp*/
          return a.name.localeCompare(b.name);
        }

        return a.symbol.localeCompare(b.symbol);
      }),
    ];
  }, [list, query]);

  const prioritized = useMemo(
    () => [...(sorted || [])].sort((a, b) => priority(b) - priority(a)),
    [sorted],
  );

  return prioritized;
}
