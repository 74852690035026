import { useMemo } from "react";
import {
  BoltIcon,
  CalendarDaysIcon,
  CurrencyDollarIcon,
  GlobeAltIcon,
  MapPinIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import { Characteristic } from "@sablier/v2-components/molecules";
import { DEFAULT_CHAIN_ID, StreamCategory } from "@sablier/v2-constants";
import {
  useChainExplorer,
  useShortAddress,
  useTokenToWallet,
} from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { useAccount } from "~/client/hooks";
import type { IChainDisplay, IMilliseconds, IToken } from "@sablier/v2-types";
import type { ComponentProps } from "react";

type Description = ComponentProps<typeof Characteristic>["description"];

interface ChainProps {
  chain?: IChainDisplay;
}

export function Chain({ chain }: ChainProps) {
  const { t } = useT();
  const description = useMemo((): Description => {
    return [
      {
        purpose: "label",
        options: {
          icon: GlobeAltIcon,
          value: `${t("words.chain")}:`,
        },
      },
      {
        purpose: "chain",
        options: {
          image: chain?.image,
          name: chain?.name,
        },
      },
    ];
  }, [chain, t]);

  return <Characteristic description={description} />;
}

interface IdentifierProps {
  id: string;
  category?: StreamCategory;
}

export function Identifier({ id }: IdentifierProps) {
  const { t } = useT();
  const description = useMemo((): Description => {
    return [
      {
        purpose: "label",
        options: {
          icon: PlayIcon,
          value: `${_.capitalize(t("words.id"))}:`,
        },
      },
      {
        purpose: "copy",
        options: {
          value: id,
          toCopy: id,
        },
      },
    ];
  }, [id, t]);

  return <Characteristic description={description} />;
}

interface StatusProps {
  status: string | undefined;
}

export function Status({ status }: StatusProps) {
  const { t } = useT();
  const description = useMemo((): Description => {
    return [
      {
        purpose: "label",
        options: {
          icon: BoltIcon,
          value: `${t("words.status")}:`,
        },
      },
      {
        purpose: "content",
        options: {
          value: _.capitalize(status),
        },
      },
    ];
  }, [status, t]);

  return <Characteristic description={description} />;
}

interface RangeProps {
  end: IMilliseconds | undefined;
  start: IMilliseconds | undefined;
}

export function Range({ end, start }: RangeProps) {
  const { t } = useT();

  const description = useMemo((): Description => {
    return [
      {
        purpose: "label",
        options: {
          icon: CalendarDaysIcon,
          value: `${_.capitalize(t("words.timing"))}:`,
        },
      },
      {
        purpose: "content",
        options: {
          value: t("structs.startsOn"),
        },
      },
      {
        purpose: "content",
        options: {
          value: _.toDuration(start, "date")[0],
        },
      },
      {
        purpose: "content",
        options: {
          value: t("words.and"),
        },
      },
      {
        purpose: "content",
        options: {
          value: t("structs.endsOn").toLowerCase(),
        },
      },
      {
        purpose: "content",
        options: {
          value: _.toDuration(end, "date")[0],
        },
      },
    ];
  }, [end, start, t]);

  return <Characteristic description={description} />;
}

interface TokenProps {
  chainId: number | undefined;
  token: IToken | undefined;
}

export function Token({ chainId, token }: TokenProps) {
  const { t } = useT();
  const { signer } = useAccount();
  const { add: doAdd } = useTokenToWallet({ signer, token });
  const shortAddress = useShortAddress(token?.address);

  const explorer = useChainExplorer({
    chainId,
    type: "token",
    hash: token?.address,
  });

  const description = useMemo((): Description => {
    return [
      {
        purpose: "label",
        options: {
          icon: CurrencyDollarIcon,
          value: `${_.capitalize(t("words.token"))}:`,
        },
      },
      {
        purpose: "amount",
        options: {
          token,
          isValueHidden: true,
          isFormatted: true,
        },
      },
      {
        purpose: "content",
        options: {
          value: t("structs.withTheAddress"),
        },
      },
      {
        purpose: "copy",
        options: {
          value: shortAddress,
          toCopy: token?.address,
        },
      },
      {
        purpose: "external",
        options: {
          value: "",
          to: explorer,
        },
      },
      {
        purpose: "content",
        options: {
          value: "--",
        },
      },
      {
        purpose: "button",
        options: {
          value: t("structs.addToWallet"),
          onClick: doAdd,
        },
      },
    ];
  }, [t, token, shortAddress, explorer, doAdd]);

  return <Characteristic description={description} />;
}

interface TransactionProps {
  chainId?: number;
  hash?: string;
}

export function Transaction({ chainId, hash }: TransactionProps) {
  const { t } = useT();
  const explorer = useChainExplorer({ chainId, type: "tx", hash });

  const description = useMemo((): Description => {
    return [
      {
        purpose: "label",
        options: {
          icon: MapPinIcon,
          value: `${t("words.transaction")}:`,
        },
      },
      {
        purpose: "content",
        options: {
          value: t("characteristic.view"),
        },
      },
      {
        purpose: "external",
        options: {
          value: t("characteristic.createTransaction"),
          to: explorer,
        },
      },
    ];
  }, [explorer, t]);

  return <Characteristic description={description} />;
}
