/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment ActionFragment on Action {\n    id\n    chainId\n    subgraphId\n    campaign {\n      id\n    }\n    category\n    hash\n    block\n    timestamp\n    from\n\n    claimStreamId\n    claimTokenId\n    claimAmount\n    claimIndex\n    claimRecipient\n\n    clawbackAmount\n    clawbackFrom\n    clawbackTo\n  }\n": types.ActionFragmentFragmentDoc,
    "\n  fragment FactoryFragment on Factory {\n    id\n    alias\n    address\n    version\n  }\n": types.FactoryFragmentFragmentDoc,
    "\n  fragment AssetFragment on Asset {\n    id\n    address\n    chainId\n    decimals\n    name\n    symbol\n  }\n": types.AssetFragmentFragmentDoc,
    "\n  fragment ActivityFragment on Activity {\n    id\n    timestamp\n    day\n    amount\n    claims\n    campaign {\n      id\n    }\n  }\n": types.ActivityFragmentFragmentDoc,
    "\n  fragment TrancheFragment on Tranche {\n    id\n    position\n    percentage\n    duration\n    endPercentage\n    startPercentage\n    endDuration\n    startDuration\n  }\n": types.TrancheFragmentFragmentDoc,
    "\n  fragment CampaignFragment on Campaign {\n    id\n    subgraphId\n    address\n    chainId\n    category\n    hash\n    timestamp\n    admin\n    lockup\n    root\n    expires\n    expiration\n    ipfsCID\n    name\n    nickname\n    aggregateAmount\n    totalRecipients\n    clawbackTime\n    streamCliff\n    streamCliffDuration\n    streamTotalDuration\n    streamCancelable\n    streamTransferable\n    claimedAmount\n    claimedCount\n    version\n    asset {\n      ...AssetFragment\n    }\n    factory {\n      ...FactoryFragment\n    }\n    streamTranches {\n      ...TrancheFragment\n    }\n  }\n": types.CampaignFragmentFragmentDoc,
    "\n  query getAirstreams_ByAliveByAdminByIdsByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $airstreamIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_in: $airstreamIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAliveByAdminByIdsByNameDocument,
    "\n  query getAirstreams_ByAliveByAdminByAssetByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $asset: String!\n    $excludeIds: [String!]\n    $name: String!\n    $currentTimestamp: BigInt!\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { asset: $asset }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAliveByAdminByAssetByNameDocument,
    "\n  query getAirstreams_ByAdminByIdsByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $airstreamIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_in: $airstreamIds }\n          { chainId: { _eq: $chainId } }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAdminByIdsByNameDocument,
    "\n  query getAirstreams_ByAdminByAssetByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $asset: String!\n    $excludeIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { asset: $asset }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAdminByAssetByNameDocument,
    "\n  query getAirstreams_ByAliveByAdminByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt\n    $admin: Bytes!\n    $excludeIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAliveByAdminByNameDocument,
    "\n  query getAirstreams_ByAliveByIdsByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { id_in: $airstreamIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAliveByIdsByNameDocument,
    "\n  query getAirstreams_ByAliveByAssetByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $asset: String!\n    $excludeIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { asset: $asset }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAliveByAssetByNameDocument,
    "\n  query getAirstreams_ByAliveByAdminByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $airstreamIds: [String!]\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_in: $airstreamIds }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAliveByAdminByIdsDocument,
    "\n  query getAirstreams_ByAliveByAdminByAsset(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $asset: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n    $excludeIds: [String!]\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { asset: $asset }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAliveByAdminByAssetDocument,
    "\n  query getAirstreams_ByAliveByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $excludeIds: [String!]\n    $name: String!\n    $currentTimestamp: BigInt!\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { chainId: $chainId }\n          { id_not_in: $excludeIds }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAliveByNameDocument,
    "\n  query getAirstreams_ByAdminByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $subgraphId: BigInt!\n    $name: String!\n    $excludeIds: [String!]\n    $asset: String # Required for compatibility\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAdminByNameDocument,
    "\n  query getAirstreams_ByIdsByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { id_in: $airstreamIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByIdsByNameDocument,
    "\n  query getAirstreams_ByCIDsByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamCIDs: [String!]\n    $excludeIds: [String!]\n    $subgraphId: BigInt!\n    $name: String!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { ipfsCID_in: $airstreamCIDs }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByCiDsByNameDocument,
    "\n  query getAirstreams_ByAssetByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $asset: String!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n    $name: String!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { asset: $asset }\n          { chainId: $chainId }\n          { id_not_in: $excludeIds }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAssetByNameDocument,
    "\n  query getAirstreams_ByAdminByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $airstreamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_in: $airstreamIds }\n          { chainId: { _eq: $chainId } }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAdminByIdsDocument,
    "\n  query getAirstreams_ByAdminByAsset(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $asset: String!\n    $excludeIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { asset: $asset }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAdminByAssetDocument,
    "\n  query getAirstreams_ByAliveByAdmin(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt\n    $admin: Bytes!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAliveByAdminDocument,
    "\n  query getAirstreams_ByAliveByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamIds: [String!]\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { id_in: $airstreamIds }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAliveByIdsDocument,
    "\n  query getAirstreams_ByAliveByAsset(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $asset: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n    $excludeIds: [String!]\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { asset: $asset }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAliveByAssetDocument,
    "\n  query getAirstreams_ByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $currentTimestamp: BigInt!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n    $name: String!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { chainId: $chainId }\n          { id_not_in: $excludeIds }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByNameDocument,
    "\n  query getAirstreams_ByAlive(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $currentTimestamp: BigInt!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { chainId: $chainId }\n          { id_not_in: $excludeIds }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAliveDocument,
    "\n  query getAirstreams_ByAdmin(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n    $asset: String # Required for compatibility\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAdminDocument,
    "\n  query getAirstreams_ByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        id_in: $airstreamIds\n        chainId: $chainId\n        subgraphId_lt: $subgraphId\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByIdsDocument,
    "\n  query getAirstreams_ByCIDs(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamCIDs: [String!]\n    $excludeIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        ipfsCID_in: $airstreamCIDs\n        id_not_in: $excludeIds\n        chainId: $chainId\n        subgraphId_lt: $subgraphId\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByCiDsDocument,
    "\n  query getAirstreams_ByAsset(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $asset: String!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $asset\n        chainId: $chainId\n        id_not_in: $excludeIds\n        subgraphId_lt: $subgraphId\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreams_ByAssetDocument,
    "\n  query getAirstreams(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n    $asset: String # Required for compatibility\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n        id_not_in: $excludeIds\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstreamsDocument,
    "\n  query getAirstream_ById($airstreamId: ID!) {\n    campaign(id: $airstreamId) {\n      ...CampaignFragment\n    }\n  }\n": types.GetAirstream_ByIdDocument,
    "\n  query getActions_ByAirstream(\n    $first: Int!\n    $airstreamId: String!\n    $subgraphId: BigInt!\n  ) {\n    actions(\n      first: $first\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { campaign: $airstreamId, subgraphId_lt: $subgraphId }\n    ) {\n      ...ActionFragment\n      campaign {\n        id\n        asset {\n          ...AssetFragment\n        }\n      }\n    }\n  }\n": types.GetActions_ByAirstreamDocument,
    "\n  query getMetadata_ByAirstream(\n    $airstreamId: ID!\n    $airstreamIdClone: String!\n    $dayFrom: BigInt!\n    $dayTo: BigInt!\n  ) {\n    campaign(id: $airstreamId) {\n      id\n      asset {\n        ...AssetFragment\n      }\n      activities(first: 7, where: { day_gte: $dayFrom, day_lte: $dayTo }) {\n        ...ActivityFragment\n      }\n      actions(first: 5, orderBy: subgraphId, orderDirection: desc) {\n        ...ActionFragment\n      }\n    }\n    actions(\n      first: 10\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { campaign: $airstreamIdClone, category: Claim }\n    ) {\n      ...ActionFragment\n    }\n    firsts: actions(\n      first: 1\n      orderBy: timestamp\n      orderDirection: asc\n      where: { campaign: $airstreamIdClone, category: Claim }\n    ) {\n      ...ActionFragment\n    }\n  }\n": types.GetMetadata_ByAirstreamDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ActionFragment on Action {\n    id\n    chainId\n    subgraphId\n    campaign {\n      id\n    }\n    category\n    hash\n    block\n    timestamp\n    from\n\n    claimStreamId\n    claimTokenId\n    claimAmount\n    claimIndex\n    claimRecipient\n\n    clawbackAmount\n    clawbackFrom\n    clawbackTo\n  }\n"): (typeof documents)["\n  fragment ActionFragment on Action {\n    id\n    chainId\n    subgraphId\n    campaign {\n      id\n    }\n    category\n    hash\n    block\n    timestamp\n    from\n\n    claimStreamId\n    claimTokenId\n    claimAmount\n    claimIndex\n    claimRecipient\n\n    clawbackAmount\n    clawbackFrom\n    clawbackTo\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment FactoryFragment on Factory {\n    id\n    alias\n    address\n    version\n  }\n"): (typeof documents)["\n  fragment FactoryFragment on Factory {\n    id\n    alias\n    address\n    version\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AssetFragment on Asset {\n    id\n    address\n    chainId\n    decimals\n    name\n    symbol\n  }\n"): (typeof documents)["\n  fragment AssetFragment on Asset {\n    id\n    address\n    chainId\n    decimals\n    name\n    symbol\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ActivityFragment on Activity {\n    id\n    timestamp\n    day\n    amount\n    claims\n    campaign {\n      id\n    }\n  }\n"): (typeof documents)["\n  fragment ActivityFragment on Activity {\n    id\n    timestamp\n    day\n    amount\n    claims\n    campaign {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment TrancheFragment on Tranche {\n    id\n    position\n    percentage\n    duration\n    endPercentage\n    startPercentage\n    endDuration\n    startDuration\n  }\n"): (typeof documents)["\n  fragment TrancheFragment on Tranche {\n    id\n    position\n    percentage\n    duration\n    endPercentage\n    startPercentage\n    endDuration\n    startDuration\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CampaignFragment on Campaign {\n    id\n    subgraphId\n    address\n    chainId\n    category\n    hash\n    timestamp\n    admin\n    lockup\n    root\n    expires\n    expiration\n    ipfsCID\n    name\n    nickname\n    aggregateAmount\n    totalRecipients\n    clawbackTime\n    streamCliff\n    streamCliffDuration\n    streamTotalDuration\n    streamCancelable\n    streamTransferable\n    claimedAmount\n    claimedCount\n    version\n    asset {\n      ...AssetFragment\n    }\n    factory {\n      ...FactoryFragment\n    }\n    streamTranches {\n      ...TrancheFragment\n    }\n  }\n"): (typeof documents)["\n  fragment CampaignFragment on Campaign {\n    id\n    subgraphId\n    address\n    chainId\n    category\n    hash\n    timestamp\n    admin\n    lockup\n    root\n    expires\n    expiration\n    ipfsCID\n    name\n    nickname\n    aggregateAmount\n    totalRecipients\n    clawbackTime\n    streamCliff\n    streamCliffDuration\n    streamTotalDuration\n    streamCancelable\n    streamTransferable\n    claimedAmount\n    claimedCount\n    version\n    asset {\n      ...AssetFragment\n    }\n    factory {\n      ...FactoryFragment\n    }\n    streamTranches {\n      ...TrancheFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAliveByAdminByIdsByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $airstreamIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_in: $airstreamIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAliveByAdminByIdsByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $airstreamIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_in: $airstreamIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAliveByAdminByAssetByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $asset: String!\n    $excludeIds: [String!]\n    $name: String!\n    $currentTimestamp: BigInt!\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { asset: $asset }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAliveByAdminByAssetByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $asset: String!\n    $excludeIds: [String!]\n    $name: String!\n    $currentTimestamp: BigInt!\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { asset: $asset }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAdminByIdsByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $airstreamIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_in: $airstreamIds }\n          { chainId: { _eq: $chainId } }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAdminByIdsByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $airstreamIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_in: $airstreamIds }\n          { chainId: { _eq: $chainId } }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAdminByAssetByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $asset: String!\n    $excludeIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { asset: $asset }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAdminByAssetByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $asset: String!\n    $excludeIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { asset: $asset }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAliveByAdminByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt\n    $admin: Bytes!\n    $excludeIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAliveByAdminByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt\n    $admin: Bytes!\n    $excludeIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAliveByIdsByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { id_in: $airstreamIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAliveByIdsByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { id_in: $airstreamIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAliveByAssetByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $asset: String!\n    $excludeIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { asset: $asset }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAliveByAssetByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $asset: String!\n    $excludeIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { asset: $asset }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAliveByAdminByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $airstreamIds: [String!]\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_in: $airstreamIds }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAliveByAdminByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $airstreamIds: [String!]\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_in: $airstreamIds }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAliveByAdminByAsset(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $asset: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n    $excludeIds: [String!]\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { asset: $asset }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAliveByAdminByAsset(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $asset: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n    $excludeIds: [String!]\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { asset: $asset }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAliveByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $excludeIds: [String!]\n    $name: String!\n    $currentTimestamp: BigInt!\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { chainId: $chainId }\n          { id_not_in: $excludeIds }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAliveByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $excludeIds: [String!]\n    $name: String!\n    $currentTimestamp: BigInt!\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { chainId: $chainId }\n          { id_not_in: $excludeIds }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAdminByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $subgraphId: BigInt!\n    $name: String!\n    $excludeIds: [String!]\n    $asset: String # Required for compatibility\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAdminByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $subgraphId: BigInt!\n    $name: String!\n    $excludeIds: [String!]\n    $asset: String # Required for compatibility\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByIdsByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { id_in: $airstreamIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByIdsByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamIds: [String!]\n    $name: String!\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { id_in: $airstreamIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByCIDsByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamCIDs: [String!]\n    $excludeIds: [String!]\n    $subgraphId: BigInt!\n    $name: String!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { ipfsCID_in: $airstreamCIDs }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByCIDsByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamCIDs: [String!]\n    $excludeIds: [String!]\n    $subgraphId: BigInt!\n    $name: String!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { ipfsCID_in: $airstreamCIDs }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAssetByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $asset: String!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n    $name: String!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { asset: $asset }\n          { chainId: $chainId }\n          { id_not_in: $excludeIds }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAssetByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $asset: String!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n    $name: String!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { asset: $asset }\n          { chainId: $chainId }\n          { id_not_in: $excludeIds }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAdminByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $airstreamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_in: $airstreamIds }\n          { chainId: { _eq: $chainId } }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAdminByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $airstreamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_in: $airstreamIds }\n          { chainId: { _eq: $chainId } }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAdminByAsset(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $asset: String!\n    $excludeIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { asset: $asset }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAdminByAsset(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $asset: String!\n    $excludeIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { asset: $asset }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAliveByAdmin(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt\n    $admin: Bytes!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAliveByAdmin(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt\n    $admin: Bytes!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAliveByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamIds: [String!]\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { id_in: $airstreamIds }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAliveByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamIds: [String!]\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { id_in: $airstreamIds }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAliveByAsset(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $asset: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n    $excludeIds: [String!]\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { asset: $asset }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAliveByAsset(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $asset: String!\n    $subgraphId: BigInt!\n    $currentTimestamp: BigInt!\n    $excludeIds: [String!]\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { asset: $asset }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $currentTimestamp: BigInt!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n    $name: String!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { chainId: $chainId }\n          { id_not_in: $excludeIds }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByName(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $currentTimestamp: BigInt!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n    $name: String!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { chainId: $chainId }\n          { id_not_in: $excludeIds }\n          { nickname_contains: $name }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAlive(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $currentTimestamp: BigInt!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { chainId: $chainId }\n          { id_not_in: $excludeIds }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAlive(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $currentTimestamp: BigInt!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { chainId: $chainId }\n          { id_not_in: $excludeIds }\n          { subgraphId_lt: $subgraphId }\n          { or: [{ expiration_gt: $currentTimestamp }, { expires: false }] }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAdmin(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n    $asset: String # Required for compatibility\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAdmin(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $admin: Bytes!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n    $asset: String # Required for compatibility\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        and: [\n          { admin: $admin }\n          { id_not_in: $excludeIds }\n          { chainId: $chainId }\n          { subgraphId_lt: $subgraphId }\n        ]\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        id_in: $airstreamIds\n        chainId: $chainId\n        subgraphId_lt: $subgraphId\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByIds(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        id_in: $airstreamIds\n        chainId: $chainId\n        subgraphId_lt: $subgraphId\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByCIDs(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamCIDs: [String!]\n    $excludeIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        ipfsCID_in: $airstreamCIDs\n        id_not_in: $excludeIds\n        chainId: $chainId\n        subgraphId_lt: $subgraphId\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByCIDs(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $airstreamCIDs: [String!]\n    $excludeIds: [String!]\n    $subgraphId: BigInt!\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        ipfsCID_in: $airstreamCIDs\n        id_not_in: $excludeIds\n        chainId: $chainId\n        subgraphId_lt: $subgraphId\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams_ByAsset(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $asset: String!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $asset\n        chainId: $chainId\n        id_not_in: $excludeIds\n        subgraphId_lt: $subgraphId\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams_ByAsset(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $asset: String!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        asset: $asset\n        chainId: $chainId\n        id_not_in: $excludeIds\n        subgraphId_lt: $subgraphId\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstreams(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n    $asset: String # Required for compatibility\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n        id_not_in: $excludeIds\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstreams(\n    $first: Int!\n    $skip: Int!\n    $chainId: BigInt!\n    $subgraphId: BigInt!\n    $excludeIds: [String!]\n    $asset: String # Required for compatibility\n  ) {\n    campaigns(\n      first: $first\n      skip: $skip\n      orderBy: subgraphId\n      orderDirection: desc\n      where: {\n        subgraphId_lt: $subgraphId\n        chainId: $chainId\n        id_not_in: $excludeIds\n      }\n    ) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAirstream_ById($airstreamId: ID!) {\n    campaign(id: $airstreamId) {\n      ...CampaignFragment\n    }\n  }\n"): (typeof documents)["\n  query getAirstream_ById($airstreamId: ID!) {\n    campaign(id: $airstreamId) {\n      ...CampaignFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getActions_ByAirstream(\n    $first: Int!\n    $airstreamId: String!\n    $subgraphId: BigInt!\n  ) {\n    actions(\n      first: $first\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { campaign: $airstreamId, subgraphId_lt: $subgraphId }\n    ) {\n      ...ActionFragment\n      campaign {\n        id\n        asset {\n          ...AssetFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getActions_ByAirstream(\n    $first: Int!\n    $airstreamId: String!\n    $subgraphId: BigInt!\n  ) {\n    actions(\n      first: $first\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { campaign: $airstreamId, subgraphId_lt: $subgraphId }\n    ) {\n      ...ActionFragment\n      campaign {\n        id\n        asset {\n          ...AssetFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getMetadata_ByAirstream(\n    $airstreamId: ID!\n    $airstreamIdClone: String!\n    $dayFrom: BigInt!\n    $dayTo: BigInt!\n  ) {\n    campaign(id: $airstreamId) {\n      id\n      asset {\n        ...AssetFragment\n      }\n      activities(first: 7, where: { day_gte: $dayFrom, day_lte: $dayTo }) {\n        ...ActivityFragment\n      }\n      actions(first: 5, orderBy: subgraphId, orderDirection: desc) {\n        ...ActionFragment\n      }\n    }\n    actions(\n      first: 10\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { campaign: $airstreamIdClone, category: Claim }\n    ) {\n      ...ActionFragment\n    }\n    firsts: actions(\n      first: 1\n      orderBy: timestamp\n      orderDirection: asc\n      where: { campaign: $airstreamIdClone, category: Claim }\n    ) {\n      ...ActionFragment\n    }\n  }\n"): (typeof documents)["\n  query getMetadata_ByAirstream(\n    $airstreamId: ID!\n    $airstreamIdClone: String!\n    $dayFrom: BigInt!\n    $dayTo: BigInt!\n  ) {\n    campaign(id: $airstreamId) {\n      id\n      asset {\n        ...AssetFragment\n      }\n      activities(first: 7, where: { day_gte: $dayFrom, day_lte: $dayTo }) {\n        ...ActivityFragment\n      }\n      actions(first: 5, orderBy: subgraphId, orderDirection: desc) {\n        ...ActionFragment\n      }\n    }\n    actions(\n      first: 10\n      orderBy: subgraphId\n      orderDirection: desc\n      where: { campaign: $airstreamIdClone, category: Claim }\n    ) {\n      ...ActionFragment\n    }\n    firsts: actions(\n      first: 1\n      orderBy: timestamp\n      orderDirection: asc\n      where: { campaign: $airstreamIdClone, category: Claim }\n    ) {\n      ...ActionFragment\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;