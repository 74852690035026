import { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { StreamShape } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Stream } from "@sablier/v2-models";
import { StreamThumbnail as Thumbnail } from "~/components/atoms";
import type { ISInput } from "../system/templates";
import Template, { sides } from "../system/templates";

const Wrapper = styled.div`
  width: 80px;
  height: ${(props) => props.theme.sizes.inputField};
  padding: 4px 0;
  border-radius: 4px;
`;

export interface Props
  extends Omit<ISInput, "left" | "onChange" | "right" | "value"> {
  value: StreamShape | string;
}

function LockedShape(props: Props) {
  const { t } = useT();
  const title: string = useMemo(
    () => t(Stream.findShape(props.value).title),
    [props.value, t],
  );

  const Left = useCallback(() => {
    return (
      <Wrapper>
        <Thumbnail shape={props.value} />
      </Wrapper>
    );
  }, [props.value]);

  const formatted = useMemo(() => {
    const left: ISInput["left"] = [
      {
        purpose: "custom",
        options: {
          value: Left,
        },
      },
    ];

    const right: ISInput["right"] = [sides.lock()];

    if (props.isLoading) {
      right.unshift(sides.loader());
    }

    return {
      placeholder: t("words.shape"),
      ...props,
      isLocked: true,
      isDisabled: true,
      left,
      right,
      value: title,
    };
  }, [Left, props, title, t]);

  return <Template.Text {...formatted} />;
}

export default memo(LockedShape, _.isEqual);
