import { useMemo } from "react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { tags } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { IAddress } from "@sablier/v2-types";
import { Base } from "../../system";

interface Props {
  className?: string;
  whitelist?: {
    address: IAddress;
    label: string;
  }[];
}

function Account({ className, whitelist }: Props) {
  const { t } = useT();
  const description = useMemo(() => {
    const addresses = whitelist
      ?.map((item) => _.toShortAddress(_.toAddress(item.address)))
      .join(", ");
    const users = whitelist?.map((item) => item.label).join(" or ");

    return t(
      addresses?.length === 1
        ? "warnings.account.description.one"
        : "warnings.account.description.many",
      { users, addresses },
    );
  }, [whitelist, t]);

  return (
    <Base
      className={className}
      cy={tags.accountWarning}
      description={description}
      label={{ icon: UserCircleIcon, value: t("warnings.account.title") }}
    />
  );
}

export default Account;
