import { useMemo } from "react";
import { REQUEST_ID, ZERO } from "@sablier/v2-constants";
import { useRequestAirstreamMetadata } from "@sablier/v2-hooks";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { AirstreamAction, Token } from "@sablier/v2-models";
import { useAirstreamCurrent } from "~/client/hooks";

export default function useAirstreamCurrentMetadata() {
  const { airstream, isLoading: isAirstreamLoading } = useAirstreamCurrent();

  const id = useMemo(() => airstream?.id, [airstream]);
  const isEnabled = useMemo(
    () => !isAirstreamLoading && !_.isNilOrEmptyString(id),
    [isAirstreamLoading, id],
  );

  const { error, isLoading, data } = useRequestAirstreamMetadata({
    id,
    key: REQUEST_ID.airstreamItemPreviewMetadata,
    isEnabled,
  });

  const metadata = useMemo(() => {
    if (
      !_.isNilOrEmptyString(error) ||
      _.isNil(data) ||
      _.isNil(data?.campaign)
    ) {
      return {
        actions: [],
        claimed: [],
        first: undefined,
        weekly: {
          amount: ZERO().humanized,
          claims: 0,
        },
      };
    }

    const token = new Token(data.campaign!.asset);

    const actions = data.campaign.actions.map(
      (action) => new AirstreamAction(action, token),
    );
    const claimed = data.actions.map(
      (action) => new AirstreamAction(action, token),
    );

    const first = data.firsts?.[0]
      ? new AirstreamAction(data.firsts?.[0], token)
      : undefined;

    const weekly = data.campaign.activities
      .map((activity) => {
        const amount = _.toValue({
          raw: activity.amount,
          decimals: token.decimals,
        });
        const claims = _.toNumber(
          !_.isNilOrEmptyString(activity.claims) ? activity.claims : 0,
        );

        return { amount, claims };
      })
      .reduce(
        (previous, current) => ({
          amount: new BigNumber(previous.amount).plus(current.amount.humanized),
          claims: previous.claims + current.claims,
        }),
        { amount: ZERO().humanized, claims: 0 },
      );

    return {
      actions,
      claimed,
      first,
      weekly,
    };
  }, [data, error]);

  return {
    metadata,
    error,
    isLoading,
  };
}
