import { useId } from "react";
import { useMachineForm } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import {
  onCheck,
  onProcess,
  onValidate,
} from "~/client/machines/specialized/streamRenounce";
import type {
  Check,
  Create,
  Result,
} from "~/client/machines/specialized/streamRenounce";

export default function useMachine() {
  const id = useId();

  const machine = useMachineForm<Check, Create, Result>({
    id,
    onCheck,
    onProcess,
    onValidate,
  });

  return {
    machine: machine[0],
    send: machine[1],
    onCheck,
    onProcess,
  };
}
