import { useCallback, useMemo } from "react";
import { MAX_AMOUNT } from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { NumericFormat } from "react-number-format";
import { Informer, Label, Warning } from "~/components/molecules";
import type { ISInput } from "../../particles";
import type { ChangeEvent, MouseEvent } from "react";
import {
  Box,
  Container,
  Field,
  Highlight,
  Sides,
  Wrapper,
} from "../../particles";
import * as utils from "../utils";

export interface Props extends ISInput<string | number | undefined> {
  className?: string;
  decimals?: number;
  min?: number | string;
  max?: number | string;
}

function Numeric({
  className,
  decimals,
  id,
  info,
  isBordered,
  isDisabled,
  isLoading,
  isLocked,
  isPreview,
  label,
  left,
  max,
  min,
  onBlur,
  onChange: callback,
  onClick,
  onFocus,
  onKeyDown,
  placeholder,
  right,
  under = null,
  value,
  warning,
}: Props) {
  const sanitized = useMemo(() => utils.doSanitize(value), [value]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => utils.onChange(e, callback),
    [callback],
  );

  const onHitbox = useCallback(
    (e: MouseEvent<HTMLDivElement>) => utils.onHitbox(e, onClick, isDisabled),
    [isDisabled, onClick],
  );
  const onCheck = useCallback(
    (values: { value?: string }) => {
      try {
        if (_.isNilOrEmptyString(values.value)) {
          return true;
        }

        const value = new BigNumber(values.value);

        const top = !_.isNilOrEmptyString(max)
          ? BigNumber.min(MAX_AMOUNT, max)
          : MAX_AMOUNT;

        if (new BigNumber(top).isLessThan(value)) {
          return false;
        }
        if (
          !_.isNilOrEmptyString(min) &&
          new BigNumber(min).isGreaterThan(value)
        ) {
          return false;
        }
        return true;
      } catch (error) {
        console.debug(error);
      }
      return false;
    },
    [max, min],
  );

  return (
    <Wrapper
      className={className}
      isLoading={isLoading}
      isLocked={isLocked}
      isDisabled={isDisabled}
      isPreview={isPreview}
    >
      {label && <Label {...label} />}
      <Container>
        {!!left && left.length > 0 && <Sides items={left} on={"left"} />}
        <NumericFormat
          allowNegative={false}
          id={id}
          customInput={Field}
          decimalScale={decimals}
          isAllowed={onCheck}
          isBordered={isBordered}
          isDisabled={isDisabled || isPreview}
          isPreview={isPreview}
          onBlur={onBlur}
          onChange={onChange}
          onClick={onClick}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          decimalSeparator={"."}
          value={sanitized}
          readOnly={isPreview || !callback}
        />
        {!!right && right.length > 0 && <Sides items={right} on={"right"} />}
        <Box data-component={"box"} onClick={onHitbox} />
        <Highlight data-component={"highlight"} />
      </Container>
      {under}
      {info && <Informer value={info} />}
      {warning && <Warning cy={"warning"} value={warning} />}
    </Wrapper>
  );
}

export default Numeric;
