import { _ } from "@sablier/v2-mixins";
import { assign, createMachine } from "xstate";

type MachineContext<Payload> = {
  creating?: boolean;
  error: string | undefined;
  payload: Payload;
};

type MachineServices = {
  doCreate: { data: unknown };
};

type MachineEvents<Payload> =
  | {
      type: "CREATE";
      payload: Payload;
    }
  | { type: "RESET" }
  | { type: "FAIL" };

type Action<Payload> = ({
  context,
}: {
  context: MachineContext<Payload>;
}) => Promise<void>;

interface Props<Payload> {
  id: string;
  onCreate: Action<Payload>;
}

/**
 *
 * Machine template for creating resources.
 *
 * Note: Pre-flight checks such as the resources already having been created will be handled outside.
 * Alternative: For a machine that includes this check, see the 'facilitator' machine template.
 * Example: creating a proxy
 */
function create<Payload>({ id, onCreate }: Props<Payload>) {
  return createMachine(
    {
      tsTypes: {} as import("./index.typegen").Typegen0,
      id: `instantiator-${id}`,
      initial: "idle",
      context: { creating: false, payload: {} as Payload, error: undefined },
      predictableActionArguments: true,
      schema: {
        context: {} as MachineContext<Payload>,
        events: {} as MachineEvents<Payload>,
        services: {} as MachineServices,
      },
      states: {
        idle: {
          entry: "doContextClean",
          on: {
            FAIL: {
              target: "failed",
            },
            CREATE: {
              target: "creating",
            },
          },
        },
        created: {
          on: {
            RESET: {
              target: "idle",
            },
            FAIL: {
              target: "failed",
            },
          },
        },
        creating: {
          entry: assign({
            creating: () => true,
          }),
          invoke: {
            id: "create",
            src: "doCreate",
            onDone: {
              target: "created",
            },
            onError: {
              actions: "doError",
              target: "failed",
            },
          },
          on: {
            RESET: {
              target: "idle",
            },
          },
          exit: assign({ creating: () => false }),
        },
        failed: {
          on: {
            RESET: {
              target: "idle",
            },
            CREATE: {
              target: "creating",
            },
          },
        },
      },
    },
    {
      actions: {
        doContextClean: assign({
          creating: (_context, _event) => false,
          error: (_context, _event) => undefined,
          payload: (_context, _event) => ({} as Payload),
        }),
        doError: assign({
          error: (_context, event) => _.toString(_.get(event, "data.message")),
        }),
      },
      services: {
        doCreate: async (context, event) => {
          await onCreate({ context: { ...context, payload: event.payload } });
        },
      },
    },
  );
}

const instantiator = {
  /**
   * Multiple instances of this machine could be necessary,
   * so we're using an instance generator
   */
  create,
};

export default instantiator;
