import { useTokenBalance as useTokenBalanceBase } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import { IToken } from "@sablier/v2-types";
import useAccount from "./useAccount";

interface Props {
  token: IToken | undefined;
}

export default function useTokenBalanceOwn({ token }: Props) {
  const { address, chainId } = useAccount();

  return useTokenBalanceBase({
    chainId,
    owner: address,
    token,
  });
}
