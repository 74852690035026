import { useMemo } from "react";
import styled from "styled-components";
import type { ISInput } from "../system/templates";
import type { IChainDisplay } from "@sablier/v2-types";
import Template, { sides } from "../system/templates";

const Templated = styled(Template.Text)`
  *[data-component="sides"][data-on="left"] {
    *[data-component="side"][data-purpose="chain"] {
      p {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

export interface Props
  extends Omit<ISInput, "left" | "right" | "onChange" | "onClick" | "value"> {
  onChange: () => void;
  value?: Partial<IChainDisplay> & { name?: string; raw?: IChainDisplay };
}

function ChainPicker(props: Props) {
  const formatted: ISInput = useMemo(() => {
    const source = props.value?.raw ? props.value?.raw : props.value;

    const left: ISInput["left"] = [
      sides.chain(source?.name, source?.image, 26),
    ];

    const right: ISInput["right"] = [
      sides.choose(props.onChange as () => void),
    ];

    if (props.isLoading) {
      right.unshift(sides.loader());
    }

    return {
      ...props,
      isPreview: "soft",
      left,
      onClick: props.onChange,
      placeholder: source?.name ? undefined : props.placeholder,
      right,
      value: "",
    };
  }, [props]);

  return <Templated {...formatted} />;
}

export default ChainPicker;
