import { useCallback } from "react";
import { REQUEST_ID, routes } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { useQuery } from "@tanstack/react-query";

export async function geolocation() {
  const response = await fetch(routes.client.api.geolocation.builder());

  if (response.ok) {
    return response.json();
  }

  const result = await response.json();
  throw new Error(_.get(result, "message"));
}

export async function includes(countries: string[]) {
  const data = await geolocation();

  if (_.isNilOrEmptyString(_.get(data, "country"))) {
    return false;
  }

  const found = _.toString(_.get(data, "country"));

  if (found === "Unknown") {
    /** If the geolocation checker returns an "Unknown" country, restrict access */
    return true;
  }

  return countries.map((c) => c.toLowerCase()).includes(found.toLowerCase());
}

function useRequestGeolocation() {
  const { error, data, isLoading } = useQuery({
    queryKey: [REQUEST_ID.geolocation],
    queryFn: async () => geolocation(),
    staleTime: Infinity,
    gcTime: Infinity,
    retry: false,
  });

  const includes = useCallback(
    (countries: string[]) => {
      if (_.isNilOrEmptyString(_.get(data, "country"))) {
        return false;
      }

      const found = _.toString(_.get(data, "country"));

      if (found === "Unknown") {
        /** If the geolocation checker returns an "Unknown" country, restrict access */
        return true;
      }

      return (
        !isLoading &&
        _.isNilOrEmptyString(error) &&
        countries.map((c) => c.toLowerCase()).includes(found.toLowerCase())
      );
    },
    [error, data, isLoading],
  );

  return {
    error,
    data,
    isLoading,
    includes,
  };
}

export default useRequestGeolocation;
