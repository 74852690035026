import { useMemo } from "react";
import styled from "styled-components";
import { CalendarIcon, ClockIcon } from "@heroicons/react/24/outline";
import { _, dayjs } from "@sablier/v2-mixins";
import duration from "dayjs/plugin/duration";
import relative from "dayjs/plugin/relativeTime";
import type { IMilliseconds } from "@sablier/v2-types";
import Icon from "../Icon";

dayjs.extend(duration);
dayjs.extend(relative);

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    height: 36px;
    padding: 0 8px;
    column-gap: 6px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.dark200};
    svg {
      color: ${(props) => props.theme.colors.gray400};
    }
  }
`;

const Value = styled.div`
  & > p {
    ${(props) => props.theme.styles.textParagraph}
  }
`;

interface Props {
  purpose?: "time" | "date";
  milliseconds?: IMilliseconds;
  prepared?: string;
}

function Duration({ milliseconds = "0", purpose = "time", prepared }: Props) {
  const value = useMemo((): string => {
    if (!_.isNilOrEmptyString(prepared)) {
      return prepared;
    }
    if (purpose === "date") {
      return _.toDuration(milliseconds, "date-full")[0];
    }

    if (purpose === "time") {
      return _.toDuration(milliseconds, "time-full")[0];
    }

    return "";
  }, [milliseconds, purpose, prepared]);

  return (
    <Wrapper>
      <Value>
        <p>{value}</p>
      </Value>
      {purpose === "time" && <Icon value={ClockIcon} />}
      {purpose === "date" && <Icon value={CalendarIcon} />}
    </Wrapper>
  );
}

export default Duration;
