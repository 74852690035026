import styled from "styled-components";
import { BookOpenIcon, GiftIcon } from "@heroicons/react/24/outline";
import { links } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { PropsWithChildren } from "react";
import Animated from "../Animated";
import Button from "../Button";
import Card from "./Card";
import Detail from "./Detail";
import Info from "./Info";

const WrapperPartial = styled.div`
  width: ${(props) => props.theme.sizes.summary};
`;

const Container = styled(Animated)`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    width: 100%;
    padding: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.dark200};
    overflow: hidden;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${(props) => props.theme.colors.border};
`;

const Top = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    min-height: ${(props) => props.theme.sizes.buttonMini};
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
  }
`;

const Title = styled.div`
  flex: 1;
  & > h2 {
    ${(props) => props.theme.styles.textMenu}
  }
`;

const Details = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex-shrink: 0;
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  }
`;

const Line = styled.div`
  width: 2px;
  height: 14px;
  background-color: ${(props) => props.theme.colors.gray500};
`;

const Extra = styled.div`
  ${(props) => props.theme.styles.row}
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxLG} {
    width: 100%;
    ${Container} {
      padding: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
    }
  }
`;
interface Props {
  className?: string;
  guide?: string | undefined;
  free?: boolean;
}

function Section({
  className,
  children,
  guide,
  free,
}: PropsWithChildren<Props>) {
  const { t } = useT();
  return (
    <Wrapper className={className}>
      <Top>
        <Title>
          <h2>{t("words.summary")}</h2>
        </Title>
        <Details>
          {free && (
            <Button
              appearance={"transparent"}
              accent={"gray400"}
              isMini
              isUnpadded
              left={GiftIcon}
              purpose={"external"}
              to={links.article.free}
              title={_.capitalize(t("structs.freeToUse"))}
            />
          )}
          {free && guide && <Line />}
          {!_.isNilOrEmptyString(guide) && (
            <Extra>
              <Button
                appearance={"transparent"}
                accent={"gray400"}
                isMini
                isUnpadded
                right={BookOpenIcon}
                purpose={"external"}
                to={guide}
                title={_.capitalize(t("words.guide"))}
              />
            </Extra>
          )}
        </Details>
      </Top>
      <Container>{children}</Container>
    </Wrapper>
  );
}

const Summary = {
  Card,
  Detail,
  Divider,
  Info,
  Section,
};

export default Summary;
