import { _ } from "@sablier/v2-mixins";
import type { IConfiguration } from "../../system/setup";
import type { ITranchedTimelock } from "./config";
import type { Translate } from "@sablier/v2-locales";
import type { IExtensionDependencies } from "~/client/types";
import { initial, overrides, shape, template } from "./config";
import Form from "./form";
import { process } from "./process";
import { certify, check, identify, precompute, simulate } from "./setup";

function summary(
  _dependencies: IExtensionDependencies,
  _extension: unknown,
  _t: Translate,
) {
  return undefined;
}

const extension: IConfiguration = {
  check,
  headers: (_isDuration: boolean) => [],
  certify,
  excludes: [],
  identify,
  process,
  Form,
  initial,
  overrides,
  shape,
  summary,
  simulate,
  precompute,
  template,
  warnings: (_extension: unknown) => [],
};

export type { ITranchedTimelock };
export { extension };
