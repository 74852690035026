import { routes } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";

export { default as supported } from "./chains";

export * from "./contracts";

/** Bump this variable when cookies (or similar systems) require an update */
export const UNIQUE_CLIENT_SYSTEM_ID = 1;

/** @deprecated */
export const FEATURE_FLASH_LOANS_ENABLED = false;
/** @deprecated */
export const FEATURE_EMPTY_PROXY_ENABLED = false;

/* ------------------------------------------------ */

/**Legacy chains are restricted from accessing the create forms and should only allow backwards compatible actions for previous stream versions (e.g. withdraw)*/
export const legacy: number[] = [];

export const api = routes.client.api;
export const tabs = routes.client.tabs;
export const pages = routes.client.pages;

export const covers = {
  airstreamClawback: "airstreamClawback",
  airstreamEligibility: "airstreamEligibility",
  airstreamFund: "airstreamFund",

  streamCancel: "streamCancel",
  streamCart: "streamCart",
  streamRenounce: "streamRenounce",
  streamTransfer: "streamTransfer",
  streamWithdraw: "streamWithdraw",
};

export const modals = {
  /** --------------- Modal Cards --------------- */

  /** --- General --- */
  account: "account",
  calldata: "calldata",
  chainPicker: "chainPicker",
  chainSwitcher: "chainSwitcher",
  countryPicker: "countryPicker",
  durationPicker: "durationPicker",
  expected: "expected",
  permissions: "permissions",
  momentPicker: "momentPicker",
  tokenPicker: "tokenPicker",
  transaction: "transaction",
  ukDisclaimer: "ukDisclaimer",
  sanctionStream: "sanctionStream",
  sanctionAccount: "sanctionAccount",
  sanctionAirstream: "sanctionAirstream",
  preflight: "preflight",

  /** --- Airstream --- */
  airstreamBypass: "airstreamBypass",
  airstreamGuide: "airstreamGuide",
  airstreamHistory: "airstreamHistory",
  airstreamPrefund: "airstreamPrefund",
  airstreamRecipients: "airstreamRecipients",
  airstreamReshape: "airstreamReshape",
  airstreamSearch: "airstreamSearch",
  airstreamTemplate: "airstreamTemplate",
  airstreamEdit: "airstreamEdit",

  /** --- Stream --- */
  streamGuide: "streamGuide",
  streamHistory: "streamHistory",
  streamLegend: "streamLegend",
  streamOptions: "streamOptions",
  streamPreviewCSV: "streamPreviewCSV",
  streamSearch: "streamSearch",
  streamShare: "streamShare",
  streamSimulator: "streamSimulator",
  streamTemplate: "streamTemplate",
  /** --------------- Modal Lists --------------- */

  airstreamDetails: "airstreamDetails",
  streamDetails: "streamDetails",
};

export const PERSIST_ID = {
  session: "session",
  expected: "expected",
  localTokens: "localTokens",
  permissions: `permissions-${UNIQUE_CLIENT_SYSTEM_ID}`,
};

export const CART_ID = {
  streams: "cart-streams-v1",
};

export const PERMISSION_ID = {
  crisp: "crisp",
  mixpanel: "mixpanel",
  sentry: "sentry",
  vercel: "vercel",
  /** ------ */
  unstable_connector: "unstable_connector_1",
};

export const STORE_ID = {
  stream: "session-stream",
  airstream: "session-airstream",
};

export const WAGMI_COOKIE_STORAGE = "WAGMI_COOKIE_STORAGE";
