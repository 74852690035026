import { useMemo } from "react";
import { useRequestAirstreamListHidden } from "@sablier/v2-hooks";
import { _ } from "@sablier/v2-mixins";
import useAccount from "./useAccount";
import useAirstreamsOwned from "./useAirstreamsOwned";
import useStreamsOwned from "./useStreamsOwned";

export default function useFlags() {
  const { chainId, isConfigured, isReady } = useAccount();
  const airstreams = useAirstreamsOwned();
  const streams = useStreamsOwned();
  const hidden = useRequestAirstreamListHidden({
    chainId,
    isEnabled: isReady,
  });

  const isWaitingInitialAirstreams = useMemo(() => {
    /**
     * An undecided connection (server side or connecting) enables loading
     */
    if (!isReady) {
      return true;
    }

    /**
     * After connection is established, an undefined result in the
     * owned store means it has not gone through an initial query.
     */
    if (
      isConfigured &&
      _.isNil(airstreams.result) &&
      _.isNil(airstreams.error)
    ) {
      return true;
    }

    return false;
  }, [isConfigured, isReady, airstreams.result, airstreams.error]);

  const isWaitingInitialStreams = useMemo(() => {
    /**
     * An undecided connection (server side or connecting) enables loading
     */
    if (!isReady) {
      return true;
    }

    /**
     * After connection is established, an undefined result in the
     * owned store means it has not gone through an initial query.
     */
    if (isConfigured && _.isNil(streams.result) && _.isNil(streams.error)) {
      return true;
    }

    return false;
  }, [isConfigured, isReady, streams.error, streams.result]);

  const isWaitingConfigurations = useMemo(() => {
    /**
     * An undecided connection (server side or connecting) enables loading
     */
    if (!isReady) {
      return true;
    }

    if (hidden.isLoading) {
      return true;
    }

    return false;
  }, [hidden, isReady]);

  return {
    isWaitingConfigurations,
    isWaitingInitialAirstreams,
    isWaitingInitialStreams,
  };
}
