import { useMemo } from "react";
import { QUERY_CACHE_ENS_TIME, chains } from "@sablier/v2-constants";
import { normalize } from "viem/ens";
import { useEnsAddress as useWagmiEnsAddress } from "wagmi";

type Props = {
  chainId: number | undefined;
  enabled?: boolean;
  gcTime?: number;
  name: string | undefined;
  staleTime?: number;
};

export default function useResolvedAddress({
  chainId = chains.mainnet.chainId,
  enabled,
  name,
  gcTime = QUERY_CACHE_ENS_TIME,
  staleTime,
}: Props) {
  const normalized = useMemo(() => {
    try {
      return normalize(name || "");
    } catch (_e) {
      return name;
    }
  }, [name]);

  return useWagmiEnsAddress({
    query: {
      gcTime,
      staleTime,
      enabled:
        !!enabled &&
        [chains.mainnet.chainId, chains.sepolia.chainId].includes(chainId),
    },
    chainId,
    name: normalized,
  });
}
