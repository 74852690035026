import { AirstreamActionCategory } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import type {
  IAddress,
  IMilliseconds,
  ISeconds,
  IStreamId,
  IValue,
} from "@sablier/v2-types";
import Token from "../Token";

type Params = {
  id: string;
  chainId: number;
  /* --------------- */
  category: string | AirstreamActionCategory;
  from: string;

  claimStreamId?: string | undefined | null;
  claimTokenId?: string | undefined;
  claimAmount?: string | undefined;
  claimIndex?: string | undefined;
  claimRecipient?: string | undefined;

  clawbackAmount?: string | undefined;
  clawbackFrom?: string | undefined;
  clawbackTo?: string | undefined;

  /* --------------- */
  subgraphId: string | undefined;
  block: string | undefined;
  hash: string | undefined;
  timestamp: ISeconds | undefined;
};

export type Preview = {
  timestamp?: string;
};

export default class AirstreamAction {
  readonly id: string;
  readonly chainId: number;
  /* --------------- */
  readonly claimStreamId: IStreamId | undefined;
  readonly claimTokenId: string | undefined;
  readonly claimAmount: IValue | undefined;
  readonly claimIndex: number | undefined;
  readonly claimRecipient: IAddress | undefined;

  readonly clawbackAmount: IValue | undefined;
  readonly clawbackFrom: IAddress | undefined;
  readonly clawbackTo: IAddress | undefined;

  readonly category: AirstreamActionCategory;
  readonly from: IAddress;
  readonly token: Token;
  /* --------------- */
  block?: string;
  hash?: string;
  timestamp?: IMilliseconds;

  constructor(params: Params, token: Token) {
    this.id = params.id;
    this.chainId = params.chainId;
    this.token = token;
    /* --------------- */

    this.category = _.capitalize(params.category) as AirstreamActionCategory;

    this.claimStreamId = params.claimStreamId || undefined;
    this.claimTokenId = params.claimTokenId;
    this.claimIndex = _.toNumber(params.claimIndex);
    this.claimRecipient = _.toAddress(params.claimRecipient);
    this.claimAmount = _.toValue({
      decimals: token.decimals,
      raw: params.claimAmount,
    });
    this.clawbackAmount = _.toValue({
      decimals: token.decimals,
      raw: params.clawbackAmount,
    });
    this.clawbackFrom = _.toAddress(params.clawbackFrom);
    this.clawbackTo = _.toAddress(params.clawbackTo);

    this.from = params.from;
    /* --------------- */

    this.block = params.block;
    this.hash = params.hash;
    this.timestamp = _.toMilliseconds(params.timestamp);
  }
}
