import styled from "styled-components";
import { External } from "@sablier/v2-components/atoms";
import { links } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";

const WrapperPartial = styled.div`
  position: relative;
  top: 0;
  left: 0;
  z-index: ${(props) => props.theme.sizes.zIndexToolbar};
  width: 100vw;
  height: 52px;
  background-color: ${(props) => props.theme.colors.dark050};
`;

const Container = styled.div`
  ${(props) => props.theme.styles.contained}
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    z-index: 20;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;

const Description = styled(External)`
  ${(props) => props.theme.styles.row}
  & {
    flex-wrap: wrap;
    justify-content: left;
    width: 100%;
    white-space: pre-wrap;
    & > * {
      display: inline-flex;
    }
    p {
      ${(props) => props.theme.styles.textParagraphMini}
      & {
        color: ${(props) => props.theme.colors.gray100};
        a {
          text-decoration: underline;

          &:hover,
          &:active {
            color: ${(props) => props.theme.colors.primaryMiddle};
          }
        }
      }
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxMD} {
    ${Container} {
      flex-direction: column;
      padding: calc(${(props) => props.theme.sizes.edge} * 1);
    }
  }

  ${(props) => props.theme.medias.maxSM} {
    height: auto;
    min-height: 52px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

function Event() {
  const { t } = useT();

  return (
    <Wrapper>
      <Container>
        <Description to={links.twitter.product}>
          <p>{t("descriptions.event.2024ETHCC")}</p>
        </Description>
      </Container>
    </Wrapper>
  );
}

export default Event;
