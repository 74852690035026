import { useMemo } from "react";
import { useT } from "@sablier/v2-locales";
import { BigNumber, _ } from "@sablier/v2-mixins";
import { IMilliseconds } from "@sablier/v2-types";
import { SwitchBox } from "~/components/molecules";
import type { ISInput } from "../system/templates";
import type { MouseEvent } from "react";
import Template, { sides } from "../system/templates";

export interface Props
  extends Omit<ISInput, "left" | "onChange" | "right" | "value"> {
  controls?: {
    isActive: boolean;
    onSwitch: (e?: MouseEvent<HTMLElement>) => void;
  };
  resolution?: string;
  /** Value describing milliseconds (if the switch is on), clear text if switch is off */
  value?: IMilliseconds | string;
}

function MomentSwitch(props: Props) {
  const { t } = useT();
  const value = useMemo(() => {
    if (_.isNilOrEmptyString(props.value)) {
      return undefined;
    }

    if (new BigNumber(props.value).isFinite() && props.controls?.isActive) {
      return _.toDuration(props.value, "date")[0];
    }

    return props.value;
  }, [props.value, props.controls?.isActive]);

  const formatted = useMemo(() => {
    const right: ISInput["right"] = [];

    if (props.resolution) {
      right.push(sides.resolution(props.resolution));
    }

    if (props.controls) {
      right.push({
        purpose: "custom",
        options: {
          value: () => (
            <SwitchBox
              id={`side-${props.id}`}
              isChecked={props.controls?.isActive}
              onChange={props.controls?.onSwitch}
            />
          ),
        },
      });
    }

    if (props.isLoading) {
      right.unshift(sides.loader());
    } else {
      if (props.controls) {
        if (props.controls?.isActive) {
          right.unshift(sides.unlock());
        } else {
          right.unshift(sides.lock());
        }
      } else {
        right.unshift(sides.calendar());
      }
    }

    return {
      placeholder: t("form.placeholder.text"),
      ...props,
      right,
      isPreview: "soft" as const,
      value,
    };
  }, [props, value, t]);

  return <Template.Text {...formatted} />;
}

export default MomentSwitch;
