import { _ } from "@sablier/v2-mixins";
import type { Locale, Translate } from "@sablier/v2-locales";

const errors = {
  UNKNOWN_ERROR: "errors.unknown",
  SERVER_ERROR: "errors.serverError",
  NOT_IMPLEMENTED: "errors.notImplemented",
  TIMEOUT: "errors.timeout",
  UNSUPPORTED_OPERATION: "errors.unsupportedOp",
  MISSING_ARGUMENT: "errors.missingArgument",
  INVALID_ARGUMENT: "errors.invalidArg",
  UNEXPECTED_ARGUMENT: "errors.unexpectedArg",
  NETWORK_ERROR: "errors.network",
  NONCE_EXPIRED: "errors.nonceExpired",
  INSUFFICIENT_FUNDS: "errors.insufficientFunds",
  UNPREDICTABLE_GAS_LIMIT: "errors.gasLimit",
  4001: "errors.transactionRejected",
  4100: "errors.notAuthorized",
  4900: "errors.provider",
  4901: "errors.provider",
  32700: "errors.invalidJson",
  32600: "errors.invalidRequest",
  32601: "errors.methodNotAllowed",
  32602: "errors.methodParams",
  32603: "errors.jsonRpc",
  326000: "errors.invalidInput",
  326001: "errors.resourceNotFound",
  326002: "errors.resourceUnavailable",
  326003: "errors.rejected",
  326004: "errors.methodNotSupported",
  326005: "errors.requestLimit",
} satisfies Record<string, Locale>;

const policy = {
  address: {
    checksummed: (t: Translate) => t("policies.address.checksum"),
    forbidden: (t: Translate) => t("policies.address.forbidden"),
    invalid: (t: Translate, extra = "") =>
      t("policies.address.invalid", { extra }),
    missing: (t: Translate, checksummed?: boolean) =>
      t("policies.address.missing", {
        checksummed: checksummed ? "checksummed " : "",
      }),
    ens: (t: Translate) => t("policies.address.ens"),
    risk: (t: Translate, address: string) =>
      t("policies.address.risk", { address }),
    sanctioned: (t: Translate) => t("policies.address.sanctioned"),
    isToken: (t: Translate) => t("policies.address.token"),
    zero: (t: Translate) => t("policies.address.zero"),
  },
  allowance: {
    missing: (t: Translate) => t("policies.allowance.missing"),
    tooLow: (t: Translate, min: string) =>
      t("policies.allowance.tooLow", { min }),
  },
  amount: {
    scientific: (t: Translate) => t("policies.amount.scientific"),
    missing: (t: Translate) => t("policies.amount.missing"),
    tooLow: (t: Translate, min: string) => t("policies.amount.tooLow", { min }),
    tooHigh: (t: Translate, max: string) =>
      t("policies.amount.tooHigh", { max }),
    wallet: (t: Translate, balance?: string) =>
      balance && balance !== "0"
        ? t("policies.amount.walletFixed", {
            balance: `only ${balance}`,
          })
        : t("policies.amount.wallet"),
    generic: (t: Translate) => t("policies.amount.generic"),
    previously: (t: Translate, balance?: string) =>
      balance && balance !== "0"
        ? t("policies.amount.previouslyFixed", {
            balance: `only ${balance}`,
          })
        : t("policies.amount.previously"),

    stream: (t: Translate, balance?: string) =>
      t("policies.amount.stream", {
        balance:
          balance && balance !== "0" ? `Only ${balance}` : "Not enough assets",
      }),
  },
  cliff: {
    missing: (t: Translate) => t("policies.cliff.missing"),
    invalid: (t: Translate) => t("policies.cliff.missing"),
    startBeforeEnd: (t: Translate) => t("policies.cliff.startBeforeEnd"),
    endAfterStart: (t: Translate) => t("policies.cliff.endAfterStart"),
    durationBeforeTotal: (t: Translate) =>
      t("policies.cliff.durationBeforeTotal"),
  },
  date: {
    missing: (t: Translate) => t("policies.date.missing"),
    invalid: (t: Translate) => t("policies.date.missing"),
    startBeforeEnd: (t: Translate) => t("policies.date.startBeforeEnd"),
    endAfterStart: (t: Translate) => t("policies.date.endAfterStart"),
    endAfterStartExcess: (t: Translate) =>
      t("policies.date.endAfterStartExcess"),
  },
  datetoday: {
    missing: (t: Translate) => t("policies.datetoday.missing"),
    invalid: (t: Translate) => t("policies.datetoday.missing"),
    startBeforeEnd: (t: Translate) => t("policies.datetoday.startBeforeEnd"),
    endAfterStart: (t: Translate) => t("policies.datetoday.endAfterStart"),
  },
  duration: {
    missing: (t: Translate) => t("policies.duration.missing"),
    invalid: (t: Translate) => t("policies.duration.missing"),
    tooHigh: (t: Translate, max: string) =>
      t("policies.duration.tooHigh", { max }),
    tooLow: (t: Translate, min: string) =>
      t("policies.duration.tooLow", { min }),
  },
  file: {
    type: (t: Translate, expected: string) =>
      t("policies.file.type", { expected }),
    missing: (t: Translate) => t("policies.file.missing"),
    invalid: (t: Translate) => t("policies.file.invalid"),
    tooBig: (t: Translate, max: string) => t("policies.file.tooBig", { max }),
    parseGeneral: (t: Translate) => t("policies.file.parseGeneral"),
    parseHeader: (t: Translate, header: string) =>
      t("policies.file.parseHeader", { header }),
    parseHeaderColumns: (t: Translate, value: number, expected: number) =>
      t("policies.file.parseHeaderColumns", { value, expected }),
    parseHeaderQuotes: (t: Translate) => t("policies.file.parseHeaderQuotes"),
    parseContent: (t: Translate) => t("policies.file.parseContent"),
    parseContentRow: (t: Translate, header: string, row: number) =>
      t("policies.file.parseContentRow", { header, row }),
    parsePercentages: (t: Translate, row: number) =>
      t("policies.file.parsePercentages", { row }),
    parseYearsPercentagesMismatch: (t: Translate, row: number) =>
      t("policies.file.parseYearsPercentagesMismatch", { row }),
    parseMaxRows: (t: Translate, max: number) =>
      t("policies.file.parseMaxRows", { max }),
    parseMinRows: (t: Translate, min: number) =>
      t("policies.file.parseMinRows", { min }),
    parseContentQuotes: (t: Translate) => t("policies.file.parseContentQuotes"),
  },
  form: {
    loading: (t: Translate) => t("policies.form.loading"),
    warning: (t: Translate, label?: string | undefined) =>
      t("policies.form.warningSpecific", {
        label: !_.isNilOrEmptyString(label) ? ` (${_.lowerCase(label)})` : "",
      }),
    values: (t: Translate, label?: string | undefined) =>
      t("policies.form.valuesSpecific", {
        label: !_.isNilOrEmptyString(label) ? ` (${_.lowerCase(label)})` : "",
      }),
    tooHigh: (t: Translate, max: string) => t("policies.form.tooHigh", { max }),
    exact: (t: Translate, value: string) => t("policies.form.exact", { value }),
    generic: (t: Translate, value: string) =>
      t("policies.form.exact", { value }),
  },
  ens: {
    address: (t: Translate, chain?: string) =>
      t("policies.ens.address", { chain: chain ? ` (on ${chain})` : "" }),
    forbidden: (t: Translate) => t("policies.ens.forbidden"),
    invalid: (t: Translate) => t("policies.ens.invalid"),
    missing: (t: Translate) => t("policies.ens.missing"),
    supported: (t: Translate) => t("policies.ens.supported"),
  },
  geolocation: {
    blocked: (t: Translate) => t("policies.geolocation.blocked"),
  },
  percentage: {
    missing: (t: Translate) => t("policies.percentage.missing"),
    inaccurate: (t: Translate, total: string) =>
      t("policies.percentage.inaccurate", { total }),
    tooLow: (t: Translate, min: string) =>
      t("policies.percentage.tooLow", { min }),
    tooHigh: (t: Translate, max: string) =>
      t("policies.percentage.tooHigh", { max }),
  },
  transitive: {
    payload: (t: Translate) => t("policies.transitive.payload"),
  },
  steps: {
    missing: (t: Translate) => t("policies.steps.missing"),
    tooLow: (t: Translate, min: string) => t("policies.steps.tooLow", { min }),
    tooHigh: (t: Translate, max: string) =>
      t("policies.steps.tooHigh", { max }),
  },
  signer: {
    forbidden: (t: Translate) => t("policies.signer.forbidden"),
    match: (t: Translate) => t("policies.signer.match"),
    missing: (t: Translate) => t("policies.signer.missing"),
    invalid: (t: Translate) => t("policies.signer.invalid"),
  },
  stream: {
    chain: (t: Translate, expected = "", received = "") =>
      t("policies.stream.chain", {
        expected: expected ? ` (${expected})` : "",
        received: received ? ` (id: ${received})` : "",
      }),
    elements: (t: Translate) => t("policies.stream.elements"),
    missing: (t: Translate, element = "identifier") =>
      t("policies.stream.missing", { element }),
    invalid: (t: Translate, element = "identifier") =>
      t("policies.stream.invalid", { element }),
    supported: (t: Translate, element = "identifier") =>
      t("policies.stream.supported", { element }),

    itemUnchained: (t: Translate) => t("policies.stream.itemUnchained"),
    itemCanceled: (t: Translate) => t("policies.stream.itemCanceled"),
    itemEarly: (t: Translate) => t("policies.stream.itemEarly"),
    itemEnded: (t: Translate) => t("policies.stream.itemEnded"),
    itemWhitelist: (t: Translate) => t("policies.stream.itemWhitelist"),

    setCanceled: (t: Translate) => t("policies.stream.setCanceled"),
    setEnded: (t: Translate) => t("policies.stream.setEnded"),
    setMisconfigured: (t: Translate) => t("policies.stream.setMisconfigured"),
    setNonCancelable: (t: Translate) => t("policies.stream.setNonCancelable"),
    setPermission: (t: Translate) => t("policies.stream.setPermission"),
    sameCategory: (t: Translate) => t("policies.stream.sameCategory"),
    sameRecipient: (t: Translate) => t("policies.stream.sameRecipient"),
    setSource: (t: Translate) => t("policies.stream.setSource"),
    setUnavailable: (t: Translate) => t("policies.stream.setUnavailable"),
    setUnchained: (t: Translate) => t("policies.stream.setUnchained"),
    someDepleted: (t: Translate) => t("policies.stream.someDepleted"),
    onlyPrivateWithdrawals: (t: Translate) =>
      t("policies.stream.onlyPrivateWithdrawals"),
    onlyNonZeroWithdrawals: (t: Translate) =>
      t("policies.stream.onlyNonZeroWithdrawals"),
  },
  error: {
    extension: (t: Translate) => t("policies.extension"),
    reverted: (t: Translate) => t("policies.reverted"),
    unidentified: (t: Translate) => t("policies.unidentified"),
    message: (t: Translate, error: unknown) => {
      const code = _.get(error, "code");
      return _.toString(
        !_.isNilOrEmptyString(code)
          ? errors[code]
            ? t(errors[code])
            : error
          : error,
      );
    },
  },
  text: {
    forbidden: (t: Translate) => t("policies.text.forbidden"),
    invalid: (t: Translate) => t("policies.text.invalid"),
    missing: (t: Translate) => t("policies.text.missing"),
    exact: (t: Translate, value: string | number) =>
      t("policies.text.exact", { value }),
    tooHigh: (t: Translate, max: string | number) =>
      t("policies.text.tooHigh", { max }),
    tooLow: (t: Translate, min: string | number) =>
      t("policies.text.tooLow", { min }),
  },
  token: {
    chainSwitch: (t: Translate) => t("policies.token.chainSwitch"),
    missing: (t: Translate) => t("policies.token.missing"),
    rebasing: (t: Translate) => t("policies.token.rebasing"),
    sanctioned: (t: Translate) => t("policies.token.sanctioned"),
  },
  url: {
    invalid: (t: Translate) => t("policies.url.invalid"),
  },
};

export { errors };
export default policy;
