import { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import { _ } from "@sablier/v2-mixins";
import type { ISInput } from "../system/templates";
import type { ChangeEvent, MouseEvent } from "react";
import Template, { sides } from "../system/templates";

const Templated = styled(Template.Text)<{ isBlind?: boolean }>`
  *[data-component="sides"][data-on="left"] {
    *[data-component="side"][data-purpose="chain"] {
      p {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }

  input {
    visibility: hidden;
    opacity: 0;
  }

  ${(props) =>
    props.isBlind &&
    css`
      position: absolute;
      z-index: -100;
      opacity: 0;
      pointer-events: none;
    `}
`;

export interface Props
  extends Omit<ISInput, "file" | "left" | "right" | "onChange" | "value" | ""> {
  isBlind?: boolean;
  onChange: (name: string, event?: ChangeEvent<HTMLInputElement>) => void;
  options?: ISInput["file"];
  value?: {
    title?: string;
    document?: File;
  };
}

function FilePicker(props: Props) {
  const onClick = useCallback(
    (e?: MouseEvent<HTMLElement>) => {
      if (!_.isFunction(props.onClick)) {
        void document.getElementById(props.id)?.click();
      } else {
        props.onClick(e);
      }
    },
    [props],
  );

  const formatted: ISInput = useMemo(() => {
    const left: ISInput["left"] = [sides.file()];

    const right: ISInput["right"] = [sides.choose(props.onClick as () => void)];

    if (props.isLoading) {
      right.unshift(sides.loader());
    }

    return {
      ...props,
      isPreview: false,
      left,
      onClick,
      onChange: props.onChange,
      placeholder: props.placeholder,
      right,
      file: props.options,
      value: undefined,
    };
  }, [props, onClick]);

  return <Templated {...formatted} />;
}

export default FilePicker;
