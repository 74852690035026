import { useMemo } from "react";
import { QUERY_CACHE_TIME, REQUEST_ID, requests } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { TRMResponse } from "@sablier/v2-types";
import { useQuery } from "@tanstack/react-query";

export default function useIsSanctioned(address: string | undefined) {
  const { data, error, isLoading } = useQuery<Array<TRMResponse>>({
    queryKey: [...REQUEST_ID.trm, { unique: address }],
    queryFn: async () => requests.sanctionsScreeningTRM(address),
    staleTime: QUERY_CACHE_TIME,
    gcTime: QUERY_CACHE_TIME,
    retry: false,
    enabled: !_.isNilOrEmptyString(address),
  });

  const isSanctioned = useMemo(() => {
    if (
      !_.isNilOrEmptyString(error) ||
      isLoading ||
      _.isNilOrEmptyString(address)
    ) {
      return false;
    }

    const addressMatch = data?.find((r) => r.address === address);
    if (addressMatch) {
      return addressMatch.isSanctioned;
    }
    return false;
  }, [error, isLoading, data, address]);

  return { isSanctioned, isLoading };
}
