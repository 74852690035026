import { useMemo } from "react";
import { DEFAULT_TOKEN_LIST } from "@sablier/v2-constants";
import { useRequestTokenList } from "@sablier/v2-hooks";
import useChain from "./useChain";

export default function useTokensWhitelisted(byChainId?: boolean | number) {
  const { id: currentChainId } = useChain();

  const chainId = useMemo(() => {
    if (byChainId === true) {
      return currentChainId;
    }
    return byChainId || currentChainId;
  }, [byChainId, currentChainId]);

  /**
   * Because react-query is instructed to cache results and block repetitive requests we're
   * allowed to use the request hook inside this hook (and not necessarily a standalone context).
   */
  const { isLoading, list: whitelisted } = useRequestTokenList({
    url: DEFAULT_TOKEN_LIST.url,
    fallback: DEFAULT_TOKEN_LIST.fallback,
  });

  const list = useMemo(
    () =>
      whitelisted
        .map((l) => {
          l.isWhitelisted = true;
          return l;
        })
        .filter((item) => !byChainId || item.chainId === chainId),
    [chainId, byChainId, whitelisted],
  );

  return {
    isLoading,
    list,
  };
}
