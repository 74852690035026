import { Translate } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { IExtensionDependencies } from "~/client/types";
import { IConfiguration } from "../../system/setup";
import { ILinear, initial, shape, template } from "./config";
import { process } from "./process";
import { certify, check, identify, precompute, simulate } from "./setup";

function summary(
  _dependencies: IExtensionDependencies,
  _extension: unknown,
  _t: Translate,
) {
  return undefined;
}

const Form = {
  Airstream: undefined,
  Group: undefined,
};

const extension: IConfiguration = {
  check,
  headers: (_isDuration: boolean) => [],
  certify,
  identify,
  precompute,
  process,
  Form,
  initial,
  shape,
  summary,
  simulate,
  template,
  warnings: (_extension: unknown) => [],
};

export type { ILinear };
export { extension };
