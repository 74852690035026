import { CreditCardIcon } from "@heroicons/react/24/outline";
import { tags } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Base } from "../../system";

function AirstreamFunded({ className }: { className?: string }) {
  const { t } = useT();

  return (
    <Base
      className={className}
      cy={tags.warningConnect}
      description={t("warnings.airstreamFunded.description")}
      label={{
        icon: CreditCardIcon,
        value: t("warnings.airstreamFunded.title"),
      }}
    />
  );
}

export default AirstreamFunded;
