import { useMemo } from "react";
import { QUERY_CACHE_TIME, REQUEST_ID, requests } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { useQuery } from "@tanstack/react-query";

interface HiddenProps {
  chainId: number | undefined;
  isEnabled: boolean;
}

export default function useRequestAirstreamListHidden({
  chainId,
  isEnabled,
}: HiddenProps) {
  const { error, data, isLoading } = useQuery({
    queryKey: [...REQUEST_ID.airstreamListHidden, { unique: chainId }],
    queryFn: async () =>
      requests.hiddenCampaigns({
        query: { chainId: chainId! },
      }),
    enabled: !_.isNilOrEmptyString(chainId) && isEnabled,
    staleTime: QUERY_CACHE_TIME,
    gcTime: QUERY_CACHE_TIME,
    retry: false,
  });

  const IDs = useMemo(() => _.get(data, "ids"), [data]);

  return { error, IDs, isLoading };
}
