import Fields from "./fields";

function Airstream() {
  return <Fields.AirstreamStepper />;
}

function Group({ streamId }: { streamId: string }) {
  return <Fields.GroupStepper streamId={streamId} />;
}
const Form = {
  Airstream,
  Group,
};

export default Form;
