import { useMemo } from "react";
import { ICountry } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";

export default function useCountrySearch(
  list: ICountry[],
  query?: string,
): ICountry[] {
  const sorted = useMemo(() => {
    if (_.isNilOrEmptyString(query)) {
      return list;
    }
    const cleanup = _.toString(query).trim().toLowerCase();

    const exact: ICountry[] = [];
    const occurrence: ICountry[] = [];

    list.forEach((country) => {
      const name = country.name.toLowerCase();
      const code = country.code.toLowerCase();

      if (name === cleanup || code === cleanup) {
        return exact.push(country);
      } else {
        const indexOfName = name.indexOf(cleanup);
        const indexOfCode = code.indexOf(cleanup);

        if (indexOfName > -1 || indexOfCode > -1) {
          return occurrence.push(country);
        }
      }
    });

    return [...exact, ...occurrence];
  }, [list, query]);

  return sorted;
}
