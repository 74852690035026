import {
  FormProvider,
  useFormContext,
  useFormField,
  useFormStore,
} from "@sablier/v2-contexts";
import { _ } from "@sablier/v2-mixins";
import type { IStoreSelector } from "@sablier/v2-types";
import type { PropsWithChildren } from "react";

interface IForm {
  years: {
    id: string;
    value?: string;
  };
  days: {
    id: string;
    value?: string;
  };
  hours: {
    id: string;
    value?: string;
  };
}

const initial: IForm = {
  years: {
    id: "years",
    value: undefined,
  },
  days: {
    id: "days",
    value: undefined,
  },
  hours: {
    id: "hours",
    value: undefined,
  },
};

function Provider({ children }: PropsWithChildren<unknown>) {
  return <FormProvider<IForm> initial={initial}>{children}</FormProvider>;
}

function useAccessor() {
  return useFormContext<IForm>().getState;
}

function useForm<Slice>(selector: IStoreSelector<IForm, Slice>): Slice {
  return useFormStore(selector);
}

function useField<I extends keyof IForm>(id: I) {
  return useFormField<IForm, I>(id);
}

export { useAccessor, useField, useForm };

export default Provider;
