import styled from "styled-components";
import {
  Animated,
  Button,
  Label,
  Warning,
} from "@sablier/v2-components/molecules";
import { Input } from "@sablier/v2-components/organisms";
import {
  BACKWEIGHTED_YEARS_MAX,
  BACKWEIGHTED_YEARS_MIN,
} from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Ensurance } from "~/client/components/molecules";
import {
  useGroupEnsurance,
  useGroupFieldStart,
  useGroupFieldUnlocks,
  useGroupFieldUnlocksPrefill,
  useGroupFieldYears,
} from "./logic";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    width: 100%;
  }
`;

const ContentPartial = styled(Animated)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: calc(${(props) => props.theme.sizes.edge} * 1);
  width: 100%;
  padding: calc(${(props) => props.theme.sizes.edge} * 1);
  padding-inline: calc(${(props) => props.theme.sizes.edge} * 1);
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.dark100};
`;

const Header = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    justify-content: space-between;
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
    border-bottom: 2px solid ${(props) => props.theme.colors.border};

    *[data-component="button"] {
      cursor: pointer;
    }
  }
`;

const Title = styled(Label)`
  color: ${(props) => props.theme.colors.white};
`;

const Content = styled(ContentPartial)`
  & > * {
    grid-column: span 1;
  }
  & > ${Header}, & > *[data-single="true"] {
    grid-column: span 4;
  }

  ${(props) => props.theme.medias.maxLG} {
    grid-template-columns: 1fr 1fr;

    & > * {
      grid-column: span 1;
    }
    & > ${Header}, & > *[data-single="true"] {
      grid-column: span 2;
    }
  }

  ${(props) => props.theme.medias.maxSM} {
    grid-template-columns: 1fr;

    & > ${Header}, & > *[data-single="true"] {
      grid-column: span 1;
    }
  }
`;

function GroupUnlocks({ streamId }: { streamId: string }) {
  const { unlocks, onChangeYearPercentage, onBlurYearPercentage } =
    useGroupFieldUnlocks(streamId);
  const { onClick } = useGroupFieldUnlocksPrefill(streamId);
  const { t } = useT();

  return (
    <Wrapper data-single={true}>
      <Content
        duration={unlocks.field.value.length % 4 === 0 ? 0 : 300}
        easing={"ease-in"}
      >
        <Header data-single={true}>
          <Title value={_.capitalize(t("words.percentages"))} />
          <Button
            accent={"dark300"}
            appearance={"solid"}
            isMini
            onClick={onClick}
            title={
              unlocks.field.value.length === 0
                ? t("structs.resetToDefaultPercentages")
                : t("structs.prefillDefault")
            }
            titleShort={t("words.prefill")}
          />
        </Header>
        {unlocks.field.value.length === 0 && (
          <div data-single={true}>
            <Warning value={t("form.warning.backweightedYears")} />
          </div>
        )}
        {unlocks.field.value.map((unlock) => (
          <Input.AmountStepper
            key={unlock.year}
            label={{
              value: t("form.label.yearPercentageUnlock", {
                year: unlock.year,
              }),
            }}
            onBlur={() => onBlurYearPercentage(unlock.year)}
            onChange={(value) =>
              onChangeYearPercentage({ value, year: unlock.year })
            }
            id={`${unlocks.field.id}-${unlock.year}`}
            decimals={2}
            min={1}
            max={100}
            isEditable
            isBordered
            measure={"%"}
            placeholder={t("form.placeholder.percentageExample")}
            value={unlock.percentage}
            warning={unlock.warning}
          />
        ))}
      </Content>
    </Wrapper>
  );
}

const EnsuranceWrapper = styled.div`
  width: 100%;

  & > div > *[data-component="card"] {
    border: 2px solid ${(props) => props.theme.colors.dark050};
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.dark200};

    *[data-component="frame"] {
      border: 2px solid ${(props) => props.theme.colors.dark050};
      background-color: ${(props) => props.theme.colors.dark100};
    }
  }
`;

function GroupEnsurance({ streamId }: { streamId: string }) {
  const { isStepper, label, value } = useGroupEnsurance(streamId);
  return (
    isStepper && (
      <EnsuranceWrapper data-single={true}>
        <Ensurance purpose={"info"} label={label} value={value} />
      </EnsuranceWrapper>
    )
  );
}

function GroupStart({ streamId }: { streamId: string }) {
  const { onClick, start, label } = useGroupFieldStart(streamId);
  const { t } = useT();

  return (
    <Input.Moment
      id={`${start.field.id}-${streamId}`}
      label={label}
      onClick={onClick}
      placeholder={t("form.placeholder.moment")}
      value={start.field.value}
      warning={start.field.warning}
    />
  );
}

function GroupYears({ streamId }: { streamId: string }) {
  const { label, years, measure, onBlur, onChange, warning } =
    useGroupFieldYears(streamId);
  const { t } = useT();

  return (
    <Input.AmountStepper
      id={years.field.id}
      min={BACKWEIGHTED_YEARS_MIN}
      max={BACKWEIGHTED_YEARS_MAX}
      isEditable
      measure={measure}
      label={label}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={t("form.placeholder.months")}
      value={years.field!.value}
      warning={warning}
    />
  );
}

const Fields = {
  GroupYears,
  GroupStart,
  GroupUnlocks,
  GroupEnsurance,
};

export default Fields;
