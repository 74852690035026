import { useEffect, useRef } from "react";
import styled from "styled-components";
import { External } from "@sablier/v2-components/atoms";
import { links } from "@sablier/v2-constants";
import { Trans } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.column}

  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 3);
    width: 100%;
    padding: calc(${(props) => props.theme.sizes.edge} * 2 / 3);
    color: ${(props) => props.theme.colors.gray200};
    border: 2px solid rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.05);

    & > *[data-component="label"] {
      --size: 14px;
      color: ${(props) => props.theme.colors.yellow};
      label {
        font-size: 10pt;
      }
    }

    & ~ div {
      display: none;
    }
  }
`;

const Content = styled.div`
  & > p {
    ${(props) => props.theme.styles.textParagraphMini}
    & {
      font-size: 10pt;
      text-align: left;
      a {
        color: ${(props) => props.theme.colors.yellow};
        text-decoration: underline;
      }
    }
  }
`;

function Disclaimer() {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      const parent = ref.current.parentElement;
      const extra = parent?.previousElementSibling;
      if (extra) {
        (extra as HTMLDivElement).style.display = "none";
      }
    }
  }, []);

  return (
    <Wrapper ref={ref}>
      <Content>
        <p>
          <Trans
            i18nKey={"modalCards.connect.disclaimer"}
            components={[
              <External key={"0"} to={links.legal.risk} />,
              <External key={"1"} to={links.legal.privacy} />,
              <External key={"2"} to={links.legal.terms} />,
            ]}
          />
        </p>
      </Content>
    </Wrapper>
  );
}

export default Disclaimer;
