import { gql } from "../../generated/protocol/gql";

/**
 * AND (4)
 *
 * +==============================================================+
 * |                                                              |
 * |     █████╗ ███╗   ██╗██████╗      ██╗    ██╗  ██╗    ██╗     |
 * |    ██╔══██╗████╗  ██║██╔══██╗    ██╔╝    ██║  ██║    ╚██╗    |
 * |    ███████║██╔██╗ ██║██║  ██║    ██║     ███████║     ██║    |
 * |    ██╔══██║██║╚██╗██║██║  ██║    ██║     ╚════██║     ██║    |
 * |    ██║  ██║██║ ╚████║██████╔╝    ╚██╗         ██║    ██╔╝    |
 * |    ╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝      ╚═╝         ╚═╝    ╚═╝     |
 * |                                                              |
 * +==============================================================+
 *
 */

export const getStreams_BySenderByRecipientByIdsByToken = gql(/* GraphQL */ `
  query getStreams_BySenderByRecipientByIdsByToken(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $sender: Bytes!
    $recipient: Bytes!
    $token: String!
    $streamIds: [String!]
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        or: [
          {
            and: [
              { asset: $token }
              { sender: $sender }
              { recipient: $recipient }
              { id_in: $streamIds }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
          {
            and: [
              { asset: $token }
              { proxender: $sender }
              { recipient: $recipient }
              { id_in: $streamIds }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
        ]
      }
    ) {
      ...StreamFragment
    }
  }
`);

/**
 * AND (3)
 *
 * +==============================================================+
 * |                                                              |
 * |     █████╗ ███╗   ██╗██████╗      ██╗    ██████╗     ██╗     |
 * |    ██╔══██╗████╗  ██║██╔══██╗    ██╔╝    ╚════██╗    ╚██╗    |
 * |    ███████║██╔██╗ ██║██║  ██║    ██║      █████╔╝     ██║    |
 * |    ██╔══██║██║╚██╗██║██║  ██║    ██║      ╚═══██╗     ██║    |
 * |    ██║  ██║██║ ╚████║██████╔╝    ╚██╗    ██████╔╝    ██╔╝    |
 * |    ╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝      ╚═╝    ╚═════╝     ╚═╝     |
 * |                                                              |
 * +==============================================================+
 */

export const getStreams_BySenderByRecipientByIds = gql(/* GraphQL */ `
  query getStreams_BySenderByRecipientByIds(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $sender: Bytes!
    $recipient: Bytes!
    $streamIds: [String!]
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        or: [
          {
            and: [
              { sender: $sender }
              { recipient: $recipient }
              { id_in: $streamIds }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
          {
            and: [
              { proxender: $sender }
              { recipient: $recipient }
              { id_in: $streamIds }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
        ]
      }
    ) {
      ...StreamFragment
    }
  }
`);

export const getStreams_BySenderByIdsByToken = gql(/* GraphQL */ `
  query getStreams_BySenderByIdsByToken(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $sender: Bytes!
    $token: String!
    $streamIds: [String!]
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        or: [
          {
            and: [
              { asset: $token }
              { sender: $sender }
              { id_in: $streamIds }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
          {
            and: [
              { asset: $token }
              { proxender: $sender }
              { id_in: $streamIds }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
        ]
      }
    ) {
      ...StreamFragment
    }
  }
`);

export const getStreams_ByRecipientByIdsByToken = gql(/* GraphQL */ `
  query getStreams_ByRecipientByIdsByToken(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $recipient: Bytes!
    $token: String!
    $streamIds: [String!]
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        recipient: $recipient
        asset: $token
        id_in: $streamIds
        subgraphId_lt: $subgraphId
        chainId: $chainId
      }
    ) {
      ...StreamFragment
    }
  }
`);

export const getStreams_BySenderByRecipientByToken = gql(/* GraphQL */ `
  query getStreams_BySenderByRecipientByToken(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $sender: Bytes!
    $recipient: Bytes!
    $token: String!
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        or: [
          {
            and: [
              { asset: $token }
              { sender: $sender }
              { recipient: $recipient }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
          {
            and: [
              { asset: $token }
              { proxender: $sender }
              { recipient: $recipient }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
        ]
      }
    ) {
      ...StreamFragment
    }
  }
`);

/**
 * AND (2)
 *
 * +==============================================================+
 * |                                                              |
 * |     █████╗ ███╗   ██╗██████╗      ██╗    ██████╗     ██╗     |
 * |    ██╔══██╗████╗  ██║██╔══██╗    ██╔╝    ╚════██╗    ╚██╗    |
 * |    ███████║██╔██╗ ██║██║  ██║    ██║      █████╔╝     ██║    |
 * |    ██╔══██║██║╚██╗██║██║  ██║    ██║     ██╔═══╝      ██║    |
 * |    ██║  ██║██║ ╚████║██████╔╝    ╚██╗    ███████╗    ██╔╝    |
 * |    ╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝      ╚═╝    ╚══════╝    ╚═╝     |
 * |                                                              |
 * +==============================================================+
 */

export const getStreams_ByRecipientByIds = gql(/* GraphQL */ `
  query getStreams_ByRecipientByIds(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $recipient: Bytes!
    $streamIds: [String!]
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        recipient: $recipient
        id_in: $streamIds
        subgraphId_lt: $subgraphId
        chainId: $chainId
      }
    ) {
      ...StreamFragment
    }
  }
`);

export const getStreams_BySenderByIds = gql(/* GraphQL */ `
  query getStreams_BySenderByIds(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $sender: Bytes!
    $streamIds: [String!]
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        or: [
          {
            and: [
              { sender: $sender }
              { id_in: $streamIds }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
          {
            and: [
              { proxender: $sender }
              { id_in: $streamIds }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
        ]
      }
    ) {
      ...StreamFragment
    }
  }
`);

export const getStreams_BySenderByRecipient = gql(/* GraphQL */ `
  query getStreams_BySenderByRecipient(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $recipient: Bytes!
    $sender: Bytes!
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        or: [
          {
            and: [
              { sender: $sender }
              { recipient: $recipient }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
          {
            and: [
              { proxender: $sender }
              { recipient: $recipient }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
        ]
      }
    ) {
      ...StreamFragment
    }
  }
`);

export const getStreams_ByIdsByToken = gql(/* GraphQL */ `
  query getStreams_ByIdsByToken(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $token: String!
    $streamIds: [String!]
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        asset: $token
        id_in: $streamIds
        subgraphId_lt: $subgraphId
        chainId: $chainId
      }
    ) {
      ...StreamFragment
    }
  }
`);

export const getStreams_ByRecipientByToken = gql(/* GraphQL */ `
  query getStreams_ByRecipientByToken(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $recipient: Bytes!
    $token: String!
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        recipient: $recipient
        asset: $token
        subgraphId_lt: $subgraphId
        chainId: $chainId
      }
    ) {
      ...StreamFragment
    }
  }
`);

export const getStreams_BySenderByToken = gql(/* GraphQL */ `
  query getStreams_BySenderByToken(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $token: String!
    $sender: Bytes!
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        or: [
          {
            and: [
              { asset: $token }
              { sender: $sender }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
          {
            and: [
              { asset: $token }
              { proxender: $sender }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
        ]
      }
    ) {
      ...StreamFragment
    }
  }
`);

/**
 * OR (3)
 *
 * +=====================================================+
 * |                                                     |
 * |     ██████╗ ██████╗      ██╗    ██████╗     ██╗     |
 * |    ██╔═══██╗██╔══██╗    ██╔╝    ╚════██╗    ╚██╗    |
 * |    ██║   ██║██████╔╝    ██║      █████╔╝     ██║    |
 * |    ██║   ██║██╔══██╗    ██║      ╚═══██╗     ██║    |
 * |    ╚██████╔╝██║  ██║    ╚██╗    ██████╔╝    ██╔╝    |
 * |     ╚═════╝ ╚═╝  ╚═╝     ╚═╝    ╚═════╝     ╚═╝     |
 * |                                                     |
 * +=====================================================+
 */

export const getStreams_BySender_Or_ByRecipient_Or_ByToken = gql(/* GraphQL */ `
  query getStreams_BySender_Or_ByRecipient_Or_ByToken(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $recipient: Bytes!
    $sender: Bytes!
    $token: String!
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        or: [
          {
            and: [
              { sender: $sender }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
          {
            and: [
              { proxender: $sender }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
          {
            and: [
              { recipient: $recipient }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
          {
            and: [
              { asset: $token }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
        ]
      }
    ) {
      ...StreamFragment
    }
  }
`);

/**
 * OR (2)
 *
 * +=====================================================+
 * |                                                     |
 * |     ██████╗ ██████╗      ██╗    ██████╗     ██╗     |
 * |    ██╔═══██╗██╔══██╗    ██╔╝    ╚════██╗    ╚██╗    |
 * |    ██║   ██║██████╔╝    ██║      █████╔╝     ██║    |
 * |    ██║   ██║██╔══██╗    ██║     ██╔═══╝      ██║    |
 * |    ╚██████╔╝██║  ██║    ╚██╗    ███████╗    ██╔╝    |
 * |     ╚═════╝ ╚═╝  ╚═╝     ╚═╝    ╚══════╝    ╚═╝     |
 * |                                                     |
 * +=====================================================+
 */

export const getStreams_BySender_Or_ByRecipient = gql(/* GraphQL */ `
  query getStreams_BySender_Or_ByRecipient(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $recipient: Bytes!
    $sender: Bytes!
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        or: [
          {
            and: [
              { sender: $sender }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
          {
            and: [
              { proxender: $sender }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
          {
            and: [
              { recipient: $recipient }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
        ]
      }
    ) {
      ...StreamFragment
    }
  }
`);

/**
 * ONE
 *
 * +===================================+
 * |                                   |
 * |     ██████╗ ███╗   ██╗███████╗    |
 * |    ██╔═══██╗████╗  ██║██╔════╝    |
 * |    ██║   ██║██╔██╗ ██║█████╗      |
 * |    ██║   ██║██║╚██╗██║██╔══╝      |
 * |    ╚██████╔╝██║ ╚████║███████╗    |
 * |     ╚═════╝ ╚═╝  ╚═══╝╚══════╝    |
 * |                                   |
 * +===================================+
 */

export const getStreams_BySender = gql(/* GraphQL */ `
  query getStreams_BySender(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $sender: Bytes!
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        or: [
          {
            and: [
              { sender: $sender }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
          {
            and: [
              { proxender: $sender }
              { subgraphId_lt: $subgraphId }
              { chainId: $chainId }
            ]
          }
        ]
      }
    ) {
      ...StreamFragment
    }
  }
`);

export const getStreams_ByRecipient = gql(/* GraphQL */ `
  query getStreams_ByRecipient(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $recipient: Bytes!
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        recipient: $recipient
        subgraphId_lt: $subgraphId
        chainId: $chainId
      }
    ) {
      ...StreamFragment
    }
  }
`);

export const getStreams_ByIds = gql(/* GraphQL */ `
  query getStreams_ByIds(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $streamIds: [String!]
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        id_in: $streamIds
        subgraphId_lt: $subgraphId
        chainId: $chainId
      }
    ) {
      ...StreamFragment
    }
  }
`);

export const getStreams_ByToken = gql(/* GraphQL */ `
  query getStreams_ByToken(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $token: String!
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: { asset: $token, subgraphId_lt: $subgraphId, chainId: $chainId }
    ) {
      ...StreamFragment
    }
  }
`);

/**
 * GENERAL
 *
 * +===================================================================+
 * |                                                                   |
 * |     ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ██╗         |
 * |    ██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗██║         |
 * |    ██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║██║         |
 * |    ██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║██║         |
 * |    ╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║███████╗    |
 * |     ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝    |
 * |                                                                   |
 * +===================================================================+
 */

export const getStreams = gql(/* GraphQL */ `
  query getStreams(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $subgraphId: BigInt!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: { subgraphId_lt: $subgraphId, chainId: $chainId }
    ) {
      ...StreamFragment
    }
  }
`);

export const getStreamIdentifiers = gql(/* GraphQL */ `
  query getStreamIdentifiers($first: Int!, $skip: Int!, $chainId: BigInt!) {
    streams(
      first: $first
      skip: $skip
      orderBy: timestamp
      orderDirection: desc
      where: { chainId: $chainId }
    ) {
      id
      alias
    }
  }
`);

export const getActions_ByStream = gql(/* GraphQL */ `
  query getActions_ByStream(
    $first: Int!
    $streamId: String!
    $subgraphId: BigInt!
  ) {
    actions(
      first: $first
      orderBy: subgraphId
      orderDirection: desc
      where: { stream: $streamId, subgraphId_lt: $subgraphId }
    ) {
      ...ActionFragment
      stream {
        id
        asset {
          ...AssetFragment
        }
      }
    }
  }
`);

export const getStream_ById = gql(/* GraphQL */ `
  query getStream_ById($streamId: ID!) {
    stream(id: $streamId) {
      ...StreamFragment
    }
  }
`);

export const getStreamIds_ByToken = gql(/* GraphQL */ `
  query getStreamIds_ByToken(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $token: String!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: { asset: $token, chainId: $chainId }
    ) {
      id
    }
  }
`);

export const getStreamIds_ByRecipient = gql(/* GraphQL */ `
  query getStreamIds_ByRecipient(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $recipient: Bytes!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: { recipient: $recipient, chainId: $chainId }
    ) {
      id
    }
  }
`);

export const getStreamIds_BySender = gql(/* GraphQL */ `
  query getStreamIds_BySender(
    $first: Int!
    $skip: Int!
    $chainId: BigInt!
    $sender: Bytes!
  ) {
    streams(
      first: $first
      skip: $skip
      orderBy: subgraphId
      orderDirection: desc
      where: {
        or: [
          { sender: $sender, chainId: $chainId }
          { proxender: $sender, chainId: $chainId }
        ]
      }
    ) {
      id
    }
  }
`);
