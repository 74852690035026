import { _ } from "@sablier/v2-mixins";
import type { IToast } from "@sablier/v2-types";

export async function interpret(
  guard: () => string | undefined | Promise<string | undefined>,
  api?: { toast?: { add: (params: IToast) => void } },
) {
  const message = await guard();
  if (!_.isNil(message)) {
    if (api && api.toast && api.toast.add) {
      api.toast.add({
        id: "machine",
        message,
        type: "warning",
        duration: 3000,
      });
    }
    throw new Error(message);
  }
}
