import { useCallback, useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import { useShallow } from "zustand/react/shallow";
import type { IModal, RecursivePartial } from "@sablier/v2-types";
import useStore from "./store";

const fallback = <M extends IModal>(id: string) =>
  ({ id, isOpen: false, data: {} } as M);

export function useModal<D extends IModal["data"]>(id: string) {
  type M = IModal<D>;

  const api = useStore(useShallow((state) => state.api));
  const stored = useStore(useShallow((state) => state.items[id])) as M;

  const access = useStore.getState;
  const modal = useMemo(() => stored || fallback(id), [id, stored]);

  const { isOpen, data } = useMemo(() => modal, [modal]);

  const setOpen = useCallback(
    (state = true, data?: D) => {
      if (_.isNil(data)) {
        api.toggle(id, state);
      } else {
        api.set<M>(id, { isOpen: state, data });
      }
    },
    [api, id],
  );

  const updateData = useCallback(
    (data: RecursivePartial<D>) => {
      api.update<M>(id, { data });
    },
    [api, id],
  );

  const doClose = useCallback(() => {
    api.toggle(id, false);
  }, [api, id]);

  const doOpen = useCallback(() => {
    api.toggle(id, true);
  }, [api, id]);

  const doReset = useCallback(() => {
    api.reset();
  }, [api]);

  return {
    access,
    data,
    doClose,
    doOpen,
    doReset,
    id,
    isOpen,
    setOpen,
    updateData,
  };
}

export function useModals() {
  const access = useStore.getState;
  const api = useStore(useShallow((state) => state.api));

  const doReset = useCallback(() => {
    api.reset();
  }, [api]);

  return {
    access,
    doReset,
  };
}
