import styled, { css } from "styled-components";
import { _ } from "@sablier/v2-mixins";
import Icon from "~/components/molecules/Icon";
import type { ISInput } from "../system/templates";
import type { IInputOption } from "@sablier/v2-types";
import * as particles from "../system/particles";
import Template from "../system/templates";

interface ContainerProps {
  orientation: "row" | "column";
  count?: number;
}

const ContainerPartial = styled(particles.Container)<ContainerProps>`
  width: 100%;
  height: auto;
`;

const Box = styled(particles.Box)`
  position: relative;
  width: 100%;
  padding: 2px;
`;

const ItemPartial = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    align-items: flex-start;
    width: 100%;
    padding: 16px ${(props) => props.theme.sizes.inputEdge};
    column-gap: ${(props) => props.theme.sizes.inputEdge};
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.dark300};
  }
`;

const Iconic = styled.div`
  --size: 14px;
  color: ${(props) => props.theme.colors.white};
`;

const Indicator = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  margin-top: 1px;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    border: 2px solid ${(props) => props.theme.colors.primaryMiddle};
    border-radius: 50%;
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    row-gap: 8px;
    input {
      display: none;
    }
  }
`;

const Title = styled.div`
  & > label {
    ${(props) => props.theme.styles.textInput}
    & {
      margin: 0;
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

const Subtitle = styled.div`
  & > p {
    ${(props) => props.theme.styles.textParagraphMini}
    & {
      color: ${(props) => props.theme.colors.gray200};
    }
  }
`;

const Item = styled(ItemPartial)`
  &:not([data-active="true"]) {
    background-color: ${(props) => props.theme.colors.transparent};

    &:hover,
    &:active {
      background-color: ${(props) => props.theme.colors.dark100};
      cursor: pointer;
    }
    ${Title}, ${Subtitle} {
      p,
      label,
      ${Iconic} {
        color: ${(props) => props.theme.colors.gray400};
      }
    }

    ${Indicator} {
      &:after {
        border-color: ${(props) => props.theme.colors.gray400};
      }
    }
  }
`;

const Container = styled(ContainerPartial)`
  ${particles.Box} {
    display: grid;
    grid-template-columns: repeat(
      ${(props) => Math.min(props.count || 3, 3)},
      1fr
    );

    ${(props) =>
      props.orientation === "column" &&
      css`
        display: flex;
        flex-direction: column;
        grid-template-columns: none;
        > * {
          flex: 1;
        }
      `}

    ${(props) =>
      props.orientation === "row" &&
      css`
        & > * {
          grid-column: span 1;
        }
      `}
  }
`;

export interface Props
  extends Omit<ISInput, "left" | "onChange" | "onClick" | "right" | "value"> {
  className?: string;
  orientation?: "row" | "column";
  onChange: (value: IInputOption) => void;
  /** Keep the id unique so the current value's position can be successfully identified */
  source: IInputOption[];
  /** Keep the id unique so the current value's position can be successfully identified */
  value?: IInputOption;
}

function Radio({
  id,
  orientation = "row",
  source = [],
  value,
  ...props
}: Props) {
  return (
    <Template.Shell
      className={props.className}
      info={props.info}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      isLocked={props.isLocked}
      isPreview={props.isPreview}
      label={props.label}
      warning={props.warning}
    >
      <Container count={source.length} orientation={orientation}>
        <Box data-component={"box"}>
          {source.map((item) => (
            <Item
              key={item.id}
              data-active={value && item.id === value.id}
              onClick={() => props.onChange(item)}
            >
              {item.icon ? (
                <Iconic>
                  <Icon purpose={"heroicon"} value={item.icon} />
                </Iconic>
              ) : (
                <Indicator />
              )}
              <Content>
                <Title>
                  <label htmlFor={`${id}-${item.id}`}>{item.title}</label>
                </Title>
                {!_.isNilOrEmptyString(item.subtitle) && (
                  <Subtitle>
                    <p>{item.subtitle}</p>
                  </Subtitle>
                )}
                <input
                  checked={value && item.id === value.id}
                  id={`${id}-${item.id}`}
                  name={`${id}-${item.id}`}
                  readOnly
                  type={"radio"}
                  value={item.id}
                />
              </Content>
            </Item>
          ))}
        </Box>
      </Container>
    </Template.Shell>
  );
}

export default Radio;
