import { useMemo } from "react";
import { CheckIcon, EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import {
  AIRSTREAMS_RECIPIENTS_MAX_SIZE,
  AIRSTREAMS_RECIPIENTS_PAGE_SIZE,
  REQUEST_ID,
} from "@sablier/v2-constants";
import { useCopy } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { Papa, _ } from "@sablier/v2-mixins";
import { getTheme } from "@sablier/v2-themes";
import { vendors } from "@sablier/v2-utils";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import type { Table } from "@sablier/v2-components/organisms";
import type { ISTableCell } from "@sablier/v2-components/organisms/Table";
import type { IAddress, ITableOptions, ITokenDisplay } from "@sablier/v2-types";
import type { ComponentProps } from "react";
import useToast from "./useToast";
import useTokens from "./useTokens";

const theme = getTheme();

type Props = {
  token: ITokenDisplay | undefined;
  file: File | undefined;
  preview: number;
  onMore: (size: number) => void;
};

type Cells = [
  ISTableCell["Label"],
  ISTableCell["Text"],
  ISTableCell["Amount"],
  ISTableCell["Label"],
  ISTableCell["Actions"],
];

type Data = ComponentProps<typeof Table>["data"];

type Items = { address: IAddress; amount: string }[];

async function request({
  file,
  preview,
}: Pick<Props, "file" | "preview">): Promise<Items> {
  if (_.isNil(file)) {
    throw new Error("Missing file");
  }

  const { results } = await Papa.parseAsync(file, {
    header: true,
    // A function to execute before parsing the first chunk https://www.papaparse.com/docs#config
    beforeFirstChunk: (chunk) => {
      const lines = chunk.split("\n");
      // We want to check if the first line contains some info (For instance if it points to our docs) and if that is the case we want to remove it for the parsing process.
      if (lines[0].startsWith("Note")) {
        lines.shift(); // Remove the first line
      }
      return lines.join("\n");
    },
    fastMode: true,
    preview,
    skipEmptyLines: true,
    transform: (value) => value.trim(),
    transformHeader: (value) => value.trim(),
  });

  return results.data.map((line) => ({
    address: _.toAddress(_.get(line, "address")),
    amount: _.toString(_.get(line, "amount")),
  }));
}

function useData({
  file,
  preview,
  onMore,
}: Pick<Props, "file" | "preview" | "onMore">) {
  const isEnabled = useMemo(() => {
    return !_.isNilOrEmptyString(file);
  }, [file]);

  const { data, error, isLoading } = useQuery({
    queryKey: [
      ...REQUEST_ID.airstreamRecipientsFromFile,
      { unique: [file?.name, file?.lastModified, preview] },
    ],
    queryFn: async () => request({ file, preview }),
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: isEnabled,
    placeholderData: keepPreviousData,
    retry: false,
  });

  const recipients = useMemo(() => {
    return data || [];
  }, [data]);

  const options: ITableOptions = useMemo(() => {
    return { empty: undefined, row: theme.sizes.tableRowSlim };
  }, []);

  const isEmpty = useMemo(
    () => !isLoading && recipients.length === 0,
    [isLoading, recipients],
  );

  const doMore = useMemo(() => {
    /** If recipients did not hit the limit and we're evenly paginated, add to the preview */

    if (recipients && recipients.length < AIRSTREAMS_RECIPIENTS_MAX_SIZE) {
      if (recipients.length % AIRSTREAMS_RECIPIENTS_PAGE_SIZE === 0) {
        return () => onMore(recipients.length);
      }
    }
    return undefined;
  }, [recipients, onMore]);

  return {
    doMore,
    error,
    isAnimated: false,
    isEmpty,
    isLoading,
    options,
    recipients,
  };
}

export default function useAirstreamRecipientsTable(props: Props): Data {
  const { doMore, isAnimated, isEmpty, isLoading, options, recipients } =
    useData(props);

  const { t } = useT();
  const { find: findToken } = useTokens(false);
  const [, , doCopy] = useCopy();
  const toast = useToast();

  const columns = useMemo(() => {
    return [
      {
        id: "1",
        layout: "Label",
        title: "#",
        weight: "var(--dashboard-column-1)",
      },
      {
        id: "2",
        layout: "Text",
        title: _.capitalize(t("words.recipient")),
        weight: "var(--dashboard-column-2)",
      },
      {
        id: "3",
        layout: "Amount",
        title: _.capitalize(t("words.amount")),
        weight: "var(--dashboard-column-3)",
      },
      {
        id: "4",
        layout: "Label",
        title: "Pass",
        weight: "var(--dashboard-column-4)",
      },
      {
        id: "5",
        title: "",
        layout: "Actions",
        weight: "var(--dashboard-column-5)",
      },
    ];
  }, [t]);

  type Instructions = Data["instructions"];

  const instructions = useMemo(() => {
    const actions: Instructions = {};

    if (_.isFunction(doMore)) {
      actions.onMore = (_event) => {
        try {
          doMore();
        } catch (error) {
          vendors.crash.log(error);
        }
      };
    }

    return actions;
  }, [doMore]);

  const rows = useMemo(() => {
    const token = findToken(props.token || {});

    return recipients.map((row, index) => {
      const cells: Cells = [
        {
          value: {
            value: `${index + 1}.`,
          },
        },
        {
          value: _.toAddress(row.address),
        },
        {
          value: {
            token,
            value: row.amount,
          },
        },
        {
          value: {
            isIconLast: true,
            value: "Valid",
            icon: CheckIcon,
          },
        },
        {
          value: {
            buttons: [
              {
                right: EllipsisHorizontalIcon,
                title: "",
                appearance: "outline",
                accent: "iconic",
                isMenu: true,
                isMini: true,
                menu: [
                  {
                    group: "custom",
                    items: [
                      {
                        title: t("structs.copyAddress"),
                        onClick: () => {
                          doCopy(row.address);
                          toast.add({
                            duration: 3500,
                            id: "identifier",
                            type: "success",
                            message: t("structs.copiedAddress", {
                              address: _.toShortAddress(row.address),
                            }),
                          });
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      ];

      return {
        id: row.address,
        cells,
      };
    });
  }, [doCopy, findToken, props, recipients, t, toast]);

  return {
    columns,
    instructions,
    options,
    rows,
    isAnimated,
    isEmpty,
    isLoading,
  };
}
