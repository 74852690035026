import { StreamShapes, links } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import type {
  IExtensionConstructorParams,
  IExtensionHeaders,
} from "../../system/setup";
import type { IMilliseconds } from "@sablier/v2-types";
import type {
  IExtensionExclude,
  IExtensionOverridesGeneral,
  IExtensionOverridesIndividual,
} from "~/client/types";
import { Extension } from "../../system/setup";

export interface IUnlock {
  year: string;
  percentage: string | undefined;
  warning?: string;
}

// every time the year field changes we prefill with an initial set of values
export const defaultBackweightedDistributions: { [key: string]: IUnlock[] } = {
  "1": [{ year: "1", percentage: "100" }],
  "2": [
    { year: "1", percentage: "30" },
    { year: "2", percentage: "70" },
  ],
  "3": [
    { year: "1", percentage: "10" },
    { year: "2", percentage: "30" },
    { year: "3", percentage: "60" },
  ],
  "4": [
    { year: "1", percentage: "10" },
    { year: "2", percentage: "20" },
    { year: "3", percentage: "30" },
    { year: "4", percentage: "40" },
  ],
  "5": [
    { year: "1", percentage: "5" },
    { year: "2", percentage: "10" },
    { year: "3", percentage: "15" },
    { year: "4", percentage: "25" },
    { year: "5", percentage: "45" },
  ],
  "6": [
    { year: "1", percentage: "5" },
    { year: "2", percentage: "5" },
    { year: "3", percentage: "10" },
    { year: "4", percentage: "15" },
    { year: "5", percentage: "20" },
    { year: "6", percentage: "45" },
  ],
  "7": [
    { year: "1", percentage: "2" },
    { year: "2", percentage: "3" },
    { year: "3", percentage: "5" },
    { year: "4", percentage: "10" },
    { year: "5", percentage: "15" },
    { year: "6", percentage: "20" },
    { year: "7", percentage: "45" },
  ],
  "8": [
    { year: "1", percentage: "2" },
    { year: "2", percentage: "3" },
    { year: "3", percentage: "5" },
    { year: "4", percentage: "10" },
    { year: "5", percentage: "15" },
    { year: "6", percentage: "20" },
    { year: "7", percentage: "20" },
    { year: "8", percentage: "25" },
  ],
  "9": [
    { year: "1", percentage: "2" },
    { year: "2", percentage: "3" },
    { year: "3", percentage: "5" },
    { year: "4", percentage: "5" },
    { year: "5", percentage: "10" },
    { year: "6", percentage: "15" },
    { year: "7", percentage: "20" },
    { year: "8", percentage: "20" },
    { year: "9", percentage: "20" },
  ],
  "10": [
    { year: "1", percentage: "1" },
    { year: "2", percentage: "2" },
    { year: "3", percentage: "3" },
    { year: "4", percentage: "5" },
    { year: "5", percentage: "10" },
    { year: "6", percentage: "15" },
    { year: "7", percentage: "15" },
    { year: "8", percentage: "15" },
    { year: "9", percentage: "15" },
    { year: "10", percentage: "19" },
  ],
};

export function getDefaultDistribution(year: string): IUnlock[] {
  return defaultBackweightedDistributions[year] || [];
}

export interface ITranchedBackweighted {
  purpose: Extension;
  /** Sourcing `end` from the base form */
  /** Sourcing `start` from the base form */
  years: {
    id: string;
    value?: string;
    warning?: string;
  };
  unlocks: {
    id: string;
    value: IUnlock[];
    warning?: string;
  };
}

export const excludes: IExtensionExclude[] = ["duration", "end", "timing"];

export function initial({
  years,
}: IExtensionConstructorParams): ITranchedBackweighted {
  const value = years ?? "4";
  const unlocks = getDefaultDistribution(value);
  return {
    purpose: Extension.TRANCHED_BACKWEIGHTED,
    years: {
      id: "years",
      value,
      warning: undefined,
    },
    unlocks: {
      id: "unlocks",
      value: unlocks,
    },
  };
}

/**
 * Override the duration and lock it to "false".
 * This will force the validation steps to look at the range (start / end).
 */

export const overrides = {
  general: (): IExtensionOverridesGeneral | undefined => {
    return {
      duration: {
        isActive: false,
      },
      isDuration: {
        value: false,
      },
    };
  },
  individual: (): IExtensionOverridesIndividual | undefined => {
    return {
      duration: {
        isActive: false,
      },
    };
  },
};

export function headers(
  _isDuration: boolean,
): IExtensionHeaders<keyof ITranchedBackweighted> {
  return [
    { header: "years", type: "integer" },
    { header: "unlocks", type: "percentages" },
  ];
}

export function warnings(extension: unknown): string[] {
  const fields = extension as ITranchedBackweighted;
  const warnings: string[] = [];
  if (!_.isNilOrEmptyString(fields.years.warning)) {
    warnings.push(`Months: ${fields.years.warning}`);
  }
  return warnings;
}

export const UNLOCK_DURATION: IMilliseconds = "1000";

export const shape = StreamShapes.tranchedBackweighted.id;
export const template = links.streamTemplate.tranchedBackweighted;
