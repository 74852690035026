import { PERCENTAGE_DECIMALS, ZERO } from "@sablier/v2-constants";
import { StreamVersion } from "@sablier/v2-constants";
import { BigNumber, _ } from "@sablier/v2-mixins";
import type { StreamCategory, StreamShape } from "@sablier/v2-constants";
import type {
  IAddress,
  IAirstreamId,
  IMilliseconds,
  ISeconds,
  IValue,
} from "@sablier/v2-types";
import AirstreamAction from "../AirstreamAction";
import Stream from "../Stream";
import Token from "../Token";

/** * ------------------------------
 * The "Attributes" part of the Airstream class will
 * include only data fields (and getters/setters)
 * ------------------------------
 * Inheritance is not used here for OOP purposes,
 * but for readability (smaller class parts)
 * ------------------------------
 */

export type Params = {
  id: string;
  address: string;
  chainId: number;
  /** --------------- */
  category: string | StreamCategory;
  admin: string;
  lockup: IAddress;
  root: string;
  expires: boolean;
  expiration?: string | undefined;
  factory: {
    address: IAddress;
  };
  /** --------------- */
  ipfsCID: string;
  aggregateAmount: string;
  totalRecipients: string;
  claimedAmount?: string;
  claimedCount?: string;
  clawbackTime?: ISeconds | undefined;
  streamCliff: boolean;
  streamCliffDuration?: ISeconds | undefined;
  streamTotalDuration: ISeconds;
  streamCancelable: boolean;
  streamTransferable: boolean;
  streamTranches?: {
    id: string;
    position: string;
    /** --------------- */
    percentage: string;
    duration: ISeconds;
    /** --------------- */
    endPercentage: string;
    endDuration: ISeconds;
    startPercentage: string;
    startDuration: ISeconds;
  }[];

  /** --------------- */
  subgraphId: string;
  hash: string;
  name: string;
  timestamp: ISeconds;
  version?: string | StreamVersion;
};

export default class Attributes {
  /**
   * ------------------------------
   * NATIVE ATTRIBUTES
   * ------------------------------
   */

  readonly id: IAirstreamId;
  readonly chainId: number;
  readonly address: IAddress;
  /* --------------- */
  readonly category: StreamCategory;
  readonly admin: IAddress;
  readonly factory: IAddress;
  readonly lockup: IAddress;
  readonly expires: boolean;
  readonly token: Token;
  readonly expiration: IMilliseconds | undefined;
  readonly version: StreamVersion;

  /** --------------- */
  readonly root: string;
  readonly ipfsCID: string;
  readonly name: string | undefined;
  readonly aggregateAmount: IValue;
  readonly totalRecipients: number;
  readonly claimedAmount: IValue;
  readonly claimedCount: number;
  readonly clawbackTime: IMilliseconds | undefined;
  readonly streamCliff: boolean;
  readonly streamCliffDuration: IMilliseconds | undefined;
  readonly streamTotalDuration: IMilliseconds;
  readonly streamCancelable: boolean;
  readonly streamTransferable: boolean;
  readonly streamTranches: {
    percentage: IValue;
    duration: IMilliseconds;
  }[];

  streamShape: StreamShape | undefined;

  subgraphId?: string;
  hash?: string;
  timestamp?: IMilliseconds;

  /* --------------- */
  actions: AirstreamAction[] = [];
  streams: Stream[] = [];

  /**
   * ------------------------------
   * DERIVED | PRIVATE ATTRIBUTES
   * ------------------------------
   *
   * Most of these attributes (e.g. status)
   * will be kept fresh by the Stream.doUpdate() method.
   */

  readonly duration: IMilliseconds;
  readonly claimedAmountPercentage: BigNumber;
  readonly claimedCountPercentage: BigNumber;
  readonly instantiated: IMilliseconds;

  protected _timeSinceExpiration: IMilliseconds;

  constructor(params: Params, token: Token) {
    /**
     * ------------------------------
     * NATIVE ASSIGNMENTS
     * ------------------------------
     */

    this.id = params.id.toLowerCase();
    this.address = _.toAddress(params.address);
    this.chainId = _.toNumber(params.chainId);
    this.token = token;
    this.instantiated = Date.now().toString();

    /** --------------- */

    this.category = params.category as StreamCategory;
    this.admin = _.toAddress(params.admin);
    this.factory = _.toAddress(params.factory.address);
    this.lockup = _.toAddress(params.lockup);
    this.root = params.root;
    this.ipfsCID = params.ipfsCID;
    this.name = !_.isNilOrEmptyString(params.name) ? params.name : undefined;

    this.subgraphId = params.subgraphId;
    this.hash = params.hash;
    this.timestamp = _.toMilliseconds(params.timestamp);

    this.expires = !!params.expires;
    this.expiration = _.toMilliseconds(this.expires ? params.expiration : "0");

    this.claimedAmount = _.toValue({
      decimals: token.decimals,
      raw: params.claimedAmount,
    });
    this.aggregateAmount = _.toValue({
      decimals: token.decimals,
      raw: params.aggregateAmount,
    });
    this.claimedCount = _.toNumber(params.claimedCount);
    this.totalRecipients = _.toNumber(params.totalRecipients);
    this.clawbackTime = _.toMilliseconds(
      !_.isNilOrEmptyString(params.clawbackTime) ? params.clawbackTime : "0",
    );

    this.streamCliff = !!params.streamCliff;
    this.streamCliffDuration = _.toMilliseconds(
      this.streamCliff ? params.streamCliffDuration : "0",
    );
    this.streamTotalDuration = _.toMilliseconds(params.streamTotalDuration);
    this.streamCancelable = !!params.streamCancelable;
    this.streamTransferable = !!params.streamTransferable;
    this.version = (params.version as StreamVersion) || StreamVersion.V22;

    this.streamTranches = (params.streamTranches || []).map((t) => ({
      percentage: _.toValue({
        raw: t.percentage,
        decimals: PERCENTAGE_DECIMALS,
      }),
      duration: _.toMilliseconds(t.duration),
    }));

    /**
     * ------------------------------
     * DERIVED ASSIGNMENTS
     * ------------------------------
     */

    const zero = ZERO(this.token.decimals);

    this.duration = this.expires
      ? new BigNumber(this.expiration)
          .minus(new BigNumber(this.timestamp))
          .toString()
      : "0";

    this.claimedAmountPercentage = new BigNumber(this.claimedAmount.humanized)
      .times(100)
      .dividedBy(this.aggregateAmount.humanized);

    this.claimedCountPercentage = new BigNumber(this.claimedCount)
      .times(100)
      .dividedBy(this.totalRecipients);

    this._timeSinceExpiration = zero.raw.toString();
  }

  get timeSinceExpiration() {
    return this._timeSinceExpiration;
  }

  get expired() {
    if (!this.expires) {
      return false;
    }
    return (
      !_.isNilOrEmptyString(this.timeSinceExpiration) &&
      new BigNumber(this.timeSinceExpiration).isPositive()
    );
  }

  get isAlive() {
    if (!this.expires) {
      return true;
    }
    return new BigNumber(this.timeSinceExpiration).isNegative();
  }
}
