import { _ } from "@sablier/v2-mixins";
import toast from "react-hot-toast";
import { External } from "~/components/atoms";
import type { IToast } from "@sablier/v2-types";

function Content({ link, message }: Pick<IToast, "link" | "message">) {
  return (
    <p>
      <span>
        {message}{" "}
        {!_.isNil(link) && <External to={link.to}>{link.label}</External>}
      </span>
    </p>
  );
}

function add({ duration = 5000, id, link, message, type }: IToast) {
  switch (type) {
    case "fail":
      toast.error(<Content link={link} message={message} />, {
        id,
        duration,
      });
      break;
    case "loading":
      toast.loading(<Content link={link} message={message} />, {
        id,
        duration,
      });
      break;

    case "success":
      toast.success(<Content link={link} message={message} />, {
        id,
        duration,
      });
      break;
    case "warning":
      toast(<Content link={link} message={message} />, {
        id,
        duration,
      });
      break;
    default:
      break;
  }
}

function remove(id: string) {
  toast.dismiss(id);
}

function removeAll() {
  toast.dismiss();
}

export { add, remove, removeAll };
