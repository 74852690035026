import { StreamShapes, links } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import type {
  IExtensionConstructorParams,
  IExtensionHeaders,
} from "../../system/setup";
import type { IMilliseconds } from "@sablier/v2-types";
import { Extension } from "../../system/setup";

export interface IDynamicUnlockCliff {
  purpose: Extension;
  unlockDuration: {
    id: string;
    value?: IMilliseconds;
    warning?: string;
  };
  unlockEnd: {
    id: string;
    value?: IMilliseconds;
    warning?: string;
  };
  unlock: {
    id: string;
    value?: string;
    warning?: string;
  };
}

export function initial({
  cliffDuration,
  cliffEnd,
  unlock,
}: IExtensionConstructorParams): IDynamicUnlockCliff {
  return {
    purpose: Extension.DYNAMIC_UNLOCK_CLIFF,
    unlockDuration: {
      id: "cliffDuration",
      value: cliffDuration,
      warning: undefined,
    },
    unlockEnd: {
      id: "cliffEnd",
      value: cliffEnd,
      warning: undefined,
    },
    unlock: {
      id: "unlock",
      value: unlock,
      warning: undefined,
    },
  };
}

export const shape = StreamShapes.dynamicUnlockCliff.id;
export const UNLOCK_DURATION: IMilliseconds = "1000";
export const EXPECTED_SEGMENTS = 4;

export function headers(
  isDuration: boolean,
): IExtensionHeaders<keyof IDynamicUnlockCliff> {
  return isDuration
    ? [
        { header: "unlockDuration", type: "duration" },
        { header: "unlock", type: "amount" },
      ]
    : [
        { header: "unlockEnd", type: "date" },
        { header: "unlock", type: "amount" },
      ];
}

export function warnings(extension: unknown): string[] {
  const fields = extension as IDynamicUnlockCliff;
  const warnings: string[] = [];
  if (!_.isNilOrEmptyString(fields.unlockDuration.warning)) {
    warnings.push(`Unlock duration: ${fields.unlockDuration.warning}`);
  }
  if (!_.isNilOrEmptyString(fields.unlockEnd.warning)) {
    warnings.push(`Unlock end: ${fields.unlockEnd.warning}`);
  }
  if (!_.isNilOrEmptyString(fields.unlock.warning)) {
    warnings.push(`Unlock amount: ${fields.unlock.warning}`);
  }
  return warnings;
}

export const template = links.streamTemplate.dynamicUnlockCliff;
