import { useMemo } from "react";
import { Characteristic } from "@sablier/v2-components/molecules";
import {
  AIRSTREAMS_PAGE_SIZE,
  AirstreamActionCategory,
} from "@sablier/v2-constants";
import {
  useResolvedTypedAddress,
  useShortAddress,
  useShortName,
} from "@sablier/v2-hooks";
import { useResolvedENS } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type {
  IAddress,
  IChainDisplay,
  IMilliseconds,
  IToken,
  ITokenDisplay,
} from "@sablier/v2-types";
import type { ComponentProps } from "react";

type Description = ComponentProps<typeof Characteristic>["description"];

interface FilterAirstreamProps {
  chain: IChainDisplay | undefined;
  admin: IAddress | undefined;
  isAlive: boolean | undefined;
  found: number | undefined;
  token: ITokenDisplay | undefined;
}

export function FilterAirstream({
  admin,
  chain,
  isAlive,
  token,
  found = 0,
}: FilterAirstreamProps) {
  const { t } = useT();
  const a = useResolvedTypedAddress(admin, chain?.chainId);

  const description = useMemo((): Description => {
    const prefix =
      found === 1
        ? t("characteristic.listingFirst")
        : found >= AIRSTREAMS_PAGE_SIZE
        ? t("characteristic.listingFirsts", { count: found })
        : `${t("characteristic.listingResults")}`;

    const list: Description = [
      {
        purpose: "content",
        options: {
          value: prefix,
        },
      },
      {
        purpose: "chain",
        options: {
          image: chain?.image,
          name: chain?.name,
        },
      },
      {
        purpose: "content",
        options: {
          value: t("words.airstreams"),
        },
      },
    ];

    if (!_.isNilOrEmptyString(token)) {
      list.push(
        {
          purpose: "content",
          options: {
            value: t("words.with"),
          },
        },
        {
          purpose: "amount",
          options: {
            isValueHidden: true,
            token,
          },
        },
      );
    }

    if (!_.isNilOrEmptyString(a.address) && _.isEthereumAddress(a.address)) {
      list.push(
        {
          purpose: "content",
          options: {
            value: t("words.involving"),
          },
        },
        {
          purpose: "copy-address",
          options: {
            isIconShown: false,
            preview: a.preview,
            toCopy: a.address,
            value: a.address,
          },
        },
      );
    }

    if (isAlive === true) {
      list.push({
        purpose: "content",
        options: {
          value: `still ongoing`,
        },
      });
    }

    return list;
  }, [a, chain, isAlive, found, token, t]);

  return <Characteristic description={description} />;
}

interface AirstreamHistoryProps {
  action: AirstreamActionCategory;
  address: IAddress | undefined;
  amount: string | undefined;
  chainId: number;
  end?: IMilliseconds | undefined;
  token: IToken | undefined;
}

export function AirstreamHistory({
  action,
  address,
  amount,
  chainId,
  end,
  token,
}: AirstreamHistoryProps) {
  const { data: ens } = useResolvedENS({
    address,
    chainId,
  });
  const shortAddress = useShortAddress(address, 4, -4);

  const description = useMemo((): Description => {
    const builder: Description = [];
    builder.push(
      {
        purpose: "label",
        options: {
          icon: address,
          iconPurpose: "identicon",
        },
      },
      {
        purpose: "copy",
        options: {
          value: shortAddress,
          toCopy: address,
        },
      },
    );

    if (ens) {
      builder.push({
        purpose: "content",
        options: {
          value: `(${ens})`,
        },
      });
    }

    if (action === "Claim") {
      builder.push(
        {
          purpose: "content",
          options: {
            value: "claimed a stream of",
          },
        },
        {
          purpose: "amount",
          options: {
            token,
            value: amount,
          },
        },
        ...(!_.isNilOrEmptyString(end)
          ? [
              {
                purpose: "content" as const,
                options: {
                  value: "ending",
                },
              },
              {
                purpose: "content" as const,
                options: {
                  value: _.toDuration(end, "date-only")[0],
                },
              },
            ]
          : []),
      );
    } else if (action === "Clawback") {
      builder.push(
        {
          purpose: "content",
          options: {
            value: "activated a clawback for",
          },
        },
        {
          purpose: "amount",
          options: {
            token,
            value: amount,
          },
        },
      );
    } else if (action === "Create") {
      builder.push({
        purpose: "content",
        options: {
          value: "created this airstream campaign",
        },
      });
    }

    return builder;
  }, [address, action, amount, end, ens, shortAddress, token]);

  return <Characteristic description={description} />;
}

interface EligibilityProps {
  address: IAddress | undefined;
  chainId: number | undefined;
}

export function Eligibility({ address, chainId }: EligibilityProps) {
  const { t } = useT();
  const { data: ens } = useResolvedENS({
    address,
    chainId,
  });
  const shortAddress = useShortAddress(address);
  const shortENS = useShortName(ens || undefined);

  const description = useMemo((): Description => {
    const builder: Description = [];
    builder.push(
      {
        purpose: "content",
        options: {
          value: t("structs.checkEligibilityFor"),
        },
      },
      !_.isNilOrEmptyString(address)
        ? {
            purpose: "address",
            options: {
              preview: shortAddress,
              value: address,
            },
          }
        : {
            purpose: "content",
            options: {
              value: t("words.address"),
            },
          },
    );

    if (ens) {
      builder.push({
        purpose: "content",
        options: {
          value: `(${shortENS})`,
        },
      });
    }

    return builder;
  }, [address, shortAddress, shortENS, ens, t]);

  return <Characteristic description={description} />;
}
